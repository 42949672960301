import { Box, TextField, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import ABSelect from "../../../../../Components/ABSelect";

export default function CreditForm(): ReactElement {
  return (
    <Box display="flex" flexDirection="column">
      {/* name */}
      <Typography variant="formText" color="primary" mt={4}>
        What do you want to name this
      </Typography>

      <TextField name="display_name" id="display_name" label="Display Name" variant="standard" />

      {/* limit */}
      <Typography color="primary" mt={4}>
        What is your credit limit
      </Typography>
      <TextField name="limit" id="limit" label="Limit" variant="standard" />

      {/* Owe */}
      <Typography color="primary" mt={4}>
        If you already have this credit card, how much do you owe?
      </Typography>
      <TextField name="outstand_balance" id="outstand_balance" label="Outstanding Balance" variant="standard" />

      {/* plan */}
      <Typography color="primary" mt={4}>
        How much do you plan to use this card
      </Typography>
      <ABSelect name="Rarely" />

      {/* balance */}
      <Typography color="primary" mt={4}>
        How much of your balance od you intend to pay each month
      </Typography>
      <ABSelect name="All of it" />
      
    </Box>
  );
}
