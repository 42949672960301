import DateFnsUtils from '@date-io/date-fns';
import { Box, Grid } from '@material-ui/core';
import { KeyboardDatePicker,MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React, { Fragment } from 'react';
import { AnalysisContext } from '../../../../../Hooks/AnalysisContext';

function DatePicker() {
  const { filter, updateFilter } = React.useContext(AnalysisContext);

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    const dateString = moment(date).format('YYYY MM DD');
    updateFilter({ ...filter, start_date: dateString });
  };

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    const dateString = moment(date).format('YYYY MM DD');
    updateFilter({ ...filter, end_date: dateString });
  };

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Box m={1}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                size="small"
                fullWidth
                inputVariant="outlined"
                label="Start Date"
                format="MM/dd/yyyy"
                value={filter?.start_date}
                InputAdornmentProps={{ position: 'start' }}
                onChange={handleStartDateChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box m={1}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                size="small"
                fullWidth
                inputVariant="outlined"
                label="Start Date"
                format="MM/dd/yyyy"
                value={filter?.end_date}
                InputAdornmentProps={{ position: 'start' }}
                onChange={handleEndDateChange}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box m={1}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              size="small"
              fullWidth
              inputVariant="outlined"
              label="Start Date"
              format="MM/dd/YYYY"
              value={filter?.end_date}
              InputAdornmentProps={{ position: 'start' }}
              onChange={handleEndDateChange}
            />
          </Box>
        </Grid>       
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}

export default DatePicker;
