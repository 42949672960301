import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import React from 'react';
import button from './button';
import palette from './palette';
import typography from './typograpy';
// For light theme

const mainColor = '#0D8ABC';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    disabled: {
      dark: string;
      main: string;
      light: string;
    };
  }
  interface PaletteOptions {
    disabled?: {
      dark: string;
      main: string;
      light: string;
    };
  }
}
declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    formText: React.CSSProperties;
    labelText: React.CSSProperties;
    tableHeader: React.CSSProperties;
    sbTextL: React.CSSProperties;
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    formText?: React.CSSProperties;
    labelText?: React.CSSProperties;
    tableHeader?: React.CSSProperties;
    sbTextL?: React.CSSProperties;
  }
}

declare module '@material-ui/core/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    formText: true;
    labelText: true;
    sbTextL: true;
    tableHeader: true;
  }
}

// A custom theme for this app
const lightTheme = createMuiTheme({
  components: {
    MuiButton: button,
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: '#0E5A7B'
        },
        input: {
          color: '#0E5A7B',
          outline: 'none'
        },

        underline: {
          '&:before': {
            borderBottom: '1px solid #DAEAF6',
            outline: 'none'
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid #0d8abc',
            outline: 'none'
          }
        }
      }
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#0E5A7B',
          outline: 'none'
        }
      }
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#0E5A7B',
          outline: 'none',
          opacity: 0.5,
          '&.Mui-focused': {
            opacity: 1
          }
        }
      }
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          color: mainColor
        }
      }
    },
    MuiListItem: {},
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: mainColor,
          fontSize: '1rem',
          borderBottom: 'solid 1px #DAEAF6'
        }
      }
    },
    MuiDialog: {
      defaultProps: {
        BackdropProps: {
          style: {
            backgroundColor: 'rgba(82, 82, 82, 0.15)',
            backdropFilter: 'blur(5px)'
          }
        }
      },
      styleOverrides: {
        paper: {
          borderRadius: 20
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: mainColor
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: '#E4EFF7 '
        }
      }
    },

    // Table Style

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: ' 1px solid #F6F6F9'
        },
        head: {
          color: mainColor
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          opacity: 1
        }
      }
    },

    // Divider
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.03)'
        }
      }
    },

    // Tooltip
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 2,
          fontSize: 14,
          padding: '15px',
          border: 'none'
        }
      }
    },

    // Material lab
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          // color: mainColor,
          borderBottom: 'none',
          backgroundColor: '#FFF',
          outline: 'none',
          '&:hover': {
            backgroundColor: '#DAEAF6',
            outline: 'none'
          },

          '&[aria-selected=true]': {
            borderColor: 'transparent',
            backgroundColor: '#FFF',
            outline: 'none'
          }
        },
        paper: {
          backgroundColor: '#FFF',
          outline: 'none'
        },
        listbox: {
          borderBottom: '2px solid #DAEAF6',
          backgroundColor: '#FFF',
          outline: 'none'
        }
      }
    }
  },
  spacing: 5,
  palette: palette,

  //Material UI Typography Theme
  typography: typography
});

const darkTheme = createMuiTheme({
  palette: {
    // type: "dark",
  }
});

export const light = responsiveFontSizes(lightTheme);
export const dark = responsiveFontSizes(darkTheme);
