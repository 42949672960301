/* eslint-disable no-use-before-define */
import { enGB } from 'date-fns/locale';
import moment from 'moment';
import React, { useState } from 'react';
import { DateRangePicker, END_DATE, START_DATE } from 'react-nice-dates';
import { AnalysisContext } from '../../../../Hooks/AnalysisContext';
import './datePicker.scss';

export default function DateRange({ reset = 0 }) {
  const { filter, updateFilter } = React.useContext(AnalysisContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleSelect = () => {
    if (startDate === null && endDate !== null) {
      updateFilter({
        ...filter,
        end_date: endDate === null ? '' : moment(endDate).format('YYYY-MM-DD')
      });
    } else if (endDate === null && startDate !== null) {
      updateFilter({
        ...filter,
        start_date: startDate === null ? '' : moment(startDate).format('YYYY-MM-DD'),
        end_date: endDate === null ? '' : moment(endDate).format('YYYY-MM-DD')
      });
    } else {
      updateFilter({
        ...filter,
        start_date: startDate === null ? '' : moment(startDate).format('YYYY-MM-DD'),
        end_date: endDate === null ? '' : moment(endDate).format('YYYY-MM-DD')
      });
    }
    // console.log(filter);
  };
  const CalendarIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
    );
  };

  return (
    <React.Fragment>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        maximumDate={new Date()}
        minimumLength={0}
        format="MM/dd/yyyy"
        locale={enGB}
      >
        {({ startDateInputProps, endDateInputProps, focus }) => (
          <div className="flex flex-row space-x-4">
            <div className="flex flex-row h-16 w-full">
              <div className="date-range w-full">
                <input
                  className={`w-full p-4 h-full border border-abx-blue text-white focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue ${
                    'input' + (focus === START_DATE ? ' -focused' : '')
                  }`}
                  {...startDateInputProps}
                  placeholder="Start date"
                />
              </div>
              <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
                <CalendarIcon />
              </div>
            </div>
            <span className="flex items-center justify-center">to</span>
            <div className="flex flex-row h-16 w-full bg-abx-pink">
              <div className="date-range w-full">
                <input
                  className={`w-full p-4 h-full text-white border border-abx-blue focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue ${
                    'input' + (focus === END_DATE ? ' -focused' : '')
                  }`}
                  {...endDateInputProps}
                  placeholder="End date"
                />
              </div>
              <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
                <CalendarIcon />
              </div>
            </div>
          </div>
        )}
      </DateRangePicker>
    </React.Fragment>
  );
}
