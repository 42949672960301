import { Box, Button, makeStyles } from '@material-ui/core';
import React from 'react';
import ReactDependentScript from 'react-dependent-script';
import { ReactComponent as OndriveIcon } from '../../../../../Components/assets/one_drive.svg';
import { useIntegrateStore } from '../../../hooks/IntegrateStore';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: '1px dashed',
    padding: '20px 50px',
    '&:hover': {
      opacity: 0.5,
      cursor: 'pointer'
    },
    display: 'flex',
    justifyContent: 'center',
    flexBasis: '100%'
  }
}));

const OneDrive = React.forwardRef((props, ref) => {
  const { setCurrentFileId, setCurrentFileName } = useIntegrateStore();

  var odOptions = {
    clientId: process.env.REACT_APP_ONE_DRIVE_CLIENT_ID,
    action: 'query',
    multiSelect: true,
    success: function (files) {
      setCurrentFileId(files.value[0].id);
    },
    advanced: {
      redirectUri: process.env.REACT_APP_ONE_DRIVE_RETURN_URI,
      endpointHint: 'api.onedrive.com',
      queryParameters: 'name,select=id,size,file,folder,photo,@microsoft.graph.downloadUrl',
      filter: 'folder,.xls,.xlsx' /* display folder and files with extension '.png' only */
    },
    cancel: function () {
      /* cancel handler */
      console.log('calcel');
    },
    error: function (error) {
      /* error handler */
      console.log(error);
    }
  };

  const classes = useStyles();

  return (
    <div>
      <ReactDependentScript
        loadingComponent={<Button variant="contained">Loading ...</Button>}
        scripts={['https://js.live.net/v7.2/OneDrive.js']}
        renderChildren={() => {
          const handleButton = () => {
            window.OneDrive.open(odOptions);
          };
          return (
            <div className={classes.wrapper}>
              <Box
                display="flex"
                flexDirection="column"
                onClick={handleButton}
                alignItems="center"
              >
                <OndriveIcon width="150px" />
                <Button ref={ref} variant="contained">
                  Pick File
                </Button>
              </Box>
            </div>
          );
        }}
      >
        <div></div>
      </ReactDependentScript>
    </div>
  );
});

export default OneDrive;
