import { Menu, Transition } from '@headlessui/react';
import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useCompanyStore } from '../Context/GlobalStore';
import { AuthContext } from '../Context/Login';

var randomColor = require('randomcolor');

const useStyles = makeStyles((theme) => ({
  appbar: {
    width: `calc(100% - ${66}px)`,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    height: 165
  }
}));

interface HeaderProps {
  title?: string;
  description?: string;
}
export default function Header({ title, description }: HeaderProps): ReactElement {
  const classes = useStyles();
  const user = useContext(AuthContext);
  const { companyName, profile } = useCompanyStore();
  const profileColour = randomColor({
    luminosity: 'light',
    hue: 'random'
  });

  const DownArrow = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    );
  };

  const SwitchIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
        />
      </svg>
    );
  };

  const PreferenceIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
        />
      </svg>
    );
  };

  const HelpIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );
  };

  const BillingIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    );
  };

  const LogoutIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
          clipRule="evenodd"
        />
      </svg>
    );
  };

  const ProfileMenu = () => {
    return (
      <Menu>
        <Menu.Button>
          <div className="flex flex-row p-4 bg-none hover:bg-gray-200 rounded-lg w-64 space-x-4 justify-center items-center z-30">
            <div
              className="rounded-full h-8 w-8 flex items-center justify-center text-abx-dark-blue font-semibold text-sm"
              style={{
                backgroundColor: profile === null ? profileColour : profile?.profile_colour
              }}
            >
              {companyName.charAt(0)}
            </div>
            <span className="text-abx-dark-blue text-sm ml-4">{companyName}</span>
            <DownArrow />
          </div>
        </Menu.Button>
        <Transition
          enter="transition duration-250 ease-in-out"
          enterFrom="transform -translate-y-10 opacity-0"
          enterTo="transform translate-y-0 opacity-95"
          leave="transition duration-250 ease-in-out"
          leaveFrom="transform translate-y-0 opacity-95"
          leaveTo="transform -translate-y-10 opacity-0"
        >
          <Menu.Items className="bg-white rounded-lg m-4 w-64 flex flex-col fixed opacity-95 z-30">
            <Menu.Item>
              <div className="pl-8 pr-10 py-4 flex flex-row space-x-8 hover:bg-abx-light-blue text-abx-dark-blue hover:text-abx-pink">
                <SwitchIcon />
                <Link to="/">Switch Company</Link>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className="pl-8 pr-10 py-4 flex flex-row space-x-8 hover:bg-abx-light-blue text-abx-dark-blue hover:text-abx-pink">
                <PreferenceIcon />
                <Link to="/detail/user">Preferences</Link>
              </div>
            </Menu.Item>
            <Menu.Item disabled>
              <div className="pl-8 pr-10 py-4 flex flex-row space-x-8 text-grey-200 opacity-50">
                <BillingIcon />
                <div style={{ cursor: 'pointer' }}>Billing</div>
              </div>
            </Menu.Item>
            <Menu.Item disabled>
              <div className="pl-8 pr-10 py-4 flex flex-row space-x-8 text-grey-200 opacity-50">
                <HelpIcon />
                <Link to="/help">Help/Support</Link>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className="pl-8 pr-10 py-4 flex flex-row space-x-8 hover:bg-abx-light-blue text-abx-dark-blue hover:text-abx-pink">
                <LogoutIcon />
                <div className="cursor-pointer" onClick={user.signOut}>
                  Sign Out
                </div>
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  return (
    <AppBar position="static" color="default" className={classes.appbar} elevation={0}>
      <Toolbar>
        <div className="grid grid-cols-6 gap-4">
          <div className="col-start-1 col-span-4 flex flex-col mt-4 ">
            <span className="text-black text-2xl font-semibold">{title}</span>
            <span className="text-abx-dark-grey text-md my-4">{description}</span>
          </div>
          <div className="col-end-8 col-span-2 align-top">
            <div>
              <ProfileMenu />
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}
