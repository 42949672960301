import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  GetFilterListDocument,
  GetFilterListQuery
} from '../../../../../generated/graphql-frontend';
import { isEmpty } from '../../../../../utils/isEmpty';
import DateRange from './FilterComponents/DateRange';
import MultiSelect from './FilterComponents/FilterSelect';
import NumberRange from './FilterComponents/NumberRange';
import Search from './FilterComponents/Search';

function FilterBody() {
  const { company_id } = useParams<CompanyIdProps>();
  const { data, loading } = useQuery<GetFilterListQuery>(GetFilterListDocument, {
    variables: { company_id: company_id }
  });
  const [reset, setReset] = React.useState<number>(0);

  const filterList = data?.user?.companies?.find((company) => !isEmpty(company))?.filter_list;
  const categories = filterList?.categories!;
  const channels = filterList?.channels!;
  const tags = filterList?.tags!;

  if (loading) return <div>Loading ...</div>;
  if (!filterList) return <div>No filter list</div>;

  return (
    <div className="mt-12 mx-8">
      <div className="my-2">
        <Search placeholder="Transaction Name" type="search" reset={reset} />
      </div>

      <div className="my-2">
        <DateRange  />
      </div>

      <div className="my-2">
        <NumberRange reset={reset} />
      </div>

      <div className="my-2">
        <MultiSelect categories={categories} placeholder="Category" type="category" reset={reset} />
      </div>

      <div className="my-2">
        <MultiSelect
          categories={channels}
          placeholder="Sales Channel"
          type="payment_channel"
          reset={reset}
        />
      </div>

      <div className="my-2">
        <MultiSelect categories={tags} placeholder="Tags" type="tags" reset={reset} />
      </div>
      <div className="flex flex-row mt-8 mb-8 w-full space-x-4">
        <button className="btn-outline-mid-blue flex-none" onClick={() => setReset(reset + 1)}>
          Clear All
        </button>
      </div>
    </div>
  );
}

export default FilterBody;
