import React, { createContext, FC, useContext, useState } from "react";

type ScenarioContextStatus = {
  activeStep: number;
  totalStep?: number;
  increaseStep: () => void;
  decreaseStep: () => void;
};

export const scenarioDefaultValues: ScenarioContextStatus = {
  activeStep: 1,
  totalStep: 7,
  increaseStep: () => {},
  decreaseStep: () => {},
};

export const ScenarioContext = createContext<ScenarioContextStatus>(scenarioDefaultValues);

export const ScenarioProvider: FC = ({ children }) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const increaseStep = () => setActiveStep((prev) => prev + 1);
  const decreaseStep = () => setActiveStep((prev) => prev - 1);

  return (
    <ScenarioContext.Provider value={{ activeStep, increaseStep, decreaseStep }}>{children}</ScenarioContext.Provider>
  );
};

export const useScenarioStore = () => useContext(ScenarioContext);
