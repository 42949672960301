import { Box, Dialog, InputBase, makeStyles, MenuItem, Select, useTheme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { Breakdown, HistoricalBreakdown } from '../../../../../generated/graphql-frontend';
import { categoryColor } from '../../category';
import Bar from '../ProgressBars/HorizontalBar';
import DetailChart from './DetailChart';
import ExpenseSkeleton from './ExpenseSkeleton';

const useStyles = makeStyles((theme) => ({
  selectIcon: {
    color: '#FFF !important'
  },
  selectInput: {
    color: 'white'
  }
}));

interface Props {
  open: boolean;
  expenseData?: Breakdown[];
  kind: TransactionType;
  period: PeriodType;
  loading: boolean;
  detailData?: HistoricalBreakdown[];
  toggleOpen: VoidFunction;
  setPeriodValue: (period: PeriodType) => void;
}

export default function ExpenseModal({
  open,
  loading,
  expenseData,
  kind,
  period,
  detailData,
  setPeriodValue,
  toggleOpen
}: Props): React.ReactElement {
  const classes = useStyles();
  const theme = useTheme();

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string;
      event: Event | React.SyntheticEvent<Element, Event>;
    }>
  ) => {
    setPeriodValue(event.target.value as PeriodType);
  };

  const get_chart_title = (title: string) => {
    let out: string;
    if (title === 'D') {
      out = 'Daily';
    } else if (title === 'W') {
      out = 'Weekly';
    } else if (title === 'M') {
      out = 'Monthly';
    } else if (title === 'Q') {
      out = 'Quarterly';
    } else if (title === 'Y') {
      out = 'Yearly';
    } else {
      out = '';
    }

    return out;
  };

  const get_summary_title = (title: string) => {
    let out: string;
    if (title === 'D') {
      out = 'today';
    } else if (title === 'W') {
      out = 'this week';
    } else if (title === 'M') {
      out = 'this month';
    } else if (title === 'Q') {
      out = 'this quarterly';
    } else if (title === 'Y') {
      out = 'this year';
    } else {
      out = '';
    }

    return out;
  };

  React.useEffect(() => {
    console.log(period);
  }, [period]);

  return (
    <Dialog open={open} onClose={toggleOpen} maxWidth="md">
      <Box bgcolor={theme.palette.primary.dark} color="white" padding={6} minWidth={500}>
        <div className="flex flex-row">
          <Select
            id="period-select"
            name="period"
            style={{ marginLeft: 'auto', width: 120, color: '#FFF' }}
            color="primary"
            classes={{ icon: classes.selectIcon }}
            IconComponent={ExpandMoreIcon}
            input={<InputBase classes={{ input: classes.selectInput }} />}
            value={period}
            onChange={handleChange}
          >
            <MenuItem value={'D'}>Daily</MenuItem>
            <MenuItem value={'W'}>Weekly</MenuItem>
            <MenuItem value={'M'}>Monthly</MenuItem>
            <MenuItem value={'Q'}>Quarterly</MenuItem>
            <MenuItem value={'CY'}>Yearly</MenuItem>
          </Select>
        </div>
        <div className="text-white text-lg my-4">{`${get_chart_title(period)} ${
          kind === 'expense' ? 'Expenses' : 'Income'
        }`}</div>
        {loading && <ExpenseSkeleton />}
        {detailData && <DetailChart detailData={detailData} />}

        {expenseData?.map((item, index) => {
          let catColour: string =
            item.category === undefined ? '#eeeeee' : categoryColor[item.category]?.colour;
          return (
            <div>
              {index === 0 && (
                <div className="text-white text-lg mt-8 mb-4">{`${
                  kind === 'expense' ? 'Expense breakdown for' : 'Income breakdown for'
                } ${get_summary_title(period)} `}</div>
              )}
              <Bar
                key={`expense-bar-modal-${index}`}
                category={item.category}
                amount={item.amount}
                color={catColour}
                percent={item.percentage}
              />
            </div>
          );
        })}
      </Box>
    </Dialog>
  );
}
