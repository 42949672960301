import { Box, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  DataKey,
  LabelList,
  LabelProps,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import { KpiChartData } from '../../../generated/graphql-frontend';
import { CashCardColor } from './CashCard';

interface Props {
  data?: KpiChartData[];
  color?: CashCardColor;
  barWidth?: number;
  dataKey?: DataKey;
}

export default function CashBar({
  data,
  color,
  barWidth = 70,
  dataKey = 'display_date'
}: Props): ReactElement {
  const theme = useTheme();
  console.log('chart data ==>', data);
  return (
    <Box width="100%" height="100%" minHeight={100} minWidth={100}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} margin={{ top: 30 }}>
          <XAxis
            dataKey={dataKey}
            tickLine={false}
            tickFormatter={(value: number) => value.toLocaleString()}
            axisLine={false}
            stroke={color === 'blue' ? '#FFF' : color === 'white' ? '#525252' : '#868383'}
            reversed
            interval={0}
          />
          <YAxis dataKey="amount" hide />
          <ReferenceLine
            y={0}
            alwaysShow
            stroke={color === 'blue' ? '#FFF' : color === 'white' ? '#0E5A7B' : '#868383'}
          />
          <Bar
            dataKey="amount"
            fill={theme.palette.secondary.main}
            background={{ fill: '#F6F6F9' }}
            stackId="a"
            barSize={barWidth}
          >
            <LabelList
              dataKey="amount"
              position="top"
              content={(props) => renderAmount({ label: props, color: color })}
            />
            {data?.map((entry, index) => {
              const amount = entry?.amount ?? 0;
              return <Cell key={`cell-${index}`} fill={amount > 0 ? '#FF008E' : '#EE0F0F'} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

interface RenderAmountProps {
  label: LabelProps;
  color?: CashCardColor;
}
const renderAmount = ({
  label: { x = 0, width = 0, y = 0, value = 0 },
  color
}: RenderAmountProps) => {
  return (
    <g>
      {value !== 0 && (
        <text
          x={x + width / 2}
          y={20}
          fill={color === 'blue' ? '#FFF' : color === 'white' ? '#525252' : '#868383'}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {Math.trunc(Number(value)).toLocaleString()}
        </text>
      )}
    </g>
  );
};
