import React, { ReactElement } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import UserList from '../Routes/UserList/index';
import Analysis from './Analysis';
import PrefFirst from './Auth/RegisterStep/Components';
import PrefSecond from './Auth/RegisterStep/Components/CompanyDetail';
import CompanyList from './CompanyList';
import DataManagement from './DataManage';
import NoTransaction from './DataManage/TransactionInfo/Components/NoTransactions';
import Home from './home/Home';
import Kpi from './KPI';
import { default as NoMatch } from './NoMatch';
import PrivateRoute from './PrivateRoute';
import Scenarios from './Scenarios';
import ShareCompany from './ShareCompany';
import TestComponent from './TestComponent';
import NotifcationTest from './NotificationTest';
import { useFlag } from '@unleash/proxy-client-react';
import ComingSoon from './ComingSoon';

function Index(): ReactElement {
  const company_token = localStorage.getItem('share_company');
  const analysisToggle = useFlag('analysis-page');
  const scenarioPageToggle = useFlag('Scenarios-page');

  if (company_token) {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to={`/share_company?tkn=${company_token}?`} />}
        />
        <Route path="/share_company" component={ShareCompany} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/onboarding/preferences" component={PrefFirst} />
      <Route exact path="/onboarding/company" component={PrefSecond} />
      <Route exact path="/companies" component={CompanyList} />
      <Route exact path="/companies/:company_id?" component={UserList} />
      <PrivateRoute
        path="/analysis/:company_id"
        component={analysisToggle ? Analysis : ComingSoon}
      />
      <PrivateRoute
        path="/scenarios/:company_id"
        component={scenarioPageToggle ? Scenarios : ComingSoon}
      />
      <PrivateRoute exact path="/test" component={TestComponent} />
      <PrivateRoute path="/data/:company_id" component={DataManagement} />
      <PrivateRoute path="/kpi/:company_id" component={Kpi} />
      <Route path="/share_company" component={ShareCompany} />
      <Route path="/notransaction" component={NoTransaction} />
      <Route path="/tn" component={NotifcationTest} />
      <Route path="*">
        <NoMatch notFound={true} />
      </Route>
    </Switch>
  );
}

export default withRouter(Index);
