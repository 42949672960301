import { Container } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ScenarioProvider } from './hooks/ScenarioContext';
import AddScenario from './Pages/AddScenario/AddScenario';
import Cashflow from './Pages/AddScenario/Cashflow';
import Scenarios from './Scenarios';

export default function Index(): ReactElement {
  const { path } = useRouteMatch();

  return (
    <div>
      <Container>
        <ScenarioProvider>
          <Switch>
            <Route exact path={path} component={Scenarios} />
            <Route exact path={`${path}/add`} component={AddScenario} />
            <Route exact path={`${path}/cashflows`} component={Cashflow} />
          </Switch>
        </ScenarioProvider>
      </Container>
    </div>
  );
}
