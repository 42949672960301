import { createContext, PropsWithChildren, useState } from 'react';
import { Transaction, TransactionFilter } from '../../../generated/graphql-frontend';
import { useApolloClient } from '@apollo/client';

interface AnalysisDefault {
  currentTransaction?: Transaction;
  updateCurrentTransaction: (transaction: Transaction) => void;
  filter?: TransactionFilter;
  updateFilter: (filter: TransactionFilter) => void;
  transOpen?: boolean;
  toggleTransOpen: () => void;
}

const initialState: AnalysisDefault = {
  currentTransaction: undefined,
  updateCurrentTransaction: () => {},
  filter: {},
  updateFilter: () => {},
  transOpen: false,
  toggleTransOpen: () => {}
};

export const AnalysisContext = createContext(initialState);

const AnalysisProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [currentTransaction, setCurrentTransaction] = useState<Transaction>();
  const [filter, setFilter] = useState<TransactionFilter>();
  const [transOpen, setTransOpen] = useState(false);
  const { cache, clearStore } = useApolloClient();

  const updateFilter = (filter: TransactionFilter) => {
    console.log('filter update=>', filter);
    cache.modify({
      fields: {
        transactions: () => {
          return [];
        }
      }
    });
    setFilter(filter);
  };
  const updateCurrentTransaction = (transaction: Transaction) => {
    setCurrentTransaction(transaction);
  };
  const toggleTransOpen = () => {
    setTransOpen((prev) => !prev);
  };

  return (
    <AnalysisContext.Provider
      value={{
        currentTransaction,
        filter,
        transOpen,
        updateCurrentTransaction,
        updateFilter,
        toggleTransOpen
      }}
    >
      {children}
    </AnalysisContext.Provider>
  );
};

export default AnalysisProvider;
