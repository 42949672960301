import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Kpi } from '../../../generated/graphql-frontend';

export default function Description({ kpi }: { kpi: Kpi }): ReactElement {
  return (
    <div>
      <Typography variant="subtitle2" color="text.secondary">
        {kpi.name}
      </Typography>
      <Typography variant="body1" color="text.secondary" mt={4}>
        {kpi.description}
      </Typography>
    </div>
  );
}
