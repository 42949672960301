import { Box, Collapse, Grid } from "@material-ui/core";
import React, { useState, ReactElement } from "react";
import ABTypography from "../../../../../Components/ABTypography";
import { ReactComponent as CreditIcon } from "../../../assets/credit.svg";
import { ReactComponent as InvestIcon } from "../../../assets/investment.svg";
import { ReactComponent as LoanIcon } from "../../../assets/loan.svg";
import { BoxContainer } from "../../../Components/BoxContainer";
import { ButtonContainer } from "../../../Components/ButtonContainer";
import TabTable from "../../../Components/TabTable";
import CreditForm from "./CreditForm";
import InvestForm from "./InvestForm";
import LoanForm from "./LoanForm";

export default function Finance(): ReactElement {
  const [selected, setSelected] = useState("credit");

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          <BoxContainer>
            <ABTypography variant="subtitle2">Add finance</ABTypography>
            <Box display="flex">
              <ButtonContainer onClick={() => setSelected("credit")}>
                <CreditIcon />
                <ABTypography variant="formText" abColor="white">
                  Credit Card
                </ABTypography>
              </ButtonContainer>
              <ButtonContainer onClick={() => setSelected("investment")}>
                <InvestIcon />
                <ABTypography abColor="white">Investment</ABTypography>
              </ButtonContainer>
              <ButtonContainer onClick={() => setSelected("loan")}>
                <LoanIcon />
                <ABTypography abColor="white">Loan</ABTypography>
              </ButtonContainer>
            </Box>
            <Collapse in={selected === "credit"}>
              <CreditForm />
            </Collapse>
            <Collapse in={selected === "investment"}>
              <InvestForm />
            </Collapse>
            <Collapse in={selected === "loan"}>
              <LoanForm />
            </Collapse>
          </BoxContainer>
        </Grid>
        <Grid item xs={12} md={5}>
          <BoxContainer>
            <TabTable title="Finance cashflows" />
          </BoxContainer>
        </Grid>
      </Grid>
    </div>
  );
}
