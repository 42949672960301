import { useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as UserBG } from '../../Components/assets/UserBG.svg';
import Sidebar from '../../Components/Sidebar';
import { COMPANY } from '../../Gqls/gql';
import NoMatch from '../../Routes/Error';
import InviteModal from './Component/InviteConfirm';
import UserTable from './Component/UserTable';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFF'
  },
  sideBarContainer: {
    backgroundColor: theme.palette.primary.light,
    minWidth: 150,
    maxWidth: 400
  },
  coverImg: {
    margin: theme.spacing(7, 0),
    width: '80%'
  },
  paper: {
    margin: theme.spacing(0, 16),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 5)
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  logoContainer: {
    textAlign: 'left'
  },
  logoImg: {
    height: '45px',
    width: '39px'
  },
  titleContainer: {
    textAlign: 'left',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    color: '#FFF'
  },
  headerContainer: {
    backgroundColor: '#0d8abc',
    paddingLeft: '23px',
    paddingTop: '17px'
  },
  formTitle: {
    textAlign: 'left',
    margin: theme.spacing(6, 0, 3, 0)
  },
  dividerMargin: {
    marginTop: '10px',
    marginBottom: '15px'
  },
  imageBG: {
    textAlign: 'center'
  },
  outButContainer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    top: 'auto',
    marginLeft: theme.spacing(4)
  },
  btnContainer: {
    padding: theme.spacing(7, 2),
    marginTop: 'auto'
  },
  companyNum: {
    borderRadius: 10,
    padding: '1px 12px 1px 12px',
    marginLeft: theme.spacing(2.5),
    backgroundColor: '#0D8ABC',
    color: '#FFF',
    fontSize: '1.5rem',
    fontWeight: 500
  },
  headerDivider: {
    height: 2
  },
  listHeader: {
    marginBottom: theme.spacing(2)
  },
  select: {
    width: 120
  }
}));
const NextButton = withStyles({
  root: {
    minWidth: 130,
    height: 35
  }
})(Button);

export default function UserList() {
  const classes = useStyles();
  const history = useHistory();
  const { company_id } = useParams();
  const [openInvite, setOpenInvite] = useState(false);
  const { data, loading, error } = useQuery(COMPANY);

  const handleInvite = () => {
    setOpenInvite(true);
  };
  if (error) return <div>{error.message}</div>;
  if (loading) return <div>Loading ... </div>;
  const users = data.users[0].companies.find((item) => item.id === company_id);
  const no_users = users?.company_users.filter((usr) => usr.status !== 'pending').length;
  if (!users) return <NoMatch />;
  return (
    <Grid container component="main" className={classes.root} direction="row">
      <Grid item xs={false} sm={5} md={4} lg={3} className={classes.sideBarContainer}>
        <Sidebar
          logoTitle="Users"
          logoSubtitle="Control access to your company"
          image={<UserBG width="100%" />}
        />
      </Grid>
      <Grid item xs={12} sm={7} md={8} lg={9} xl={8}>
        {/* Form title and description */}
        <div className={classes.paper}>
          <Grid
            container
            justifyContent="space-between"
            className={classes.formTitle}
            marginTop={16}
            marginBottom={5}
          >
            <Grid item>
              <Typography variant="h1">
                Users
                <span className={classes.companyNum}>{no_users}</span>
              </Typography>
              <br />
              {/*`Company ID: ${company_id}`*/}
            </Grid>
            <Grid item>
              <Button color="primary" variant="outlined" onClick={handleInvite}>
                Invite a User
              </Button>
              <InviteModal open={openInvite} setOpen={setOpenInvite} companyID={company_id} />
            </Grid>
          </Grid>
          <UserTable
            companyID={company_id}
            rows={data.users[0].companies.find((item) => item.id === company_id).company_users}
          />
          <Grid item xs={12} md={12} lg={8} xl={6}></Grid>
          <Grid container justifyContent="space-between" className={classes.btnContainer}>
            <Grid item>
              <NextButton variant="contained" color="primary" onClick={() => history.goBack()}>
                Back
              </NextButton>
            </Grid>
          </Grid>
          <Grid item></Grid>
        </div>
      </Grid>
    </Grid>
  );
}
