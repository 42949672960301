import { Autocomplete, Button, TextField, Typography } from '@material-ui/core';
import '@material-ui/lab';
import React, { useState } from 'react';
import { CURRENCIES } from '../../../Consts/Currency';
import BasicContainer from '../../../Containers/BasicContainer';
import Header from '../../../Containers/Header';
import CheckTable from '../Components/CheckTable';

export default function Introduce() {
  const [name, setName] = useState('');

  return (
    <div className="root">
      <Header
        title="Check Data File"
        description="Check the data file and make sure the data is correct before uploading."
      />
      <BasicContainer>
        <Typography>Santander.csv</Typography>
        <TextField
          name="name"
          label="Name"
          onChange={(event) => {
            setName(event.target.value);
          }}
          value={name}
          style={{ width: 300 }}
        />
        <Autocomplete
          aria-label="Currency"
          style={{ width: 300 }}
          options={CURRENCIES.map((item) => item.currency_name)}
          renderInput={(params) => (
            <TextField {...params} label="Currency" variant="standard" />
          )}
        />
        <Button variant="contained" color="primary">
          Upload
        </Button>
        <CheckTable />
      </BasicContainer>
    </div>
  );
}
