import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import mapObject from 'map-obj';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import ABLoading from '../../../../Components/Loading/ABLoading';
import { LongButton } from '../../../../Components/LongButton';
import { DataFileData, useUploadDataMutation } from '../../../../generated/graphql-frontend';
import { UploadContext } from '../../UploadContext';
import { UploadStep } from './Const';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9FAFB'
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

interface ReviewProps {
  rows: Record<string, string>[];
}
export default function Review({ rows }: ReviewProps) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [uploadData, { loading }] = useUploadDataMutation();
  const [mappedData, setMappedData] = React.useState<Record<string, any>[]>([]);
  const { state, dispatch } = React.useContext(UploadContext);
  const { company_id } = useParams<CompanyIdProps>();

  const mappedValue = state.mapping;

  /**
   * convert Excel date to Unix timestamp https://stackoverflow.com/questions/1703505/excel-date-to-unix-timestamp/6154953#6154953
   * Unix Timestamp = (Excel Timestamp - 25569) * 86400
   * Excel Timestamp =  (Unix Timestamp / 86400) + 25569
   * 86400 = Seconds in a day
   * 25569 = Days between 1970/01/01 and 1900/01/01 (min date in Windows Excel)
   * 24107 = Days between 1970/01/01 and 1904/01/02 (min date in Mac Excel 2007)
   */

  React.useEffect(() => {
    const mappedOldKeys = Object.keys(mappedKeys);
    const tempMappedData: unknown = rows.map((row) => {
      const replacedRow = mapObject(row, (oldKey, value: unknown) => {
        if (mappedOldKeys.includes(oldKey)) {
          return mappedKeys[oldKey] === 'date'
            ? [
                mappedKeys[oldKey],
                typeof value === 'number'

                  ? moment((Number(value) - 25569) * 86400 * 1000).format('YYYY-MM-DD')
                  : (
                    value+"  : Date Format Error" 
                  )
              ]
            : [mappedKeys[oldKey], value];
        } else {
          return mapObject.mapObjectSkip;
        }
      });
      return replacedRow;
    });
    console.log('temp mapped data ==>', tempMappedData);
    setMappedData(tempMappedData as Record<string, any>[]);
    dispatch({
      type: 'setDataFileInput',
      payload: {
        ...state.dataFileInput,
        company_id,
        data: tempMappedData as DataFileData[],
        description: mappedValue.description,
        currency: mappedValue.currency
      }
    });
  }, []);

  const mappedKeys = Object.keys(mappedValue).reduce((ret, key) => {
    ret[mappedValue[key]] = key;
    return ret;
  }, {} as Record<string, string>);

  if (!mappedData[0]) return <div>No key</div>;
  var newKeys = Object.keys(mappedData[0]).filter((key) => key.trim().length !== 0);

  const TableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {newKeys.map((key, index) => (
            <td className="text-left text-abx-dark-blue font-semibold" key={key}>
              {key.toUpperCase()}
            </td>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const RenderRow = ({ keys, data }: { keys: string[]; data: Record<string, any> }) => {
    return (
      <React.Fragment>
        {newKeys.map((key, index) => {
          return (
            <td className="text-left text-abx-dark-blue py-4" key={data[key]}>
              {data[key]}
            </td>
          );
        })}
      </React.Fragment>
    );
  };
  const getRowsData = () => {
    return (
      rowsPerPage > 0
        ? mappedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : mappedData
    ).map((row, index) => {
      return (
        <StyledTableRow key={index}>
          <RenderRow key={index} data={row} keys={Object.keys(mappedValue)} />
        </StyledTableRow>
      );
    });
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpload = () => {
    uploadData({
      variables: { dataFileInput: state.dataFileInput },
      update: (cache, updatedData) => {
        const updatedDataFileLists = updatedData.data?.uploadDataFile?.object;
        if (updatedDataFileLists) {
          const cacheId = cache.identify(updatedDataFileLists);
          cache.modify({
            fields: {
              data_files: (existingDataFileLists = [], { toReference }) => {
                if (cacheId) return [...existingDataFileLists, toReference(cacheId)];
              }
            }
          });
        }
      }
    }).then(() => {
      dispatch({ type: 'setCurrentStep', payload: UploadStep.LOAD });
    });
  };

  return (
    <div className="bg-white rounded-lg p-6 relative w-full">
      <div className="bg-white rounded-lg p-6 relative w-full">
        <div className="mb-8">
          <div className="flex flex-col">
            <span className="text-abx-dark-blue text-lg font-semibold">
              {state.dataFileInput.name}
            </span>
            <span className="text-abx-dark-blue">{mappedValue.description}</span>
          </div>
          <Box position="absolute" top={30} right={30}>
            <LongButton
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={loading}
            >
              Upload
              {loading && <ABLoading />}
            </LongButton>
          </Box>
        </div>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHeader />
            <TableBody>
              {getRowsData()}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter style={{ marginLeft: 0, marginRight: 'auto' }}></TableFooter>
          </Table>
        </TableContainer>
        <Box display="flex" justifyItems="center" justifyContent="center">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            labelDisplayedRows={(dispRowsInfo) => {
              return dispRowsInfo.page + 1;
            }}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showLastButton={true}
            showFirstButton
            align="center"
          />
        </Box>
      </div>
    </div>
  );
}
