import React, { useEffect, useState } from 'react';
import BasicContainer from '../../../Containers/BasicContainer';
import Header from '../../../Containers/Header';
import { useCompanyStore } from '../../../Context/GlobalStore';
import BankTable from './BankTable';
import ConnectDialog from './ConnectBankDialog';

const useLinkToken = (token: string | null, companyId: string | null) => {
  const [linkToken, setLinkToken] = useState('');

  useEffect(() => {
    async function getLinkToken() {
      const url = `${process.env.REACT_APP_BASE_URL}/external/plaid/link${process.env.REACT_APP_URL_SUFFIX}/${companyId}`;
      //const url = `http://localhost:4000/dev_local/plaid/link/beta/${companyId}`;
      const header = new Headers();
      header.append('authorization', `Bearer ${token}`);
      header.append('mode', 'cors');
      const request = new Request(url, {
        method: 'GET',
        headers: header
      });
      const response = await fetch(request);
      const data = await response.text();
      setLinkToken(data);
    }
    // TODO commented link token because it doesn't work on localhost
    getLinkToken();
  }, [companyId, token]);

  return linkToken;
};
export default function BankAccounts() {
  const [openModal, setOpenModal] = useState(false);
  const { currentCompanyId, bearerToken } = useCompanyStore();
  const linkToken = useLinkToken(bearerToken, currentCompanyId);
  console.log('link token ==>', linkToken);
  // const linkToken = '';

  const toggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  return (
    <div>
      <Header
        title="Connect a Business Bank Account"
        description="For the accounts you have connected see your total cash in the bank"
      />
      <BasicContainer>
        <BankTable setOpenModal={setOpenModal} />
      </BasicContainer>
      <ConnectDialog linkToken={linkToken} open={openModal} toggleOpen={toggleModal} />
    </div>
  );
}
