import { useFlag } from '@unleash/proxy-client-react';
import React, { ReactElement } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useGetKpiQuery } from '../../generated/graphql-frontend';
import Loading from '../../Loading';
import ComingSoon from '../ComingSoon';
import NoTransaction from '../DataManage/TransactionInfo/Components/NoTransactions';
import Error from '../Error';
import KPI from './KPI';

export default function Index(): ReactElement {
  const { path } = useRouteMatch();
  const toggleKpiPage = useFlag('kpi-page');

  const { company_id } = useParams<CompanyIdProps>();
  const { data, loading, error } = useGetKpiQuery({ variables: { companyId: company_id } });
  const kpis = data?.user?.company?.kpis;

  console.log('kpis value ==>', kpis);
  if (loading) return <Loading />;
  if (error) return <Error />;
  if (kpis?.length === undefined || (kpis?.length !== undefined && kpis.length <= 0))
    return <NoTransaction company_id={company_id} />;

  return (
    <Switch>
      <Route path={path}>{toggleKpiPage ? <KPI kpis={kpis} /> : ComingSoon}</Route>
    </Switch>
  );
}
