import { Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    margin: theme.spacing(3, 0),
  },
  cancel: {
    marginLeft: 0,
    marginRight: "auto",
  },
}));

export default function FormDialog({
  title,
  children,
  open,
  setOpen,
  onConfirm,
}) {
  const classes = useStyles();

  const handleDeleteBtn = () => {
    setOpen(false);
    onConfirm();
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog-title" color="primary">
        {title}
      </DialogTitle>
      <DialogContent>
        <Divider />
        <br />
        <DialogContentText>{children}</DialogContentText>
        <br />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          color="primary"
          variant="outlined"
          className={classes.cancel}
        >
          Cancel
        </Button>
        <Button onClick={handleDeleteBtn} color="primary" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
