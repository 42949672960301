import { Box, Dialog, DialogContent } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import React, { ReactElement } from "react";
import { ABTab } from "../../../Components/ABTab";
import { ABTabList } from "../../../Components/ABTabList";
import Cashflow from "./Cashflow";
import Data from "./Data";
import Growth from "./Growth";
import Profit from "./Profit";
import Summary from "./Summary";

interface Props {
  open: boolean;
  toggleDialog: () => void;
}

function ScenariosDialog({ open, toggleDialog }: Props): ReactElement {
  const [value, setValue] = React.useState("2");
  // const { activeStep, decreaseStep, increaseStep } = useScenarioStore();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Dialog open={open} onClose={toggleDialog} maxWidth="md" fullWidth>
      <DialogContent>
        <Box minHeight={600} borderRadius={20}>
          <TabContext value={value}>
            <Box px={10}>
              <ABTabList onChange={handleChange} aria-label="kpi-tabs" variant="fullWidth">
                <ABTab label="Summary" value="1" />
                <ABTab label="Data" value="2" />
                <ABTab label="Cashflow" value="3" />
                <ABTab label="Profit" value="4" />
                <ABTab label="Growth" value="5" />
              </ABTabList>
            </Box>

            <TabPanel value="1">
              <Summary />
            </TabPanel>
            <TabPanel value="2">
              <Data />
            </TabPanel>
            <TabPanel value="3">
              <Cashflow />
            </TabPanel>
            <TabPanel value="4">
              <Profit />
            </TabPanel>
            <TabPanel value="5">
              <Growth />
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
export default React.memo(ScenariosDialog);
