import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  Upload: any;
};





export type Account = {
  __typename?: 'Account';
  company_id: Scalars['ID'];
  account_id: Scalars['String'];
  account_name: Scalars['String'];
  account_type: Scalars['String'];
  institution?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['String']>;
  account_subtype: Scalars['String'];
  balance: Scalars['Float'];
  available?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
};

export type AccountResponse = {
  __typename?: 'AccountResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<Account>;
  id?: Maybe<Scalars['String']>;
};

export type Alert = {
  __typename?: 'Alert';
  id?: Maybe<Scalars['String']>;
  kpi_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  percentage_hit?: Maybe<Scalars['Float']>;
  last_triggered?: Maybe<Scalars['String']>;
  target_hit?: Maybe<Scalars['Boolean']>;
};

export type AlertInput = {
  company_id: Scalars['ID'];
  kpi_name: Scalars['String'];
  condition?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type AlertResponse = {
  __typename?: 'AlertResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<Alert>;
  id?: Maybe<Scalars['String']>;
};

export type AlertUpdateInput = {
  company_id: Scalars['ID'];
  kpi_name: Scalars['String'];
  alert_id: Scalars['ID'];
  condition?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type Breakdown = {
  __typename?: 'Breakdown';
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
};

export type CashInBank = {
  __typename?: 'CashInBank';
  date: Scalars['String'];
  amount: Scalars['Float'];
};

export type CashflowInput = {
  company_id: Scalars['String'];
  scenario_name: Scalars['String'];
  name: Scalars['String'];
  cashflow_type: Scalars['String'];
  period?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  no_payments?: Maybe<Scalars['Int']>;
  first_amount?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  upper_amount?: Maybe<Scalars['Float']>;
  lower_amount?: Maybe<Scalars['Float']>;
  cost_period?: Maybe<Scalars['String']>;
  cost_amount?: Maybe<Scalars['Float']>;
  cost_upper?: Maybe<Scalars['Float']>;
  cost_lower?: Maybe<Scalars['Float']>;
  ignore?: Maybe<Scalars['Boolean']>;
  existing_fixed?: Maybe<Scalars['Boolean']>;
  utilisation?: Maybe<Scalars['String']>;
  repayment_type?: Maybe<Scalars['String']>;
  payoff_period?: Maybe<Scalars['Int']>;
  outstanding?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  expense?: Maybe<Scalars['Boolean']>;
  day_of_month?: Maybe<Scalars['Int']>;
  day_of_week?: Maybe<Scalars['String']>;
  custom_data?: Maybe<Array<Maybe<CustomCfInput>>>;
};

export type CashflowResponse = {
  __typename?: 'CashflowResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<Cashflows>;
  id?: Maybe<Scalars['String']>;
};

export type CashflowUpdateInput = {
  company_id: Scalars['String'];
  scenario_name: Scalars['String'];
  name: Scalars['String'];
  cashflow_type?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  no_payments?: Maybe<Scalars['Int']>;
  first_amount?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  upper_amount?: Maybe<Scalars['Float']>;
  lower_amount?: Maybe<Scalars['Float']>;
  cost_period?: Maybe<Scalars['String']>;
  cost_amount?: Maybe<Scalars['Float']>;
  cost_upper?: Maybe<Scalars['Float']>;
  cost_lower?: Maybe<Scalars['Float']>;
  ignore?: Maybe<Scalars['Boolean']>;
  utilisation?: Maybe<Scalars['String']>;
  repayment_type?: Maybe<Scalars['String']>;
  payoff_period?: Maybe<Scalars['Int']>;
  outstanding?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  expense?: Maybe<Scalars['Boolean']>;
  day_of_month?: Maybe<Scalars['Int']>;
  day_of_week?: Maybe<Scalars['String']>;
  custom_data?: Maybe<Array<Maybe<CustomCfInput>>>;
};

export type Cashflows = {
  __typename?: 'Cashflows';
  name: Scalars['String'];
  cashflow_type?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  no_periods?: Maybe<Scalars['Int']>;
  first_amount?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  display_amount?: Maybe<Scalars['Float']>;
  upper_amount?: Maybe<Scalars['Float']>;
  lower_amount?: Maybe<Scalars['Float']>;
  cost_amount?: Maybe<Scalars['Float']>;
  cost_upper?: Maybe<Scalars['Float']>;
  cost_lower?: Maybe<Scalars['Float']>;
  outstanding?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  utilisation?: Maybe<Scalars['String']>;
  repayment_type?: Maybe<Scalars['String']>;
  payoff_period?: Maybe<Scalars['Int']>;
  expense?: Maybe<Scalars['Boolean']>;
  existing?: Maybe<Scalars['Boolean']>;
  rate?: Maybe<Scalars['Float']>;
  no_payments?: Maybe<Scalars['Int']>;
  ignore?: Maybe<Scalars['Boolean']>;
  day_of_month?: Maybe<Scalars['Int']>;
  day_of_week?: Maybe<Scalars['String']>;
  custom_data?: Maybe<Array<Maybe<CustomCf>>>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  business_type: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  date_founded?: Maybe<Scalars['String']>;
  no_employees?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  last_accessed?: Maybe<Scalars['String']>;
  company_users?: Maybe<Array<Maybe<Share>>>;
  role?: Maybe<Scalars['String']>;
  usage?: Maybe<Usage>;
  data_files?: Maybe<Array<Maybe<DataFile>>>;
  filter_list?: Maybe<FilterData>;
  first_time_user?: Maybe<Scalars['Boolean']>;
  kpis?: Maybe<Array<Maybe<Kpi>>>;
  transactions?: Maybe<Array<Maybe<TransactionResponse>>>;
  transaction_breakdown?: Maybe<Array<Maybe<Breakdown>>>;
  agg_transactions?: Maybe<Array<Maybe<HistoricalBreakdown>>>;
  cash_in_bank?: Maybe<Array<Maybe<CashInBank>>>;
  kpi_drilldown?: Maybe<DrillDownSummary>;
  integrations?: Maybe<Array<Maybe<Integration>>>;
  scenarios?: Maybe<Array<Maybe<Scenarios>>>;
  connected_integrations?: Maybe<Array<Maybe<ConnectedIntegration>>>;
  data_status?: Maybe<Array<Maybe<DataStatus>>>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  transaction_info?: Maybe<Array<Maybe<TransactionInfo>>>;
};


export type CompanyKpisArgs = {
  dummy_data?: Maybe<Scalars['Boolean']>;
};


export type CompanyTransactionsArgs = {
  filter?: Maybe<TransactionFilter>;
  on?: Maybe<Scalars['String']>;
  forward?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};


export type CompanyTransaction_BreakdownArgs = {
  transaction_type: Scalars['String'];
  period: Scalars['String'];
  no_groups?: Maybe<Scalars['Int']>;
};


export type CompanyAgg_TransactionsArgs = {
  transaction_type: Scalars['String'];
  period: Scalars['String'];
  no_periods?: Maybe<Scalars['Int']>;
  chart_data?: Maybe<Scalars['Boolean']>;
};


export type CompanyKpi_DrilldownArgs = {
  kpi: Scalars['String'];
};


export type CompanyScenariosArgs = {
  name?: Maybe<Scalars['String']>;
};

export type CompanyAll = {
  __typename?: 'CompanyAll';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  description: Scalars['String'];
  business_type: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  date_founded?: Maybe<Scalars['String']>;
  usage?: Maybe<Usage>;
  no_employees?: Maybe<Scalars['String']>;
  kpis?: Maybe<Array<Maybe<Kpi>>>;
  company_users?: Maybe<Array<Maybe<Share>>>;
  connected_integrations?: Maybe<Array<Maybe<ConnectedIntegration>>>;
  integrations?: Maybe<Array<Maybe<Integration>>>;
  scenarios?: Maybe<Array<Maybe<Scenarios>>>;
  transactions?: Maybe<Array<Maybe<TransactionResponse>>>;
  data_status?: Maybe<Array<Maybe<DataStatus>>>;
  created?: Maybe<Scalars['String']>;
  last_accessed?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Role>>>;
};


export type CompanyAllKpisArgs = {
  dummy_data?: Maybe<Scalars['Boolean']>;
};


export type CompanyAllScenariosArgs = {
  name?: Maybe<Scalars['String']>;
};


export type CompanyAllTransactionsArgs = {
  filter?: Maybe<TransactionFilter>;
  on?: Maybe<Scalars['String']>;
  forward?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};

export type CompanyInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  business_type: Scalars['String'];
  country: Scalars['String'];
  city: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  date_founded: Scalars['String'];
  no_employees: Scalars['String'];
};

export type CompanyResponse = {
  __typename?: 'CompanyResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<Company>;
  id?: Maybe<Scalars['String']>;
};

export type CompanyShare = {
  id?: Maybe<Scalars['String']>;
  request_email: Scalars['String'];
};

export type CompanyUpdateInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  business_type?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  date_founded?: Maybe<Scalars['String']>;
  no_employees?: Maybe<Scalars['String']>;
};

export type ConnectedIntegration = {
  __typename?: 'ConnectedIntegration';
  name?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  integration_type?: Maybe<Scalars['String']>;
  what_can_do?: Maybe<Array<Maybe<Scalars['String']>>>;
  what_need?: Maybe<Array<Maybe<Scalars['String']>>>;
  image_url?: Maybe<Scalars['String']>;
  connected?: Maybe<Scalars['Boolean']>;
  connected_files?: Maybe<Scalars['Int']>;
};

export type CountryList = {
  __typename?: 'CountryList';
  country?: Maybe<Scalars['String']>;
  iso_country?: Maybe<Scalars['String']>;
  currency_name?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  currency_symbol?: Maybe<Scalars['String']>;
  currency_suffix?: Maybe<Scalars['Boolean']>;
  currency_display_name?: Maybe<Scalars['String']>;
};

export type CurrencyList = {
  __typename?: 'CurrencyList';
  currency_name?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  currency_symbol?: Maybe<Scalars['String']>;
  currency_suffix?: Maybe<Scalars['Boolean']>;
  currency_display_name?: Maybe<Scalars['String']>;
};

export enum CurrentValueSuffixes {
  Percentage = 'percentage',
  Currency = 'currency',
  None = 'none'
}

export type CustomCf = {
  __typename?: 'CustomCF';
  date?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type CustomCfInput = {
  date?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type DataFile = {
  __typename?: 'DataFile';
  file_id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  upload_date?: Maybe<Scalars['String']>;
};

export type DataFileData = {
  transaction_id?: Maybe<Scalars['ID']>;
  date: Scalars['String'];
  amount: Scalars['Float'];
  merchant?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  payment_channel?: Maybe<Scalars['String']>;
  pending?: Maybe<Scalars['Boolean']>;
  refund?: Maybe<Scalars['Boolean']>;
  ignore?: Maybe<Scalars['Boolean']>;
  customer_id?: Maybe<Scalars['String']>;
  customer_pcode?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  customer_city?: Maybe<Scalars['String']>;
  customer_country?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  subscription?: Maybe<Scalars['String']>;
};

export type DataFileInput = {
  data: Array<Maybe<DataFileData>>;
  company_id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
};

export type DataFileResponse = {
  __typename?: 'DataFileResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<DataFile>;
  id?: Maybe<Scalars['String']>;
};

export type DataStatus = {
  __typename?: 'DataStatus';
  account_type?: Maybe<Scalars['String']>;
  colour?: Maybe<Scalars['String']>;
  connection_status?: Maybe<Scalars['String']>;
  no_items?: Maybe<Scalars['Int']>;
  main_text?: Maybe<Scalars['String']>;
  sub_text?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  button_inactive?: Maybe<Scalars['Boolean']>;
};

export type DrillDownItem = {
  __typename?: 'DrillDownItem';
  date: Scalars['String'];
  amount: Scalars['Float'];
  month?: Maybe<Scalars['String']>;
};

export type DrillDownSummary = {
  __typename?: 'DrillDownSummary';
  monthly?: Maybe<Array<Maybe<DrillDownItem>>>;
  Jan?: Maybe<Array<Maybe<DrillDownItem>>>;
  Feb?: Maybe<Array<Maybe<DrillDownItem>>>;
  Mar?: Maybe<Array<Maybe<DrillDownItem>>>;
  Apr?: Maybe<Array<Maybe<DrillDownItem>>>;
  May?: Maybe<Array<Maybe<DrillDownItem>>>;
  Jun?: Maybe<Array<Maybe<DrillDownItem>>>;
  Jul?: Maybe<Array<Maybe<DrillDownItem>>>;
  Aug?: Maybe<Array<Maybe<DrillDownItem>>>;
  Sep?: Maybe<Array<Maybe<DrillDownItem>>>;
  Oct?: Maybe<Array<Maybe<DrillDownItem>>>;
  Nov?: Maybe<Array<Maybe<DrillDownItem>>>;
  Dec?: Maybe<Array<Maybe<DrillDownItem>>>;
};

export type Error = {
  __typename?: 'Error';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Fx = {
  __typename?: 'FX';
  currency?: Maybe<Scalars['String']>;
  base?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type FilterData = {
  __typename?: 'FilterData';
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  transaction_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  display_transactions?: Maybe<Array<Maybe<Scalars['String']>>>;
  transaction_columns?: Maybe<Array<Maybe<TransactionColumns>>>;
};

export type HistoricalBreakdown = {
  __typename?: 'HistoricalBreakdown';
  date?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type Integration = {
  __typename?: 'Integration';
  company_id: Scalars['ID'];
  item_id: Scalars['String'];
  integration_name: Scalars['String'];
  integration_type?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  mapping?: Maybe<Scalars['String']>;
  replace_transactions?: Maybe<Array<Maybe<Scalars['String']>>>;
  mime_type?: Maybe<Scalars['String']>;
  override_currency?: Maybe<Scalars['String']>;
  sheet?: Maybe<Scalars['String']>;
  webhook?: Maybe<Scalars['Boolean']>;
  webhook_id?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
};

export type IntegrationDeleteInput = {
  company_id: Scalars['ID'];
  item_id: Scalars['String'];
  provider: Scalars['String'];
};

export type IntegrationFile = {
  __typename?: 'IntegrationFile';
  id?: Maybe<Scalars['ID']>;
  mime_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sheets?: Maybe<Array<Maybe<Scalars['String']>>>;
  columns?: Maybe<Array<Maybe<SheetColumns>>>;
  error?: Maybe<QueryError>;
};

export type IntegrationInput = {
  company_id: Scalars['ID'];
  item_id: Scalars['String'];
  integration_name: Scalars['String'];
  integration_type?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  mapping?: Maybe<Scalars['String']>;
  replace_transactions?: Maybe<Array<Maybe<Scalars['String']>>>;
  sheet?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  override_currency?: Maybe<Scalars['String']>;
};

export type IntegrationRefreshInput = {
  company_id: Scalars['ID'];
  item_id: Scalars['String'];
  provider: Scalars['String'];
};

export type IntegrationResponse = {
  __typename?: 'IntegrationResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<Integration>;
  id?: Maybe<Scalars['String']>;
};

export type IntegrationStatus = {
  __typename?: 'IntegrationStatus';
  provider?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
};

export type IntegrationUpdateInput = {
  company_id: Scalars['ID'];
  item_id: Scalars['String'];
  integration_name?: Maybe<Scalars['String']>;
  integration_type?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  mapping?: Maybe<Scalars['String']>;
  replace_transactions?: Maybe<Array<Maybe<Scalars['String']>>>;
  mime_type?: Maybe<Scalars['String']>;
  sheet?: Maybe<Scalars['String']>;
  override_currency?: Maybe<Scalars['String']>;
};

export type InviteUser = {
  __typename?: 'InviteUser';
  id?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type Kpi = {
  __typename?: 'KPI';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  current_value?: Maybe<Scalars['Float']>;
  current_value_suffix?: Maybe<CurrentValueSuffixes>;
  headline?: Maybe<Scalars['String']>;
  percentage_change?: Maybe<Scalars['Float']>;
  positive_change?: Maybe<Scalars['Boolean']>;
  subheading?: Maybe<Scalars['String']>;
  intelligence?: Maybe<Scalars['String']>;
  show_currency?: Maybe<Scalars['Boolean']>;
  is_percentage?: Maybe<Scalars['Boolean']>;
  breakdown_chart_title?: Maybe<Scalars['String']>;
  breakdown_chart?: Maybe<Array<Maybe<KpiBreakdownData>>>;
  full_breakdown?: Maybe<Array<Maybe<KpiBreakdownData>>>;
  chart_data?: Maybe<Array<Maybe<KpiChartData>>>;
  alerts?: Maybe<Array<Maybe<Alert>>>;
};

export type KpiBreakdownData = {
  __typename?: 'KPIBreakdownData';
  item?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type KpiChartData = {
  __typename?: 'KPIChartData';
  date?: Maybe<Scalars['String']>;
  display_date?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createUser?: Maybe<UserResponse>;
  deleteUser?: Maybe<UserResponse>;
  lastAccessedUser?: Maybe<UserResponse>;
  updateUser?: Maybe<UserResponse>;
  updateUserPlan?: Maybe<UserPlanResponse>;
  createCompany?: Maybe<CompanyResponse>;
  deleteCompany?: Maybe<CompanyResponse>;
  lastAccessedCompany?: Maybe<CompanyResponse>;
  updateCompany?: Maybe<CompanyResponse>;
  shareCompany?: Maybe<ShareResponse>;
  resolveCompanyShare?: Maybe<CompanyResponse>;
  expiredCompanyShare?: Maybe<Response>;
  createRole?: Maybe<RoleResponse>;
  deleteRole?: Maybe<RoleResponse>;
  updateRole?: Maybe<RoleResponse>;
  createIntegration?: Maybe<Response>;
  deleteIntegration?: Maybe<Response>;
  updateIntegration?: Maybe<Response>;
  refreshIntegration?: Maybe<Response>;
  updateTransactionInfo?: Maybe<TransactionInfoResponse>;
  deleteAccount?: Maybe<AccountResponse>;
  addTransactions?: Maybe<Response>;
  uploadDataFile?: Maybe<DataFileResponse>;
  deleteDataFile?: Maybe<DataFileResponse>;
  createScenario?: Maybe<ScenarioResponse>;
  updateScenario?: Maybe<ScenarioResponse>;
  deleteScenario?: Maybe<ScenarioResponse>;
  createAlert?: Maybe<AlertResponse>;
  updateAlert?: Maybe<AlertResponse>;
  deleteAlert?: Maybe<AlertResponse>;
  runSimulation?: Maybe<Response>;
  createCashflow?: Maybe<CashflowResponse>;
  updateCashflow?: Maybe<CashflowResponse>;
  deleteCashflow?: Maybe<CashflowResponse>;
  updateFX?: Maybe<Response>;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationLastAccessedUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationUpdateUserArgs = {
  input: UserUpdateInput;
};


export type MutationUpdateUserPlanArgs = {
  input: UserPlanInput;
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationDeleteCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationLastAccessedCompanyArgs = {
  company_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['ID']>;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyUpdateInput;
};


export type MutationShareCompanyArgs = {
  input: ShareCompanyInput;
};


export type MutationResolveCompanyShareArgs = {
  input: ResolveShareCompanyInput;
};


export type MutationExpiredCompanyShareArgs = {
  tkn: Scalars['String'];
};


export type MutationCreateRoleArgs = {
  input: RoleInput;
};


export type MutationDeleteRoleArgs = {
  user_id?: Maybe<Scalars['ID']>;
  company_id?: Maybe<Scalars['ID']>;
};


export type MutationUpdateRoleArgs = {
  input: RoleInput;
};


export type MutationCreateIntegrationArgs = {
  input: IntegrationInput;
};


export type MutationDeleteIntegrationArgs = {
  input: IntegrationDeleteInput;
};


export type MutationUpdateIntegrationArgs = {
  input: IntegrationUpdateInput;
};


export type MutationRefreshIntegrationArgs = {
  input: IntegrationRefreshInput;
};


export type MutationUpdateTransactionInfoArgs = {
  input?: Maybe<TransactionInfoInput>;
};


export type MutationDeleteAccountArgs = {
  company_id: Scalars['ID'];
  account_id: Scalars['ID'];
};


export type MutationAddTransactionsArgs = {
  input: TransactionInput;
};


export type MutationUploadDataFileArgs = {
  input: DataFileInput;
};


export type MutationDeleteDataFileArgs = {
  company_id: Scalars['ID'];
  file_id: Scalars['ID'];
};


export type MutationCreateScenarioArgs = {
  input: ScenarioInput;
};


export type MutationUpdateScenarioArgs = {
  input: ScenarioInput;
};


export type MutationDeleteScenarioArgs = {
  company_id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateAlertArgs = {
  input: AlertInput;
};


export type MutationUpdateAlertArgs = {
  input: AlertUpdateInput;
};


export type MutationDeleteAlertArgs = {
  company_id: Scalars['ID'];
  kpi_name: Scalars['String'];
  alert_id: Scalars['ID'];
};


export type MutationRunSimulationArgs = {
  company_id: Scalars['String'];
  scenario_name: Scalars['String'];
};


export type MutationCreateCashflowArgs = {
  input: CashflowInput;
};


export type MutationUpdateCashflowArgs = {
  input: CashflowInput;
};


export type MutationDeleteCashflowArgs = {
  company_id: Scalars['String'];
  scenario_name: Scalars['String'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  users?: Maybe<Array<Maybe<User>>>;
  user?: Maybe<User>;
  companies?: Maybe<Array<CompanyAll>>;
  roles?: Maybe<Array<Role>>;
  share?: Maybe<Array<Maybe<Share>>>;
  data_files?: Maybe<Array<Maybe<DataFile>>>;
  FXs?: Maybe<Array<Fx>>;
  system: System;
  KPIs?: Maybe<Array<Maybe<Kpi>>>;
  integration_status?: Maybe<IntegrationStatus>;
  simulations?: Maybe<Array<Maybe<Simulation>>>;
  alerts?: Maybe<Array<Maybe<Alert>>>;
  transactions?: Maybe<Array<Maybe<TransactionResponse>>>;
  file_integration?: Maybe<Array<Maybe<IntegrationFile>>>;
  accounts?: Maybe<Array<Maybe<Account>>>;
  transaction_info?: Maybe<Array<Maybe<TransactionInfo>>>;
  quotas?: Maybe<Array<Maybe<Quota>>>;
};


export type QueryUsersArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCompaniesArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryRolesArgs = {
  user_id?: Maybe<Scalars['ID']>;
  company_id?: Maybe<Scalars['ID']>;
};


export type QueryShareArgs = {
  company_id?: Maybe<Scalars['ID']>;
};


export type QueryData_FilesArgs = {
  company_id?: Maybe<Scalars['ID']>;
};


export type QueryKpIsArgs = {
  company_id: Scalars['ID'];
  kpi_name?: Maybe<Scalars['String']>;
  dummy_data?: Maybe<Scalars['Boolean']>;
};


export type QueryIntegration_StatusArgs = {
  company_id: Scalars['String'];
  provider: Scalars['String'];
};


export type QuerySimulationsArgs = {
  company_id: Scalars['ID'];
  scenario_name?: Maybe<Scalars['String']>;
};


export type QueryAlertsArgs = {
  company_id: Scalars['ID'];
  kpi_name?: Maybe<Scalars['String']>;
};


export type QueryTransactionsArgs = {
  company_id: Scalars['ID'];
  filter?: Maybe<TransactionFilter>;
  on?: Maybe<Scalars['String']>;
  forward?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
};


export type QueryFile_IntegrationArgs = {
  company_id: Scalars['ID'];
  provider: Scalars['String'];
  file_id: Scalars['String'];
};


export type QueryAccountsArgs = {
  company_id?: Maybe<Scalars['ID']>;
};


export type QueryTransaction_InfoArgs = {
  company_id?: Maybe<Scalars['ID']>;
};


export type QueryQuotasArgs = {
  plan_name?: Maybe<Scalars['String']>;
};

export type QueryError = {
  __typename?: 'QueryError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Quota = {
  __typename?: 'Quota';
  plan?: Maybe<Scalars['String']>;
  no_companies_allowed?: Maybe<Scalars['Int']>;
  no_scenarios?: Maybe<Scalars['Int']>;
  no_bank_accounts?: Maybe<Scalars['Int']>;
  no_apps?: Maybe<Scalars['Int']>;
};

export type ResolveShareCompanyInput = {
  tkn: Scalars['String'];
  accepted: Scalars['Boolean'];
};

export type Response = {
  __typename?: 'Response';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  user?: Maybe<User>;
  company?: Maybe<Company>;
  role?: Maybe<Scalars['String']>;
};

export type RoleInput = {
  user_id: Scalars['ID'];
  company_id: Scalars['String'];
  role?: Maybe<Scalars['String']>;
};

export type RoleResponse = {
  __typename?: 'RoleResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<Role>;
  id?: Maybe<Scalars['String']>;
};

export type ScenarioInput = {
  company_id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  optimising_for?: Maybe<Scalars['String']>;
};

export type ScenarioResponse = {
  __typename?: 'ScenarioResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<Scenarios>;
  id?: Maybe<Scalars['String']>;
};

export type Scenarios = {
  __typename?: 'Scenarios';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  optimising_for?: Maybe<Scalars['String']>;
  sim_date?: Maybe<Scalars['String']>;
  cashflows?: Maybe<Array<Maybe<Cashflows>>>;
  simulation?: Maybe<Array<Maybe<Simulation>>>;
  created?: Maybe<Scalars['String']>;
};


export type ScenariosCashflowsArgs = {
  name?: Maybe<Scalars['String']>;
};

export type Share = {
  __typename?: 'Share';
  company_id?: Maybe<Scalars['String']>;
  request_email?: Maybe<Scalars['String']>;
  request_name?: Maybe<Scalars['String']>;
  invitee?: Maybe<InviteUser>;
  user?: Maybe<User>;
  shared_by?: Maybe<InviteUser>;
  status?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['String']>;
  last_accessed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
};

export type ShareCompanyInput = {
  id: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
};

export type ShareResponse = {
  __typename?: 'ShareResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<Share>;
  id?: Maybe<Scalars['String']>;
};

export type SheetColumns = {
  __typename?: 'SheetColumns';
  sheet?: Maybe<Scalars['String']>;
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SimResults = {
  __typename?: 'SimResults';
  date?: Maybe<Scalars['String']>;
  revenue?: Maybe<Scalars['Float']>;
  expense?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  cash_in_bank?: Maybe<Scalars['Float']>;
  revenue_growth?: Maybe<Scalars['Float']>;
  forecast_type?: Maybe<Scalars['String']>;
};

export type SimStatistics = {
  __typename?: 'SimStatistics';
  runway_period?: Maybe<Scalars['String']>;
  runway?: Maybe<Scalars['Int']>;
  required_cash?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  average_profit?: Maybe<Scalars['Float']>;
  growth?: Maybe<Scalars['Float']>;
  average_growth?: Maybe<Scalars['Float']>;
  prob_no_cash?: Maybe<Scalars['Float']>;
};

export type Simulation = {
  __typename?: 'Simulation';
  sim_date?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  sim_data?: Maybe<Array<Maybe<SimResults>>>;
  stats?: Maybe<SimStatistics>;
  compare_to_base?: Maybe<SimStatistics>;
};

export type System = {
  __typename?: 'System';
  trial_on_register?: Maybe<Scalars['Boolean']>;
  trial_length?: Maybe<Scalars['Int']>;
  countries?: Maybe<Array<Maybe<CountryList>>>;
  currencies?: Maybe<Array<Maybe<CurrencyList>>>;
  business_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  time_zones?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_notifications?: Maybe<Array<Maybe<Scalars['String']>>>;
  access_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  transaction_categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  pricing?: Maybe<Scalars['Float']>;
};


export type SystemCountriesArgs = {
  country?: Maybe<Scalars['String']>;
};


export type SystemPricingArgs = {
  revenue: Scalars['Float'];
};

export type Transaction = {
  __typename?: 'Transaction';
  company_id: Scalars['ID'];
  transaction_id: Scalars['String'];
  merchant?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  amount: Scalars['Float'];
  date: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  payment_channel?: Maybe<Scalars['String']>;
  pending?: Maybe<Scalars['String']>;
  transaction_info?: Maybe<TransactionInfo>;
  ignore?: Maybe<Scalars['Boolean']>;
  last_updated?: Maybe<Scalars['String']>;
  details?: Maybe<TransactionDetails>;
};

export type TransactionColumns = {
  __typename?: 'TransactionColumns';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type TransactionDetails = {
  __typename?: 'TransactionDetails';
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  repeat?: Maybe<Scalars['String']>;
  number_transactions?: Maybe<Scalars['Int']>;
  total_transactions?: Maybe<Scalars['Float']>;
  avg_transactions?: Maybe<Scalars['Float']>;
};

export type TransactionFilter = {
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  lower_amount?: Maybe<Scalars['Float']>;
  upper_amount?: Maybe<Scalars['Float']>;
  category?: Maybe<Array<Maybe<Scalars['String']>>>;
  payment_channel?: Maybe<Array<Maybe<Scalars['String']>>>;
  transaction_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  transaction_name?: Maybe<Array<Maybe<Scalars['String']>>>;
  search?: Maybe<Scalars['String']>;
  recurring?: Maybe<Scalars['String']>;
  include_ignore?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TransactionInfo = {
  __typename?: 'TransactionInfo';
  company_id: Scalars['ID'];
  transaction_name: Scalars['String'];
  display_name: Scalars['String'];
  transaction_type?: Maybe<Scalars['String']>;
  recurring?: Maybe<Scalars['String']>;
  continuous?: Maybe<Scalars['Boolean']>;
  recurring_end_date?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  ignore?: Maybe<Scalars['Boolean']>;
  new?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TransactionInfoInput = {
  company_id: Scalars['ID'];
  transaction_name: Scalars['String'];
  display_name?: Maybe<Scalars['String']>;
  transaction_type?: Maybe<Scalars['String']>;
  recurring?: Maybe<Scalars['String']>;
  continuous?: Maybe<Scalars['Boolean']>;
  recurring_end_date?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  ignore?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TransactionInfoResponse = {
  __typename?: 'TransactionInfoResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<TransactionInfo>;
  id?: Maybe<Scalars['String']>;
};

export type TransactionInput = {
  data: Scalars['JSON'];
  company_id: Scalars['String'];
  name: Scalars['String'];
};

export type TransactionResponse = {
  __typename?: 'TransactionResponse';
  data?: Maybe<Array<Maybe<Transaction>>>;
  cursor?: Maybe<Scalars['String']>;
  request_size?: Maybe<Scalars['Int']>;
  number_remaining?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['Int']>;
};


export type Usage = {
  __typename?: 'Usage';
  company_name?: Maybe<Scalars['String']>;
  no_scenarios?: Maybe<Scalars['Int']>;
  no_bank_accounts?: Maybe<Scalars['Int']>;
  no_apps?: Maybe<Scalars['Int']>;
  scenario_quota_met?: Maybe<Scalars['Boolean']>;
  bank_quota_met?: Maybe<Scalars['Boolean']>;
  app_quota_met?: Maybe<Scalars['Boolean']>;
  quota_met?: Maybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  support_id?: Maybe<Scalars['String']>;
  tracking_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  referral_source?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  email_notifications?: Maybe<Scalars['String']>;
  last_accessed?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  companies?: Maybe<Array<Maybe<Company>>>;
  company?: Maybe<Company>;
  fx_rates?: Maybe<Array<Maybe<Fx>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  shared_with_me?: Maybe<Array<Maybe<Share>>>;
  shared_by_me?: Maybe<Array<Maybe<Share>>>;
  user_plan?: Maybe<UserPlan>;
  error?: Maybe<QueryError>;
};


export type UserCompaniesArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type UserCompanyArgs = {
  id: Scalars['ID'];
};

export type UserInput = {
  id?: Maybe<Scalars['ID']>;
  support_id?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['String'];
  time_zone: Scalars['String'];
  currency: Scalars['String'];
};

export type UserPlan = {
  __typename?: 'UserPlan';
  user?: Maybe<User>;
  quota?: Maybe<Quota>;
  no_companies?: Maybe<Scalars['Int']>;
  company_usage?: Maybe<Array<Maybe<Usage>>>;
  active_trial?: Maybe<Scalars['Boolean']>;
  no_trial_days?: Maybe<Scalars['Int']>;
  company_quota_met?: Maybe<Scalars['Boolean']>;
};

export type UserPlanInput = {
  user_id: Scalars['String'];
  plan_name: Scalars['String'];
};

export type UserPlanResponse = {
  __typename?: 'UserPlanResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<UserPlan>;
  id?: Maybe<Scalars['String']>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  status: Scalars['Boolean'];
  error?: Maybe<Error>;
  response?: Maybe<Scalars['String']>;
  object?: Maybe<User>;
  id?: Maybe<Scalars['String']>;
};

export type UserUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  support_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  time_zone?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  email_notifications?: Maybe<Scalars['String']>;
};

export type GetTransactionsQueryVariables = Exact<{
  company_id: Scalars['ID'];
  limit: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<TransactionFilter>;
}>;


export type GetTransactionsQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', company?: Maybe<{ __typename?: 'Company', transactions?: Maybe<Array<Maybe<{ __typename?: 'TransactionResponse', cursor?: Maybe<string>, request_size?: Maybe<number>, number_remaining?: Maybe<number>, total_count?: Maybe<number>, data?: Maybe<Array<Maybe<{ __typename?: 'Transaction', company_id: string, transaction_id: string, merchant?: Maybe<string>, name: string, amount: number, date: string, currency?: Maybe<string>, payment_channel?: Maybe<string>, pending?: Maybe<string>, ignore?: Maybe<boolean>, last_updated?: Maybe<string>, details?: Maybe<{ __typename?: 'TransactionDetails', name?: Maybe<string>, display_name?: Maybe<string>, category?: Maybe<string>, repeat?: Maybe<string>, amount?: Maybe<number>, avg_transactions?: Maybe<number>, number_transactions?: Maybe<number>, total_transactions?: Maybe<number> }>, transaction_info?: Maybe<{ __typename?: 'TransactionInfo', transaction_name: string, company_id: string, category: string, tags?: Maybe<Array<Maybe<string>>> }> }>>> }>>> }> }> };

export type GetTransactionBreakDownQueryVariables = Exact<{
  transaction_type: Scalars['String'];
  company_id: Scalars['ID'];
  period: Scalars['String'];
  no_groups: Scalars['Int'];
}>;


export type GetTransactionBreakDownQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, companies?: Maybe<Array<Maybe<{ __typename?: 'Company', id: string, name: string, agg_transactions?: Maybe<Array<Maybe<{ __typename?: 'HistoricalBreakdown', date?: Maybe<string>, amount?: Maybe<number> }>>>, transaction_breakdown?: Maybe<Array<Maybe<{ __typename?: 'Breakdown', name?: Maybe<string>, category?: Maybe<string>, amount?: Maybe<number>, percentage?: Maybe<number> }>>> }>>> }> };

export type GetFilterListQueryVariables = Exact<{
  company_id: Scalars['ID'];
}>;


export type GetFilterListQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', companies?: Maybe<Array<Maybe<{ __typename?: 'Company', filter_list?: Maybe<{ __typename?: 'FilterData', channels?: Maybe<Array<Maybe<string>>>, categories?: Maybe<Array<Maybe<string>>>, tags?: Maybe<Array<Maybe<string>>>, transaction_names?: Maybe<Array<Maybe<string>>>, transaction_columns?: Maybe<Array<Maybe<{ __typename?: 'TransactionColumns', name?: Maybe<string>, description?: Maybe<string>, required?: Maybe<boolean>, type?: Maybe<string> }>>> }> }>>> }> };

export type GetCashInBankQueryVariables = Exact<{
  company_id: Scalars['ID'];
}>;


export type GetCashInBankQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', companies?: Maybe<Array<Maybe<{ __typename?: 'Company', cash_in_bank?: Maybe<Array<Maybe<{ __typename?: 'CashInBank', date: string, amount: number }>>> }>>> }> };

export type GetUserInCompanyQueryVariables = Exact<{
  company_id: Scalars['ID'];
}>;


export type GetUserInCompanyQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, first_name?: Maybe<string>, last_name?: Maybe<string>, email?: Maybe<string>, currency?: Maybe<string>, companies?: Maybe<Array<Maybe<{ __typename?: 'Company', role?: Maybe<string> }>>> }> };

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, first_name?: Maybe<string>, last_name?: Maybe<string>, email?: Maybe<string>, time_zone?: Maybe<string>, currency?: Maybe<string> }> };

export type GetAccountQueryVariables = Exact<{
  company_id: Scalars['ID'];
}>;


export type GetAccountQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', company?: Maybe<{ __typename?: 'Company', id: string, name: string, description: string, accounts?: Maybe<Array<Maybe<{ __typename?: 'Account', company_id: string, account_id: string, account_name: string, institution?: Maybe<string>, account_type: string, currency?: Maybe<string>, balance: number, last_updated?: Maybe<string>, created?: Maybe<string> }>>> }> }> };

export type DeleteAccountMutationVariables = Exact<{
  account_id: Scalars['ID'];
  company_id: Scalars['ID'];
}>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount?: Maybe<{ __typename?: 'AccountResponse', status: boolean, response?: Maybe<string>, error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }>, object?: Maybe<{ __typename?: 'Account', account_id: string, company_id: string }> }> };

export type GetIntegrationInfoQueryVariables = Exact<{
  company_id: Scalars['ID'];
}>;


export type GetIntegrationInfoQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', companies?: Maybe<Array<Maybe<{ __typename?: 'Company', connected_integrations?: Maybe<Array<Maybe<{ __typename?: 'ConnectedIntegration', integration_type?: Maybe<string>, name?: Maybe<string>, display_name?: Maybe<string>, what_can_do?: Maybe<Array<Maybe<string>>>, what_need?: Maybe<Array<Maybe<string>>>, image_url?: Maybe<string>, connected?: Maybe<boolean>, connected_files?: Maybe<number> }>>> }>>> }> };

export type UploadDataMutationVariables = Exact<{
  dataFileInput: DataFileInput;
}>;


export type UploadDataMutation = { __typename?: 'Mutation', uploadDataFile?: Maybe<{ __typename?: 'DataFileResponse', status: boolean, id?: Maybe<string>, response?: Maybe<string>, error?: Maybe<{ __typename?: 'Error', message?: Maybe<string>, code?: Maybe<string> }>, object?: Maybe<{ __typename: 'DataFile', file_id?: Maybe<string>, name?: Maybe<string>, description?: Maybe<string>, upload_date?: Maybe<string> }> }> };

export type DeleteDataFileMutationVariables = Exact<{
  company_id: Scalars['ID'];
  fileid: Scalars['ID'];
}>;


export type DeleteDataFileMutation = { __typename?: 'Mutation', deleteDataFile?: Maybe<{ __typename?: 'DataFileResponse', status: boolean, id?: Maybe<string>, response?: Maybe<string>, error?: Maybe<{ __typename?: 'Error', message?: Maybe<string>, code?: Maybe<string> }> }> };

export type GetFileDataQueryVariables = Exact<{
  company_id: Scalars['ID'];
  provider: Scalars['String'];
  file_id: Scalars['String'];
}>;


export type GetFileDataQuery = { __typename?: 'Query', file_integration?: Maybe<Array<Maybe<{ __typename?: 'IntegrationFile', id?: Maybe<string>, sheets?: Maybe<Array<Maybe<string>>>, columns?: Maybe<Array<Maybe<{ __typename?: 'SheetColumns', sheet?: Maybe<string>, columns?: Maybe<Array<Maybe<string>>> }>>> }>>> };

export type UpdateIntegrationMutationVariables = Exact<{
  company_id: Scalars['ID'];
  item_id: Scalars['String'];
  provider: Scalars['String'];
  mapping: Scalars['String'];
  replace_transactions?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type UpdateIntegrationMutation = { __typename?: 'Mutation', updateIntegration?: Maybe<{ __typename?: 'Response', status: boolean, response?: Maybe<string>, error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type GetTransactionNameQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTransactionNameQuery = { __typename?: 'Query', transaction_info?: Maybe<Array<Maybe<{ __typename?: 'TransactionInfo', transaction_name: string }>>> };

export type GetDataFilesQueryVariables = Exact<{
  company_id: Scalars['ID'];
}>;


export type GetDataFilesQuery = { __typename?: 'Query', data_files?: Maybe<Array<Maybe<{ __typename?: 'DataFile', file_id?: Maybe<string>, name?: Maybe<string>, description?: Maybe<string>, upload_date?: Maybe<string> }>>> };

export type GetAccountCardQueryVariables = Exact<{
  company_id?: Maybe<Scalars['ID']>;
}>;


export type GetAccountCardQuery = { __typename?: 'Query', companies?: Maybe<Array<{ __typename?: 'CompanyAll', data_status?: Maybe<Array<Maybe<{ __typename?: 'DataStatus', account_type?: Maybe<string>, connection_status?: Maybe<string>, colour?: Maybe<string>, main_text?: Maybe<string>, sub_text?: Maybe<string>, no_items?: Maybe<number>, button_inactive?: Maybe<boolean>, button_text?: Maybe<string> }>>> }>> };

export type CompanyUsageQueryVariables = Exact<{
  company_id?: Maybe<Scalars['ID']>;
}>;


export type CompanyUsageQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', companies?: Maybe<Array<Maybe<{ __typename?: 'Company', id: string, name: string, role?: Maybe<string>, usage?: Maybe<{ __typename?: 'Usage', no_apps?: Maybe<number>, no_scenarios?: Maybe<number>, no_bank_accounts?: Maybe<number>, bank_quota_met?: Maybe<boolean>, app_quota_met?: Maybe<boolean>, scenario_quota_met?: Maybe<boolean>, quota_met?: Maybe<boolean> }> }>>> }> };

export type IntegrationStatusQueryVariables = Exact<{
  company_id: Scalars['String'];
  provider: Scalars['String'];
}>;


export type IntegrationStatusQuery = { __typename?: 'Query', integration_status?: Maybe<{ __typename?: 'IntegrationStatus', provider?: Maybe<string>, company_id?: Maybe<string>, status?: Maybe<string>, created?: Maybe<string> }> };

export type IsFirstUserQueryVariables = Exact<{
  company_id?: Maybe<Scalars['ID']>;
}>;


export type IsFirstUserQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', companies?: Maybe<Array<Maybe<{ __typename?: 'Company', first_time_user?: Maybe<boolean> }>>> }> };

export type LastAccessedCompanyMutationVariables = Exact<{
  companyId?: Maybe<Scalars['ID']>;
}>;


export type LastAccessedCompanyMutation = { __typename?: 'Mutation', lastAccessedCompany?: Maybe<{ __typename?: 'CompanyResponse', status: boolean, id?: Maybe<string>, response?: Maybe<string>, error?: Maybe<{ __typename?: 'Error', message?: Maybe<string>, code?: Maybe<string> }> }> };

export type GetKpiQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type GetKpiQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', company?: Maybe<{ __typename?: 'Company', kpis?: Maybe<Array<Maybe<{ __typename?: 'KPI', name?: Maybe<string>, description?: Maybe<string>, category?: Maybe<string>, variant?: Maybe<string>, headline?: Maybe<string>, current_value?: Maybe<number>, current_value_suffix?: Maybe<CurrentValueSuffixes>, percentage_change?: Maybe<number>, positive_change?: Maybe<boolean>, intelligence?: Maybe<string>, subheading?: Maybe<string>, order?: Maybe<number>, breakdown_chart_title?: Maybe<string>, is_percentage?: Maybe<boolean>, breakdown_chart?: Maybe<Array<Maybe<{ __typename?: 'KPIBreakdownData', item?: Maybe<string>, percentage?: Maybe<number>, amount?: Maybe<number> }>>>, full_breakdown?: Maybe<Array<Maybe<{ __typename?: 'KPIBreakdownData', item?: Maybe<string>, percentage?: Maybe<number>, amount?: Maybe<number> }>>>, chart_data?: Maybe<Array<Maybe<{ __typename?: 'KPIChartData', date?: Maybe<string>, display_date?: Maybe<string>, amount?: Maybe<number> }>>> }>>> }> }> };

export type CreateKpiAlertMutationVariables = Exact<{
  alertInput: AlertInput;
}>;


export type CreateKpiAlertMutation = { __typename?: 'Mutation', createAlert?: Maybe<{ __typename?: 'AlertResponse', status: boolean, error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }>, object?: Maybe<{ __typename?: 'Alert', id?: Maybe<string>, kpi_name?: Maybe<string>, description?: Maybe<string>, condition?: Maybe<string>, created?: Maybe<string>, amount?: Maybe<number> }> }> };

export type UpdateKpiAlertMutationVariables = Exact<{
  alertInput: AlertUpdateInput;
}>;


export type UpdateKpiAlertMutation = { __typename?: 'Mutation', updateAlert?: Maybe<{ __typename?: 'AlertResponse', status: boolean, error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type DeleteKpiAlertMutationVariables = Exact<{
  company_id: Scalars['ID'];
  kpiName: Scalars['String'];
  alert_id: Scalars['ID'];
}>;


export type DeleteKpiAlertMutation = { __typename?: 'Mutation', deleteAlert?: Maybe<{ __typename?: 'AlertResponse', status: boolean, error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type GetKpiAlertsQueryVariables = Exact<{
  company_id: Scalars['ID'];
  kpi_name: Scalars['String'];
}>;


export type GetKpiAlertsQuery = { __typename?: 'Query', alerts?: Maybe<Array<Maybe<{ __typename?: 'Alert', id?: Maybe<string>, kpi_name?: Maybe<string>, description?: Maybe<string>, created?: Maybe<string>, last_triggered?: Maybe<string>, target_hit?: Maybe<boolean> }>>> };

export type GetScenariosQueryVariables = Exact<{
  company_id?: Maybe<Scalars['ID']>;
}>;


export type GetScenariosQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, companies?: Maybe<Array<Maybe<{ __typename?: 'Company', id: string, scenarios?: Maybe<Array<Maybe<{ __typename?: 'Scenarios', name: string, description?: Maybe<string>, optimising_for?: Maybe<string>, sim_date?: Maybe<string>, cashflows?: Maybe<Array<Maybe<{ __typename?: 'Cashflows', name: string, cashflow_type?: Maybe<string>, period?: Maybe<string>, start_date?: Maybe<string>, end_date?: Maybe<string>, no_periods?: Maybe<number>, first_amount?: Maybe<number>, amount?: Maybe<number>, display_amount?: Maybe<number>, upper_amount?: Maybe<number>, lower_amount?: Maybe<number>, cost_amount?: Maybe<number>, cost_upper?: Maybe<number>, cost_lower?: Maybe<number>, outstanding?: Maybe<number>, limit?: Maybe<number>, utilisation?: Maybe<string>, repayment_type?: Maybe<string>, payoff_period?: Maybe<number>, expense?: Maybe<boolean>, existing?: Maybe<boolean>, rate?: Maybe<number>, no_payments?: Maybe<number>, ignore?: Maybe<boolean>, day_of_month?: Maybe<number>, day_of_week?: Maybe<string>, custom_data?: Maybe<Array<Maybe<{ __typename?: 'CustomCF', date?: Maybe<string>, amount?: Maybe<number> }>>> }>>> }>>> }>>> }> };

export type CreateScenarioMutationVariables = Exact<{
  input: ScenarioInput;
}>;


export type CreateScenarioMutation = { __typename?: 'Mutation', createScenario?: Maybe<{ __typename?: 'ScenarioResponse', response?: Maybe<string>, status: boolean, id?: Maybe<string>, error?: Maybe<{ __typename?: 'Error', message?: Maybe<string>, code?: Maybe<string> }>, object?: Maybe<{ __typename?: 'Scenarios', name: string, description?: Maybe<string> }> }> };

export type UpdateScenarioMutationVariables = Exact<{
  input: ScenarioInput;
}>;


export type UpdateScenarioMutation = { __typename?: 'Mutation', updateScenario?: Maybe<{ __typename?: 'ScenarioResponse', response?: Maybe<string>, status: boolean, id?: Maybe<string>, error?: Maybe<{ __typename?: 'Error', message?: Maybe<string>, code?: Maybe<string> }>, object?: Maybe<{ __typename?: 'Scenarios', name: string, description?: Maybe<string> }> }> };

export type DeleteScenarioMutationVariables = Exact<{
  company_id: Scalars['String'];
  name: Scalars['String'];
}>;


export type DeleteScenarioMutation = { __typename?: 'Mutation', deleteScenario?: Maybe<{ __typename?: 'ScenarioResponse', response?: Maybe<string>, status: boolean, id?: Maybe<string>, error?: Maybe<{ __typename?: 'Error', message?: Maybe<string>, code?: Maybe<string> }>, object?: Maybe<{ __typename?: 'Scenarios', name: string, description?: Maybe<string> }> }> };

export type GetTransactionInfoQueryVariables = Exact<{
  company_id: Scalars['ID'];
}>;


export type GetTransactionInfoQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', id: string, company?: Maybe<{ __typename?: 'Company', id: string, transaction_info?: Maybe<Array<Maybe<{ __typename?: 'TransactionInfo', company_id: string, transaction_name: string, display_name: string, continuous?: Maybe<boolean>, transaction_type?: Maybe<string>, recurring?: Maybe<string>, recurring_end_date?: Maybe<string>, category: string, ignore?: Maybe<boolean>, new?: Maybe<boolean>, tags?: Maybe<Array<Maybe<string>>> }>>> }> }> };

export type UpdateTransactionInfoMutationVariables = Exact<{
  input?: Maybe<TransactionInfoInput>;
}>;


export type UpdateTransactionInfoMutation = { __typename?: 'Mutation', updateTransactionInfo?: Maybe<{ __typename?: 'TransactionInfoResponse', status: boolean, response?: Maybe<string>, id?: Maybe<string>, object?: Maybe<{ __typename?: 'TransactionInfo', company_id: string, tags?: Maybe<Array<Maybe<string>>>, transaction_name: string, display_name: string, transaction_type?: Maybe<string>, recurring?: Maybe<string>, continuous?: Maybe<boolean>, recurring_end_date?: Maybe<string>, category: string, ignore?: Maybe<boolean>, new?: Maybe<boolean> }>, error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type GetTagsQueryVariables = Exact<{
  company_id: Scalars['ID'];
}>;


export type GetTagsQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', companies?: Maybe<Array<Maybe<{ __typename?: 'Company', filter_list?: Maybe<{ __typename?: 'FilterData', tags?: Maybe<Array<Maybe<string>>> }> }>>> }> };

export type GetTransactionColumsQueryVariables = Exact<{
  company_id: Scalars['ID'];
}>;


export type GetTransactionColumsQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', companies?: Maybe<Array<Maybe<{ __typename?: 'Company', filter_list?: Maybe<{ __typename?: 'FilterData', transaction_columns?: Maybe<Array<Maybe<{ __typename?: 'TransactionColumns', name?: Maybe<string>, description?: Maybe<string>, type?: Maybe<string>, required?: Maybe<boolean> }>>> }> }>>> }> };

export type GetKpiDrilldownQueryVariables = Exact<{
  company_id: Scalars['ID'];
  kpi: Scalars['String'];
}>;


export type GetKpiDrilldownQuery = { __typename?: 'Query', user?: Maybe<{ __typename?: 'User', company?: Maybe<{ __typename?: 'Company', kpi_drilldown?: Maybe<{ __typename?: 'DrillDownSummary', monthly?: Maybe<Array<Maybe<{ __typename?: 'DrillDownItem', date: string, amount: number, month?: Maybe<string> }>>> }> }> }> };


export const GetTransactionsDocument = gql`
    query GetTransactions($company_id: ID!, $limit: Int!, $cursor: String, $filter: TransactionFilter) {
  user {
    company(id: $company_id) {
      transactions(limit: $limit, cursor: $cursor, filter: $filter) {
        data {
          company_id
          transaction_id
          merchant
          name
          amount
          date
          currency
          payment_channel
          pending
          ignore
          last_updated
          details {
            name
            display_name
            category
            repeat
            amount
            avg_transactions
            number_transactions
            total_transactions
          }
          transaction_info {
            transaction_name
            company_id
            category
            tags
          }
        }
        cursor
        request_size
        number_remaining
        total_count
      }
    }
  }
}
    `;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
      }
export function useGetTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;
export const GetTransactionBreakDownDocument = gql`
    query GetTransactionBreakDown($transaction_type: String!, $company_id: ID!, $period: String!, $no_groups: Int!) {
  user {
    id
    companies(id: $company_id) {
      id
      name
      agg_transactions(transaction_type: $transaction_type, period: $period) {
        date
        amount
      }
      transaction_breakdown(
        transaction_type: $transaction_type
        period: $period
        no_groups: $no_groups
      ) {
        name
        category
        amount
        percentage
      }
    }
  }
}
    `;

/**
 * __useGetTransactionBreakDownQuery__
 *
 * To run a query within a React component, call `useGetTransactionBreakDownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionBreakDownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionBreakDownQuery({
 *   variables: {
 *      transaction_type: // value for 'transaction_type'
 *      company_id: // value for 'company_id'
 *      period: // value for 'period'
 *      no_groups: // value for 'no_groups'
 *   },
 * });
 */
export function useGetTransactionBreakDownQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionBreakDownQuery, GetTransactionBreakDownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionBreakDownQuery, GetTransactionBreakDownQueryVariables>(GetTransactionBreakDownDocument, options);
      }
export function useGetTransactionBreakDownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionBreakDownQuery, GetTransactionBreakDownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionBreakDownQuery, GetTransactionBreakDownQueryVariables>(GetTransactionBreakDownDocument, options);
        }
export type GetTransactionBreakDownQueryHookResult = ReturnType<typeof useGetTransactionBreakDownQuery>;
export type GetTransactionBreakDownLazyQueryHookResult = ReturnType<typeof useGetTransactionBreakDownLazyQuery>;
export type GetTransactionBreakDownQueryResult = Apollo.QueryResult<GetTransactionBreakDownQuery, GetTransactionBreakDownQueryVariables>;
export const GetFilterListDocument = gql`
    query GetFilterList($company_id: ID!) {
  user {
    companies(id: $company_id) {
      filter_list {
        channels
        categories
        tags
        transaction_names
        transaction_columns {
          name
          description
          required
          type
        }
      }
    }
  }
}
    `;

/**
 * __useGetFilterListQuery__
 *
 * To run a query within a React component, call `useGetFilterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterListQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetFilterListQuery(baseOptions: Apollo.QueryHookOptions<GetFilterListQuery, GetFilterListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFilterListQuery, GetFilterListQueryVariables>(GetFilterListDocument, options);
      }
export function useGetFilterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterListQuery, GetFilterListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFilterListQuery, GetFilterListQueryVariables>(GetFilterListDocument, options);
        }
export type GetFilterListQueryHookResult = ReturnType<typeof useGetFilterListQuery>;
export type GetFilterListLazyQueryHookResult = ReturnType<typeof useGetFilterListLazyQuery>;
export type GetFilterListQueryResult = Apollo.QueryResult<GetFilterListQuery, GetFilterListQueryVariables>;
export const GetCashInBankDocument = gql`
    query GetCashInBank($company_id: ID!) {
  user {
    companies(id: $company_id) {
      cash_in_bank {
        date
        amount
      }
    }
  }
}
    `;

/**
 * __useGetCashInBankQuery__
 *
 * To run a query within a React component, call `useGetCashInBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCashInBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCashInBankQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetCashInBankQuery(baseOptions: Apollo.QueryHookOptions<GetCashInBankQuery, GetCashInBankQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCashInBankQuery, GetCashInBankQueryVariables>(GetCashInBankDocument, options);
      }
export function useGetCashInBankLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCashInBankQuery, GetCashInBankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCashInBankQuery, GetCashInBankQueryVariables>(GetCashInBankDocument, options);
        }
export type GetCashInBankQueryHookResult = ReturnType<typeof useGetCashInBankQuery>;
export type GetCashInBankLazyQueryHookResult = ReturnType<typeof useGetCashInBankLazyQuery>;
export type GetCashInBankQueryResult = Apollo.QueryResult<GetCashInBankQuery, GetCashInBankQueryVariables>;
export const GetUserInCompanyDocument = gql`
    query GetUserInCompany($company_id: ID!) {
  user {
    id
    first_name
    last_name
    email
    currency
    companies(id: $company_id) {
      role
    }
  }
}
    `;

/**
 * __useGetUserInCompanyQuery__
 *
 * To run a query within a React component, call `useGetUserInCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInCompanyQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetUserInCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetUserInCompanyQuery, GetUserInCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInCompanyQuery, GetUserInCompanyQueryVariables>(GetUserInCompanyDocument, options);
      }
export function useGetUserInCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInCompanyQuery, GetUserInCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInCompanyQuery, GetUserInCompanyQueryVariables>(GetUserInCompanyDocument, options);
        }
export type GetUserInCompanyQueryHookResult = ReturnType<typeof useGetUserInCompanyQuery>;
export type GetUserInCompanyLazyQueryHookResult = ReturnType<typeof useGetUserInCompanyLazyQuery>;
export type GetUserInCompanyQueryResult = Apollo.QueryResult<GetUserInCompanyQuery, GetUserInCompanyQueryVariables>;
export const GetProfileDocument = gql`
    query GetProfile {
  user {
    id
    first_name
    last_name
    email
    time_zone
    currency
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const GetAccountDocument = gql`
    query GetAccount($company_id: ID!) {
  user {
    company(id: $company_id) {
      id
      name
      description
      accounts {
        company_id
        account_id
        account_name
        institution
        account_type
        currency
        balance
        last_updated
        created
      }
    }
  }
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($account_id: ID!, $company_id: ID!) {
  deleteAccount(account_id: $account_id, company_id: $company_id) {
    status
    error {
      code
      message
    }
    response
    object {
      account_id
      company_id
    }
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      account_id: // value for 'account_id'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const GetIntegrationInfoDocument = gql`
    query GetIntegrationInfo($company_id: ID!) {
  user {
    companies(id: $company_id) {
      connected_integrations {
        integration_type
        name
        display_name
        what_can_do
        what_need
        image_url
        connected
        connected_files
      }
    }
  }
}
    `;

/**
 * __useGetIntegrationInfoQuery__
 *
 * To run a query within a React component, call `useGetIntegrationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationInfoQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetIntegrationInfoQuery(baseOptions: Apollo.QueryHookOptions<GetIntegrationInfoQuery, GetIntegrationInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationInfoQuery, GetIntegrationInfoQueryVariables>(GetIntegrationInfoDocument, options);
      }
export function useGetIntegrationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationInfoQuery, GetIntegrationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationInfoQuery, GetIntegrationInfoQueryVariables>(GetIntegrationInfoDocument, options);
        }
export type GetIntegrationInfoQueryHookResult = ReturnType<typeof useGetIntegrationInfoQuery>;
export type GetIntegrationInfoLazyQueryHookResult = ReturnType<typeof useGetIntegrationInfoLazyQuery>;
export type GetIntegrationInfoQueryResult = Apollo.QueryResult<GetIntegrationInfoQuery, GetIntegrationInfoQueryVariables>;
export const UploadDataDocument = gql`
    mutation uploadData($dataFileInput: DataFileInput!) {
  uploadDataFile(input: $dataFileInput) {
    status
    error {
      message
      code
    }
    id
    object {
      file_id
      name
      description
      upload_date
      __typename
    }
    response
  }
}
    `;
export type UploadDataMutationFn = Apollo.MutationFunction<UploadDataMutation, UploadDataMutationVariables>;

/**
 * __useUploadDataMutation__
 *
 * To run a mutation, you first call `useUploadDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDataMutation, { data, loading, error }] = useUploadDataMutation({
 *   variables: {
 *      dataFileInput: // value for 'dataFileInput'
 *   },
 * });
 */
export function useUploadDataMutation(baseOptions?: Apollo.MutationHookOptions<UploadDataMutation, UploadDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDataMutation, UploadDataMutationVariables>(UploadDataDocument, options);
      }
export type UploadDataMutationHookResult = ReturnType<typeof useUploadDataMutation>;
export type UploadDataMutationResult = Apollo.MutationResult<UploadDataMutation>;
export type UploadDataMutationOptions = Apollo.BaseMutationOptions<UploadDataMutation, UploadDataMutationVariables>;
export const DeleteDataFileDocument = gql`
    mutation deleteDataFile($company_id: ID!, $fileid: ID!) {
  deleteDataFile(company_id: $company_id, file_id: $fileid) {
    status
    id
    error {
      message
      code
    }
    response
  }
}
    `;
export type DeleteDataFileMutationFn = Apollo.MutationFunction<DeleteDataFileMutation, DeleteDataFileMutationVariables>;

/**
 * __useDeleteDataFileMutation__
 *
 * To run a mutation, you first call `useDeleteDataFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataFileMutation, { data, loading, error }] = useDeleteDataFileMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      fileid: // value for 'fileid'
 *   },
 * });
 */
export function useDeleteDataFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataFileMutation, DeleteDataFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataFileMutation, DeleteDataFileMutationVariables>(DeleteDataFileDocument, options);
      }
export type DeleteDataFileMutationHookResult = ReturnType<typeof useDeleteDataFileMutation>;
export type DeleteDataFileMutationResult = Apollo.MutationResult<DeleteDataFileMutation>;
export type DeleteDataFileMutationOptions = Apollo.BaseMutationOptions<DeleteDataFileMutation, DeleteDataFileMutationVariables>;
export const GetFileDataDocument = gql`
    query GetFileData($company_id: ID!, $provider: String!, $file_id: String!) {
  file_integration(
    company_id: $company_id
    provider: $provider
    file_id: $file_id
  ) {
    id
    sheets
    columns {
      sheet
      columns
    }
  }
}
    `;

/**
 * __useGetFileDataQuery__
 *
 * To run a query within a React component, call `useGetFileDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileDataQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      provider: // value for 'provider'
 *      file_id: // value for 'file_id'
 *   },
 * });
 */
export function useGetFileDataQuery(baseOptions: Apollo.QueryHookOptions<GetFileDataQuery, GetFileDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileDataQuery, GetFileDataQueryVariables>(GetFileDataDocument, options);
      }
export function useGetFileDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileDataQuery, GetFileDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileDataQuery, GetFileDataQueryVariables>(GetFileDataDocument, options);
        }
export type GetFileDataQueryHookResult = ReturnType<typeof useGetFileDataQuery>;
export type GetFileDataLazyQueryHookResult = ReturnType<typeof useGetFileDataLazyQuery>;
export type GetFileDataQueryResult = Apollo.QueryResult<GetFileDataQuery, GetFileDataQueryVariables>;
export const UpdateIntegrationDocument = gql`
    mutation UpdateIntegration($company_id: ID!, $item_id: String!, $provider: String!, $mapping: String!, $replace_transactions: [String]) {
  updateIntegration(
    input: {company_id: $company_id, item_id: $item_id, provider: $provider, mapping: $mapping, replace_transactions: $replace_transactions}
  ) {
    status
    error {
      code
      message
    }
    response
  }
}
    `;
export type UpdateIntegrationMutationFn = Apollo.MutationFunction<UpdateIntegrationMutation, UpdateIntegrationMutationVariables>;

/**
 * __useUpdateIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntegrationMutation, { data, loading, error }] = useUpdateIntegrationMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      item_id: // value for 'item_id'
 *      provider: // value for 'provider'
 *      mapping: // value for 'mapping'
 *      replace_transactions: // value for 'replace_transactions'
 *   },
 * });
 */
export function useUpdateIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIntegrationMutation, UpdateIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIntegrationMutation, UpdateIntegrationMutationVariables>(UpdateIntegrationDocument, options);
      }
export type UpdateIntegrationMutationHookResult = ReturnType<typeof useUpdateIntegrationMutation>;
export type UpdateIntegrationMutationResult = Apollo.MutationResult<UpdateIntegrationMutation>;
export type UpdateIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateIntegrationMutation, UpdateIntegrationMutationVariables>;
export const GetTransactionNameDocument = gql`
    query GetTransactionName {
  transaction_info {
    transaction_name
  }
}
    `;

/**
 * __useGetTransactionNameQuery__
 *
 * To run a query within a React component, call `useGetTransactionNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTransactionNameQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionNameQuery, GetTransactionNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionNameQuery, GetTransactionNameQueryVariables>(GetTransactionNameDocument, options);
      }
export function useGetTransactionNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionNameQuery, GetTransactionNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionNameQuery, GetTransactionNameQueryVariables>(GetTransactionNameDocument, options);
        }
export type GetTransactionNameQueryHookResult = ReturnType<typeof useGetTransactionNameQuery>;
export type GetTransactionNameLazyQueryHookResult = ReturnType<typeof useGetTransactionNameLazyQuery>;
export type GetTransactionNameQueryResult = Apollo.QueryResult<GetTransactionNameQuery, GetTransactionNameQueryVariables>;
export const GetDataFilesDocument = gql`
    query GetDataFiles($company_id: ID!) {
  data_files(company_id: $company_id) {
    file_id
    name
    description
    upload_date
  }
}
    `;

/**
 * __useGetDataFilesQuery__
 *
 * To run a query within a React component, call `useGetDataFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataFilesQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetDataFilesQuery(baseOptions: Apollo.QueryHookOptions<GetDataFilesQuery, GetDataFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataFilesQuery, GetDataFilesQueryVariables>(GetDataFilesDocument, options);
      }
export function useGetDataFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataFilesQuery, GetDataFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataFilesQuery, GetDataFilesQueryVariables>(GetDataFilesDocument, options);
        }
export type GetDataFilesQueryHookResult = ReturnType<typeof useGetDataFilesQuery>;
export type GetDataFilesLazyQueryHookResult = ReturnType<typeof useGetDataFilesLazyQuery>;
export type GetDataFilesQueryResult = Apollo.QueryResult<GetDataFilesQuery, GetDataFilesQueryVariables>;
export const GetAccountCardDocument = gql`
    query GetAccountCard($company_id: ID) {
  companies(id: $company_id) {
    data_status {
      account_type
      connection_status
      colour
      main_text
      sub_text
      no_items
      button_inactive
      button_text
    }
  }
}
    `;

/**
 * __useGetAccountCardQuery__
 *
 * To run a query within a React component, call `useGetAccountCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountCardQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetAccountCardQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountCardQuery, GetAccountCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountCardQuery, GetAccountCardQueryVariables>(GetAccountCardDocument, options);
      }
export function useGetAccountCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountCardQuery, GetAccountCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountCardQuery, GetAccountCardQueryVariables>(GetAccountCardDocument, options);
        }
export type GetAccountCardQueryHookResult = ReturnType<typeof useGetAccountCardQuery>;
export type GetAccountCardLazyQueryHookResult = ReturnType<typeof useGetAccountCardLazyQuery>;
export type GetAccountCardQueryResult = Apollo.QueryResult<GetAccountCardQuery, GetAccountCardQueryVariables>;
export const CompanyUsageDocument = gql`
    query CompanyUsage($company_id: ID) {
  user {
    companies(id: $company_id) {
      id
      name
      role
      usage {
        no_apps
        no_scenarios
        no_bank_accounts
        bank_quota_met
        app_quota_met
        scenario_quota_met
        quota_met
      }
    }
  }
}
    `;

/**
 * __useCompanyUsageQuery__
 *
 * To run a query within a React component, call `useCompanyUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsageQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useCompanyUsageQuery(baseOptions?: Apollo.QueryHookOptions<CompanyUsageQuery, CompanyUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyUsageQuery, CompanyUsageQueryVariables>(CompanyUsageDocument, options);
      }
export function useCompanyUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyUsageQuery, CompanyUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyUsageQuery, CompanyUsageQueryVariables>(CompanyUsageDocument, options);
        }
export type CompanyUsageQueryHookResult = ReturnType<typeof useCompanyUsageQuery>;
export type CompanyUsageLazyQueryHookResult = ReturnType<typeof useCompanyUsageLazyQuery>;
export type CompanyUsageQueryResult = Apollo.QueryResult<CompanyUsageQuery, CompanyUsageQueryVariables>;
export const IntegrationStatusDocument = gql`
    query IntegrationStatus($company_id: String!, $provider: String!) {
  integration_status(company_id: $company_id, provider: $provider) {
    provider
    company_id
    status
    created
  }
}
    `;

/**
 * __useIntegrationStatusQuery__
 *
 * To run a query within a React component, call `useIntegrationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationStatusQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useIntegrationStatusQuery(baseOptions: Apollo.QueryHookOptions<IntegrationStatusQuery, IntegrationStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationStatusQuery, IntegrationStatusQueryVariables>(IntegrationStatusDocument, options);
      }
export function useIntegrationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationStatusQuery, IntegrationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationStatusQuery, IntegrationStatusQueryVariables>(IntegrationStatusDocument, options);
        }
export type IntegrationStatusQueryHookResult = ReturnType<typeof useIntegrationStatusQuery>;
export type IntegrationStatusLazyQueryHookResult = ReturnType<typeof useIntegrationStatusLazyQuery>;
export type IntegrationStatusQueryResult = Apollo.QueryResult<IntegrationStatusQuery, IntegrationStatusQueryVariables>;
export const IsFirstUserDocument = gql`
    query IsFirstUser($company_id: ID) {
  user {
    companies(id: $company_id) {
      first_time_user
    }
  }
}
    `;

/**
 * __useIsFirstUserQuery__
 *
 * To run a query within a React component, call `useIsFirstUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFirstUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFirstUserQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useIsFirstUserQuery(baseOptions?: Apollo.QueryHookOptions<IsFirstUserQuery, IsFirstUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsFirstUserQuery, IsFirstUserQueryVariables>(IsFirstUserDocument, options);
      }
export function useIsFirstUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsFirstUserQuery, IsFirstUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsFirstUserQuery, IsFirstUserQueryVariables>(IsFirstUserDocument, options);
        }
export type IsFirstUserQueryHookResult = ReturnType<typeof useIsFirstUserQuery>;
export type IsFirstUserLazyQueryHookResult = ReturnType<typeof useIsFirstUserLazyQuery>;
export type IsFirstUserQueryResult = Apollo.QueryResult<IsFirstUserQuery, IsFirstUserQueryVariables>;
export const LastAccessedCompanyDocument = gql`
    mutation lastAccessedCompany($companyId: ID) {
  lastAccessedCompany(company_id: $companyId) {
    status
    error {
      message
      code
    }
    id
    response
  }
}
    `;
export type LastAccessedCompanyMutationFn = Apollo.MutationFunction<LastAccessedCompanyMutation, LastAccessedCompanyMutationVariables>;

/**
 * __useLastAccessedCompanyMutation__
 *
 * To run a mutation, you first call `useLastAccessedCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLastAccessedCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lastAccessedCompanyMutation, { data, loading, error }] = useLastAccessedCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useLastAccessedCompanyMutation(baseOptions?: Apollo.MutationHookOptions<LastAccessedCompanyMutation, LastAccessedCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LastAccessedCompanyMutation, LastAccessedCompanyMutationVariables>(LastAccessedCompanyDocument, options);
      }
export type LastAccessedCompanyMutationHookResult = ReturnType<typeof useLastAccessedCompanyMutation>;
export type LastAccessedCompanyMutationResult = Apollo.MutationResult<LastAccessedCompanyMutation>;
export type LastAccessedCompanyMutationOptions = Apollo.BaseMutationOptions<LastAccessedCompanyMutation, LastAccessedCompanyMutationVariables>;
export const GetKpiDocument = gql`
    query GetKPI($companyId: ID!) {
  user {
    company(id: $companyId) {
      kpis {
        name
        description
        category
        variant
        headline
        current_value
        current_value_suffix
        percentage_change
        positive_change
        intelligence
        subheading
        order
        breakdown_chart_title
        is_percentage
        breakdown_chart {
          item
          percentage
          amount
        }
        full_breakdown {
          item
          percentage
          amount
        }
        chart_data {
          date
          display_date
          amount
        }
      }
    }
  }
}
    `;

/**
 * __useGetKpiQuery__
 *
 * To run a query within a React component, call `useGetKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetKpiQuery(baseOptions: Apollo.QueryHookOptions<GetKpiQuery, GetKpiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKpiQuery, GetKpiQueryVariables>(GetKpiDocument, options);
      }
export function useGetKpiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKpiQuery, GetKpiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKpiQuery, GetKpiQueryVariables>(GetKpiDocument, options);
        }
export type GetKpiQueryHookResult = ReturnType<typeof useGetKpiQuery>;
export type GetKpiLazyQueryHookResult = ReturnType<typeof useGetKpiLazyQuery>;
export type GetKpiQueryResult = Apollo.QueryResult<GetKpiQuery, GetKpiQueryVariables>;
export const CreateKpiAlertDocument = gql`
    mutation CreateKPIAlert($alertInput: AlertInput!) {
  createAlert(input: $alertInput) {
    status
    error {
      code
      message
    }
    object {
      id
      kpi_name
      description
      condition
      created
      amount
    }
  }
}
    `;
export type CreateKpiAlertMutationFn = Apollo.MutationFunction<CreateKpiAlertMutation, CreateKpiAlertMutationVariables>;

/**
 * __useCreateKpiAlertMutation__
 *
 * To run a mutation, you first call `useCreateKpiAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKpiAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKpiAlertMutation, { data, loading, error }] = useCreateKpiAlertMutation({
 *   variables: {
 *      alertInput: // value for 'alertInput'
 *   },
 * });
 */
export function useCreateKpiAlertMutation(baseOptions?: Apollo.MutationHookOptions<CreateKpiAlertMutation, CreateKpiAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKpiAlertMutation, CreateKpiAlertMutationVariables>(CreateKpiAlertDocument, options);
      }
export type CreateKpiAlertMutationHookResult = ReturnType<typeof useCreateKpiAlertMutation>;
export type CreateKpiAlertMutationResult = Apollo.MutationResult<CreateKpiAlertMutation>;
export type CreateKpiAlertMutationOptions = Apollo.BaseMutationOptions<CreateKpiAlertMutation, CreateKpiAlertMutationVariables>;
export const UpdateKpiAlertDocument = gql`
    mutation UpdateKPIAlert($alertInput: AlertUpdateInput!) {
  updateAlert(input: $alertInput) {
    status
    error {
      code
      message
    }
  }
}
    `;
export type UpdateKpiAlertMutationFn = Apollo.MutationFunction<UpdateKpiAlertMutation, UpdateKpiAlertMutationVariables>;

/**
 * __useUpdateKpiAlertMutation__
 *
 * To run a mutation, you first call `useUpdateKpiAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKpiAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKpiAlertMutation, { data, loading, error }] = useUpdateKpiAlertMutation({
 *   variables: {
 *      alertInput: // value for 'alertInput'
 *   },
 * });
 */
export function useUpdateKpiAlertMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKpiAlertMutation, UpdateKpiAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKpiAlertMutation, UpdateKpiAlertMutationVariables>(UpdateKpiAlertDocument, options);
      }
export type UpdateKpiAlertMutationHookResult = ReturnType<typeof useUpdateKpiAlertMutation>;
export type UpdateKpiAlertMutationResult = Apollo.MutationResult<UpdateKpiAlertMutation>;
export type UpdateKpiAlertMutationOptions = Apollo.BaseMutationOptions<UpdateKpiAlertMutation, UpdateKpiAlertMutationVariables>;
export const DeleteKpiAlertDocument = gql`
    mutation DeleteKPIAlert($company_id: ID!, $kpiName: String!, $alert_id: ID!) {
  deleteAlert(company_id: $company_id, alert_id: $alert_id, kpi_name: $kpiName) {
    status
    error {
      code
      message
    }
  }
}
    `;
export type DeleteKpiAlertMutationFn = Apollo.MutationFunction<DeleteKpiAlertMutation, DeleteKpiAlertMutationVariables>;

/**
 * __useDeleteKpiAlertMutation__
 *
 * To run a mutation, you first call `useDeleteKpiAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKpiAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKpiAlertMutation, { data, loading, error }] = useDeleteKpiAlertMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      kpiName: // value for 'kpiName'
 *      alert_id: // value for 'alert_id'
 *   },
 * });
 */
export function useDeleteKpiAlertMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKpiAlertMutation, DeleteKpiAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKpiAlertMutation, DeleteKpiAlertMutationVariables>(DeleteKpiAlertDocument, options);
      }
export type DeleteKpiAlertMutationHookResult = ReturnType<typeof useDeleteKpiAlertMutation>;
export type DeleteKpiAlertMutationResult = Apollo.MutationResult<DeleteKpiAlertMutation>;
export type DeleteKpiAlertMutationOptions = Apollo.BaseMutationOptions<DeleteKpiAlertMutation, DeleteKpiAlertMutationVariables>;
export const GetKpiAlertsDocument = gql`
    query GetKPIAlerts($company_id: ID!, $kpi_name: String!) {
  alerts(company_id: $company_id, kpi_name: $kpi_name) {
    id
    kpi_name
    description
    created
    last_triggered
    target_hit
  }
}
    `;

/**
 * __useGetKpiAlertsQuery__
 *
 * To run a query within a React component, call `useGetKpiAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiAlertsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      kpi_name: // value for 'kpi_name'
 *   },
 * });
 */
export function useGetKpiAlertsQuery(baseOptions: Apollo.QueryHookOptions<GetKpiAlertsQuery, GetKpiAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKpiAlertsQuery, GetKpiAlertsQueryVariables>(GetKpiAlertsDocument, options);
      }
export function useGetKpiAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKpiAlertsQuery, GetKpiAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKpiAlertsQuery, GetKpiAlertsQueryVariables>(GetKpiAlertsDocument, options);
        }
export type GetKpiAlertsQueryHookResult = ReturnType<typeof useGetKpiAlertsQuery>;
export type GetKpiAlertsLazyQueryHookResult = ReturnType<typeof useGetKpiAlertsLazyQuery>;
export type GetKpiAlertsQueryResult = Apollo.QueryResult<GetKpiAlertsQuery, GetKpiAlertsQueryVariables>;
export const GetScenariosDocument = gql`
    query GetScenarios($company_id: ID) {
  user {
    id
    companies(id: $company_id) {
      id
      scenarios {
        name
        description
        optimising_for
        sim_date
        cashflows {
          name
          cashflow_type
          period
          start_date
          end_date
          no_periods
          first_amount
          amount
          display_amount
          upper_amount
          lower_amount
          cost_amount
          cost_upper
          cost_lower
          outstanding
          limit
          utilisation
          repayment_type
          payoff_period
          expense
          existing
          rate
          no_payments
          ignore
          day_of_month
          day_of_week
          custom_data {
            date
            amount
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetScenariosQuery__
 *
 * To run a query within a React component, call `useGetScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenariosQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetScenariosQuery(baseOptions?: Apollo.QueryHookOptions<GetScenariosQuery, GetScenariosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenariosQuery, GetScenariosQueryVariables>(GetScenariosDocument, options);
      }
export function useGetScenariosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenariosQuery, GetScenariosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenariosQuery, GetScenariosQueryVariables>(GetScenariosDocument, options);
        }
export type GetScenariosQueryHookResult = ReturnType<typeof useGetScenariosQuery>;
export type GetScenariosLazyQueryHookResult = ReturnType<typeof useGetScenariosLazyQuery>;
export type GetScenariosQueryResult = Apollo.QueryResult<GetScenariosQuery, GetScenariosQueryVariables>;
export const CreateScenarioDocument = gql`
    mutation CreateScenario($input: ScenarioInput!) {
  createScenario(input: $input) {
    response
    error {
      message
      code
    }
    status
    object {
      name
      description
    }
    id
  }
}
    `;
export type CreateScenarioMutationFn = Apollo.MutationFunction<CreateScenarioMutation, CreateScenarioMutationVariables>;

/**
 * __useCreateScenarioMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMutation, { data, loading, error }] = useCreateScenarioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<CreateScenarioMutation, CreateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScenarioMutation, CreateScenarioMutationVariables>(CreateScenarioDocument, options);
      }
export type CreateScenarioMutationHookResult = ReturnType<typeof useCreateScenarioMutation>;
export type CreateScenarioMutationResult = Apollo.MutationResult<CreateScenarioMutation>;
export type CreateScenarioMutationOptions = Apollo.BaseMutationOptions<CreateScenarioMutation, CreateScenarioMutationVariables>;
export const UpdateScenarioDocument = gql`
    mutation UpdateScenario($input: ScenarioInput!) {
  updateScenario(input: $input) {
    response
    error {
      message
      code
    }
    status
    object {
      name
      description
    }
    id
  }
}
    `;
export type UpdateScenarioMutationFn = Apollo.MutationFunction<UpdateScenarioMutation, UpdateScenarioMutationVariables>;

/**
 * __useUpdateScenarioMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioMutation, { data, loading, error }] = useUpdateScenarioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScenarioMutation, UpdateScenarioMutationVariables>(UpdateScenarioDocument, options);
      }
export type UpdateScenarioMutationHookResult = ReturnType<typeof useUpdateScenarioMutation>;
export type UpdateScenarioMutationResult = Apollo.MutationResult<UpdateScenarioMutation>;
export type UpdateScenarioMutationOptions = Apollo.BaseMutationOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>;
export const DeleteScenarioDocument = gql`
    mutation DeleteScenario($company_id: String!, $name: String!) {
  deleteScenario(company_id: $company_id, name: $name) {
    response
    error {
      message
      code
    }
    status
    object {
      name
      description
    }
    id
  }
}
    `;
export type DeleteScenarioMutationFn = Apollo.MutationFunction<DeleteScenarioMutation, DeleteScenarioMutationVariables>;

/**
 * __useDeleteScenarioMutation__
 *
 * To run a mutation, you first call `useDeleteScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScenarioMutation, { data, loading, error }] = useDeleteScenarioMutation({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDeleteScenarioMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScenarioMutation, DeleteScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScenarioMutation, DeleteScenarioMutationVariables>(DeleteScenarioDocument, options);
      }
export type DeleteScenarioMutationHookResult = ReturnType<typeof useDeleteScenarioMutation>;
export type DeleteScenarioMutationResult = Apollo.MutationResult<DeleteScenarioMutation>;
export type DeleteScenarioMutationOptions = Apollo.BaseMutationOptions<DeleteScenarioMutation, DeleteScenarioMutationVariables>;
export const GetTransactionInfoDocument = gql`
    query GetTransactionInfo($company_id: ID!) {
  user {
    id
    company(id: $company_id) {
      id
      transaction_info {
        company_id
        transaction_name
        display_name
        continuous
        transaction_type
        recurring
        recurring_end_date
        category
        ignore
        new
        tags
      }
    }
  }
}
    `;

/**
 * __useGetTransactionInfoQuery__
 *
 * To run a query within a React component, call `useGetTransactionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionInfoQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetTransactionInfoQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionInfoQuery, GetTransactionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionInfoQuery, GetTransactionInfoQueryVariables>(GetTransactionInfoDocument, options);
      }
export function useGetTransactionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionInfoQuery, GetTransactionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionInfoQuery, GetTransactionInfoQueryVariables>(GetTransactionInfoDocument, options);
        }
export type GetTransactionInfoQueryHookResult = ReturnType<typeof useGetTransactionInfoQuery>;
export type GetTransactionInfoLazyQueryHookResult = ReturnType<typeof useGetTransactionInfoLazyQuery>;
export type GetTransactionInfoQueryResult = Apollo.QueryResult<GetTransactionInfoQuery, GetTransactionInfoQueryVariables>;
export const UpdateTransactionInfoDocument = gql`
    mutation updateTransactionInfo($input: TransactionInfoInput) {
  updateTransactionInfo(input: $input) {
    status
    object {
      company_id
      tags
      transaction_name
      display_name
      transaction_type
      recurring
      continuous
      recurring_end_date
      category
      ignore
      new
    }
    error {
      code
      message
    }
    response
    id
  }
}
    `;
export type UpdateTransactionInfoMutationFn = Apollo.MutationFunction<UpdateTransactionInfoMutation, UpdateTransactionInfoMutationVariables>;

/**
 * __useUpdateTransactionInfoMutation__
 *
 * To run a mutation, you first call `useUpdateTransactionInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTransactionInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTransactionInfoMutation, { data, loading, error }] = useUpdateTransactionInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTransactionInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransactionInfoMutation, UpdateTransactionInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransactionInfoMutation, UpdateTransactionInfoMutationVariables>(UpdateTransactionInfoDocument, options);
      }
export type UpdateTransactionInfoMutationHookResult = ReturnType<typeof useUpdateTransactionInfoMutation>;
export type UpdateTransactionInfoMutationResult = Apollo.MutationResult<UpdateTransactionInfoMutation>;
export type UpdateTransactionInfoMutationOptions = Apollo.BaseMutationOptions<UpdateTransactionInfoMutation, UpdateTransactionInfoMutationVariables>;
export const GetTagsDocument = gql`
    query GetTags($company_id: ID!) {
  user {
    companies(id: $company_id) {
      filter_list {
        tags
      }
    }
  }
}
    `;

/**
 * __useGetTagsQuery__
 *
 * To run a query within a React component, call `useGetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetTagsQuery(baseOptions: Apollo.QueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
      }
export function useGetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagsQuery, GetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTagsQuery, GetTagsQueryVariables>(GetTagsDocument, options);
        }
export type GetTagsQueryHookResult = ReturnType<typeof useGetTagsQuery>;
export type GetTagsLazyQueryHookResult = ReturnType<typeof useGetTagsLazyQuery>;
export type GetTagsQueryResult = Apollo.QueryResult<GetTagsQuery, GetTagsQueryVariables>;
export const GetTransactionColumsDocument = gql`
    query GetTransactionColums($company_id: ID!) {
  user {
    companies(id: $company_id) {
      filter_list {
        transaction_columns {
          name
          description
          type
          required
        }
      }
    }
  }
}
    `;

/**
 * __useGetTransactionColumsQuery__
 *
 * To run a query within a React component, call `useGetTransactionColumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionColumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionColumsQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetTransactionColumsQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionColumsQuery, GetTransactionColumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionColumsQuery, GetTransactionColumsQueryVariables>(GetTransactionColumsDocument, options);
      }
export function useGetTransactionColumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionColumsQuery, GetTransactionColumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionColumsQuery, GetTransactionColumsQueryVariables>(GetTransactionColumsDocument, options);
        }
export type GetTransactionColumsQueryHookResult = ReturnType<typeof useGetTransactionColumsQuery>;
export type GetTransactionColumsLazyQueryHookResult = ReturnType<typeof useGetTransactionColumsLazyQuery>;
export type GetTransactionColumsQueryResult = Apollo.QueryResult<GetTransactionColumsQuery, GetTransactionColumsQueryVariables>;
export const GetKpiDrilldownDocument = gql`
    query GetKPIDrilldown($company_id: ID!, $kpi: String!) {
  user {
    company(id: $company_id) {
      kpi_drilldown(kpi: $kpi) {
        monthly {
          date
          amount
          month
        }
      }
    }
  }
}
    `;

/**
 * __useGetKpiDrilldownQuery__
 *
 * To run a query within a React component, call `useGetKpiDrilldownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiDrilldownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiDrilldownQuery({
 *   variables: {
 *      company_id: // value for 'company_id'
 *      kpi: // value for 'kpi'
 *   },
 * });
 */
export function useGetKpiDrilldownQuery(baseOptions: Apollo.QueryHookOptions<GetKpiDrilldownQuery, GetKpiDrilldownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKpiDrilldownQuery, GetKpiDrilldownQueryVariables>(GetKpiDrilldownDocument, options);
      }
export function useGetKpiDrilldownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKpiDrilldownQuery, GetKpiDrilldownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKpiDrilldownQuery, GetKpiDrilldownQueryVariables>(GetKpiDrilldownDocument, options);
        }
export type GetKpiDrilldownQueryHookResult = ReturnType<typeof useGetKpiDrilldownQuery>;
export type GetKpiDrilldownLazyQueryHookResult = ReturnType<typeof useGetKpiDrilldownLazyQuery>;
export type GetKpiDrilldownQueryResult = Apollo.QueryResult<GetKpiDrilldownQuery, GetKpiDrilldownQueryVariables>;
export type AccountKeySpecifier = ('company_id' | 'account_id' | 'account_name' | 'account_type' | 'institution' | 'currency' | 'item_id' | 'account_subtype' | 'balance' | 'available' | 'limit' | 'status' | 'last_updated' | 'created' | AccountKeySpecifier)[];
export type AccountFieldPolicy = {
	company_id?: FieldPolicy<any> | FieldReadFunction<any>,
	account_id?: FieldPolicy<any> | FieldReadFunction<any>,
	account_name?: FieldPolicy<any> | FieldReadFunction<any>,
	account_type?: FieldPolicy<any> | FieldReadFunction<any>,
	institution?: FieldPolicy<any> | FieldReadFunction<any>,
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	item_id?: FieldPolicy<any> | FieldReadFunction<any>,
	account_subtype?: FieldPolicy<any> | FieldReadFunction<any>,
	balance?: FieldPolicy<any> | FieldReadFunction<any>,
	available?: FieldPolicy<any> | FieldReadFunction<any>,
	limit?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	last_updated?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AccountResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | AccountResponseKeySpecifier)[];
export type AccountResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AlertKeySpecifier = ('id' | 'kpi_name' | 'description' | 'condition' | 'created' | 'amount' | 'percentage_hit' | 'last_triggered' | 'target_hit' | AlertKeySpecifier)[];
export type AlertFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	kpi_name?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	condition?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	percentage_hit?: FieldPolicy<any> | FieldReadFunction<any>,
	last_triggered?: FieldPolicy<any> | FieldReadFunction<any>,
	target_hit?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AlertResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | AlertResponseKeySpecifier)[];
export type AlertResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BreakdownKeySpecifier = ('name' | 'amount' | 'percentage' | 'category' | BreakdownKeySpecifier)[];
export type BreakdownFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	percentage?: FieldPolicy<any> | FieldReadFunction<any>,
	category?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CashInBankKeySpecifier = ('date' | 'amount' | CashInBankKeySpecifier)[];
export type CashInBankFieldPolicy = {
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CashflowResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | CashflowResponseKeySpecifier)[];
export type CashflowResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CashflowsKeySpecifier = ('name' | 'cashflow_type' | 'period' | 'start_date' | 'end_date' | 'no_periods' | 'first_amount' | 'amount' | 'display_amount' | 'upper_amount' | 'lower_amount' | 'cost_amount' | 'cost_upper' | 'cost_lower' | 'outstanding' | 'limit' | 'utilisation' | 'repayment_type' | 'payoff_period' | 'expense' | 'existing' | 'rate' | 'no_payments' | 'ignore' | 'day_of_month' | 'day_of_week' | 'custom_data' | CashflowsKeySpecifier)[];
export type CashflowsFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	cashflow_type?: FieldPolicy<any> | FieldReadFunction<any>,
	period?: FieldPolicy<any> | FieldReadFunction<any>,
	start_date?: FieldPolicy<any> | FieldReadFunction<any>,
	end_date?: FieldPolicy<any> | FieldReadFunction<any>,
	no_periods?: FieldPolicy<any> | FieldReadFunction<any>,
	first_amount?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	display_amount?: FieldPolicy<any> | FieldReadFunction<any>,
	upper_amount?: FieldPolicy<any> | FieldReadFunction<any>,
	lower_amount?: FieldPolicy<any> | FieldReadFunction<any>,
	cost_amount?: FieldPolicy<any> | FieldReadFunction<any>,
	cost_upper?: FieldPolicy<any> | FieldReadFunction<any>,
	cost_lower?: FieldPolicy<any> | FieldReadFunction<any>,
	outstanding?: FieldPolicy<any> | FieldReadFunction<any>,
	limit?: FieldPolicy<any> | FieldReadFunction<any>,
	utilisation?: FieldPolicy<any> | FieldReadFunction<any>,
	repayment_type?: FieldPolicy<any> | FieldReadFunction<any>,
	payoff_period?: FieldPolicy<any> | FieldReadFunction<any>,
	expense?: FieldPolicy<any> | FieldReadFunction<any>,
	existing?: FieldPolicy<any> | FieldReadFunction<any>,
	rate?: FieldPolicy<any> | FieldReadFunction<any>,
	no_payments?: FieldPolicy<any> | FieldReadFunction<any>,
	ignore?: FieldPolicy<any> | FieldReadFunction<any>,
	day_of_month?: FieldPolicy<any> | FieldReadFunction<any>,
	day_of_week?: FieldPolicy<any> | FieldReadFunction<any>,
	custom_data?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CompanyKeySpecifier = ('id' | 'name' | 'description' | 'business_type' | 'country' | 'city' | 'date_founded' | 'no_employees' | 'position' | 'created' | 'last_accessed' | 'company_users' | 'role' | 'usage' | 'data_files' | 'filter_list' | 'first_time_user' | 'kpis' | 'transactions' | 'transaction_breakdown' | 'agg_transactions' | 'cash_in_bank' | 'kpi_drilldown' | 'integrations' | 'scenarios' | 'connected_integrations' | 'data_status' | 'accounts' | 'transaction_info' | CompanyKeySpecifier)[];
export type CompanyFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	business_type?: FieldPolicy<any> | FieldReadFunction<any>,
	country?: FieldPolicy<any> | FieldReadFunction<any>,
	city?: FieldPolicy<any> | FieldReadFunction<any>,
	date_founded?: FieldPolicy<any> | FieldReadFunction<any>,
	no_employees?: FieldPolicy<any> | FieldReadFunction<any>,
	position?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	last_accessed?: FieldPolicy<any> | FieldReadFunction<any>,
	company_users?: FieldPolicy<any> | FieldReadFunction<any>,
	role?: FieldPolicy<any> | FieldReadFunction<any>,
	usage?: FieldPolicy<any> | FieldReadFunction<any>,
	data_files?: FieldPolicy<any> | FieldReadFunction<any>,
	filter_list?: FieldPolicy<any> | FieldReadFunction<any>,
	first_time_user?: FieldPolicy<any> | FieldReadFunction<any>,
	kpis?: FieldPolicy<any> | FieldReadFunction<any>,
	transactions?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_breakdown?: FieldPolicy<any> | FieldReadFunction<any>,
	agg_transactions?: FieldPolicy<any> | FieldReadFunction<any>,
	cash_in_bank?: FieldPolicy<any> | FieldReadFunction<any>,
	kpi_drilldown?: FieldPolicy<any> | FieldReadFunction<any>,
	integrations?: FieldPolicy<any> | FieldReadFunction<any>,
	scenarios?: FieldPolicy<any> | FieldReadFunction<any>,
	connected_integrations?: FieldPolicy<any> | FieldReadFunction<any>,
	data_status?: FieldPolicy<any> | FieldReadFunction<any>,
	accounts?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_info?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CompanyAllKeySpecifier = ('id' | 'name' | 'description' | 'business_type' | 'country' | 'city' | 'position' | 'date_founded' | 'usage' | 'no_employees' | 'kpis' | 'company_users' | 'connected_integrations' | 'integrations' | 'scenarios' | 'transactions' | 'data_status' | 'created' | 'last_accessed' | 'roles' | CompanyAllKeySpecifier)[];
export type CompanyAllFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	business_type?: FieldPolicy<any> | FieldReadFunction<any>,
	country?: FieldPolicy<any> | FieldReadFunction<any>,
	city?: FieldPolicy<any> | FieldReadFunction<any>,
	position?: FieldPolicy<any> | FieldReadFunction<any>,
	date_founded?: FieldPolicy<any> | FieldReadFunction<any>,
	usage?: FieldPolicy<any> | FieldReadFunction<any>,
	no_employees?: FieldPolicy<any> | FieldReadFunction<any>,
	kpis?: FieldPolicy<any> | FieldReadFunction<any>,
	company_users?: FieldPolicy<any> | FieldReadFunction<any>,
	connected_integrations?: FieldPolicy<any> | FieldReadFunction<any>,
	integrations?: FieldPolicy<any> | FieldReadFunction<any>,
	scenarios?: FieldPolicy<any> | FieldReadFunction<any>,
	transactions?: FieldPolicy<any> | FieldReadFunction<any>,
	data_status?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	last_accessed?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CompanyResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | CompanyResponseKeySpecifier)[];
export type CompanyResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ConnectedIntegrationKeySpecifier = ('name' | 'display_name' | 'integration_type' | 'what_can_do' | 'what_need' | 'image_url' | 'connected' | 'connected_files' | ConnectedIntegrationKeySpecifier)[];
export type ConnectedIntegrationFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	display_name?: FieldPolicy<any> | FieldReadFunction<any>,
	integration_type?: FieldPolicy<any> | FieldReadFunction<any>,
	what_can_do?: FieldPolicy<any> | FieldReadFunction<any>,
	what_need?: FieldPolicy<any> | FieldReadFunction<any>,
	image_url?: FieldPolicy<any> | FieldReadFunction<any>,
	connected?: FieldPolicy<any> | FieldReadFunction<any>,
	connected_files?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CountryListKeySpecifier = ('country' | 'iso_country' | 'currency_name' | 'currency_code' | 'currency_symbol' | 'currency_suffix' | 'currency_display_name' | CountryListKeySpecifier)[];
export type CountryListFieldPolicy = {
	country?: FieldPolicy<any> | FieldReadFunction<any>,
	iso_country?: FieldPolicy<any> | FieldReadFunction<any>,
	currency_name?: FieldPolicy<any> | FieldReadFunction<any>,
	currency_code?: FieldPolicy<any> | FieldReadFunction<any>,
	currency_symbol?: FieldPolicy<any> | FieldReadFunction<any>,
	currency_suffix?: FieldPolicy<any> | FieldReadFunction<any>,
	currency_display_name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CurrencyListKeySpecifier = ('currency_name' | 'currency_code' | 'currency_symbol' | 'currency_suffix' | 'currency_display_name' | CurrencyListKeySpecifier)[];
export type CurrencyListFieldPolicy = {
	currency_name?: FieldPolicy<any> | FieldReadFunction<any>,
	currency_code?: FieldPolicy<any> | FieldReadFunction<any>,
	currency_symbol?: FieldPolicy<any> | FieldReadFunction<any>,
	currency_suffix?: FieldPolicy<any> | FieldReadFunction<any>,
	currency_display_name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CustomCFKeySpecifier = ('date' | 'amount' | CustomCFKeySpecifier)[];
export type CustomCFFieldPolicy = {
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DataFileKeySpecifier = ('file_id' | 'name' | 'description' | 'upload_date' | DataFileKeySpecifier)[];
export type DataFileFieldPolicy = {
	file_id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	upload_date?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DataFileResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | DataFileResponseKeySpecifier)[];
export type DataFileResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DataStatusKeySpecifier = ('account_type' | 'colour' | 'connection_status' | 'no_items' | 'main_text' | 'sub_text' | 'button_text' | 'button_inactive' | DataStatusKeySpecifier)[];
export type DataStatusFieldPolicy = {
	account_type?: FieldPolicy<any> | FieldReadFunction<any>,
	colour?: FieldPolicy<any> | FieldReadFunction<any>,
	connection_status?: FieldPolicy<any> | FieldReadFunction<any>,
	no_items?: FieldPolicy<any> | FieldReadFunction<any>,
	main_text?: FieldPolicy<any> | FieldReadFunction<any>,
	sub_text?: FieldPolicy<any> | FieldReadFunction<any>,
	button_text?: FieldPolicy<any> | FieldReadFunction<any>,
	button_inactive?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DrillDownItemKeySpecifier = ('date' | 'amount' | 'month' | DrillDownItemKeySpecifier)[];
export type DrillDownItemFieldPolicy = {
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	month?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DrillDownSummaryKeySpecifier = ('monthly' | 'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec' | DrillDownSummaryKeySpecifier)[];
export type DrillDownSummaryFieldPolicy = {
	monthly?: FieldPolicy<any> | FieldReadFunction<any>,
	Jan?: FieldPolicy<any> | FieldReadFunction<any>,
	Feb?: FieldPolicy<any> | FieldReadFunction<any>,
	Mar?: FieldPolicy<any> | FieldReadFunction<any>,
	Apr?: FieldPolicy<any> | FieldReadFunction<any>,
	May?: FieldPolicy<any> | FieldReadFunction<any>,
	Jun?: FieldPolicy<any> | FieldReadFunction<any>,
	Jul?: FieldPolicy<any> | FieldReadFunction<any>,
	Aug?: FieldPolicy<any> | FieldReadFunction<any>,
	Sep?: FieldPolicy<any> | FieldReadFunction<any>,
	Oct?: FieldPolicy<any> | FieldReadFunction<any>,
	Nov?: FieldPolicy<any> | FieldReadFunction<any>,
	Dec?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ErrorKeySpecifier = ('code' | 'message' | ErrorKeySpecifier)[];
export type ErrorFieldPolicy = {
	code?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FXKeySpecifier = ('currency' | 'base' | 'rate' | FXKeySpecifier)[];
export type FXFieldPolicy = {
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	base?: FieldPolicy<any> | FieldReadFunction<any>,
	rate?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FilterDataKeySpecifier = ('categories' | 'channels' | 'tags' | 'transaction_names' | 'display_transactions' | 'transaction_columns' | FilterDataKeySpecifier)[];
export type FilterDataFieldPolicy = {
	categories?: FieldPolicy<any> | FieldReadFunction<any>,
	channels?: FieldPolicy<any> | FieldReadFunction<any>,
	tags?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_names?: FieldPolicy<any> | FieldReadFunction<any>,
	display_transactions?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_columns?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HistoricalBreakdownKeySpecifier = ('date' | 'amount' | HistoricalBreakdownKeySpecifier)[];
export type HistoricalBreakdownFieldPolicy = {
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type IntegrationKeySpecifier = ('company_id' | 'item_id' | 'integration_name' | 'integration_type' | 'provider' | 'mapping' | 'replace_transactions' | 'mime_type' | 'override_currency' | 'sheet' | 'webhook' | 'webhook_id' | 'last_updated' | 'created' | IntegrationKeySpecifier)[];
export type IntegrationFieldPolicy = {
	company_id?: FieldPolicy<any> | FieldReadFunction<any>,
	item_id?: FieldPolicy<any> | FieldReadFunction<any>,
	integration_name?: FieldPolicy<any> | FieldReadFunction<any>,
	integration_type?: FieldPolicy<any> | FieldReadFunction<any>,
	provider?: FieldPolicy<any> | FieldReadFunction<any>,
	mapping?: FieldPolicy<any> | FieldReadFunction<any>,
	replace_transactions?: FieldPolicy<any> | FieldReadFunction<any>,
	mime_type?: FieldPolicy<any> | FieldReadFunction<any>,
	override_currency?: FieldPolicy<any> | FieldReadFunction<any>,
	sheet?: FieldPolicy<any> | FieldReadFunction<any>,
	webhook?: FieldPolicy<any> | FieldReadFunction<any>,
	webhook_id?: FieldPolicy<any> | FieldReadFunction<any>,
	last_updated?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>
};
export type IntegrationFileKeySpecifier = ('id' | 'mime_type' | 'name' | 'sheets' | 'columns' | 'error' | IntegrationFileKeySpecifier)[];
export type IntegrationFileFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	mime_type?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	sheets?: FieldPolicy<any> | FieldReadFunction<any>,
	columns?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>
};
export type IntegrationResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | IntegrationResponseKeySpecifier)[];
export type IntegrationResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type IntegrationStatusKeySpecifier = ('provider' | 'company_id' | 'status' | 'created' | IntegrationStatusKeySpecifier)[];
export type IntegrationStatusFieldPolicy = {
	provider?: FieldPolicy<any> | FieldReadFunction<any>,
	company_id?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InviteUserKeySpecifier = ('id' | 'display_name' | 'first_name' | 'last_name' | 'email' | InviteUserKeySpecifier)[];
export type InviteUserFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	display_name?: FieldPolicy<any> | FieldReadFunction<any>,
	first_name?: FieldPolicy<any> | FieldReadFunction<any>,
	last_name?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>
};
export type KPIKeySpecifier = ('name' | 'description' | 'category' | 'variant' | 'order' | 'current_value' | 'current_value_suffix' | 'headline' | 'percentage_change' | 'positive_change' | 'subheading' | 'intelligence' | 'show_currency' | 'is_percentage' | 'breakdown_chart_title' | 'breakdown_chart' | 'full_breakdown' | 'chart_data' | 'alerts' | KPIKeySpecifier)[];
export type KPIFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	variant?: FieldPolicy<any> | FieldReadFunction<any>,
	order?: FieldPolicy<any> | FieldReadFunction<any>,
	current_value?: FieldPolicy<any> | FieldReadFunction<any>,
	current_value_suffix?: FieldPolicy<any> | FieldReadFunction<any>,
	headline?: FieldPolicy<any> | FieldReadFunction<any>,
	percentage_change?: FieldPolicy<any> | FieldReadFunction<any>,
	positive_change?: FieldPolicy<any> | FieldReadFunction<any>,
	subheading?: FieldPolicy<any> | FieldReadFunction<any>,
	intelligence?: FieldPolicy<any> | FieldReadFunction<any>,
	show_currency?: FieldPolicy<any> | FieldReadFunction<any>,
	is_percentage?: FieldPolicy<any> | FieldReadFunction<any>,
	breakdown_chart_title?: FieldPolicy<any> | FieldReadFunction<any>,
	breakdown_chart?: FieldPolicy<any> | FieldReadFunction<any>,
	full_breakdown?: FieldPolicy<any> | FieldReadFunction<any>,
	chart_data?: FieldPolicy<any> | FieldReadFunction<any>,
	alerts?: FieldPolicy<any> | FieldReadFunction<any>
};
export type KPIBreakdownDataKeySpecifier = ('item' | 'percentage' | 'amount' | KPIBreakdownDataKeySpecifier)[];
export type KPIBreakdownDataFieldPolicy = {
	item?: FieldPolicy<any> | FieldReadFunction<any>,
	percentage?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type KPIChartDataKeySpecifier = ('date' | 'display_date' | 'amount' | KPIChartDataKeySpecifier)[];
export type KPIChartDataFieldPolicy = {
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	display_date?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('createUser' | 'deleteUser' | 'lastAccessedUser' | 'updateUser' | 'updateUserPlan' | 'createCompany' | 'deleteCompany' | 'lastAccessedCompany' | 'updateCompany' | 'shareCompany' | 'resolveCompanyShare' | 'expiredCompanyShare' | 'createRole' | 'deleteRole' | 'updateRole' | 'createIntegration' | 'deleteIntegration' | 'updateIntegration' | 'refreshIntegration' | 'updateTransactionInfo' | 'deleteAccount' | 'addTransactions' | 'uploadDataFile' | 'deleteDataFile' | 'createScenario' | 'updateScenario' | 'deleteScenario' | 'createAlert' | 'updateAlert' | 'deleteAlert' | 'runSimulation' | 'createCashflow' | 'updateCashflow' | 'deleteCashflow' | 'updateFX' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	createUser?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteUser?: FieldPolicy<any> | FieldReadFunction<any>,
	lastAccessedUser?: FieldPolicy<any> | FieldReadFunction<any>,
	updateUser?: FieldPolicy<any> | FieldReadFunction<any>,
	updateUserPlan?: FieldPolicy<any> | FieldReadFunction<any>,
	createCompany?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteCompany?: FieldPolicy<any> | FieldReadFunction<any>,
	lastAccessedCompany?: FieldPolicy<any> | FieldReadFunction<any>,
	updateCompany?: FieldPolicy<any> | FieldReadFunction<any>,
	shareCompany?: FieldPolicy<any> | FieldReadFunction<any>,
	resolveCompanyShare?: FieldPolicy<any> | FieldReadFunction<any>,
	expiredCompanyShare?: FieldPolicy<any> | FieldReadFunction<any>,
	createRole?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteRole?: FieldPolicy<any> | FieldReadFunction<any>,
	updateRole?: FieldPolicy<any> | FieldReadFunction<any>,
	createIntegration?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteIntegration?: FieldPolicy<any> | FieldReadFunction<any>,
	updateIntegration?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshIntegration?: FieldPolicy<any> | FieldReadFunction<any>,
	updateTransactionInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	addTransactions?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadDataFile?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteDataFile?: FieldPolicy<any> | FieldReadFunction<any>,
	createScenario?: FieldPolicy<any> | FieldReadFunction<any>,
	updateScenario?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteScenario?: FieldPolicy<any> | FieldReadFunction<any>,
	createAlert?: FieldPolicy<any> | FieldReadFunction<any>,
	updateAlert?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteAlert?: FieldPolicy<any> | FieldReadFunction<any>,
	runSimulation?: FieldPolicy<any> | FieldReadFunction<any>,
	createCashflow?: FieldPolicy<any> | FieldReadFunction<any>,
	updateCashflow?: FieldPolicy<any> | FieldReadFunction<any>,
	deleteCashflow?: FieldPolicy<any> | FieldReadFunction<any>,
	updateFX?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('users' | 'user' | 'companies' | 'roles' | 'share' | 'data_files' | 'FXs' | 'system' | 'KPIs' | 'integration_status' | 'simulations' | 'alerts' | 'transactions' | 'file_integration' | 'accounts' | 'transaction_info' | 'quotas' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	users?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	companies?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	share?: FieldPolicy<any> | FieldReadFunction<any>,
	data_files?: FieldPolicy<any> | FieldReadFunction<any>,
	FXs?: FieldPolicy<any> | FieldReadFunction<any>,
	system?: FieldPolicy<any> | FieldReadFunction<any>,
	KPIs?: FieldPolicy<any> | FieldReadFunction<any>,
	integration_status?: FieldPolicy<any> | FieldReadFunction<any>,
	simulations?: FieldPolicy<any> | FieldReadFunction<any>,
	alerts?: FieldPolicy<any> | FieldReadFunction<any>,
	transactions?: FieldPolicy<any> | FieldReadFunction<any>,
	file_integration?: FieldPolicy<any> | FieldReadFunction<any>,
	accounts?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_info?: FieldPolicy<any> | FieldReadFunction<any>,
	quotas?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryErrorKeySpecifier = ('code' | 'message' | QueryErrorKeySpecifier)[];
export type QueryErrorFieldPolicy = {
	code?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QuotaKeySpecifier = ('plan' | 'no_companies_allowed' | 'no_scenarios' | 'no_bank_accounts' | 'no_apps' | QuotaKeySpecifier)[];
export type QuotaFieldPolicy = {
	plan?: FieldPolicy<any> | FieldReadFunction<any>,
	no_companies_allowed?: FieldPolicy<any> | FieldReadFunction<any>,
	no_scenarios?: FieldPolicy<any> | FieldReadFunction<any>,
	no_bank_accounts?: FieldPolicy<any> | FieldReadFunction<any>,
	no_apps?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ResponseKeySpecifier = ('status' | 'error' | 'response' | ResponseKeySpecifier)[];
export type ResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RoleKeySpecifier = ('user' | 'company' | 'role' | RoleKeySpecifier)[];
export type RoleFieldPolicy = {
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	role?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RoleResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | RoleResponseKeySpecifier)[];
export type RoleResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScenarioResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | ScenarioResponseKeySpecifier)[];
export type ScenarioResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScenariosKeySpecifier = ('name' | 'description' | 'optimising_for' | 'sim_date' | 'cashflows' | 'simulation' | 'created' | ScenariosKeySpecifier)[];
export type ScenariosFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	optimising_for?: FieldPolicy<any> | FieldReadFunction<any>,
	sim_date?: FieldPolicy<any> | FieldReadFunction<any>,
	cashflows?: FieldPolicy<any> | FieldReadFunction<any>,
	simulation?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareKeySpecifier = ('company_id' | 'request_email' | 'request_name' | 'invitee' | 'user' | 'shared_by' | 'status' | 'role' | 'last_updated' | 'last_accessed' | 'created' | ShareKeySpecifier)[];
export type ShareFieldPolicy = {
	company_id?: FieldPolicy<any> | FieldReadFunction<any>,
	request_email?: FieldPolicy<any> | FieldReadFunction<any>,
	request_name?: FieldPolicy<any> | FieldReadFunction<any>,
	invitee?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	shared_by?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	role?: FieldPolicy<any> | FieldReadFunction<any>,
	last_updated?: FieldPolicy<any> | FieldReadFunction<any>,
	last_accessed?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | ShareResponseKeySpecifier)[];
export type ShareResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SheetColumnsKeySpecifier = ('sheet' | 'columns' | SheetColumnsKeySpecifier)[];
export type SheetColumnsFieldPolicy = {
	sheet?: FieldPolicy<any> | FieldReadFunction<any>,
	columns?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SimResultsKeySpecifier = ('date' | 'revenue' | 'expense' | 'profit' | 'cash_in_bank' | 'revenue_growth' | 'forecast_type' | SimResultsKeySpecifier)[];
export type SimResultsFieldPolicy = {
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	revenue?: FieldPolicy<any> | FieldReadFunction<any>,
	expense?: FieldPolicy<any> | FieldReadFunction<any>,
	profit?: FieldPolicy<any> | FieldReadFunction<any>,
	cash_in_bank?: FieldPolicy<any> | FieldReadFunction<any>,
	revenue_growth?: FieldPolicy<any> | FieldReadFunction<any>,
	forecast_type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SimStatisticsKeySpecifier = ('runway_period' | 'runway' | 'required_cash' | 'profit' | 'average_profit' | 'growth' | 'average_growth' | 'prob_no_cash' | SimStatisticsKeySpecifier)[];
export type SimStatisticsFieldPolicy = {
	runway_period?: FieldPolicy<any> | FieldReadFunction<any>,
	runway?: FieldPolicy<any> | FieldReadFunction<any>,
	required_cash?: FieldPolicy<any> | FieldReadFunction<any>,
	profit?: FieldPolicy<any> | FieldReadFunction<any>,
	average_profit?: FieldPolicy<any> | FieldReadFunction<any>,
	growth?: FieldPolicy<any> | FieldReadFunction<any>,
	average_growth?: FieldPolicy<any> | FieldReadFunction<any>,
	prob_no_cash?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SimulationKeySpecifier = ('sim_date' | 'status' | 'sim_data' | 'stats' | 'compare_to_base' | SimulationKeySpecifier)[];
export type SimulationFieldPolicy = {
	sim_date?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	sim_data?: FieldPolicy<any> | FieldReadFunction<any>,
	stats?: FieldPolicy<any> | FieldReadFunction<any>,
	compare_to_base?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SystemKeySpecifier = ('trial_on_register' | 'trial_length' | 'countries' | 'currencies' | 'business_type' | 'time_zones' | 'email_notifications' | 'access_types' | 'transaction_categories' | 'pricing' | SystemKeySpecifier)[];
export type SystemFieldPolicy = {
	trial_on_register?: FieldPolicy<any> | FieldReadFunction<any>,
	trial_length?: FieldPolicy<any> | FieldReadFunction<any>,
	countries?: FieldPolicy<any> | FieldReadFunction<any>,
	currencies?: FieldPolicy<any> | FieldReadFunction<any>,
	business_type?: FieldPolicy<any> | FieldReadFunction<any>,
	time_zones?: FieldPolicy<any> | FieldReadFunction<any>,
	email_notifications?: FieldPolicy<any> | FieldReadFunction<any>,
	access_types?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_categories?: FieldPolicy<any> | FieldReadFunction<any>,
	pricing?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TransactionKeySpecifier = ('company_id' | 'transaction_id' | 'merchant' | 'name' | 'amount' | 'date' | 'currency' | 'payment_channel' | 'pending' | 'transaction_info' | 'ignore' | 'last_updated' | 'details' | TransactionKeySpecifier)[];
export type TransactionFieldPolicy = {
	company_id?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_id?: FieldPolicy<any> | FieldReadFunction<any>,
	merchant?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	date?: FieldPolicy<any> | FieldReadFunction<any>,
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	payment_channel?: FieldPolicy<any> | FieldReadFunction<any>,
	pending?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_info?: FieldPolicy<any> | FieldReadFunction<any>,
	ignore?: FieldPolicy<any> | FieldReadFunction<any>,
	last_updated?: FieldPolicy<any> | FieldReadFunction<any>,
	details?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TransactionColumnsKeySpecifier = ('name' | 'description' | 'type' | 'required' | TransactionColumnsKeySpecifier)[];
export type TransactionColumnsFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	required?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TransactionDetailsKeySpecifier = ('name' | 'amount' | 'category' | 'display_name' | 'tags' | 'repeat' | 'number_transactions' | 'total_transactions' | 'avg_transactions' | TransactionDetailsKeySpecifier)[];
export type TransactionDetailsFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	display_name?: FieldPolicy<any> | FieldReadFunction<any>,
	tags?: FieldPolicy<any> | FieldReadFunction<any>,
	repeat?: FieldPolicy<any> | FieldReadFunction<any>,
	number_transactions?: FieldPolicy<any> | FieldReadFunction<any>,
	total_transactions?: FieldPolicy<any> | FieldReadFunction<any>,
	avg_transactions?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TransactionInfoKeySpecifier = ('company_id' | 'transaction_name' | 'display_name' | 'transaction_type' | 'recurring' | 'continuous' | 'recurring_end_date' | 'category' | 'ignore' | 'new' | 'tags' | TransactionInfoKeySpecifier)[];
export type TransactionInfoFieldPolicy = {
	company_id?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_name?: FieldPolicy<any> | FieldReadFunction<any>,
	display_name?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_type?: FieldPolicy<any> | FieldReadFunction<any>,
	recurring?: FieldPolicy<any> | FieldReadFunction<any>,
	continuous?: FieldPolicy<any> | FieldReadFunction<any>,
	recurring_end_date?: FieldPolicy<any> | FieldReadFunction<any>,
	category?: FieldPolicy<any> | FieldReadFunction<any>,
	ignore?: FieldPolicy<any> | FieldReadFunction<any>,
	new?: FieldPolicy<any> | FieldReadFunction<any>,
	tags?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TransactionInfoResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | TransactionInfoResponseKeySpecifier)[];
export type TransactionInfoResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TransactionResponseKeySpecifier = ('data' | 'cursor' | 'request_size' | 'number_remaining' | 'total_count' | TransactionResponseKeySpecifier)[];
export type TransactionResponseFieldPolicy = {
	data?: FieldPolicy<any> | FieldReadFunction<any>,
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	request_size?: FieldPolicy<any> | FieldReadFunction<any>,
	number_remaining?: FieldPolicy<any> | FieldReadFunction<any>,
	total_count?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UsageKeySpecifier = ('company_name' | 'no_scenarios' | 'no_bank_accounts' | 'no_apps' | 'scenario_quota_met' | 'bank_quota_met' | 'app_quota_met' | 'quota_met' | UsageKeySpecifier)[];
export type UsageFieldPolicy = {
	company_name?: FieldPolicy<any> | FieldReadFunction<any>,
	no_scenarios?: FieldPolicy<any> | FieldReadFunction<any>,
	no_bank_accounts?: FieldPolicy<any> | FieldReadFunction<any>,
	no_apps?: FieldPolicy<any> | FieldReadFunction<any>,
	scenario_quota_met?: FieldPolicy<any> | FieldReadFunction<any>,
	bank_quota_met?: FieldPolicy<any> | FieldReadFunction<any>,
	app_quota_met?: FieldPolicy<any> | FieldReadFunction<any>,
	quota_met?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserKeySpecifier = ('id' | 'support_id' | 'tracking_id' | 'first_name' | 'last_name' | 'referral_source' | 'email' | 'time_zone' | 'currency' | 'email_notifications' | 'last_accessed' | 'created' | 'companies' | 'company' | 'fx_rates' | 'roles' | 'shared_with_me' | 'shared_by_me' | 'user_plan' | 'error' | UserKeySpecifier)[];
export type UserFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	support_id?: FieldPolicy<any> | FieldReadFunction<any>,
	tracking_id?: FieldPolicy<any> | FieldReadFunction<any>,
	first_name?: FieldPolicy<any> | FieldReadFunction<any>,
	last_name?: FieldPolicy<any> | FieldReadFunction<any>,
	referral_source?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	time_zone?: FieldPolicy<any> | FieldReadFunction<any>,
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	email_notifications?: FieldPolicy<any> | FieldReadFunction<any>,
	last_accessed?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	companies?: FieldPolicy<any> | FieldReadFunction<any>,
	company?: FieldPolicy<any> | FieldReadFunction<any>,
	fx_rates?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	shared_with_me?: FieldPolicy<any> | FieldReadFunction<any>,
	shared_by_me?: FieldPolicy<any> | FieldReadFunction<any>,
	user_plan?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserPlanKeySpecifier = ('user' | 'quota' | 'no_companies' | 'company_usage' | 'active_trial' | 'no_trial_days' | 'company_quota_met' | UserPlanKeySpecifier)[];
export type UserPlanFieldPolicy = {
	user?: FieldPolicy<any> | FieldReadFunction<any>,
	quota?: FieldPolicy<any> | FieldReadFunction<any>,
	no_companies?: FieldPolicy<any> | FieldReadFunction<any>,
	company_usage?: FieldPolicy<any> | FieldReadFunction<any>,
	active_trial?: FieldPolicy<any> | FieldReadFunction<any>,
	no_trial_days?: FieldPolicy<any> | FieldReadFunction<any>,
	company_quota_met?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserPlanResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | UserPlanResponseKeySpecifier)[];
export type UserPlanResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserResponseKeySpecifier = ('status' | 'error' | 'response' | 'object' | 'id' | UserResponseKeySpecifier)[];
export type UserResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	error?: FieldPolicy<any> | FieldReadFunction<any>,
	response?: FieldPolicy<any> | FieldReadFunction<any>,
	object?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TypedTypePolicies = TypePolicies & {
	Account?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AccountKeySpecifier | (() => undefined | AccountKeySpecifier),
		fields?: AccountFieldPolicy,
	},
	AccountResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AccountResponseKeySpecifier | (() => undefined | AccountResponseKeySpecifier),
		fields?: AccountResponseFieldPolicy,
	},
	Alert?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AlertKeySpecifier | (() => undefined | AlertKeySpecifier),
		fields?: AlertFieldPolicy,
	},
	AlertResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AlertResponseKeySpecifier | (() => undefined | AlertResponseKeySpecifier),
		fields?: AlertResponseFieldPolicy,
	},
	Breakdown?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BreakdownKeySpecifier | (() => undefined | BreakdownKeySpecifier),
		fields?: BreakdownFieldPolicy,
	},
	CashInBank?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CashInBankKeySpecifier | (() => undefined | CashInBankKeySpecifier),
		fields?: CashInBankFieldPolicy,
	},
	CashflowResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CashflowResponseKeySpecifier | (() => undefined | CashflowResponseKeySpecifier),
		fields?: CashflowResponseFieldPolicy,
	},
	Cashflows?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CashflowsKeySpecifier | (() => undefined | CashflowsKeySpecifier),
		fields?: CashflowsFieldPolicy,
	},
	Company?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyKeySpecifier | (() => undefined | CompanyKeySpecifier),
		fields?: CompanyFieldPolicy,
	},
	CompanyAll?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyAllKeySpecifier | (() => undefined | CompanyAllKeySpecifier),
		fields?: CompanyAllFieldPolicy,
	},
	CompanyResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyResponseKeySpecifier | (() => undefined | CompanyResponseKeySpecifier),
		fields?: CompanyResponseFieldPolicy,
	},
	ConnectedIntegration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ConnectedIntegrationKeySpecifier | (() => undefined | ConnectedIntegrationKeySpecifier),
		fields?: ConnectedIntegrationFieldPolicy,
	},
	CountryList?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CountryListKeySpecifier | (() => undefined | CountryListKeySpecifier),
		fields?: CountryListFieldPolicy,
	},
	CurrencyList?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CurrencyListKeySpecifier | (() => undefined | CurrencyListKeySpecifier),
		fields?: CurrencyListFieldPolicy,
	},
	CustomCF?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CustomCFKeySpecifier | (() => undefined | CustomCFKeySpecifier),
		fields?: CustomCFFieldPolicy,
	},
	DataFile?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DataFileKeySpecifier | (() => undefined | DataFileKeySpecifier),
		fields?: DataFileFieldPolicy,
	},
	DataFileResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DataFileResponseKeySpecifier | (() => undefined | DataFileResponseKeySpecifier),
		fields?: DataFileResponseFieldPolicy,
	},
	DataStatus?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DataStatusKeySpecifier | (() => undefined | DataStatusKeySpecifier),
		fields?: DataStatusFieldPolicy,
	},
	DrillDownItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DrillDownItemKeySpecifier | (() => undefined | DrillDownItemKeySpecifier),
		fields?: DrillDownItemFieldPolicy,
	},
	DrillDownSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DrillDownSummaryKeySpecifier | (() => undefined | DrillDownSummaryKeySpecifier),
		fields?: DrillDownSummaryFieldPolicy,
	},
	Error?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ErrorKeySpecifier | (() => undefined | ErrorKeySpecifier),
		fields?: ErrorFieldPolicy,
	},
	FX?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FXKeySpecifier | (() => undefined | FXKeySpecifier),
		fields?: FXFieldPolicy,
	},
	FilterData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FilterDataKeySpecifier | (() => undefined | FilterDataKeySpecifier),
		fields?: FilterDataFieldPolicy,
	},
	HistoricalBreakdown?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HistoricalBreakdownKeySpecifier | (() => undefined | HistoricalBreakdownKeySpecifier),
		fields?: HistoricalBreakdownFieldPolicy,
	},
	Integration?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | IntegrationKeySpecifier | (() => undefined | IntegrationKeySpecifier),
		fields?: IntegrationFieldPolicy,
	},
	IntegrationFile?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | IntegrationFileKeySpecifier | (() => undefined | IntegrationFileKeySpecifier),
		fields?: IntegrationFileFieldPolicy,
	},
	IntegrationResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | IntegrationResponseKeySpecifier | (() => undefined | IntegrationResponseKeySpecifier),
		fields?: IntegrationResponseFieldPolicy,
	},
	IntegrationStatus?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | IntegrationStatusKeySpecifier | (() => undefined | IntegrationStatusKeySpecifier),
		fields?: IntegrationStatusFieldPolicy,
	},
	InviteUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InviteUserKeySpecifier | (() => undefined | InviteUserKeySpecifier),
		fields?: InviteUserFieldPolicy,
	},
	KPI?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | KPIKeySpecifier | (() => undefined | KPIKeySpecifier),
		fields?: KPIFieldPolicy,
	},
	KPIBreakdownData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | KPIBreakdownDataKeySpecifier | (() => undefined | KPIBreakdownDataKeySpecifier),
		fields?: KPIBreakdownDataFieldPolicy,
	},
	KPIChartData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | KPIChartDataKeySpecifier | (() => undefined | KPIChartDataKeySpecifier),
		fields?: KPIChartDataFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	QueryError?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryErrorKeySpecifier | (() => undefined | QueryErrorKeySpecifier),
		fields?: QueryErrorFieldPolicy,
	},
	Quota?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QuotaKeySpecifier | (() => undefined | QuotaKeySpecifier),
		fields?: QuotaFieldPolicy,
	},
	Response?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ResponseKeySpecifier | (() => undefined | ResponseKeySpecifier),
		fields?: ResponseFieldPolicy,
	},
	Role?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RoleKeySpecifier | (() => undefined | RoleKeySpecifier),
		fields?: RoleFieldPolicy,
	},
	RoleResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RoleResponseKeySpecifier | (() => undefined | RoleResponseKeySpecifier),
		fields?: RoleResponseFieldPolicy,
	},
	ScenarioResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScenarioResponseKeySpecifier | (() => undefined | ScenarioResponseKeySpecifier),
		fields?: ScenarioResponseFieldPolicy,
	},
	Scenarios?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScenariosKeySpecifier | (() => undefined | ScenariosKeySpecifier),
		fields?: ScenariosFieldPolicy,
	},
	Share?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareKeySpecifier | (() => undefined | ShareKeySpecifier),
		fields?: ShareFieldPolicy,
	},
	ShareResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareResponseKeySpecifier | (() => undefined | ShareResponseKeySpecifier),
		fields?: ShareResponseFieldPolicy,
	},
	SheetColumns?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SheetColumnsKeySpecifier | (() => undefined | SheetColumnsKeySpecifier),
		fields?: SheetColumnsFieldPolicy,
	},
	SimResults?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SimResultsKeySpecifier | (() => undefined | SimResultsKeySpecifier),
		fields?: SimResultsFieldPolicy,
	},
	SimStatistics?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SimStatisticsKeySpecifier | (() => undefined | SimStatisticsKeySpecifier),
		fields?: SimStatisticsFieldPolicy,
	},
	Simulation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SimulationKeySpecifier | (() => undefined | SimulationKeySpecifier),
		fields?: SimulationFieldPolicy,
	},
	System?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SystemKeySpecifier | (() => undefined | SystemKeySpecifier),
		fields?: SystemFieldPolicy,
	},
	Transaction?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TransactionKeySpecifier | (() => undefined | TransactionKeySpecifier),
		fields?: TransactionFieldPolicy,
	},
	TransactionColumns?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TransactionColumnsKeySpecifier | (() => undefined | TransactionColumnsKeySpecifier),
		fields?: TransactionColumnsFieldPolicy,
	},
	TransactionDetails?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TransactionDetailsKeySpecifier | (() => undefined | TransactionDetailsKeySpecifier),
		fields?: TransactionDetailsFieldPolicy,
	},
	TransactionInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TransactionInfoKeySpecifier | (() => undefined | TransactionInfoKeySpecifier),
		fields?: TransactionInfoFieldPolicy,
	},
	TransactionInfoResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TransactionInfoResponseKeySpecifier | (() => undefined | TransactionInfoResponseKeySpecifier),
		fields?: TransactionInfoResponseFieldPolicy,
	},
	TransactionResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TransactionResponseKeySpecifier | (() => undefined | TransactionResponseKeySpecifier),
		fields?: TransactionResponseFieldPolicy,
	},
	Usage?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UsageKeySpecifier | (() => undefined | UsageKeySpecifier),
		fields?: UsageFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	},
	UserPlan?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserPlanKeySpecifier | (() => undefined | UserPlanKeySpecifier),
		fields?: UserPlanFieldPolicy,
	},
	UserPlanResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserPlanResponseKeySpecifier | (() => undefined | UserPlanResponseKeySpecifier),
		fields?: UserPlanResponseFieldPolicy,
	},
	UserResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserResponseKeySpecifier | (() => undefined | UserResponseKeySpecifier),
		fields?: UserResponseFieldPolicy,
	}
};