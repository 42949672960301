import { InMemoryCache } from '@apollo/client';
import { TypedTypePolicies } from '../generated/graphql-frontend';

const typePolicies: TypedTypePolicies = {
  Account: {
    keyFields: ['account_id']
  },
  TransactionInfo: {
    keyFields: ['transaction_name', 'company_id']
  },
  Alert: {
    keyFields: ['kpi_name', 'id']
  },
  Transaction: {
    keyFields: ['company_id', 'transaction_id']
  },
  DataFile: {
    keyFields: ['file_id']
  }
};
export const cache = new InMemoryCache({
  typePolicies
});
