import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@material-ui/core';

const button: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
} = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true
  },
  styleOverrides: {
    root: {
      textTransform: 'capitalize'
    },
    textPrimary: {
      '&:hover': {
        color: '#FF008E',
        borderColor: '#FF008E'
      }
    },
    outlinedPrimary: {
      '&:hover': {
        backgroundColor: '#FF008E',
        color: '#FFF',
        borderColor: '#FF008E'
      }
    },
    containedPrimary: {
      '&:hover': {
        backgroundColor: '#FF008E'
      }
    }
  }
};

export default button;
