import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Transaction, useGetTransactionsQuery } from '../../../../../generated/graphql-frontend';
import { isEmpty } from '../../../../../utils/isEmpty';
import { AnalysisContext } from '../../../Hooks/AnalysisContext';
import TransactionHeader from '../TransactionHeader';
import TransactionList from './TransactionList';
import TransactionModal from './TransactionModal';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3)
  }
}));

export const TransactionContainer = () => {
  const classes = useStyles();
  const { company_id } = useParams<CompanyIdProps>();
  const { filter } = React.useContext(AnalysisContext);

  const { data, loading, fetchMore, error } = useGetTransactionsQuery({
    variables: { company_id, limit: 50, filter },
    notifyOnNetworkStatusChange: true
  });
  const transactions_data = data?.user?.company?.transactions?.find(
    (transaction) => !isEmpty(transaction)
  );
  const transactions = transactions_data?.data;
  const cursor = transactions_data?.cursor;
  const remainNum = transactions_data?.number_remaining;

  const fetchMoreTransactions = () => {
    fetchMore({
      variables: { company_id, cursor, limit: 50 },
      updateQuery: (previousData, { fetchMoreResult }) => {
        const existingTransactions = previousData.user?.company?.transactions?.find(
          (item) => !isEmpty(item)
        );
        const incomingTransactions = fetchMoreResult?.user?.company?.transactions?.find(
          (item) => !isEmpty(item)
        );
        const incomingTransData = incomingTransactions?.data;

        if (!incomingTransData) return previousData;
        return {
          user: {
            company: {
              transactions: [
                {
                  cursor: incomingTransactions?.cursor,
                  number_remaining: incomingTransactions?.number_remaining,
                  total_count: incomingTransactions?.total_count,
                  request_size: incomingTransactions?.request_size,
                  data: existingTransactions?.data?.concat(incomingTransData)
                }
              ]
            }
          }
        };
      }
    });
  };

  if (error) return <Typography>Error: ${error.message}</Typography>;

  return (
    <div className={classes.root}>
      <TransactionHeader />
      <TransactionModal />
      <TransactionList
        data={transactions as Transaction[]}
        remainNum={remainNum}
        loading={loading}
        fetchMoreData={fetchMoreTransactions}
      />
    </div>
  );
};
