import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../Components/assets/ABx Logo.png';
import NoImg from '../Components/assets/error_woman.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#525252',
    minHeight: '100vh',
    display: 'flex',
    padding: '40px',
    color: 'white'

    /*background: `url(${NoImg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",*/
  },
  container: {
    marginBottom: theme.spacing(8),
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '40px'
  },
  imageRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '20px',
    width: '50%'
  },
  fullColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '20px',
    marginBottom: '40px',
    width: '100%'
  },
  mainText: {
    colour: 'white',
    fontSize: '1rem',
    fontWeight: 400
  },
  titleText: {
    color: 'white',
    fontSize: '3rem',
    fontWeight: 500,
    marginBottom: '30px'
  },
  linkText: {
    color: '#FF008E',
    fontSize: '1rem',
    fontWeight: 400
  },
  logo: {
    position: 'relative',
    top: '-10px',
    left: '0px',
    marginBottom: '20px'
  },
  imageFixed: {
    minWidth: '300px'
  },
  imageVariable: {
    width: '50%',
    height: '50%',
    minWidth: '200px',
    display: 'none'
  }
}));

interface noMatchProps {
  notFound: boolean;
}

export default function NoMatch({ notFound = false }: noMatchProps): ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const matches = useMediaQuery(theme.breakpoints.up('md'));
  var Error = notFound ? 'Whoops we can’t find that page!' : 'Oh no, something went wrong!';
  return (
    <div className={classes.paper}>
      <div className={classes.container}>
        <div className={classes.imageRow}>
          <div className={matches ? classes.leftColumn : classes.fullColumn}>
            <div className={classes.mainText}>
              <img height={90} width={80} src={Logo} className={classes.logo} alt="Logo" />
            </div>
            <div className={classes.titleText}>{Error}</div>
            <button className="btn-outline-white" onClick={() => history.goBack()}>
              Go Back
            </button>
          </div>
          <div>
            {matches ? (
              <img src={NoImg} className={classes.imageFixed} alt="No Image" />
            ) : (
              <img src={NoImg} className={classes.imageVariable} alt="no match" />
            )}
          </div>
        </div>
        <div style={{ paddingLeft: '20px' }}>
          <span className={classes.mainText}>Keep getting an error? Contact us at </span>
          <a className={classes.linkText} href="mailto:support@alchemybox.io">
            support@alchemybox.io
          </a>
        </div>
      </div>
    </div>
  );
}
