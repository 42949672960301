import React from 'react';
import Sidebar from '../../../../Components/Sidebar';
import { Grid } from '@material-ui/core';
import { ReactComponent as PreferenceBG } from '../../../../Components/assets/PreferenceBG.svg';
import PreferenceForm from './UserDetail/PreferenceForm';

export default function PrefFirst() {
  return (
    <div>
      <Grid container direction="row" style={{ background: '#FFF' }}>
        <Grid item xs={false} sm={5} md={4} lg={3}>
          <Sidebar
            logoTitle={'Get Started'}
            logoSubtitle={'Create a company'}
            image={<PreferenceBG width="100%" />}
          />
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9} xl={8}>
          <PreferenceForm />
        </Grid>
      </Grid>
    </div>
  );
}
