import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement, useContext } from 'react';
import Logo from '../Components/assets/ABx Logo.png';
import LogoutIcon from '../Routes/Auth/RegisterStep/Components/LogoutIcon';
import { AuthContext } from '../Context/Login';

interface SidebarProps {
  logoTitle: string;
  logoSubtitle: string;
  image: ReactElement;
}

export default function Sidebar({ logoTitle, logoSubtitle, image }: SidebarProps) {
  const user = useContext(AuthContext);

  return (
    <Box
      display="flex"
      flexShrink={0}
      bgcolor="#DAEAF6"
      flexDirection="column"
      height="100%"
      minHeight="100vh"
    >
      <Box bgcolor="#0D8ABC" pl={5} py={8}>
        <img height={85} width={75} src={Logo} alt="Logo" />
        <Box mt={5} mb={3} style={{ color: 'white' }}>
          <Typography variant="subtitle2" style={{ padding: '0px', marginTop: '30px' }}>
            {logoTitle}
          </Typography>
          <Typography variant="sbTextL" style={{ position: 'relative', top: '-10px' }}>
            {logoSubtitle}
          </Typography>
        </Box>
      </Box>
      <Box textAlign="center" marginTop="auto" p={7}>
        {image}
      </Box>
      <Box mt="auto" p={2}>
        <Button color="primary" variant="text" startIcon={<LogoutIcon />} onClick={user.signOut}>
          Sign out
        </Button>
      </Box>
    </Box>
  );
}
