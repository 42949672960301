import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { ReactElement } from 'react';
import {
  DataFile,
  GetDataFilesDocument,
  useDeleteDataFileMutation
} from '../../../../generated/graphql-frontend';

interface Props {
  company_id: string;
  fileid: string;
}

const options = ['Delete'];
const OptionType = typeof options;

const ITEM_HEIGHT = 48;

export default function DataFileMoreButton({ company_id, fileid }: Props): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleteDataFile, { loading }] = useDeleteDataFileMutation();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement>, option: string) => {
    if (option === 'Delete') {
      deleteDataFile({
        variables: { company_id, fileid },
        update(cache, { data }) {
          if (data?.deleteDataFile?.status === true) {
            const deletedId = data?.deleteDataFile.id;
            const existingFiles: any = cache.readQuery({
              query: GetDataFilesDocument,
              variables: { company_id }
            });
            const existingFileLists: DataFile[] = existingFiles.data_files;
            const deletedFile = existingFileLists.find((file) => file.file_id === deletedId);
            if (deletedFile) {
              const cacheId = cache.identify(deletedFile);
              cache.modify({
                fields: {
                  data_files(existingRefs, { readField }) {
                    return existingRefs.filter(
                      (dataFile: DataFile) => deletedId !== readField('file_id', dataFile)
                    );
                  }
                }
              });
            }
          }
        }
      }).then(() => {
        setAnchorEl(null);
      });
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            disabled={loading}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
