import { Box, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ReactComponent as UpArrow } from '../../../assests/icon/upArrow.svg';
import { useCompanyStore } from '../../../Context/GlobalStore';
import { CurrentValueSuffixes, Kpi } from '../../../generated/graphql-frontend';
import CashBar from './CashBar';
import HorizontalChart from './HorizontalChart';

export default function History({ kpi }: { kpi: Kpi }): ReactElement {
  const { profile } = useCompanyStore();
  const currentValueSuffix = () => {
    switch (kpi.current_value_suffix) {
      case CurrentValueSuffixes.Currency:
        return profile?.currency;
      case CurrentValueSuffixes.Percentage:
        return '%';
      case CurrentValueSuffixes.None:
        return '';
      default:
        return;
    }
  };
  return (
    <div>
      <Typography variant="subtitle2" display="flex">
        {kpi.name}
        <Typography
          component="span"
          variant="subtitle2"
          color="success.main"
          ml={2}
          mr={4}
          display="flex"
          alignItems="center"
        >
          {kpi.percentage_change?.toFixed(2)}%
          <UpArrow />
        </Typography>
      </Typography>
      <Typography variant="h2" color="text.secondary">
        {kpi?.current_value?.toLocaleString()}
        {currentValueSuffix()}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {kpi.intelligence}
        <Typography component="span" color="success.main"></Typography>{' '}
      </Typography>
      <Box mt={4} height={250}>
        <CashBar data={kpi.chart_data} barWidth={50} dataKey="date" />
      </Box>
      {kpi.full_breakdown && kpi.full_breakdown?.length > 0 && (
        <Box my={4} height={kpi.full_breakdown?.length*40} >
          <Typography variant="subtitle2" display="flex">
            {kpi.breakdown_chart_title}
          </Typography>
          <HorizontalChart data={kpi?.full_breakdown} color="white" />
        </Box>
      )}
    </div>
  );
}
