import { EventDataMap, TagData, TagifySettings } from '@yaireo/tagify';
import Tags from '@yaireo/tagify/dist/react.tagify'; // React-wrapper file
import '@yaireo/tagify/dist/tagify.css'; // Tagify CSS
import React, { useEffect, useRef, useState } from 'react';
import './multiselect.css'; // Custom CSS

// ================= TAGFIELD ==================

interface TagFieldProps {
  options: string[];
  initialValues?: string[];
  placeholder?: string;
  onChange: (tags: string[]) => void;
  reset?: number;
}

export const MultiSelect = ({
  options,
  initialValues = [],
  onChange,
  placeholder = 'Please select',
  reset = 0
}: TagFieldProps) => {
  // Tagify settings object
  const baseTagifySettings: TagifySettings<TagData> = {
    blacklist: [],
    maxTags: 20,
    backspace: true,
    placeholder: placeholder,
    editTags: false,
    enforceWhitelist: true,
    skipInvalid: true,
    delimiters:"`",
    dropdown: {
      enabled: 0
    },

    callbacks: {}
  };

  const tagifyRef = useRef<any>(null);
  const handleChange = (event: CustomEvent<EventDataMap[keyof EventDataMap]>) => {
    console.log(event.type);
  };

  const [tags, setTag] = useState(initialValues);
  const [ClassVal, setClassVal] = useState('tagStyle');
  const [suggestions, setSuggestions] = useState(options);

  useEffect(() => {

    if (tags) {
      onChange(tags);
      tags.length > 0 ? setClassVal('activeTagStyle') : setClassVal('tagStyle');
    } else {
      setClassVal('tagStyle');
    }
  }, [tags]);

  useEffect(() => {
    if (reset) {
      tagifyRef.current && tagifyRef.current.removeAllTags();
    }
  }, [reset]);

  const addTag = (e: CustomEvent<EventDataMap[keyof EventDataMap]>) => {

    let updatedTags = e.detail.tagify.value.map((val) => val.value);

    setTag(updatedTags);
    let updatedSuggestions = suggestions?.concat(updatedTags);
    setSuggestions(updatedSuggestions?.map((suggestion) => suggestion));
  };

  const removeTag = (e: CustomEvent<EventDataMap[keyof EventDataMap]>) => {

    let updatedTags = e.detail.tagify.value.map((val) => val.value);

    setTag(updatedTags);
    let updatedSuggestions = suggestions?.concat(updatedTags);
    setSuggestions(updatedSuggestions?.map((suggestion) => suggestion));

  };

  const settings: TagifySettings<TagData> = {
    ...baseTagifySettings,
    whitelist: suggestions,
    dropdown: {
      maxItems: 100,
      classname: 'MultiTagStyle',
      enabled: 0,
      closeOnSelect: false
    },

    callbacks: {
      add: addTag,
      remove: removeTag,
      blur: handleChange,
      invalid: handleChange,
      click: handleChange,
      focus: handleChange,
      'edit:updated': addTag,
      'edit:start': handleChange
    }
  };

  return (
    <div className="form-group w-full">
      <div className="flex text-white border border-abx-blue bg-abx-blue hover:bg-abx-light-blue hover:border-abx-blue hover:border p-2 py-3">
        <Tags
          tagifyRef={tagifyRef}
          settings={settings as TagifySettings<TagData>}
          defaultValue={initialValues}
          className="MultiTagStyle"
        />
      </div>
    </div>
  );
};
