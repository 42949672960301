import { makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import ABSWitch from "../../../../../Components/ABSwitch";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function LoanForm(): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* Name */}
      <Typography variant="formText" color="primary" marginTop={4}>
        What do you want to name this?
      </Typography>
      <TextField id="loan_name" name="loan_name" variant="standard" size="small" placeholder="Name" />

      {/* Date */}
      <Typography variant="formText" color="primary" marginTop={4}>
        When are you recieving your loan?
      </Typography>
      <TextField id="loan_name" name="loan_name" variant="standard" size="small" placeholder="Name" />

      {/* Amount */}
      <Typography variant="formText" color="primary" marginTop={4}>
        How much are you receiving?
      </Typography>
      <TextField id="loan_name" name="loan_name" variant="standard" size="small" placeholder="Name" />

      {/* Monthly */}
      <Typography variant="formText" color="primary" marginTop={4}>
        How often you paying this back?
      </Typography>
      <TextField id="loan_name" name="loan_name" variant="standard" size="small" placeholder="Name" />

      {/* Payint back loan */}
      <Typography variant="formText" color="primary" marginTop={4}>
        When do you start paying back your loan?
      </Typography>
      <TextField id="loan_name" name="loan_name" variant="standard" size="small" placeholder="Name" />

      {/* Payment */}
      <Typography variant="formText" color="primary" marginTop={4}>
        Do you know how many payments you will make?
      </Typography>
      <ABSWitch />

      {/* Payment you will make */}
      <Typography variant="formText" color="primary" marginTop={4}>
        How many payments will you make?
      </Typography>
      <TextField id="num_payment" name="num_payment" variant="standard" size="small" placeholder="Number of payments" />

      {/* First payment */}
      <Typography variant="formText" color="primary" marginTop={4}>
        Do you have a larger first payment or deposit?
      </Typography>
      <ABSWitch />

      {/* Amount */}
      <Typography variant="formText" color="primary" marginTop={4}>
        How much is the first payment?
      </Typography>
      <TextField id="firest_payment" name="first_payment" variant="standard" size="small" placeholder="First Payment" />

      {/* Amount */}
      <Typography variant="formText" color="primary" marginTop={4}>
        How much are the rest of your payments?
      </Typography>
      <TextField id="repayment" name="repayment" variant="standard" size="small" placeholder="Repayment Amount" />
    </div>
  );
}
