import { Box, useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@mui/material';
import { useFlag } from '@unleash/proxy-client-react';
import React, { createRef, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '../../Components/assets/ABx Logo.png';
import { useCompanyStore } from '../../Context/GlobalStore';
import { SideTooltip } from './Component/SideTooltip';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    height: 100
  },

  list: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    padding: 0
  },
  logoutContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    marginTop: 'auto'
  },
  selectedArrow: {
    width: 0,
    height: 0,
    borderTop: '15px solid transparent',
    borderRight: `12px solid ${theme.palette.background.default}`,
    borderBottom: '15px solid transparent',
    position: 'absolute',
    right: '0px',
    transition: 'all  .35s ease-in-out',
    marginTop: '17px'
    // transform: "rotate(45deg)",
  },
  drawContainer: {
    overflow: 'hidden',
    width: '90px'
  }
}));

const ABDrawer = styled(Drawer)`
  color: white;
  & .MuiDrawer-paperAnchorDockedLeft {
    width: 90px;
    border-color: transparent;
    background-color: #0d8abc;
    border-right: 0px solid rgba(0, 0, 0, 0.12);
  }
`;

export default function MainSideBar() {
  const classes = useStyles();
  const [selected, setSelected] = useState(0);
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { currentCompanyId } = useCompanyStore();

  const itemRefs = useMemo(() => Array.from({ length: 4 }).map(() => createRef()), []);

  const analysisPageToggle = useFlag('analysis-page');
  const scenariosPageToggle = useFlag('Scenarios-page');
  const kpiPageToggle = useFlag('kpi-page');

  const pageToggleLists = {
    data: true,
    kpi: kpiPageToggle,
    analysis: analysisPageToggle,
    scenarios: scenariosPageToggle
  };

  const Cloud = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-8 w-8 ${selected === 'data' ? 'text-abx-orange' : 'text-white'}`}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M5.5 16a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 16h-8z" />
      </svg>
    );
  };

  const Chart = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-8 w-8 ${selected === 'kpi' ? 'text-abx-orange' : 'text-white'}`}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
        <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
      </svg>
    );
  };

  const Bar = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-8 w-8 ${selected === 'analysis' ? 'text-abx-orange' : 'text-white'}`}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
      </svg>
    );
  };

  const Forecast = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-8 w-8 ${selected === 'scenarios' ? 'text-abx-orange' : 'text-white'}`}
        viewBox="0 0 22 22"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z" />
      </svg>
    );
  };
  const toolbarList = [
    {
      title: 'Data',
      name: 'data',
      icon: <Cloud />
    },
    {
      title: 'Key Performance Indicators',
      name: 'kpi',
      icon: <Chart />
    },
    {
      title: 'Transaction History',
      name: 'analysis',
      icon: <Bar />
    },
    {
      title: 'Scenarios',
      name: 'scenarios',
      icon: <Forecast />
    }
  ];

  // TODO: Following router was hardcoded. Need to change after confing all router.
  const handleClickMenu = (name) => {
    switch (name) {
      case 'data':
        history.push(`/data/${currentCompanyId}`);
        break;
      case 'analysis':
        history.push(`/analysis/${currentCompanyId}`);
        break;
      case 'kpi':
        history.push(`/kpi/${currentCompanyId}`);
        break;
      case 'scenarios':
        history.push(`/scenarios/${currentCompanyId}`);
        break;
      default:
        break;
    }
    setSelected(name);
  };

  // Check current router when page load
  // e.g: http://localhost:5000/data/0dd10f67-427c-11ec-8707-f95574540dae ==>["","data","0dd10f67-427c-11ec-8707-f95574540dae"]
  useEffect(() => {
    const currentRouter = location.pathname.split('/')[1];
    setSelected(currentRouter);
  }, [setSelected, location.pathname]);

  return (
    <ABDrawer variant="permanent" sx={{ display: isMobile ? 'none' : 'block' }}>
      <div className={classes.drawContainer}>
        <Box display="flex" justifyContent="center" my={5}>
          <Link to="/">
            <img src={Logo} alt="logo" className="h-8 w-14" />
          </Link>
        </Box>

        <div className="flex flex-col space-y-2 justify-items-center justify-center align-middle content-center">
          {toolbarList.map((item, index) => {
            if (!pageToggleLists[item.name ?? '']) return null;
            return (
              <SideTooltip title={item.title} key={`tooltip${index}`} placement="right" arrow>
                <div
                  ref={itemRefs[index]}
                  id={`menu-${item.name}`}
                  className="ml-6 py-2 flex flex-col content-center cursor-pointer"
                  key={item.name}
                  onClick={() => handleClickMenu(item.name)}
                >
                  {item.icon}
                </div>
              </SideTooltip>
            );
          })}
        </div>
      </div>
    </ABDrawer>
  );
}
