import { Button, InputBase, Select, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ABTypography from '../../../../../Components/ABTypography';
import { useCompanyStore } from '../../../../../Context/GlobalStore';
import { useGetTransactionBreakDownQuery } from '../../../../../generated/graphql-frontend';
import { isEmpty } from '../../../../../utils/isEmpty';
import { categoryColor } from '../../category';
import Bar from '../ProgressBars/HorizontalBar';
import ExpenseModal from './ExpenseModal';
import ExpenseSkeleton from './ExpenseSkeleton';

const useStyles = makeStyles((theme) => ({
  selectIcon: {
    color: '#FFF !important'
  },
  selectInput: {
    color: 'inherit'
  },
  buttonRoot: {
    color: 'white'
  },
  buttonOutlined: {
    border: '1px solid #FFF'
  }
}));

export default function Expense() {
  const classes = useStyles();
  const { company_id } = useParams<CompanyIdProps>();
  const theme = useTheme();

  const [period, setPeriod] = useState<PeriodType>('M');
  const [kind, setKind] = useState<TransactionType>('expense');
  const [openExpenseModal, setopenExpenseModal] = useState<boolean>(false);
  const { profile } = useCompanyStore();
  const { data, error, loading } = useGetTransactionBreakDownQuery({
    variables: {
      transaction_type: kind,
      period,
      no_groups: 10,
      company_id
    }
  });

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string;
      event: Event | React.SyntheticEvent<Element, Event>;
    }>
  ) => {
    setPeriod(event.target.value as PeriodType);
  };

  const handleChangeKind = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: string;
      event: Event | React.SyntheticEvent<Element, Event>;
    }>
  ) => {
    setKind(event.target.value as TransactionType);
  };

  const handleSetPeriod = (value: PeriodType) => {
    setPeriod(value);
  };
  const expenseArray = data?.user?.companies?.find(
    (company) => !isEmpty(company)
  )?.transaction_breakdown;
  const detailData = data?.user?.companies?.find((company) => !isEmpty(company))?.agg_transactions;

  const totalAmount = expenseArray?.reduce((sum, crr) => {
    if (!crr.amount) return sum;
    return sum + crr.amount;
  }, 0);

  if (error) return <div>{`Error: ${error.message}`}</div>;

  return (
    <Box borderRadius={5} padding={4} bgcolor={theme.palette.primary.dark} color="white">
      <Box mb={5}>
        <Box display="flex">
          <Select
            id="period-select"
            name="period"
            style={{ color: '#FFF' }}
            classes={{ icon: classes.selectIcon }}
            IconComponent={ExpandMoreIcon}
            input={<InputBase classes={{ input: classes.selectInput }} />}
            value={kind}
            onChange={handleChangeKind}
          >
            <MenuItem value={'expense'}>Expense Breakdown</MenuItem>
            <MenuItem value={'income'}>Income Breakdown</MenuItem>
          </Select>

          <Select
            id="period-select"
            name="period"
            style={{ marginLeft: 'auto', width: 120, color: '#FFF' }}
            color="primary"
            classes={{ icon: classes.selectIcon }}
            IconComponent={ExpandMoreIcon}
            input={<InputBase classes={{ input: classes.selectInput }} />}
            value={period}
            onChange={handleChange}
          >
            <MenuItem value={'D'}>Today</MenuItem>
            <MenuItem value={'W'}>This Week</MenuItem>
            <MenuItem value={'M'}>This Month</MenuItem>
            <MenuItem value={'Q'}>This Quarter</MenuItem>
            <MenuItem value={'CY'}>This Year</MenuItem>
          </Select>
        </Box>

        <Box display="flex" mt={2}>
          <Typography variant="h4">
            {kind === 'expense' ? 'Total Expenses' : 'Total Income'}
          </Typography>
          <ABTypography variant="h4" ml={2} abColor="white">
            {Number(totalAmount).toLocaleString()} {profile?.currency}{' '}
          </ABTypography>
        </Box>
      </Box>
      {loading ? (
        <ExpenseSkeleton />
      ) : (
        expenseArray?.slice(0, 3).map((item) => {
          let catColour: string =
            item.category == null ? '#eeeeee' : categoryColor[item.category]?.colour;
          return (
            <Bar
              key={uuidv4()}
              category={item.category}
              amount={item.amount}
              color={catColour}
              percent={item.percentage}
            />
          );
        })
      )}

      <Box>
        <Button
          variant="outlined"
          style={{ color: 'white' }}
          onClick={() => setopenExpenseModal(!openExpenseModal)}
        >
          More Details
        </Button>
      </Box>
      <ExpenseModal
        open={openExpenseModal}
        loading={loading}
        expenseData={expenseArray}
        kind={kind}
        period={period}
        detailData={detailData}
        toggleOpen={() => setopenExpenseModal(!openExpenseModal)}
        setPeriodValue={handleSetPeriod}
      />
    </Box>
  );
}
