import { Box, Button, LinearProgress } from "@material-ui/core";
import React, { ReactElement } from "react";
import ABTypography from "./ABTypography";

interface Props {
  activeValue: number;
  totalValue?: number;
  increaseStep: () => void;
  decreasestep: () => void;
}

export default function ABStepper({
  activeValue = 2,
  totalValue = 7,
  increaseStep,
  decreasestep,
}: Props): ReactElement {
  const handleCancel = () => {
    if (activeValue > 1) decreasestep();
  };
  return (
    <Box
      display="flex"
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      paddingX={12}
      paddingY={2}
      bgcolor="rgba(233, 233, 236, 0.95)"
      zIndex={1000}
    >
      <Button variant="outlined" disabled={activeValue <= 1} color="primary" onClick={() => handleCancel()}>
        {activeValue > 1 ? "Back" : "Cancel"}
      </Button>
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Box flexShrink={0}>
          <ABTypography noWrap>{`Setp ${activeValue} of ${totalValue}`}</ABTypography>
        </Box>
        <Box height={10} width="80%" bgcolor="#F6F6F9" marginLeft="20px">
          <Box height="100%" width={`${(activeValue / totalValue) * 100}%`} bgcolor="#0D8ABC"></Box>
        </Box>
      </Box>
      <LinearProgress />
      <Button variant="contained" color="primary" disabled={activeValue >= 7} onClick={() => increaseStep()}>
        Next
      </Button>
    </Box>
  );
}
