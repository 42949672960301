import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import TransitionLayout from '../Routes/Component/TransitionLayout';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 66,
    paddingBottom: 40,
    scrollbarWidth: 'none'
  }
}));

const BasicContainer: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TransitionLayout>{children}</TransitionLayout>
    </div>
  );
};
export default BasicContainer;
