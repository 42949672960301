import FormGroup from '@material-ui/core/FormGroup';
import Stack from '@material-ui/core/Stack';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  overflow: 'visible',
  margin: theme.spacing(1),
  '& .MuiSwitch-switchBase': {
    padding: 1,
    color: '#0D8ABC',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#FF008E',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.grey[50],
        // border: `1px solid ${theme.palette.grey[400]}`,
        opacity: 1
        // border: "none"
      },
      '& + .MuiSwitch-thumb': {
        color: '#0D8ABC !important',
        opacity: 1
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#0D8ABC',
      border: '6px solid #fff'
    }
  },
  '& .MuiSwitch-thumb': {
    width: 24,
    height: 24,
    borderRadius: 4
  },
  '& .MuiSwitch-track': {
    borderRadius: 5,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#F0F6F9',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border-color'])
  }
}));

interface ABSwitchProps extends SwitchProps {
  yesText?: string;
  noText?: string;
  callback?: (param: boolean) => void;
}

export default function ABSwitch({
  yesText = 'Yes',
  noText = 'No',
  callback = () => {},
  ...props
}: ABSwitchProps) {
  const [switchVal, setSwitchVal] = React.useState(false);
  const handleChange = () => {
    setSwitchVal(!switchVal);
  };
  React.useEffect(() => {
    callback(switchVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchVal]);
  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{noText}</Typography>
        <IOSSwitch {...props} onChange={handleChange} />
        <Typography>{yesText}</Typography>
      </Stack>
    </FormGroup>
  );
}
