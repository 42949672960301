import { useTheme } from '@material-ui/core';
import { Button, Typography, Paper } from '@mui/material';
import Box from '@mui/system/Box';
import React, { ReactElement } from 'react';
import { KPIVariation } from '.';
import { CurrentValueSuffixes, Kpi } from '../../../../generated/graphql-frontend';
import CashBar from '../CashBar';
import HorizontalChart from '../HorizontalChart';
import { ReactComponent as UpArrow } from '../../../../assests/icon/upArrow.svg';
import { ReactComponent as DownArrow } from '../../../../assests/icon/downArrow.svg';
import { useCompanyStore } from '../../../../Context/GlobalStore';

interface Props {
  color?: 'white' | 'blue' | 'disabled';
  kpi?: Kpi;
  variant?: KPIVariation;
  toggleDialog?: (kpi?: Kpi) => void;
}

export default function BarCashCard({
  color = 'blue',
  kpi = {},
  toggleDialog = () => {},
  variant = 'bar'
}: Props): ReactElement {
  const theme = useTheme();
  const { profile } = useCompanyStore();
  const { percentage_change = 0 } = kpi;

  const currentValueSuffix = () => {
    switch (kpi.current_value_suffix) {
      case CurrentValueSuffixes.Currency:
        return profile?.currency;
      case CurrentValueSuffixes.Percentage:
        return '%';
      case CurrentValueSuffixes.None:
        return '';
      default:
        return;
    }
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: 5 }}>
      <Box
        position="relative"
        px={5}
        pt={6}
        pb={6}
        borderRadius={3}
        minWidth={322}
        minHeight={444}
        height="100%"
        textAlign="center"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column" textAlign="left">
          <Box display="flex">
            <Typography variant="body1">{kpi?.name} </Typography>
            {percentage_change ? (
              <Typography
                variant="body2"
                color={percentage_change > 0 ? 'success' : 'error'}
                marginLeft={2}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {(percentage_change * 100).toFixed(1)} %
                <span>{percentage_change > 0 ? <UpArrow /> : <DownArrow />}</span>
              </Typography>
            ) : (
              ''
            )}
          </Box>
          {variant !== 'headline' && <Typography>{kpi?.subheading}</Typography>}
          {color !== 'disabled' && kpi?.current_value && (
            <Typography
              variant="subtitle1"
              color={color === 'white' ? theme.palette.primary.dark : 'white'}
            >
              {Math.trunc(kpi?.current_value).toLocaleString()}
              {currentValueSuffix()}
            </Typography>
          )}
        </Box>
        {color === 'disabled' ? (
          <CashBar
            data={[
              { amount: 0, date: 'Nov 2021', display_date: 'This month' },
              { amount: 0, date: 'Nov 2021', display_date: 'Last month' }
            ]?.slice(0, 2)}
          />
        ) : (
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {variant === 'bar' ? (
              <CashBar data={kpi?.chart_data?.slice(0, 2)} color={color} />
            ) : variant === 'headline' ? (
              <Box marginTop={-10}>
                <Typography variant="h1">{kpi?.headline}</Typography>
                <Typography variant="body1">{kpi?.subheading}</Typography>
              </Box>
            ) : variant === 'horizontal' ? (
              <HorizontalChart data={kpi?.breakdown_chart} color={color} />
            ) : (
              <div>other variant</div>
            )}
          </div>
        )}

        <Button
          variant="whiteOutline"
          onClick={() => toggleDialog(kpi)}
          disabled={color === 'disabled'}
        >
          More Details
        </Button>
      </Box>
    </Paper>
  );
}
