import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const typography: TypographyOptions = {
  fontFamily: 'Poppins, sans-serif',
  h1: {
    fontSize: '1.875rem',
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: '-0.5px',
    lineHeight: 2
  },
  h2: {
    fontSize: '1.41rem',
    fontWeight: 600,
    lineHeight: 1.5
  },
  h3: {
    fontSize: '1.294rem',
    lineHeight: 1.5,
    fontWeight: 500,
    fontStretch: 'normal'
  },
  h4: {
    fontSize: '1.078rem',
    fontWeight: 500
  },
  button: {
    fontSize: 14,
    fontFamily: 'Montserrat, sans-serif'
  },
  subtitle1: {
    fontSize: '1.063rem',
    fontWeight: 600
  },
  subtitle2: {
    fontSize: '1.063rem',
    fontWeight: 500,
    lineHeight: 2.5
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5
  },
  labelText: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.5
  },
  sbTextL: {
    fontSize: '1.6rem',
    fontWeight: 400
  },
  body2: {
    fontWeight: 600,
    lineHeight: 1.5
  },

  formText: {
    fontSize: '1rem',
    fontWeight: 400
  },
  tableHeader: {
    fontSize: '1.125rem',
    fontWeight: 500
  }
};

export default typography;
