import { IconButton } from "@material-ui/core";
import ProgressBar from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState, useCallback } from "react";
import { ReactComponent as SuccessPIC } from "../assets/Success.svg";
import { useSnackbar, SnackbarContent } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  container: {
    backgroundColor: "#DAEAF6",
    display: "flex",
    alignItems: "stretch",
    alignContent: "center",
  },
  textWrapper: {
    color: "#FFF",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#0D8ABC",
    padding: theme.spacing(1),
    minWidth: "10vw",
    maxWidth: "16vw",
  },
  picBackground: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "#DAEAF6",
    width: "10vw",
    padding: theme.spacing(2, 1, 2, 1),
  },
  textContainer: {
    // height: "100%",
  },
  picProps: {
    width: "100%",
  },
  close: {
    position: "absolute",
    padding: theme.spacing(1),
    top: 0,
    right: 0,
  },
  progressBar: {
    backgroundClip: "#F00",
  },
  progressColorPrimary: {
    backgroundColor: "#0D8ABC",
  },
  progressBarPrimary: {
    backgroundColor: "#DAEAF6",
  },
}));

const useTimeCounter = () => {
  const [progress, setProgress] = useState(100);

  React.useEffect(() => {
    let internalCount = 100;
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress - 1);
      internalCount = internalCount - 1;
      if (internalCount < 1) clearInterval(timer);
    }, 40);
  }, []);

  return [progress];
};

interface SuccessContentProps {
  title?: string;
  message: string | React.ReactNode;
  id: string | number;
  picture?: React.ReactNode;
}
export default function SuccessContent({ title = "Success!", message, id, picture = SuccessPIC }: SuccessContentProps) {
  const classes = useStyles();
  const [progress] = useTimeCounter();
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.textWrapper}>
            <div className={classes.textContainer}>
              <Typography variant="h4">{title}</Typography>
              <br />
              <Typography>{message}</Typography>
            </div>
          </div>
          <SuccessPIC width="50%" height="80%" />
        </div>
        <Box className={classes.close}>
          <IconButton onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ProgressBar
          variant="determinate"
          className={classes.progressBar}
          value={progress}
          classes={{
            colorPrimary: classes.progressColorPrimary,
            barColorPrimary: classes.progressBarPrimary,
          }}
        />
      </div>
    </SnackbarContent>
  );
}
