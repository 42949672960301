import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  ButtonBase,
  Typography
} from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/ZoomOutMap';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Tippy from '@tippy.js/react';
import moment from 'moment';
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import Autocomplete from '../Autocomplete';

export const Portal = ({ children }: PropsWithChildren<unknown>) => {
  return createPortal(children, document.body);
};

interface RepeatPopoutProps {
  button_text: string;
  default_value_period: string;
  period: string;
  onEndDateChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
  default_value_endDate: string;
  endDate: string;
  colour: string;
  error?: boolean;
  hide_clear?: boolean;
  onPeriodChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | string[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => void;
}
export function RepeatPopout({
  button_text,
  default_value_period,
  period,
  default_value_endDate,
  endDate,
  onEndDateChange,
  onPeriodChange,
  colour = 'grey',
  error = false,
  hide_clear = false
}: RepeatPopoutProps) {
  const [result, setResult] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const capitalise = (str: string) => {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const calc_result = (val_period: string, val_date: string | Date) => {
    let res = '';
    if (val_period === 'never') {
      res = `${capitalise(val_period)}`;
    } else if (val_period === null) {
      res = 'Never';
    } else if (val_date === null || val_date === '') {
      res = `${capitalise(val_period)}`;
    } else {
      res = `${capitalise(val_period)} until ${moment(val_date).format('YYYY-MM-DD')}`;
    }
    return res;
  };

  const periodValue =
    period === '' && (default_value_period !== '' || default_value_endDate == null)
      ? default_value_period
      : 'never';
  const dateValue = useMemo(
    () =>
      endDate === '' && (default_value_endDate !== '' || default_value_endDate == null)
        ? default_value_endDate
        : new Date(),
    [endDate, default_value_endDate]
  );

  useEffect(() => {
    if (!loaded) {
      setResult(calc_result(periodValue, dateValue));
      setLoaded(true);
    }
    if (period === null && endDate === null) {
      setResult('Never');
    } else if (endDate === null || period === 'never') {
      setResult(`${capitalise(period)}`);
    } else {
      setResult(`${capitalise(period)} until ${moment(endDate).format('dd/mm/yyyy')}`);
    }
  }, [endDate, period, loaded, periodValue, calc_result, dateValue]);

  const Close = () => {
    return (
      <div onClick={() => setVisible(false)} style={{ cursor: 'pointer' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="float-right h-6 w-6 ml-4 text-abx-dark-blue hover:text-abx-pink"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    );
  };

  const repeatDisplay = calc_result(period, endDate);
  const displayEndDate = endDate.trim() ? endDate.trim() : new Date();

  const PopUp = () => {
    return (
      <div className="bg-white text-abx-dark-blue p-0  mt-0 mb-0 w-72 flex flex-col rounded-lg ">
        <div className="bg-white flex flex-col mr-4 mt-4">
          <div className="w-full">
            <Close />
          </div>
          <div className="mx-8 mb-8 flex flex-col text-center">
            <span className="font-semibold text-lg mb-8 mt-0">
              How often does this transaction repeat?
            </span>
            <Autocomplete
              id="rand"
              minWidth={100}
              options={['never', 'daily', 'weekly', 'monthly', 'quarterly', 'annually']}
              onChange={onPeriodChange}
              value={period}
              variant="outlined"
            />

            <KeyboardDatePicker
              autoOk
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              value={displayEndDate}
              InputAdornmentProps={{ position: 'end' }}
              onChange={onEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              inputVariant="standard"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Tippy
        content={<PopUp />}
        hideOnClick={true}
        interactive={true}
        visible={visible}
        animation="scale-extreme"
        duration={[300, 250]}
        inertia={true}
        trigger="click"
        arrow={false}
        theme="light"
        zIndex={1100}
      >
        <ButtonBase
          // ref={setReferenceElement}
          onClick={() => setVisible(!visible)}
          className={`${
            error
              ? 'btn-underline-error'
              : result === ''
              ? 'btn-underline'
              : 'btn-underline-entered'
          } flex flex-row align-middle`}
          sx={{
            width: '100%',
            paddingBottom: '4px !important',
            borderBottom: '1px solid #DAEAF6 !important'
          }}
        >
          <Typography align="left" width="100%">
            {repeatDisplay === '' ? button_text : repeatDisplay}
          </Typography>
          <ExpandIcon />
        </ButtonBase>
      </Tippy>
    </React.Fragment>
  );
}
