import { Box, Dialog } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { ReactElement } from 'react';
import { ABTab } from '../../../Components/ABTab';
import { ABTabList } from '../../../Components/ABTabList';
import { Kpi } from '../../../generated/graphql-frontend';
import Alerts from './Alerts/Alerts';
import Description from './Description';
import History from './History';

interface Props {
  open: boolean;
  toggleDialog: (kpi?: Kpi) => void;
  selectedKpi: Kpi;
}

export default function KPIDialog({ open, toggleDialog, selectedKpi }: Props): ReactElement {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Dialog open={open} onClose={toggleDialog} maxWidth="md" fullWidth>
      <Box height={800} p={8}>
        <TabContext value={value}>
          <Box px={10}>
            <ABTabList onChange={handleChange} aria-label="kpi-tabs" variant="fullWidth">
              <ABTab label="History" value="1" classes={{}} />
              <ABTab label="Description" value="2" />
              <ABTab label="Alerts" value="3" />
            </ABTabList>
          </Box>

          <TabPanel value="1">
            <History kpi={selectedKpi} />
          </TabPanel>
          <TabPanel value="2">
            <Description kpi={selectedKpi} />
          </TabPanel>
          <TabPanel value="3">
            <Alerts kpi={selectedKpi} />
          </TabPanel>
        </TabContext>
      </Box>
    </Dialog>
  );
}
