import { Box, Button } from '@material-ui/core';
import Tippy from '@tippy.js/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import BasicContainer from '../../../Containers/BasicContainer';
import Header from '../../../Containers/Header';
import '../../../tooltip.css';
import { useIntegrateStore } from '../hooks/IntegrateStore';
import { useUrlQuery } from '../hooks/useUrlQuery';
import SearchBox from './Components/SearchBox';
import SideDrawer from './Components/SideDrawer/SideDrawer';
import ToolArray from './Components/ToolArray/ToolArray';

export default function Integrate() {
  const [searchTerm, setSearchTerm] = useState('');
  const urlQuery = useUrlQuery();
  const { setOpenDrawer, setCurrentProvider } = useIntegrateStore();
  const history = useHistory();
  const DataFileIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 m-1"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );
  };
  React.useEffect(() => {
    const provider = urlQuery.get('provider')?.toString();
    const status = urlQuery.get('success')?.toString();
    if (provider) setCurrentProvider(provider);
    setOpenDrawer(status === 'true');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header
        title="Data Integrations"
        description="Add a data connection to enable product level analysis"
      />
      <BasicContainer>
        <Box mt={2} mb={3} display="flex" justifyContent="space-between"></Box>

        <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <div className="flex flex-col mb-8 mt-8 space-x-2">
          <div className="w-full">
            <Tippy
              content="You can add data using a comma separated (*.csv) or excel file."
              hideOnClick={true}
              interactive={false}
              animation="scale-extreme"
              duration={[300, 250]}
              inertia={true}
              arrow={false}
              className="tooltip"
              zIndex={9999}
            >
              <Button color="primary" variant="outlined" onClick={() => history.push('upload')}>
                <DataFileIcon />
                <span className="mr-2">Add a data File</span>
              </Button>
            </Tippy>
          </div>

          {/*<div className="text-abx-dark-blue w-auto">
            You can upload data using a comma separated
            (*.csv) or excel file, however be aware that we can't automatically
            monitor your business using these files.
        </div>*/}
        </div>
        <ToolArray searchTerm={searchTerm} />
        <SideDrawer />
      </BasicContainer>
    </div>
  );
}
