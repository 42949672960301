import React from 'react';
import BasicContainer from '../../../Containers/BasicContainer';
import Header from '../../../Containers/Header';
import TransactionTable from './Components/TransactionTable/TransactionTable';

export default function TransactionInfo() {
  return (
    <div>
      <Header
        title="Transaction Information"
        description="Name, categorise & tag your transactions, this makes the information we give you more accurate."
      />
      <BasicContainer>
        <TransactionTable />
      </BasicContainer>
    </div>
  );
}
