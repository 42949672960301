import { Box } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import React, { ReactElement } from "react";
import ABStepper from "../../../../Components/ABStepper";
import { ABTab } from "../../../../Components/ABTab";
import { ABTabList } from "../../../../Components/ABTabList";
import { useScenarioStore } from "../../hooks/ScenarioContext";
import Custom from "./Custom/Custom";
import Employees from "./Employees";
import Finance from "./Finance/Finance";
import Product from "./Product";
import Property from "./Property/Property";
import Tax from "./Tax";

export default function Cashflow(): ReactElement {
  const [value, setValue] = React.useState("1");
  const { activeStep, increaseStep, decreaseStep } = useScenarioStore();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box height={600} p={8}>
        <TabContext value={value}>
          <Box px={10} display="flex" justifyContent="center">
            <ABTabList
              onChange={handleChange}
              aria-label="kpi-tabs"
              variant="scrollable"
              indicatorColor="secondary"
              scrollButtons="auto"
            >
              <ABTab label="Property & Utilities" value="1" />
              <ABTab label="Product/Service" value="2" />
              <ABTab label="Employee & Staff" value="3" />
              <ABTab label="Finance" value="4" />
              <ABTab label="Tax" value="5" />
              <ABTab label="Custom & Others" value="6" />
            </ABTabList>
          </Box>

          <TabPanel value="1">
            <Property />
          </TabPanel>
          <TabPanel value="2">
            <Product />
          </TabPanel>
          <TabPanel value="3">
            <Employees />
          </TabPanel>
          <TabPanel value="4">
            <Finance />
          </TabPanel>
          <TabPanel value="5">
            <Tax />
          </TabPanel>
          <TabPanel value="6">
            <Custom />
          </TabPanel>
        </TabContext>

        <ABStepper activeValue={activeStep} increaseStep={increaseStep} decreasestep={decreaseStep} />
        <Box height={65} />
      </Box>
    </div>
  );
}
