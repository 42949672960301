const initialState = {
    open: false,
    title: '',
    message: '',
    image: null,
    show_close: true,
    timeout: 8000,
    first_button: null,
    second_button: null,
  };
  
  export default function notification(state = initialState, action) {
    switch (action.type) {
      case "TOGGLE_ABX_NOTIFICATION_OPEN": {
        return {
          ...state,
          open: true,
          title: action.title,
          message: action.message,
          image: action.image,
          show_close: action.show_close,
          timeout: action.timeout,
          first_button: action.first_button,
          second_button: action.second_button
        };
      }
  
      case "TOGGLE_ABX_NOTIFICATION_CLOSE": {
        return {
          ...state,
          open: false,
        };
      }
  
      default: {
        return state;
      }
    }
  }

  const initialCallbackState = {
    caller: '',
  };
  
  export function callback(state = initialCallbackState, action) {
    switch (action.type) {
      case "NOTIFICATION_CALLBACK": {
        return {
          ...state,
          caller: action.caller,
        };
      }

      case "CLEAR_NOTIFICATION_CALLBACK": {
        return {
          ...state,
          caller: '',
        };
      }
  
  
      default: {
        return state;
      }
    }
  }
  