import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import React from 'react';
import AlertMenu from './AlertMenu';
import moment from 'moment';
import { Alert } from '../../../../generated/graphql-frontend';

interface DescriptionItemProps {
  alert: Alert;
}
const DescriptionItem = ({ alert }: DescriptionItemProps) => {
  return (
    <Box mb={2}>
      <Grid container alignItems="center" alignContent="center">
        <Grid item xs={12} sm={8} md={7}>
          <Typography color="text.secondary">{alert.description}</Typography>
        </Grid>

        <Grid item xs={12} sm={2} md={3}>
          <Typography color="text.secondary">
            {moment(alert.created).format('YYYY-MM-DD hh:mm:ss')}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item sm={false}>
            <AlertMenu alert={alert} />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};

export default DescriptionItem;
