import { PaletteOptions } from '@material-ui/core';

const mainColor = '#0D8ABC';

const palette: PaletteOptions = {
  primary: {
    dark: '#0E5A7B',
    main: mainColor,
    light: '#DAEAF6'
  },
  secondary: {
    main: '#FF008E'
  },

  background: {
    default: '#F4F4F6',
    paper: '#FFF'
  },
  error: {
    dark: '#FF7171',
    main: '#EE0F0F'
  },
  text: {
    primary: '#0E5A7B',
    secondary: '#6C6B6B'
  },
  success: {
    main: '#04E3AC'
  },
  disabled: {
    light: 'rgba(233, 233, 236, 0.95)',
    dark: 'rgba(134, 131, 131, 0.95)',
    main: 'rgba(134, 131, 131, 0.95)'
  }
};

export default palette;
