import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { kFormatter } from "../../utils/kFormatter";
import ScenariosDialog from "./ScenariosDialog/ScenarioDialog";

const data = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const useStyle = makeStyles((theme) => ({
  chartContainer: {
    borderRadius: theme.spacing(3),
    padding: theme.spacing(5),
  },
}));

const renderLegend = (props) => {
  const { payload } = props;
  const containerStyle = {
    marginTop: 10,
  };

  return (
    <Grid container style={containerStyle}>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <Box display="inline" flexGrow={2}>
          {payload.map((entry, index) => (
            <Box display="inline-block" key={`entry-item${index}`}>
              <Typography variant="subtitle1">
                {entry.value}
                <span
                  style={{
                    width: "80px",
                    height: "3px",
                    backgroundColor: entry.color,
                    paddingLeft: "36px",
                    display: "inline-block",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                >
                  <span
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: entry.color,
                      display: "block",
                      borderRadius: "4px",
                      marginTop: "-2px",
                    }}
                  ></span>
                </span>
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>
  );
};
export default function CashInBankChart() {
  const classes = useStyle();
  const [openDetail, setOpenDetail] = useState(false);

  const toggleOpenDetail = () => {
    setOpenDetail((prev) => !prev);
  };

  return (
    <Paper elevation={0} className={classes.chartContainer}>
      <Box marginBottom={2}>
        <Typography variant="subtitle1" color="primary">
          Cash In the Bank
        </Typography>
      </Box>
      <ResponsiveContainer height={500}>
        <LineChart data={data}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name" />
          <YAxis axisLine={false} tickFormatter={(tick) => kFormatter(tick)} />
          <Tooltip />
          <Legend content={renderLegend} />
          <Line dataKey="pv" stroke="#FF008E" activeDot={{ r: 8 }} />
          <Line dataKey="uv" stroke="#56CCF2" />
        </LineChart>
      </ResponsiveContainer>
      <Button variant="outlined" color="primary" onClick={() => toggleOpenDetail()}>
        {" "}
        More Detail
      </Button>
      <ScenariosDialog open={openDetail} toggleDialog={toggleOpenDetail} />
    </Paper>
  );
}
