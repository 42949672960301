import { MemoryStorage } from '@aws-amplify/core';
import { CssBaseline } from '@material-ui/core';
import Amplify from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import ApolloWrapper from './Context/ApolloWrapper';
import { CompanyProvider, UIProvider } from './Context/GlobalStore';
import AuthProvider from './Context/Login';
import './index.css';
import configureStore, { history } from './redux/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import FlagProvider from '@unleash/proxy-client-react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

/*
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] = authConfig.oauth.redirectSignIn.split(',');

const [localRedirectSignOut, productionRedirectSignOut] =
  authConfig.oauth.redirectSignOut.split(',');
*/

const oauthConfig = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USERPOOL,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS',
  storage: MemoryStorage
};

/*const oauthConfig = {
  ...authConfig,
  aws_user_pools_id: 'eu-west-1_QSyjSis5H',
  aws_user_pools_web_client_id: '3ibkiuisuulaage17sjv89s63f',
  oauth: {
    ...authConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut
  },
  storage: MemoryStorage
};*/

Amplify.configure(oauthConfig);

const store = configureStore();
if (process.env.NODE_ENV !== 'development') console.log = () => {};

const flagConfig: any = {
  url: 'https://unleash-proxy-qm2vh.ondigitalocean.app/proxy',
  clientKey: 'e23ab6baff92278add932410f26883b34b0e142f0a6a1808bb10e7eda9d81fb2',
  refreshInterval: 15,
  appName: 'alchemy-flag',
  environment: 'dev'
};

ReactDOM.render(
  // <StylesProvider injectFirst>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AuthProvider>
        <CompanyProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CssBaseline />
            <ApolloWrapper>
              <UIProvider>
                <FlagProvider config={flagConfig}>
                  <App />
                </FlagProvider>
              </UIProvider>
            </ApolloWrapper>
          </MuiPickersUtilsProvider>
        </CompanyProvider>
      </AuthProvider>
    </ConnectedRouter>
  </Provider>,
  // </StylesProvider>,
  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
