import React from 'react';
import Sidebar from '../../../../../Components/Sidebar';
import { ReactComponent as PreferenceBG } from '../../../../../Components/assets/PreferenceBG.svg';
import CompanyDetail from './CompanyDetail';
import { Box, Grid, Hidden, useTheme } from '@material-ui/core';

export default function PrefSecond() {
  const theme = useTheme();
  return (
    <Box bgcolor={theme.palette.background.paper} minHeight="100vh">
      <Grid container direction="row">
        <Grid item sm={4} md={4} lg={3}>
          <Hidden smDown>
            <Sidebar
              logoTitle={'Get Started'}
              logoSubtitle={'Create a company'}
              image={<PreferenceBG width="100%" />}
            />
          </Hidden>
        </Grid>
        <Grid item sm={8} md={8} lg={9} xl={8}>
          <CompanyDetail />
        </Grid>
      </Grid>
    </Box>
  );
}
