import { BaseTagData, ChangeEventData, TagData, TagifySettings } from '@yaireo/tagify';
import Tags from '@yaireo/tagify/dist/react.tagify'; // React-wrapper file
import '@yaireo/tagify/dist/tagify.css'; // Tagify CSS
import React from 'react';
import './tag.css'; // Custom CSS

// ================= TAGFIELD ==================

// Tagify settings object
const baseTagifySettings: TagifySettings<TagData> = {
  blacklist: [],
  maxTags: 10,
  backspace: 'edit',
  placeholder: 'Add Tags',
  editTags: 1,
  dropdown: {
    enabled: 0
  },
  callbacks: {}
};

interface TagFieldProps {
  initialValues?: string[];
  suggestedValues?: string[];
  value?: string[];
  loading?: boolean;
  onChange: (tags: string[]) => void;
}

const TagField = ({ initialValues, value, loading, suggestedValues, onChange }: TagFieldProps) => {
  const [tagLists, setTagLists] = React.useState<string[]>(initialValues ?? []);
  const settings: TagifySettings<TagData> = {
    ...baseTagifySettings,
    whitelist: suggestedValues,
    dropdown: {
      maxItems: 20,
      classname: 'tagStyle',
      enabled: 0,
      closeOnSelect: false
    }
  };

  const handleChange = (event: CustomEvent<ChangeEventData<BaseTagData>>) => {
    console.log('handleChange');
    const tagArray = event.detail.tagify.value.map((tag) => tag.value);
    console.log('tag array', tagArray);
    setTagLists(tagArray);
  };

  React.useEffect(() => {
    if (tagLists) {
      onChange(tagLists);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagLists]);

  return (
    <div className="bg-gray-50 bg-opacity-40 hover:bg-gray-100 rounded-md p-1 w-full">
      <Tags
        settings={settings as TagifySettings<TagData>}
        value={tagLists}
        loading={loading}
        onChange={handleChange}
        className="tagStyle"
      />
    </div>
  );
};
export default React.memo(TagField);
