import { Popover } from '@headlessui/react';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow.js';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
const Context = createContext();

export const Portal = ({ children }) => {
  return createPortal(children, document.body);
};

export function DropdownButton({
  children,
  button_text,
  default_value = '',
  colour = 'grey',
  error = false,
  hide_clear = false,
  callback
}) {
  const [selectedTitle, setSelectedTitle] = useState('');
  const [loaded, setLoaded] = useState(false);
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();

  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom', // "bottom"
    modifiers: [preventOverflow],
    strategy: 'absolute' // "absolute",
  });

  useEffect(() => {
    if (!loaded) {
      React.Children.forEach(children, ({ props }) => {
        if (props.title.toLowerCase() === default_value.toLowerCase()) {
          setSelectedTitle(props.title);
        }
      });
    }

    setLoaded(true);

    if (selectedTitle === '') {
      callback(null);
    } else {
      callback(selectedTitle);
    }
  }, []);
  const Close = () => {
    return (
      <a href="#" className="" onClick={() => setSelectedTitle('')}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 ml-4 text-abx-dark-blue hover:text-abx-pink"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </a>
    );
  };

  const DownArrow = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    );
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className={`${
              error
                ? 'btn-underline-error'
                : selectedTitle === ''
                ? 'btn-underline'
                : 'btn-underline-entered'
            } flex flex-row align-middle`}
          >
            {selectedTitle === '' ? button_text : selectedTitle}
            <div className="transform -translate-y-1 ml-4">
              {hide_clear ? <DownArrow /> : selectedTitle === '' ? <DownArrow /> : <Close />}
            </div>
          </Popover.Button>

          {open && (
            <Portal>
              <div
                ref={setPopperElement}
                style={{ ...styles.popper, zIndex: 1500 }}
                className="bg-white absolute p-0 z-50 mt-4 mb-0 w-96 flex flex-col"
                {...attributes.popper}
              >
                <Popover.Panel static>
                  <Context.Provider
                    value={{
                      title: selectedTitle,
                      colour: colour,
                      default_value: default_value,
                      changeTitle: (x) => setSelectedTitle(x)
                    }}
                  >
                    {children}
                  </Context.Provider>
                </Popover.Panel>
              </div>
            </Portal>
          )}
        </>
      )}
    </Popover>
  );
}

export function DropdownButtonItem({ children, title }) {
  const titleData = useContext(Context);

  if (titleData === undefined) {
    throw new Error('You must specify title data to the dropdown item provider');
  }

  const handle_click = (value) => {
    titleData.changeTitle(title);
  };

  const Standard = `flex flex-col border-0 ${
    titleData.colour === 'white' ? 'bg-white' : 'bg-abx-light-grey'
  } hover:bg-abx-light-blue w-full px-8 py-4 mb-1 z-30`;
  const Selected = 'flex flex-col bg-abx-light-blue w-full px-8 py-4 mb-1 z-30';

  return (
    <div
      className={titleData.title === title ? Selected : Standard}
      onClick={() => handle_click(title)}
    >
      <button className="flex flex-col w-full text-left">
        <b>{title}</b>
        <span>{children}</span>
      </button>
    </div>
  );
}
