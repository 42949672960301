import React, { useCallback } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import { useHistory, useParams } from 'react-router-dom';
import ABLoading from '../../../Components/Loading/ABLoading';
import { LongButton } from '../../../Components/LongButton';
import { ABxNotificationOpen } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Sent from '../../../Components/assets/message_sent.svg';

import {
  useGetAccountCardQuery,
  useGetAccountQuery,
  useIsFirstUserQuery,
  useLastAccessedCompanyMutation
} from '../../../generated/graphql-frontend';

interface PlaidConnectProps {
  linkToken: string;
  loading?: boolean;
  onboarding?: boolean;
}

const PlaidConnect = ({ linkToken, loading, onboarding = false }: PlaidConnectProps) => {
  const { company_id } = useParams<CompanyIdProps>();
  const { refetch } = useIsFirstUserQuery({ variables: { company_id } });
  const { refetch: cardQueryRefetch } = useGetAccountCardQuery({
    variables: { company_id }
  });
  const { refetch: accountRefetch } = useGetAccountQuery({
    variables: { company_id }
  });

  const [updateLastAccess] = useLastAccessedCompanyMutation({
    variables: { companyId: company_id }
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const onSuccess = useCallback(async (token, metadata) => {
    try {
      await updateLastAccess().then((res) => {
        if (res.data?.lastAccessedCompany?.status) {
          refetch();
          // history.replace('integrate');
        }
      });
      dispatch(
        ABxNotificationOpen({
          title: 'Processing you account data',
          message:
            'I have am attempting to connect your bank account to ABx, I will let you know when this is done',
          image: Sent,
          show_close: true,
          timeout: 3000
        })
      );
      history.push(`/data/${company_id}`);

      //await fetch(request);
      await refetch();
      await cardQueryRefetch();
      await accountRefetch();
      if (onboarding) {
        dispatch(
          ABxNotificationOpen({
            title: 'Bank Account Connected',
            message:
              "I have connected your business bank account! To add another account click 'Add more' otherwise check 'Connect apps' to connect an app",
            image: Sent,
            show_close: true,
            timeout: 300000,
            second_button: { text: 'Add more', style: 'outline', link: 'back' },
            first_button: { text: 'Connect apps', style: 'main', link: 'integrate' }
          })
        );
      } else {
        dispatch(
          ABxNotificationOpen({
            title: 'Bank Account Connected',
            message: 'I have connected your business bank account',
            image: Sent,
            show_close: false,
            timeout: 300000,
            second_button: { text: 'Add more', style: 'outline', link: 'back' },
            first_button: { text: 'Dismiss', style: 'main', link: '' }
          })
        );
      }
    } catch (e) {
      dispatch(
        ABxNotificationOpen({
          title: 'Could not connect Bank Account',
          message:
            'Sorry I could not add the bank account for you, I have contacted the ABx team on your behalf. Please try again later.',
          image: Sent
        })
      );
      console.log(e);
    }
    try {
      let post_data = { public_token: token, metadata: metadata };
      const url = `${process.env.REACT_APP_BASE_URL}/external/plaid/callback?company_id=${company_id}`;
      //const url = `http://localhost:4000/dev_local/plaid/callback?company_id=${company_id}`;
      const header = new Headers();
      const request = new Request(url, {
        method: 'POST',
        body: JSON.stringify(post_data),
        headers: header
      });
      const response = await fetch(request);
      response
        .json()
        .then((res) => {
          if (res.status === 200) {
            history.replace('integrate');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const config: PlaidLinkOptions = {
    clientName: '',
    // env: "",
    // product: [""],
    // publicKey: "",
    token: linkToken,
    onSuccess
  };

  const { open, ready } = usePlaidLink(config);
  const handleOpen = () => {
    open();
  };
  return (
    <LongButton
      variant="contained"
      color="primary"
      onClick={() => handleOpen()}
      disabled={!ready || loading}
    >
      Connect
      {(!ready || loading) && <ABLoading />}
    </LongButton>
  );
};
export default PlaidConnect;
