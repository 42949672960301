import { Box, Button, useTheme } from '@material-ui/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { ReactElement } from 'react';
import ABTypography from '../ABTypography';

interface Props {
  type?: Maybe<string>;
  accountNum?: Maybe<number> | undefined;
  connectStatus?: Maybe<string> | undefined;
  cardColor?: Maybe<string> | undefined;
  inactive?: Maybe<boolean> | undefined;
  mainText?: Maybe<string> | undefined;
  subText?: Maybe<string> | undefined;
  buttonText?: Maybe<string> | undefined;
  handleClick?: () => void;
}

export default function ConnectionCard({
  type,
  accountNum,
  connectStatus,
  inactive,
  mainText,
  subText,
  buttonText,
  handleClick,
  cardColor
}: Props): ReactElement {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={theme.palette.background.paper}
      maxWidth="400px"
      padding={5}
      borderRadius={4}
    >
      <Box display="flex" alignContent="center" alignItems="center">
        <div style={{ width: 10, height: 10, backgroundColor: `#${String(cardColor)}` }}></div>
        <ABTypography ml={1}>{type}</ABTypography>
      </Box>
      <Box my={6}>
        <ABTypography variant="h2">{mainText}</ABTypography>
        <ABTypography>{subText}</ABTypography>
      </Box>
      {inactive ? (
        <Button variant="contained" color="primary" onClick={handleClick} disabled>
          {buttonText}
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={handleClick}>
          {buttonText}
        </Button>
      )}
    </Box>
  );
}
