import { Box } from '@material-ui/core';
import React from 'react';
import { google } from '../../../../../Consts/Config';
import Button from '@material-ui/core/Button';
import useDrivePicker from 'react-google-drive-picker';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as GoogleIcon } from '../../../../../Components/assets/google-drive-1.svg';
import { useIntegrateStore } from '../../../hooks/IntegrateStore';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    '&:hover': {
      opacity: 0.5,
      cursor: 'pointer'
    },

    padding: '30px 80px ',
    border: '1px #000 dashed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));
const GoogleDrive = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { setCurrentFileName, setCurrentFileId } = useIntegrateStore();
  const [openPicker, data] = useDrivePicker();

  const handleOpenPicker = () => {
    try {
      openPicker({
        clientId: google.CLIENT_ID || '',
        developerKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
        viewId: 'DOCS',
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: true

        // viewMimeTypes: [
        //   'application/vnd.google-apps.folder',
        //   'application/vnd.ms-excel',
        //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        //   'text/csv'
        // ]
      });
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (data) {
      const fileName = data.docs[0].name;
      const fileId = data.docs[0].id;
      setCurrentFileName(fileName);
      setCurrentFileId(fileId);
      console.log('data ==>', data);
      console.log('data ==>', data.docs);
      console.log('data ==>', data.action);
    }
  }, [data, setCurrentFileId, setCurrentFileName]);

  return (
    <Box className={classes.root} onClick={() => handleOpenPicker()}>
      <GoogleIcon width="150px" style={{ marginBottom: '15px' }} />
      <Button variant="contained">Open Picker</Button>
    </Box>
  );
});
export default GoogleDrive;
