import { useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorImg from '../Components/assets/Error.png';
import { ReactComponent as PreferenceBG } from '../Components/assets/PreferenceBG.svg';
import SuccessImg from '../Components/assets/Success.png';
import SideBar from '../Components/Sidebar';
import { RESOLVE_COMPANY_SHARE } from '../Gqls/gql';
import { ABxNotificationClose, ABxNotificationOpen, ClearCallback } from '../redux/actions';

const useStyles = makeStyles((theme) => ({
  sideBarContainer: {
    backgroundColor: theme.palette.primary.light,
    minWidth: 150,
    maxWidth: 400
  },
  paper: {
    /*margin: theme.spacing(0, 15),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 5),
    },*/
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    justifyItems: 'center',
    backgroundColor: '#fff'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },

  formTitle: {
    textAlign: 'left',
    margin: theme.spacing(6, 0, 3, 0)
  },
  title: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(20)
  },
  dividerMargin: {
    marginTop: '10px',
    marginBottom: '15px'
  },
  btnContainer: {
    padding: theme.spacing(7, 0),
    marginTop: 'auto'
  },
  expiredContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export default function ShareCompany() {
  const classes = useStyles();
  const params = new URL(document.location).searchParams;
  const tokenParameter = params.get('tkn');
  const token =
    tokenParameter.charAt(tokenParameter.length - 1) === '?'
      ? tokenParameter.slice(0, -1)
      : tokenParameter;
  const history = useHistory();
  const dispatch = useDispatch();
  const reject_callback = useSelector((state) => {
    if (state.callback.caller === 'yes') {
      dispatch(ClearCallback());
      dispatch(ABxNotificationClose());
      handleReject();
    } else if (state.callback.caller === 'no') {
      dispatch(ClearCallback());
      dispatch(ABxNotificationClose());
    } else {
    }
  });
  const [resolveCompanyShare] = useMutation(RESOLVE_COMPANY_SHARE);

  const decodedToken = jwtDecode(token);
  console.log('Decoded token', decodedToken);
  if (decodedToken) {
    localStorage.removeItem('share_company');
    // return <NoMatch />;
  }

  const onExitNotification = () => {
    localStorage.removeItem('share_company');
    history.push('/');
  };

  const handleAccept = () => {
    resolveCompanyShare({
      variables: { input: { tkn: token, accepted: true } }
    })
      .then(({ data }) => {
        // enqueueSnackbar('This is a success message!', { variant });
        if (data.resolveCompanyShare.status === true) {
          //setSuccessOpen(true);
          dispatch(
            ABxNotificationOpen({
              title: 'Invitation accepted!',
              message: `I have added ${data.resolveCompanyShare.company_name} to your list of companies.`,
              image: SuccessImg
            })
          );
          onConfirm();
        } else {
          const message = data.resolveCompanyShare.error.message;
          dispatch(ABxNotificationOpen({ title: 'Error!', message: message, image: ErrorImg }));
          onConfirm();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleContinue = () => {
    dispatch(
      ABxNotificationOpen({
        title: 'Link Expired',
        message:
          'This link has has expired, please ask the company administrator to resend the application',
        image: ErrorImg
      })
    );
    localStorage.removeItem('share_company');
    history.push('/');
  };

  const confirmReject = () => {
    dispatch(
      ABxNotificationOpen({
        title: 'Are you sure?',
        message: `Are you sure that you do not want to add this company to your list of companies?`,
        image: SuccessImg,
        show_close: false,
        timeout: 600000,
        first_button: {
          text: 'Yes',
          style: 'main',
          link: '__callback__',
          id: 'yes'
        },
        second_button: {
          text: 'No',
          style: 'outline',
          link: '__callback__',
          id: 'no'
        }
      })
    );
  };

  const handleReject = () => {
    resolveCompanyShare({
      variables: { input: { tkn: token, accepted: false } }
    })
      .then(({ data }) => {})
      .catch((res) => {});
    onConfirm();
  };

  const onConfirm = () => {
    localStorage.removeItem('share_company');
    history.push('/');
    dispatch(
      ABxNotificationOpen({
        title: 'Invite rejected',
        message: 'The invite was rejected',
        image: ErrorImg
      })
    );
  };

  const admin_name = decodedToken.from_first_name + ' ' + decodedToken.from_last_name;
  const company_name = decodedToken.company_name;
  const expired_timestamp = decodedToken.exp;
  const current_timestamp = new Date().getTime() / 1000;
  const hasExpired = expired_timestamp < current_timestamp;

  return (
    <Grid container className={classes.root} direction="row">
      {/* SideBar container */}
      <Grid item xs={false} sm={5} md={4} lg={3} className={classes.sideBarContainer}>
        <SideBar
          logoTitle="Get Started"
          logoSubtitle="Join a company"
          image={<PreferenceBG width="100%" />}
        />
      </Grid>
      <Grid item xs={12} sm={7} md={8} lg={9} xl={8}>
        <div className={classes.paper}>
          <Grid item xs={12} md={12} lg={10} xl={10}>
            <div className={classes.title}>
              <center>
                {!hasExpired ? (
                  <div>
                    <Typography color="primary.dark" variant="h1" paragraph>
                      {admin_name} has invited you to join {company_name}
                    </Typography>
                    <Typography variant="body" paragraph>
                      By clicking accept {company_name} will be added to your list of companies
                    </Typography>
                  </div>
                ) : (
                  <div className={classes.expiredContent}>
                    <Typography variant="h2">This link has expired.</Typography>
                    <Button
                      onClick={handleContinue}
                      variant="contained"
                      color="primary"
                      style={{ marginTop: '100px' }}
                    >
                      Continue
                    </Button>
                  </div>
                )}
              </center>
            </div>

            {!hasExpired && (
              <Grid container spacing={8} justifyContent="center">
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={confirmReject}>
                    Reject
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={handleAccept}>
                    Accept
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
      {/*<SuccessConfirm open={successOpen} setOpen={setSuccessOpen} companyName={company_name} onConfirm={onConfirm} />*/}
    </Grid>
  );
}
