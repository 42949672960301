import { Theme } from '@material-ui/core';
import React, { createContext, useContext, useReducer, useState } from 'react';
import { localNames } from '../helpers/data';
import { light } from '../Theme/theme';

type JustCreate = {
  id: string;
  status: boolean;
};

type ProfileType = {
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  timezone: string;
  currency: string;
  profile_colour: string;
};

type GlobalContextStatus = {
  justCreated: JustCreate;
  currentCompanyId: string | null;
  bearerToken: string | null;
  plaidToken: string | null;
  companyName: string;
  profile: ProfileType | null;
  setJustCreated: (id: string, status: boolean) => void;
  setCurrentCompanyId: (id: string | null) => void;
  setCompanyName: (name: string) => void;
  setProfile: (profile: ProfileType) => void;
  setBearerToken: (token: string) => void;
};

type UIContextStatus = {
  theme: Theme;
  changeTheme: (theme: Theme) => void;
};

const localToken = localStorage.getItem(localNames.token);

const initialState: GlobalContextStatus = {
  justCreated: { id: '', status: false },
  currentCompanyId: null,
  bearerToken: localToken,
  plaidToken: '',
  companyName: '',
  profile: null,
  setJustCreated: () => {},
  setCurrentCompanyId: () => {},
  setCompanyName: () => {},
  setProfile: () => {},
  setBearerToken: () => {}
};
const initialUIStatus: UIContextStatus = {
  theme: light,
  changeTheme: () => {}
};

const CompanyContext = createContext(initialState);
const UIContext = createContext(initialUIStatus);
type Actions =
  | { type: 'setCurrentCompanyId'; id: string | null }
  | { type: 'setCompanyName'; name: string }
  | { type: 'setToken'; token: string }
  | { type: 'setProfile'; profile: ProfileType | null };

type State = {
  currentCompanyId: string | null;
  bearerToken: string | null;
  plaidToken: string | null;
  companyName: string;
  profile: ProfileType | null;
};

function GlobalReducer(state: State, action: Actions) {
  switch (action.type) {
    case 'setCurrentCompanyId':
      if (action.id !== null) {
        localStorage.setItem('currentCompanyId', action.id);
      }
      return { ...state, currentCompanyId: action.id };
    case 'setCompanyName':
      if (action.name !== null) {
        localStorage.setItem('companyName', action.name);
      }
      return { ...state, companyName: action.name };
    case 'setProfile':
      if (action.profile !== null) {
        localStorage.setItem('profile', JSON.stringify(action.profile));
      }
      return { ...state, profile: action.profile };
    case 'setToken':
      return { ...state, bearerToken: action.token };
    default:
      return state;
  }
}

if (localStorage.getItem('currentCompanyId')) {
  const currentId = localStorage.getItem('currentCompanyId');
  const companyName = localStorage.getItem('companyName');
  const plaidToken = localStorage.getItem('plaidToken');
  const profile_raw = localStorage.getItem('profile');
  const profile =
    profile_raw === null
      ? {
          user_id: '',
          first_name: '',
          last_name: '',
          email: '',
          timezone: '',
          currency: '',
          profile_colour: ''
        }
      : JSON.parse(profile_raw);
  initialState.currentCompanyId = currentId;
  initialState.companyName = companyName === null ? '' : companyName;
  initialState.plaidToken = plaidToken;
  initialState.profile = profile;
}

// Company Provider
export const CompanyProvider: React.FC = ({ children }) => {
  const [justCreated, setJustCreated] = useState({
    id: '',
    status: false
  });

  const justCreatedHandle = (id: string, status: boolean) => {
    setJustCreated({ id: id, status: status });
  };

  const [globalState, dispatch] = useReducer(GlobalReducer, initialState);
  const setCompanyId = (id: string | null) => {
    dispatch({ type: 'setCurrentCompanyId', id: id });
  };

  const setCompanyName = (name: string) => {
    dispatch({ type: 'setCompanyName', name: name });
  };

  const setProfile = (profile: ProfileType | null) => {
    dispatch({ type: 'setProfile', profile: profile });
  };

  const setToken = (token: string) => {
    dispatch({ type: 'setToken', token: token });
  };

  return (
    <CompanyContext.Provider
      value={{
        justCreated: justCreated,
        currentCompanyId: globalState.currentCompanyId,
        companyName: globalState.companyName,
        bearerToken: globalState.bearerToken,
        plaidToken: globalState.plaidToken,
        profile: globalState.profile,
        setJustCreated: justCreatedHandle,
        setCurrentCompanyId: setCompanyId,
        setCompanyName: setCompanyName,
        setBearerToken: setToken,
        setProfile: setProfile
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

// UI provider
export const UIProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(light);
  const changeTheme = (theme: Theme) => {
    setTheme(theme);
  };
  return <UIContext.Provider value={{ theme, changeTheme }}>{children}</UIContext.Provider>;
};

export const useCompanyStore = () => useContext(CompanyContext);
export const useUIStore = () => useContext(UIContext);
