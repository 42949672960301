const cookiePrefix = "boxalchemy___";

export const cookieNames = {
  cookieAuth: `${cookiePrefix}cookie-auth`,
  isConnected: `${cookiePrefix}isConnected`, //cookie value ->"true"
  transactionInfo: `${cookiePrefix}transactionInfo`, //cookie value ->"true"
};

const localStoragePrefix = "boxalchemy___";
export const localNames = {
  token: `${localStoragePrefix}token`,
  transactionInfo: `${localStoragePrefix}transactionInfo`,
  isTransactionInfoChanged: `${localStoragePrefix}transactionInfoChanged`,
};
