import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { UploadContext } from '../UploadContext';
import { UploadStep } from '../UploadStatement/Components/Const';

interface BackButtonProps {
  setStep?: () => void;
}

export default function BackButton({ setStep }: BackButtonProps) {
  const history = useHistory();
  const { state, dispatch } = React.useContext(UploadContext);

  const handleBack = () => {
    if (state.currentStep - 1 >= 0) {
      dispatch({ type: 'setCurrentStep', payload: state.currentStep - 1 });
    } else {
      history.goBack();
    }
  };
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => handleBack()}
      disabled={state.currentStep === UploadStep.LOAD}
    >
      Back
    </Button>
  );
}
