import { Box, Grid, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import DescriptionTableItem from './DescriptionTableItem';

interface AlertType {
  id?: string;
  kpi_name?: string;
  description?: string;
  created?: string;
  last_triggered?: string;
  target_hit?: boolean;
}
export default function DescriptionTable({ alertList }: { alertList: AlertType[] }): ReactElement {
  return (
    <Box mt={4}>
      <Grid container alignItems="center" alignContent="center">
        <Grid item xs={12} sm={8} md={7}>
          <Typography variant="tableHeader" color="text.secondary">
            Descripton
          </Typography>
        </Grid>

        <Grid item xs={12} sm={2} md={3}>
          <Typography variant="tableHeader" color="text.secondary">
            Date
          </Typography>
        </Grid>
      </Grid>
      {alertList.map((alert) => (
        <DescriptionTableItem alert={alert} />
      ))}
    </Box>
  );
}
