import { createCtx } from '../../Context/ContextGet';
import { DataFileData, DataFileInput } from '../../generated/graphql-frontend';
import { UploadStep } from './UploadStatement/Components/Const';

interface UploadDefault {
  dataFileInput: DataFileInput;
  currentStep: number;
  mapping: Record<string, string>;
  setDataFileInput: () => void;
  setFileName: () => void;
  setDescription: () => void;
  setData: () => void;
  setCurrency: () => void;
  setMapping: () => void;
}
const initialState: UploadDefault = {
  currentStep: UploadStep.LOAD,
  mapping: {},
  dataFileInput: {
    company_id: '',
    data: [],
    description: '',
    name: '',
    currency: ''
  },
  setDataFileInput: () => {},
  setFileName: () => {},
  setDescription: () => {},
  setData: () => {},
  setCurrency: () => {},
  setMapping: () => {}
};
type AppState = typeof initialState;

type Action =
  | { type: 'setCurrentStep'; payload: number }
  | { type: 'setDataFileInput'; payload: DataFileInput }
  | { type: 'setFileName'; payload: string }
  | { type: 'setDescription'; payload: string }
  | { type: 'setData'; payload: DataFileData[] }
  | { type: 'setMapping'; payload: Record<string, string> }
  | { type: 'setCurrency'; payload: string };

function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case 'setCurrentStep':
      return {
        ...state,
        currentStep: action.payload
      };
    case 'setDataFileInput':
      return {
        ...state,
        dataFileInput: action.payload
      };
    case 'setFileName':
      return {
        ...state,
        dataFileInput: { ...state.dataFileInput, name: action.payload }
      };
    case 'setDescription':
      return {
        ...state,
        dataFileInput: { ...state.dataFileInput, description: action.payload }
      };
    case 'setCurrency':
      return {
        ...state,
        dataFileInput: { ...state.dataFileInput, currency: action.payload }
      };

    case 'setData':
      return {
        ...state,
        dataFileInput: { ...state.dataFileInput, data: action.payload }
      };
    case 'setMapping':
      return {
        ...state,
        mapping: action.payload
      };
    default:
      throw new Error();
  }
}

const [ctx, provider] = createCtx(reducer, initialState);
export const UploadContext = ctx;
export const UploadProvider = provider;
