import React from "react";
import "./index.css";
import LoadingImg from "./Components/assets/loading.gif";

export default function Loading() {
  return (
    <div className="loading-container">
      <img src={LoadingImg} className="loading-img" alt="loading" />
    </div>
  );
}
