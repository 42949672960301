import { ApolloClient, ApolloProvider, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React from 'react';
import { cache } from './Cache';

export default function ApolloWrapper(props: React.PropsWithChildren<{}>) {
  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_BASE_URL}${
      process.env.REACT_APP_BASE_URL !== 'http://localhost:3000/dev_local'
        ? `/graphql${process.env.REACT_APP_URL_SUFFIX}`
        : process.env.REACT_APP_URL_SUFFIX
    }`
  });

  const authLink = setContext((_, { headers, ...rest }) => {
    if (process.env?.REACT_APP_SESSION_ID) {
      headers = { ...headers, 'X-ABX-SESSION': process.env?.REACT_APP_SESSION_ID };
    }
    return {
      ...rest,
      headers: {
        ...headers
      }
    };
  });

  const client = new ApolloClient({
    cache: cache,
    link: authLink.concat(httpLink),
    connectToDevTools: true
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
