import React from 'react';
import Header from '../../Containers/Header';
import { UploadProvider } from './UploadContext';
import UploadBody from './UploadStatement/Components/UploadBody';

export default function StatementUpload() {
  return (
    <div className="root">
      <UploadProvider>
        <Header
          title="Data Files"
          description="Upload a comma separated or Excel file, please note that data files will not allow us to monitor your business automatically, we recommend that you connect a file stored on cloud storage."
        />
        <UploadBody />
      </UploadProvider>
    </div>
  );
}
