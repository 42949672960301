import { motion } from 'framer-motion';
import React from 'react';

export default function TransitionLayout({ children }) {
  const pageVariants = {
    in: {
      opacity: 1
      //   x: 0,
    },
    out: {
      opacity: 0
      //   x: "100vw",
    }
  };
  const pageTransition = {
    transition: 'ease-in-out',
    duration: 0.95
  };
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {children}
    </motion.div>
  );
}
