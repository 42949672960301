type Button = {
  text: string;
  style: string;
  link: string;
};

type NotificationContentProps = {
  title?: string;
  message: string;
  image?: null | string; //React.ReactNode;
  open?: boolean;
  show_close?: boolean;
  timeout?: number;
  first_button?: null | Button;
  second_button?: null | Button;
};

export const ABxNotificationOpen = ({
  title = '',
  message = '',
  image = null,
  show_close = true,
  timeout = 8000,
  first_button = null,
  second_button = null
}: NotificationContentProps) => ({
  type: 'TOGGLE_ABX_NOTIFICATION_OPEN',
  title,
  message,
  image,
  show_close,
  timeout,
  first_button,
  second_button
});

export const ABxNotificationClose = () => ({
  type: 'TOGGLE_ABX_NOTIFICATION_CLOSE'
});

export const ABxError = ({
  title = '',
  message = '',
  image = null,
  show_close = true,
  timeout = 8000,
  first_button = null,
  second_button = null
}: NotificationContentProps) => ({
  type: 'TOGGLE_ABX_ERROR_OPEN',
  title,
  message,
  image,
  show_close,
  timeout,
  first_button,
  second_button
});

export const ABxSuccess = ({
  title = '',
  message = '',
  image = null,
  show_close = true,
  timeout = 8000,
  first_button = null,
  second_button = null
}: NotificationContentProps) => ({
  type: 'TOGGLE_ABX_SUCCESS_OPEN',
  title,
  message,
  image,
  show_close,
  timeout,
  first_button,
  second_button
});

type CallbackProps = {
  caller: string;
};

export const NotificationCallback = ({ caller = '' }: CallbackProps) => ({
  type: 'NOTIFICATION_CALLBACK',
  caller
});

export const ClearCallback = () => ({
  type: 'CLEAR_NOTIFICATION_CALLBACK'
});

/*export const ABxInteractiveMsg = () => async(dispatch: Dispatch) => {
      dispatch(ABxNotificationOpen)
    }*/
