import { Box, Button, Card, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { LongButton } from '../../../../../Components/LongButton';
import { firstCapital } from '../../../../../utils/firstCapital';
import CheckCard from './assets/CheckCard.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 265,
      borderRadius: '17px !important'
    },
    media: {
      height: 130,
      width: '100%'
    },
    status: {
      width: 60
    }
  })
);

interface Props {
  displayName?: string;
  name?: string;
  imgURL?: string;
  connected?: boolean;
  isHaveIssue?: boolean;
  connectURL?: string;
  toggleToolDialog: (name?: string) => void;
}

export default function ToolCard({
  displayName,
  name,
  imgURL,
  connected = false,
  toggleToolDialog
}: Props): ReactElement {
  const classes = useStyles();
  return (
    <Card classes={{ root: classes.root }} elevation={0}>
      <div className="flex flex-col h-80 p-4">
        <div className="flex-none">
          <span className="text-abx-dark-blue text-sm font-semibold">
            {firstCapital(displayName)}
          </span>
        </div>
        <div className="w-full flex-grow text-center flex justify-center items-center my-4">
          <img src={imgURL} className="w-48 object-contain object-center" alt="card" />
        </div>
        <div className="flex-none">
          {connected ? (
            <Box textAlign="center" mb={3}>
              <LongButton variant="outlined" color="primary" onClick={() => toggleToolDialog(name)}>
                Connect
              </LongButton>
            </Box>
          ) : (
            <Box display="flex" mx={3} mt="auto" mb={3}>
              <Button variant="contained" style={{ backgroundColor: '#00AD82', color: 'white' }}>
                Edit
              </Button>
              <Box marginLeft="auto">
                <img src={CheckCard} style={{ marginLeft: 'auto' }} alt="card" />
              </Box>
            </Box>
          )}
        </div>
      </div>
    </Card>
  );
}
