import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

export default function ArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M6.37654 7.11264L6.37654 16H10.6251V7.11264H17L8.49959 0L0 7.11264H6.37654Z" fill="#00AD82" />
    </SvgIcon>
  );
}
