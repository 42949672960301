import { FormHelperText, InputLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tippy from '@tippy.js/react';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import * as yup from 'yup';
import ABSWitch from '../../../../../Components/ABSwitch';
import { LongButton } from '../../../../../Components/LongButton';
import { TextField } from '../../../../../Components/TextField';
import { CURRENCIES } from '../../../../../Consts/Currency';
import '../../../../../tooltip.css';
import { UploadContext } from '../../../UploadContext';
import { UploadStep } from '../Const';
import PreviewDialog from '../PreviewDialog';

type TransactionField = {
  name: keyof TransactionsField;
  label: string;
  description: string;
  type: string;
  required: boolean;
};

const mapperSchema = yup.object({
  transaction_id: yup.string(),
  date: yup.string().required('Date field is required'),
  amount: yup.string().required('Amount field is required'),
  merchant: yup.string(),
  name: yup.string().required('Name is required'),
  currency: yup.string(),
  payment_channel: yup.string(),
  pending: yup.string(),
  refund: yup.string(),
  ignore: yup.string(),
  customer_id: yup.string(),
  customer_pcode: yup.string(),
  customer_name: yup.string(),
  customer_city: yup.string(),
  customer_country: yup.string(),
  quantity: yup.string(),
  subscription: yup.string(),
  description: yup.string().required('Description required'),
  currencyOfField: yup.string()
});

type TransactionsField = yup.InferType<typeof mapperSchema>;

interface MapBodyInterface {
  data: any;
}

export default function MapBody({ data }: MapBodyInterface) {
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [showCurrency, setShowCurrency] = useState<boolean>(false);
  const { state, dispatch } = useContext(UploadContext);

  const formik = useFormik({
    initialValues: state.mapping,
    validationSchema: mapperSchema,
    onSubmit: (values) => {
      dispatch({ type: 'setMapping', payload: values });
      dispatch({ type: 'setCurrentStep', payload: UploadStep.REVIEW });
    }
  });
  const dataFields = () => {
    return transactionColumns.sort((firstCol, secondCol) => {
      if (firstCol.required === true && secondCol.required === false) {
        return -1;
      } else if (firstCol.required === false && secondCol.required === true) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const getKeys = () => {
    return Object.keys(data[0]);
  };

  return (
    <form className="bg-white rounded-xl mt-0 p-8 w-full" onSubmit={formik.handleSubmit}>
      <div className="mb-16">
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box display="flex" alignItems="center">
              <div className="flex flex-row space-x-4 align-middle items-center ">
                <div className="text-lg font-semibold text-abx-dark-blue">
                  {state.dataFileInput.name}
                </div>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenPreview(true)}
                  endIcon={<VisibilityIcon />}
                >
                  Preview
                </Button>
              </div>

              <PreviewDialog open={openPreview} setOpen={setOpenPreview} data={data} />
            </Box>
            <Box right={90} top={54}>
              <LongButton variant="contained" type="submit" color="primary">
                Next
              </LongButton>
            </Box>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box my={2} display="flex">
              <TextField
                id="description"
                name="description"
                placeholder="Description"
                variant="standard"
                value={formik.values.description}
                error={!formik.touched.description && Boolean(formik.errors.description)}
                helperText={!formik.touched.description && formik.errors.description}
                style={{ width: '500px' }}
                onChange={formik.handleChange}
              />
            </Box>
          </div>
          <div className="mt-4 flex flex-row align-middle items-center space-x-4">
            <span>Do you want to set or override the currency?</span>
            <div>
              <ABSWitch callback={(value) => setShowCurrency(value)} />
            </div>

            {showCurrency && (
              <Box>
                <FormControl>
                  <Select
                    variant="standard"
                    label="Currency"
                    id="currencyOfField"
                    name="currencyOfField"
                    value={formik.values.currencyOfField || ''}
                    onChange={formik.handleChange}
                    style={{ width: 250 }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    {CURRENCIES.map((currency, index) => (
                      <MenuItem key={index} value={currency.currency_display_name}>
                        {currency.currency_display_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
          </div>
        </div>
      </div>
      <table className="w-full grid">
        <thead>
          <tr className="flex flex-row items-center justify-center text-left">
            <th className="w-1/3 mr-2"></th>
            <th className="w-1/3 mr-2"></th>
            <th className="w-1/3 mr-2"></th>
          </tr>
        </thead>
        <tbody>
          {dataFields().map((field, index) => {
            return (
              <tr
                className={`mt-0 p-4 flex flex-row items-center justify-center text-center ${
                  index % 2 === 1 ? 'bg-white' : 'bg-gray-50'
                }`}
                key={field.name}
              >
                <td className="w-1/3 mr-2 text-left">
                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel>{field.label}</InputLabel>
                    {console.log('initial values==>', state.mapping[field.name])}
                    <Select
                      id={field.name}
                      name={field.name}
                      defaultValue={state.mapping[field.name]}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors[field.name])}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {getKeys().map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={Boolean(formik.errors[field.name])}>
                      {formik.touched[field.name] && formik.errors[field.name]}
                    </FormHelperText>
                  </FormControl>
                </td>
                <td className="w-1/3 mr-2">
                  <div className="flex flex-row items-center justify-center text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </div>
                </td>
                <Tippy
                  content={field.description}
                  hideOnClick={true}
                  interactive={false}
                  animation="scale-extreme"
                  duration={[300, 250]}
                  inertia={true}
                  arrow={false}
                  className="tooltip"
                  zIndex={9999}
                >
                  <td className="w-1/3 flex flex-row items-center mr-4">
                    <span className="text-abx-dark-blue">
                      {field.name}{' '}
                      {field.required && (
                        <span color="primary" className="ml-2 font-semibold">
                          <b>Required</b>
                        </span>
                      )}
                    </span>
                  </td>
                </Tippy>
              </tr>
            );
          })}
        </tbody>
      </table>
    </form>
  );
}

const transactionColumns: TransactionField[] = [
  {
    name: 'transaction_id',
    label: 'Transaction ID',
    description:
      'A unique identifier of the transaction, if this is not provided it will be automatically generated',
    type: 'string',
    required: false
  },
  {
    name: 'date',
    label: 'Date',
    description: 'The date (and time if avaliable) at which the transaction occured',
    type: 'string',
    required: true
  },
  {
    name: 'name',
    label: 'Name',
    description: 'The name of the transaction',
    type: 'string',
    required: true
  },
  {
    name: 'merchant',
    label: 'Merchant',
    description: 'The merchant of the transaction',
    type: 'string',
    required: false
  },
  {
    name: 'amount',
    label: 'Amount',
    description: 'The total of value of the transaction',
    type: 'float',
    required: true
  },
  {
    name: 'customer_id',
    label: 'Customer Id',
    description: 'If you have a unique identifier of a customer',
    type: 'string',
    required: false
  },
  {
    name: 'customer_pcode',
    label: 'Customer Post Code',
    description:
      "If you do not have a customer id, we'll make one for you using your customers post code and name. Note: we do not store this data on our systems and if you have two customers with the same name and post code we'll think its the same person. So an ID is better!",
    type: 'string',
    required: false
  },
  {
    name: 'customer_name',
    label: 'Customer Name',
    description:
      "If you do not have a customer id, we'll make one for you using your customers post code and name. Note: we do not store this data on our systems and if you have two customers with the same name and post code we'll think its the same person. So an ID is better!",
    type: 'string',
    required: false
  },
  {
    name: 'quantity',
    label: 'Quantity',
    description: 'Number of items sold',
    type: 'integer',
    required: false
  },
  {
    name: 'currency',
    label: 'Currency',
    description: 'The currency of the transaction',
    type: 'string',
    required: false
  },
  {
    name: 'payment_channel',
    label: 'Payment Channel',
    description: 'The channel in which the payment was made, i.e. in shop, paypal, shopify',
    type: 'string',
    required: false
  },
  {
    name: 'pending',
    label: 'Pending',
    description: 'If the transaction is still pending (true for pending)',
    type: 'boolean',
    required: false
  },
  {
    name: 'refund',
    label: 'Refund',
    description: 'Whether the transaction has been refunded or not',
    type: 'boolean',
    required: false
  },
  {
    name: 'ignore',
    label: 'Ignore',
    description: '',
    type: 'boolean',
    required: false
  }
];
