import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { ReactElement } from 'react';
import ABSelect from '../../../../Components/ABSelect';
import { BoxContainer } from '../../Components/BoxContainer';
import TabTable from '../../Components/TabTable';

export default function Product(): ReactElement {
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          <BoxContainer>
            {/* Title */}
            <Typography variant="subtitle2">Add a product Service</Typography>
            {/* Name */}
            <Typography variant="formText" color="primary">
              What is the name of this product or service?
            </Typography>
            <TextField name="name" label="name" variant="standard" />
            {/* Date */}
            <Typography variant="formText" color="primary" mt={4}>
              When will you start providing this product or service?
            </Typography>

            <KeyboardDatePicker
              // disableToolbar
              fullWidth
              autoOk
              variant="inline"
              inputVariant="standard"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date Founded"
              InputAdornmentProps={{ position: 'end' }}
              value={new Date().toLocaleDateString()}
              onChange={() => console.log('Changed')}
            />

            {/* Expected monthly income */}
            <Typography variant="formText" color="primary" mt={4}>
              And expected monthly income is between...
            </Typography>
            <Box display="flex" alignItems="flex-end">
              <TextField name="name" label="Lower amount" variant="standard" />
              &nbsp;
              <Box display="flex" justifyContent="flex-end" ml={2} mr={2}>
                <Typography variant="formText" color="primary">
                  and
                </Typography>
              </Box>
              &nbsp;
              <TextField name="name" label="cower amount" variant="standard" />
            </Box>

            {/* Excepted cose */}
            <Typography variant="formText" color="primary" mt={5}>
              My expected costs are paid...
            </Typography>
            <ABSelect name="Monthly" />
            {/* Expect to pay */}
            <Typography variant="formText" color="primary" mt={4}>
              And expect to pay between...
            </Typography>
            <Box display="flex" alignContent="flex-end" alignItems="flex-end">
              <TextField name="name" label="Lower amount" variant="standard" />
              <Typography variant="formText" color="primary" mx={4}>
                and
              </Typography>
              <TextField name="name" label="Upper amount" variant="standard" />
            </Box>
            <Box mt={5}>
              <Button variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </BoxContainer>
        </Grid>
        <Grid item xs={12} md={5}>
          <BoxContainer>
            <TabTable title="Product  or services cashflows" />
          </BoxContainer>
        </Grid>
      </Grid>
    </div>
  );
}
