import { experimentalStyled } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

// export const SideTooltip = withStyles({
//   tooltip: {
//     fontSize: 14,
//     borderRadius: 0,
//     textAlign: "center",
//     padding: 10,
//     left: -20,
//     backgroundColor: "#616161",
//     border: "solid 1px #616161",
//   },
// })(Tooltip);

export const SideTooltip = experimentalStyled((props) => <Tooltip {...props} />)({
  "&.MuiTooltip-tooltip": {
    fontSize: 14,
    borderRadius: 0,
    textAlign: "center",
    padding: 0,
    left: 0,
    backgroundColor: "#0E5A7B",
    border: "none",
  },
});
