import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { useRef, useState } from 'react';

// ----------------------------------------------------------------------

type BankMoreMenuProps = {
  onDelete: VoidFunction;
};

export default function BankMoreMenu({ onDelete }: BankMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    onDelete();
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleClick}>
          {/*<ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} color="#FF008E" />
          </ListItemIcon>*/}
          <ListItemText
            className="text-abx-error"
            primary="Delete"
            
          />
        </MenuItem>
      </Menu>
    </>
  );
}
