export const CURRENCIES = [
  {
    currency_name: "Australian Dollar",
    currency_code: "AUD",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "Australian Dollar ($)",
  },
  {
    currency_name: "Bulgarian Lev",
    currency_code: "BGN",
    currency_symbol: "Лв",
    currency_suffix: true,
    currency_display_name: "Bulgarian Lev (Лв)",
  },
  {
    currency_name: "Brazilian Real",
    currency_code: "BRL",
    currency_symbol: "R$",
    currency_suffix: true,
    currency_display_name: "Brazilian Real (R$)",
  },
  {
    currency_name: "Canadian Dollars",
    currency_code: "CAD",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "Canadian Dollars ($)",
  },
  {
    currency_name: "Swiss Franc",
    currency_code: "CHF",
    currency_symbol: "CHF",
    currency_suffix: false,
    currency_display_name: "Swiss Franc (CHF)",
  },
  {
    currency_name: "Chinese Yuan",
    currency_code: "CNY",
    currency_symbol: "¥",
    currency_suffix: true,
    currency_display_name: "Chinese Yuan (¥)",
  },
  {
    currency_name: "Czech Koruna",
    currency_code: "CZK",
    currency_symbol: "Kč",
    currency_suffix: false,
    currency_display_name: "Czech Koruna (Kč)",
  },
  {
    currency_name: "Danish Krone",
    currency_code: "DKK",
    currency_symbol: "Kr",
    currency_suffix: true,
    currency_display_name: "Danish Krone (Kr)",
  },
  {
    currency_name: "Pound Sterling",
    currency_code: "GBP",
    currency_symbol: "£",
    currency_suffix: true,
    currency_display_name: "Pound Sterling (£)",
  },
  {
    currency_name: "Hong Kong Dollar",
    currency_code: "HKD",
    currency_symbol: "HK$",
    currency_suffix: true,
    currency_display_name: "Hong Kong Dollar (HK$)",
  },
  {
    currency_name: "Croatian Kuna",
    currency_code: "HRK",
    currency_symbol: "kn",
    currency_suffix: false,
    currency_display_name: "Croatian Kuna (kn)",
  },
  {
    currency_name: "Hungarian Forint",
    currency_code: "HUF",
    currency_symbol: "Ft",
    currency_suffix: false,
    currency_display_name: "Hungarian Forint (Ft)",
  },
  {
    currency_name: "Indonesian Rupiah",
    currency_code: "IDR",
    currency_symbol: "Rp",
    currency_suffix: true,
    currency_display_name: "Indonesian Rupiah (Rp)",
  },
  {
    currency_name: "Israeli New Shekel",
    currency_code: "ILS",
    currency_symbol: "₪",
    currency_suffix: true,
    currency_display_name: "Israeli New Shekel (₪)",
  },
  {
    currency_name: "Indian rupee",
    currency_code: "INR",
    currency_symbol: "₹",
    currency_suffix: true,
    currency_display_name: "Indian rupee (₹)",
  },
  {
    currency_name: "Icelandic Króna",
    currency_code: "ISK",
    currency_symbol: "krónur",
    currency_suffix: false,
    currency_display_name: "Icelandic Króna (krónur)",
  },
  {
    currency_name: "Japanese Yen",
    currency_code: "JPY",
    currency_symbol: "JP¥",
    currency_suffix: true,
    currency_display_name: "Japanese Yen (JP¥)",
  },
  {
    currency_name: "South Korean won",
    currency_code: "KRW",
    currency_symbol: "₩",
    currency_suffix: true,
    currency_display_name: "South Korean won (₩)",
  },
  {
    currency_name: "Mexican Peso",
    currency_code: "MXN",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "Mexican Peso ($)",
  },
  {
    currency_name: "Malaysian Ringgit",
    currency_code: "MYR",
    currency_symbol: "RM",
    currency_suffix: true,
    currency_display_name: "Malaysian Ringgit (RM)",
  },
  {
    currency_name: "Norwegian Krone",
    currency_code: "NOK",
    currency_symbol: "kr",
    currency_suffix: false,
    currency_display_name: "Norwegian Krone (kr)",
  },
  {
    currency_name: "New Zealand Dollar",
    currency_code: "NZD",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "New Zealand Dollar ($)",
  },
  {
    currency_name: "Philippine peso",
    currency_code: "PHP",
    currency_symbol: "₱",
    currency_suffix: true,
    currency_display_name: "Philippine peso (₱)",
  },
  {
    currency_name: "Polish złoty",
    currency_code: "PLN",
    currency_symbol: "zł",
    currency_suffix: false,
    currency_display_name: "Polish złoty (zł)",
  },
  {
    currency_name: "Romanian leu",
    currency_code: "RON",
    currency_symbol: "lei",
    currency_suffix: false,
    currency_display_name: "Romanian leu (lei)",
  },
  {
    currency_name: "Russian Rouble",
    currency_code: "RUB",
    currency_symbol: "₽",
    currency_suffix: true,
    currency_display_name: "Russian Rouble (₽)",
  },
  {
    currency_name: "Swedish krona",
    currency_code: "SEK",
    currency_symbol: "kr",
    currency_suffix: false,
    currency_display_name: "Swedish krona (kr)",
  },
  {
    currency_name: "Singapore dollar",
    currency_code: "SGD",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "Singapore dollar ($)",
  },
  {
    currency_name: "Thai baht",
    currency_code: "THB",
    currency_symbol: "฿",
    currency_suffix: true,
    currency_display_name: "Thai baht (฿)",
  },
  {
    currency_name: "Turkish lira",
    currency_code: "TRY",
    currency_symbol: "₺",
    currency_suffix: true,
    currency_display_name: "Turkish lira (₺)",
  },
  {
    currency_name: "United States Dollar",
    currency_code: "USD",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "United States Dollar ($)",
  },
  {
    currency_name: "South African rand",
    currency_code: "ZAR",
    currency_symbol: "R",
    currency_suffix: true,
    currency_display_name: "South African rand (R)",
  },
  {
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    currency_name: "Jersey Pound",
    currency_code: "GBP",
    currency_symbol: "£",
    currency_suffix: true,
    currency_display_name: "Jersey Pound (£)",
  },
  {
    currency_name: "Guernsey Pound",
    currency_code: "GBP",
    currency_symbol: "£",
    currency_suffix: true,
    currency_display_name: "Guernsey Pound (£)",
  },
];
