import { Box, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { ReactElement } from 'react';
import ABSelect from '../../../../../Components/ABSelect';
import ABSWitch from '../../../../../Components/ABSwitch';
import { LongButton } from '../../../../../Components/LongButton';

export default function UtilityForm(): ReactElement {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="formText" color="primary">
        What do you want to name this?
      </Typography>
      <TextField placeholder="Name" variant="standard" />
      <Typography variant="formText" color="primary" mt={4}>
        When do you have to start paying for this utility?
      </Typography>

      <KeyboardDatePicker
        // disableToolbar
        fullWidth
        autoOk
        variant="inline"
        inputVariant="standard"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        label="Date Founded"
        InputAdornmentProps={{ position: 'end' }}
        value={new Date().toLocaleDateString()}
        onChange={() => console.log('Changed')}
      />

      <Typography variant="formText" color="primary" mt={4}>
        How often do you pay this bill?
      </Typography>
      <ABSelect name="Monthly" />
      <Typography variant="formText" color="primary" mt={4}>
        Do you pay a fixed amount every period?
      </Typography>
      <ABSWitch />
      <Typography variant="formText" color="primary" mt={4}>
        What is the upper and lower amount you will pay?
      </Typography>
      <Box display="flex" alignItems="flex-end">
        <TextField variant="standard" label="Lower Amount" />{' '}
        <Typography variant="formText" marginX={4} color="textSecondary">
          and{' '}
        </Typography>{' '}
        <TextField variant="standard" label="Upper Amount" />
      </Box>

      <Box mt={4}>
        <LongButton type="submit" variant="contained" color="primary">
          Save
        </LongButton>
      </Box>
    </Box>
  );
}
