import {
  createStyles,
  StandardProps,
  Theme,
  Typography,
  TypographyClassKey,
  TypographyProps,
  useTheme,
  withStyles,
} from "@material-ui/core";
import React from "react";

const styles = (theme: Theme) => createStyles({});

enum Colors {
  mainDarkText = "#0E5A7B",
  white = "#FFF",
  success = "#00AD82",
}

type ColorStrings = keyof typeof Colors;
type ClassKey = TypographyClassKey;

interface TypeProps extends StandardProps<TypographyProps, ClassKey> {
  abColor?: ColorStrings | undefined;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  mx?: number;
  my?: number;
}

const ABTypography = withStyles(styles)(
  ({ abColor = "mainDarkText", mt, mb, ml, mr, mx, my, children, ...props }: TypeProps) => {
    const theme = useTheme();
    return (
      <Typography
        style={{
          color: props.color ? undefined : Colors[abColor],
          marginTop: mt && theme.spacing(mt),
          marginRight: mr && theme.spacing(mr),
          marginBottom: mb && theme.spacing(mb),
          marginLeft: ml && theme.spacing(ml),
        }}
        {...props}
      >
        {children}
      </Typography>
    );
  }
);
export default ABTypography;
