import { IconButton, ListItem } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Transaction } from '../../../../../generated/graphql-frontend';
import ProductIcon from '../../../Components/Icons/ProductIcon';
import { AnalysisContext } from '../../../Hooks/AnalysisContext';

interface TransactionItemProps {
  transaction: Transaction;
}
function TransactionItem({ transaction }: TransactionItemProps) {
  const { updateCurrentTransaction, toggleTransOpen } = React.useContext(AnalysisContext);
  const category = transaction.transaction_info?.category;

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    updateCurrentTransaction(transaction);
    toggleTransOpen();
  };
  const handleCategoryClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    // TODO  change filter of category when user click category icon
    // dispatch({ type: 'setFilter', payload: { ...state.filter, category: category } });
  };
  if (!category) return <div>No category</div>;
  return (
    <ListItem button onClick={(e) => handleClick(e)} style={{ cursor: 'pointer' }}>
      <div className="pr-5 pl-2 py-3 flex flex-row w-full justify-center align-middle items-center">
        <span className="text-abx-transaction-date mr-2 flex-none w-12">
          {moment(transaction.date).format('HH:MM')}
        </span>
        <span className="ml-2 whitespace-nowrap overflow-ellipsis text-abx-dark-grey flex-grow">
          {transaction?.details?.display_name}
        </span>
        <div>
          <div className="text-abx-dark-blue flex-none flex w-48 justify-center align-middle items-center">
            <span className="rounded-md flex-grow ml-2 pl-8">
              <IconButton onClick={handleCategoryClick}>
                <ProductIcon category={category} />
              </IconButton>
            </span>
            <span
              className={`flex-none w-30text-md ${
                Number(transaction.amount) < 0 ? 'text-abx-dark-blue' : 'text-abx-dark-grey'
              } text-right items-end justify-end`}
            >
              {Number(transaction.amount) > 0
                ? `+${Number(transaction.amount).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}`
                : Number(transaction.amount).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
              &nbsp; {transaction.currency}
            </span>
          </div>
        </div>
      </div>
    </ListItem>
  );
}
export default React.memo(TransactionItem);
