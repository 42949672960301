import { useMutation } from '@apollo/client';
import { Autocomplete, Box, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import '@material-ui/lab';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { LongButton } from '../../../../../Components/LongButton';
import { CURRENCIES } from '../../../../../Consts/Currency';
import { COUNTRIES } from '../../../../../Consts/FormData';
import { TIMEZONE } from '../../../../../Consts/timeZone';
import { CREATE_USER } from '../../../../../Gqls/gql';
import { AuthContext } from '../../../../../Context/Login';

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  timezone: yup.string().required('Timezone name is required'),
  currency: yup.string().required('Currency name is required')
});

export default function PreferenceForm() {
  const history = useHistory();
  const [createCompany] = useMutation(CREATE_USER);
  const user = useContext(AuthContext);

  React.useEffect(() => {
    console.log(user);
  }, [user]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      timezone: user?.timezone,
      currency: user?.currency
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const preferenceInfo = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: user?.email,
        time_zone: values.timezone,
        currency: values.currency
      };
      createCompany({ variables: { input: { ...preferenceInfo } } })
        .then((res) => {
          if (res.data.createUser.status === true) {
            history.push('/onboarding/company');
          }
        })
        .catch((error) => console.log(error));
    }
  });

  return (
    <Box mx={16} mt={16}>
      <div>
        <Typography variant="h1">Preferences</Typography>
        <Typography variant="body1" mt={5} mb={4}>
          Don't worry you can change these preferences at any time
        </Typography>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Box width="60%">
          <TextField
            fullWidth
            variant="standard"
            id="firstName"
            name="firstName"
            label="First Name"
            margin="normal"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            variant="standard"
            label="Last Name"
            margin="normal"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
          <Autocomplete
            id="timezone"
            name="timezone"
            color="primary"
            forcePopupIcon={true}
            options={TIMEZONE}
            value={formik.values.timezone}
            onChange={(event, value) => {
              formik.setFieldValue('timezone', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Time Zone"
                margin="normal"
                variant="standard"
                value={formik.values.timezone}
                error={formik.touched.timezone && Boolean(formik.errors.timezone)}
                helperText={formik.touched.timezone && formik.errors.timezone}
              />
            )}
          />
          <Autocomplete
            id="currency"
            name="currency"
            color="primary"
            forcePopupIcon={true}
            options={CURRENCIES.map((item) => item.currency_code).filter((item, index) => {
              return item.indexOf(item) !== index;
            })}
            value={formik.values.currency}
            onChange={(event, value) => {
              formik.setFieldValue('currency', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Currency"
                margin="normal"
                variant="standard"
                value={formik.values.currency}
                error={formik.touched.currency && Boolean(formik.errors.currency)}
                helperText={formik.touched.currency && formik.errors.currency}
              />
            )}
          />
        </Box>
        <Box display="flex" marginTop={10}>
          <LongButton
            color="primary"
            variant="contained"
            type="submit"
            style={{ marginLeft: 'auto' }}
          >
            Next &nbsp; &gt;
          </LongButton>
        </Box>
      </form>
    </Box>
  );
}
