import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import SuccessPicture from '../Components/assets/In_Progress.svg';
import Bot from '../Components/Bot/Bot';
import ABLoading from '../Components/Loading/ABLoading';
import ButtonLoading from '../Components/Loading/ButtonLoading';
import DualLoading from '../Components/Loading/DualRing';
import Spinner from '../Components/Loading/Spinner';
import MainSideBar from '../Components/MainSideBar/MainSideBar';
import ErrorContent from '../Components/Notification/ErrorContent';
import SuccessContent from '../Components/Notification/SuccessContent';
import { TextField } from '../Components/TextField';
import Upgrade from '../Components/Upgrade';
import SearchBox from './DataManage/Integrate/Components/SearchBox';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  container: {
    margin: theme.spacing(5),
    padding: theme.spacing(5)
  },
  paper: {
    padding: theme.spacing(3)
  }
}));
export default function TestComponent() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [time, setTime] = useState({ enter: 500, exit: 600 });
  const classes = useStyles();

  const handleNotification = () => {
    const content = (key, message) => {
      return (
        <div>
          <SuccessContent message={message} onDismiss={() => closeSnackbar()} />
        </div>
      );
    };
    enqueueSnackbar('this is message for test', {
      autoHideDuration: 5000,
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      content,
      transitionDuration: time
    });
  };
  const handleErrorNotification = () => {
    const content = (key, message) => {
      return (
        <div>
          <ErrorContent message={message} onDismiss={() => closeSnackbar()} />
        </div>
      );
    };
    enqueueSnackbar('This is error notification', {
      autoHideDuration: 5000,
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      content,
      transitionDuration: time
    });
  };

  const handleInProgress = () => {
    const content = (key, message) => {
      return (
        <div>
          <SuccessContent
            message={message}
            onDismiss={() => closeSnackbar()}
            picture={SuccessPicture}
            title="In progress"
          />
        </div>
      );
    };
    const message =
      "So you can get started we have loaded the last 30 days of transactions. We'll let you know when we have everything";
    enqueueSnackbar(message, {
      autoHideDuration: 5000,
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      content,
      transitionDuration: time
    });
  };
  return (
    <div>
      <div className={classes.root}>
        <MainSideBar variant="permanent">This is drawer</MainSideBar>
        <Container className={classes.container}>
          <Typography variant="h1">This is for test component</Typography>
          <Grid container>
            <Grid item xs={6}>
              <Button color="primary" variant="contained" onClick={handleNotification}>
                Success Notification test
              </Button>
              <br />
              <br />
              <Button variant="contained" color="secondary" onClick={handleErrorNotification}>
                <ButtonLoading />
                Error Notification Test
              </Button>
              <br />
              <br />
              <Button variant="contained" color="primary">
                Loading..
                <DualLoading />
              </Button>
              <br />
              <br />
              <Button variant="contained" color="primary">
                Loading...
                <Spinner />
              </Button>
              <br />
              <br />
              <Button variant="contained" disabled>
                <ABLoading />
                Loading...
              </Button>
              Auto Complete for drawer
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                Notification enter and exist time
                <br />
                <br />
                <TextField
                  variant="outlined"
                  color="primary"
                  label="Enter Time"
                  value={time.enter}
                  onChange={(event) => setTime({ ...time, enter: Number(event.target.value) })}
                />
                <br />
                <br />
                <TextField variant="standard" label="test" fullWidth />
                <br />
                <br />
                <br />
                Auto Complete for drawer
                <br />
                Notification for "In progress"
                <Button onClick={handleInProgress} color="primary" variant="contained">
                  In progress notification
                </Button>
                <br />
                <Box position="absolute" bottom={3} right={3}>
                  <Bot />
                </Box>
              </Paper>
            </Grid>
          </Grid>

          <Grid container className={classes.container}></Grid>
          <Box ml={10}>
            Search box
            <SearchBox />
            <Upgrade />
          </Box>
        </Container>
      </div>
    </div>
  );
}
