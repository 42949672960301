import React from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

const theme = {
  background: '#DAEAF6',
  fontFamily: 'Helvetica Neue',
  headerBgColor: '#DAEAF6',
  headerFontColor: '#0E5A7B',
  headerFontSize: '15px',
  botBubbleColor: '#0E5A7B',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a'
};

const styles = {
  bubbleOption: {
    minWidth: '200px',
    backgroundColor: '#0D8ABC'
  },
  bubbleStyle: {
    minWidth: '70%'
  },
  contentContainer: {
    textAlign: 'center'
  }
};
export default function Bot() {
  const handleEnd = ({ step, values }) => {};

  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        className="ChatBot"
        customStyle={{ backgroundColor: '#F00' }}
        headerTitle="A drop in sales"
        handleEnd={handleEnd}
        bubbleStyle={styles.bubbleStyle}
        bubbleOptionStyle={styles.bubbleOption}
        contentStyle={styles.contentContainer}
        steps={[
          {
            id: '1',
            message:
              'I have loaded your last 90 days of transactions, I will let you know when I have the rest.',
            trigger: '2'
          },
          {
            id: '2',
            options: [
              { value: '1', label: 'The product is seasonal', trigger: '3' },
              {
                value: '2',
                label: 'This is a wider market trend',
                trigger: '3'
              },
              { value: '3', label: 'It is being discontinued', trigger: '3' },
              { value: '4', label: 'Other', trigger: '3' }
            ]
          },
          {
            id: '3',
            message: 'A callback message was called!',
            end: true
          }
        ]}
      />
    </ThemeProvider>
  );
}
