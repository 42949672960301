import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Dispatch, SetStateAction } from 'react';
import ABLoading from '../../../../../Components/Loading/ABLoading';
import { useCompanyStore } from '../../../../../Context/GlobalStore';
import { ConnectedIntegration } from '../../../../../generated/graphql-frontend';
import { useIntegrateStore } from '../../../hooks/IntegrateStore';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    padding: 40
  },
  actionContainer: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.background.paper
  }
}));

interface ConfirmTypes {
  tool: ConnectedIntegration;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
export default function Confirm({ tool, open, setOpen }: ConfirmTypes) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { currentCompanyId } = useCompanyStore();
  const { setOpenDrawer, currentProviderName } = useIntegrateStore();
  const [shop, setShop] = React.useState('');

  if (!process.env.REACT_APP_LOCAL_SITE) return null;
  const url = `${
    process.env.REACT_APP_PROVIDER_AUTH
  }/${currentProviderName}?company_id=${currentCompanyId}&redirect_url=${encodeURIComponent(
    process.env.REACT_APP_LOCAL_SITE
  )}`;

  const handleConnectBtn = () => {
    setLoading(true);
    fetch(
      url + (currentProviderName?.toLowerCase() === 'shopify' && shop !== '')
        ? `${url}&shop=${shop}`
        : url,
      { method: 'GET' }
    )
      .then((response) => {
        response.json().then((data) => {
          setLoading(false);
          if (data.success === true) {
            if (data.already_connected === false) {
              window.open(data.url, '_self');
            } else {
              setOpenDrawer(true);
              setOpen(false);
            }
          }
        });
      })
      .catch(function (err) {
        setLoading(false);
        console.info(
          err + ' url: ' + url + (currentProviderName?.toLowerCase() === 'shopify' && shop !== '')
            ? '&shop=' + shop
            : ''
        );
      });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      maxWidth="sm"
    >
      <Box className={classes.headerContainer}>
        <Header tool={tool} />
        <Typography variant="subtitle1">What Can You Do</Typography>
        <Box mb={3}>
          {tool.what_can_do?.map((item, index) => (
            <React.Fragment key={`wrapper-${index}`}>
              <Typography component="li">{item}</Typography>
            </React.Fragment>
          ))}
        </Box>
        <br />
        <Typography variant="subtitle1">What You Need</Typography>
        <Box mb={3}>
          <div>
            {tool.what_need?.map((item, index) => (
              <React.Fragment key={`what-need-${index}`}>
                <Typography component="li" variant="body1">
                  {item}
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        {tool.name?.toLowerCase() === 'shopify' ? (
          <TextField
            fullWidth
            id={tool.name}
            variant="standard"
            value={shop}
            label="Enter your shop name"
            onChange={(e) => setShop(e.target.value)}
          />
        ) : (
          <></>
        )}

        <br />
      </Box>
      <Box display="flex" mb={5} mx={10} className={classes.actionContainer}>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          color="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Box flexGrow={1}></Box>
        <Button
          onClick={() => handleConnectBtn()}
          color="primary"
          variant="contained"
          disabled={loading || (shop === '' && tool.name?.toLowerCase() === 'shopify')}
        >
          {loading && <ABLoading />}
          Connect
        </Button>
      </Box>
    </Dialog>
  );
}
