import { useMutation, useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVertRounded';
import React, { useState } from 'react';
import ConfirmModal from '../../../Components/ConfirmModal/ConfirmModal';
import { COMPANY, DELETE_ROLE } from '../../../Gqls/gql';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2),
    padding: 0
  },
  menuItem: {
    padding: theme.spacing(1, 3)
  }
}));

export default function MoreMenu({ id, userID, userName }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { data: data_company, loading: loading_company } = useQuery(COMPANY);

  const [deleteRole] = useMutation(DELETE_ROLE);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleDelete = (event) => {
    setOpen(false);
    //Delete Role in User List
    deleteRole({ variables: { user_id: userID, company_id: id } })
      .then((res) => {
        // if(res.deleteRole. )
      })
      .catch((res) => {});
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (loading_company) return <div>Loading...</div>;

  const companies = data_company.users[0].companies;
  return (
    <div className={classes.root}>
      <IconButton ref={anchorRef} onClick={handleToggle}>
        <MoreIcon />
      </IconButton>
      <ConfirmModal
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title={<Typography variant="h3">Are you sure?</Typography>}
        children={`This will cause the user ${userName} to loose access to ${
          companies.find((item) => item.id === id).name
        }`}
        onConfirm={handleDelete}
      />
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem
                    onClick={() => {
                      setConfirmOpen(true);
                    }}
                    className={classes.menuItem}
                  >
                    <Typography color="error">Delete</Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
