import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(4),
  },
}));

export default function ConfirmModal(props) {
  const { title, open, setOpen, onConfirm, deleteName } = props;
  const [confirmText, setConfirmText] = useState("");
  const [confirmTextError, setConfirmTextError] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const classes = useStyles();

  const handleDeleteBtn = () => {
    if (confirmText.normalize().trim() === ("Delete " + deleteName).normalize().trim()) {
      setOpen(false);
      setConfirmText("");
      setConfirmTextError(false);
      onConfirm();
    } else {
      setConfirmTextError(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      classes={{ paper: classes.container }}
      maxWidth="xs"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Box my={10} mx={10}>
        <Typography variant="h2" align="center">
          {title}
        </Typography>
        <br />
        <Typography>
          This will delete the company {deleteName}, this action <b>cannot</b> be reversed.
        </Typography>
        <br />
        <Typography>To continue type the text below and click delete:</Typography>
        <Box mt={10}></Box>
        <Typography color="error">Delete {deleteName}</Typography>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              autoFocus
              margin="dense"
              variant="standard"
              id="match_text"
              label="Enter text above"
              fullWidth
              value={confirmText}
              error={confirmTextError}
              helperText={confirmTextError && "You should input above text to delete company"}
              onChange={(e) => {
                setConfirmText(e.target.value);
                if (e.target.value.normalize().trim() === ("Delete " + deleteName).normalize().trim()) {
                  setDisableButton(false);
                } else {
                  setDisableButton(true);
                }
                if (e.target.value.length > 0) {
                  setConfirmTextError(false);
                }
              }}
            />
          </Grid>
        </Grid>
        <br />
        <Box display="flex">
          <Button
            onClick={() => {
              setOpen(false);
              setConfirmTextError(false);
              setConfirmText("");
            }}
            color="primary"
            variant="outlined"
            className={classes.cancel}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteBtn}
            color="error"
            variant="contained"
            disabled={disableButton}
            style={{ marginLeft: "auto" }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
