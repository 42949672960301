import Tippy from '@tippy.js/react';
import { Auth } from 'aws-amplify';
import { BroadcastChannel } from 'broadcast-channel';
import queryString from 'query-string';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import validator from 'validator';
import country from './country_code.json';
import currency from './currency.json';
import BlueLogo from './img/ABx Logo Blue.png';
import Logo from './img/ABx Logo.png';
import Clouds from './img/clouds.png';
import FB from './img/fb-signin.png';
import Google from './img/google-signin-dark.png';
import './index.css';

export const AuthContext = createContext();

const options = {
  webWorkerSupport: true
};

const authChannel = new BroadcastChannel('auth-channel', options);
const profileChannel = new BroadcastChannel('profile-channel', options);

class ABxAuth {
  constructor(formState, setFormState, user, setUser) {
    this.formState = formState;
    this.setFormState = setFormState;
    this.user = user;
    this.setUser = setUser;
  }

  signIn = async () => {
    this.setFormState(() => ({
      ...this.formState,
      loading: true
    }));
    try {
      console.log('login in');
      const { email, password } = this.formState;
      console.log(this.formState);
      await Auth.signIn(email, password);
      //this.setPage("signedIn");
      await this.createSession();
      //this.setPage('signedIn');
    } catch (err) {
      console.log('error');
      //console.log(err.message);
      if (err.code === 'UserNotConfirmedException') {
        console.log('Not confirmed resending code');
        this.setPage('confirmSignUp');
        await this.resendConfirmation();
      } else {
        console.log(`Run the other anyway ${err.code}`);
        this.setFormState(() => ({ ...this.formState, error: err.message }));
        console.log(err);
      }
    }
  };

  confirmSignUp = async () => {
    const { email, authCode } = this.formState;
    this.setFormState(() => ({
      ...this.formState,
      loading: true
    }));
    await Auth.confirmSignUp(email, authCode);
    //this.setFormState(() => ({ ...this.formState, formType: 'confirmSignUp' }));
    await this.createSession();
    //this.setPage('signedIn');
  };

  signUp = async () => {
    this.toggleLoading();
    try {
      const { email, password } = this.formState;
      await Auth.signUp(email, password);
      //this.signIn();
      this.setPage('confirmSignUp');
    } catch (err) {
      this.setFormState(() => ({ ...this.formState, error: err.message }));
      console.log(err);
    }
  };

  signOut = async () => {
    navigator.serviceWorker.controller.postMessage('logout');
    await Auth.signOut();
    this.setFormState(() => ({ ...this.formState, formType: 'signIn' }));
  };

  federatedSignIn = async (provider) => {
    this.setFormState(() => ({
      ...this.formState,
      loading: true
    }));
    //alert(`Provider: ${provider}`);
    const user = await Auth.federatedSignIn({ provider: provider });
    //alert('figure');
    console.log(user);
    await this.createSession();
    //alert('Session');
    const new_attribute = await Auth.updateUserAttributes(user, {
      'custom:locale': this.getLocale()
    });
    //alert('done');
    console.log(user);
  };

  turnOnMFA = () => {};

  turnOffMFA = () => {};

  confirmMFA = () => {};

  forgottenPassword = async () => {
    const { email } = this.formState;
    this.setFormState(() => ({
      ...this.formState,
      loading: true
    }));
    await Auth.forgotPassword(email)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log(err));
    this.setFormState(() => ({
      ...this.formState,
      newPassword: '',
      error: '',
      formType: 'confirmForgottenPassword'
    }));
  };

  confirmForgottenPassword = async () => {
    const { email, authCode, password } = this.formState;
    await Auth.forgotPasswordSubmit(email, authCode, password)
      .then((data) => {
        console.log(data);
        this.signIn();
      })
      .catch((err) => {
        this.setFormState(() => ({ ...this.formState, error: err.message }));
        console.log(err);
      });
  };

  resendConfirmation = async () => {
    try {
      const { email } = this.formState;
      await Auth.resendSignUp(email);
      console.log('code resent successfully');
    } catch (err) {
      console.log(err);
    }
  };

  changePassword = async () => {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, this.formState.oldPassword, this.formState.newPassword);
      })
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  };

  forgottenMFA = () => {};

  validatePassword = () => {
    const { email, password, confirmPassword, tnc } = this.formState;
    if (!validator.isEmail(email)) {
      console.log('Invalid email address');
    } else if (!validator.isStrongPassword(password)) {
      console.log(
        'Password must contain upper and lower case characters, at least one number, a symbol and be at least 8 characters long'
      );
    }
  };

  getToken = async () => {
    let tkn = await Auth.currentSession().then((res) => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      let idt = res.getIdToken().getJwtToken();
      //You can print them to see the full objects
      //console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
      //console.log(`myJwt: ${jwt}`);
      return { access_token: jwt, id_token: idt };
    });
    return tkn;
  };

  toggleSignIn = () => {
    this.formState.formType === 'signIn'
      ? this.setFormState(() => ({
          ...initialFormState,
          formType: 'signUp',
          error: ''
        }))
      : this.setFormState(() => ({
          ...initialFormState,
          formType: 'signIn',
          error: ''
        }));
  };

  checkCurrency = (cty) => {
    try {
      return currency.find((curr) => curr.iso_country === country[cty]);
    } catch (err) {
      return {
        name: '',
        iso_country: '',
        currency_name: '',
        currency_code: '',
        currency_symbol: '',
        suffix: null
      };
    }
  };

  getLocale = () => {
    if (navigator.languages != undefined) return navigator.languages[0];
    return navigator.language;
  };

  updateUserAttributes = async () => {
    const user = await Auth.currentAuthenticatedUser();
    fetch(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_KEY}`)
      .then((res) => res.json())
      .then((out) => {
        //console.log(out);
        console.log('Updating user attributes....');
        let countryDets = this.checkCurrency(out.country);

        /*Auth.updateUserAttributes(user, {
          'custom:timezone': out.timezone,
          'custom:locale': this.getLocale(),
          'custom:city': out.city,
          'custom:is_eu': String(countryDets.is_eu),
          'custom:country': countryDets.iso_country,
          'custom:country_name': countryDets.name,
          'custom:currency_code': countryDets.currency_code,
          'custom:currency_name': countryDets.currency_name,
          'custom:currency_symbol': countryDets.currency_symbol
        });*/
        Auth.updateUserAttributes(user, {
          'custom:locale': this.getLocale()
        });
      })
      .catch((err) => console.log(err));
  };

  createSession = async () => {
    console.log('creating sess....');
    //alert('creating sess....');
    const url = `${process.env.REACT_APP_VALIDATE_URL}/create`;
    console.log(url);
    const tkn = await this.getToken();
    console.log(tkn);
    const header = {
      Authorization: `Bearer ${tkn.access_token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    let options = {
      method: 'POST',
      headers: header,
      body: JSON.stringify({ id: tkn.id_token })
    };
    console.log(options);
    await fetch(url, options)
      .then((res) => {
        console.log('Session created');
        //alert('Session created');
        console.log(res);
        //this.setPage("signedIn");
        //this.toggleLoading();
      })
      .catch((err) => {
        console.log('Could not create session');
        console.log(err);
        this.setFormState(() => ({ ...this.formState, error: err.message }));
      });
  };

  showForgottenPassword = () => {
    this.setFormState(() => ({
      ...this.formState,
      formType: 'forgotPassword',
      password: '',
      error: ''
    }));
  };

  checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log('checked');
      this.setUser(user);
      console.log(user);
      this.setFormState(() => ({
        ...this.formState,
        formType: 'signedIn',
        password: '',
        error: ''
      }));
    } catch (err) {
      console.log(err);
    }
  };

  toggleLoading = () => {
    this.setFormState(() => ({
      ...this.formState,
      loading: !this.formState.loading
    }));
  };

  setPage = (val) => {
    try {
      this.setFormState(() => ({
        ...this.formState,
        formType: val,
        password: '',
        error: ''
      }));
    } catch (err) {
      console.log(err);
    }
  };
}

const initialFormState = {
  email: '',
  password: '',
  confirmPassword: '',
  newPassword: '',
  oldPassword: '',
  authCode: '',
  formType: 'signUp',
  error: '',
  loading: false,
  tnc: false
};

function AuthProvider({ children }) {
  const [formState, updateFormState] = useState(initialFormState);
  const [user, updateUser] = useState(initialFormState);
  const [tnc, setTnc] = useState(false);
  const [test, setTest] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [profile, setProfile] = useState(null);
  const { search } = useLocation();
  const qs = queryString.parse(search);
  const { formType } = formState;

  const authenticate = useMemo(() => new ABxAuth(formState, updateFormState, user, updateUser), []);

  const logout = () => {
    authenticate.signOut();
  };

  authChannel.addEventListener('message', function (event) {
    console.log('New message from service worker', event);
    console.log('Update logged in to:', event.logged_in);
    setLoggedIn(event.logged_in);
    //alert(`Message from service worker logged_in: ${event.logged_in}`);
  });

  profileChannel.addEventListener('message', function (event) {
    console.log('Update profile', event);
    setProfile(event);
  });

  useEffect(() => {
    //Handle offline
    if (process.env?.REACT_APP_SESSION_ID) {
      let header = {
        'Content-Type': 'application/json',
        'X-ABX-SESSION': process.env?.REACT_APP_SESSION_ID
      };
      let options = {
        method: 'GET',
        headers: header
      };
      fetch(`${process.env.REACT_APP_VALIDATE_URL}/profile`, options)
        .then((response) => response.json())
        .then((res) => {
          setLoggedIn(true);
          setProfile(res);
          //console.log('Profile', res);
          //console.log('Profile', profile);
          //authenticate.setPage('signedIn');
          // setLoggedIn(true);
          // authenticate.setPage('signedIn');
          // updateFormState(() => ({
          //   ...formState,
          //   loading: false
          // }));
        })
        .catch((err) => console.log(err));
    }
    console.log('effect called');
    if (!loggedIn && qs.type?.toLocaleLowerCase() === 'signin') {
      console.log('sign in called');
      authenticate.setPage('signIn');
    }
    navigator.serviceWorker.ready.then(() => {
      console.log('Calling logged in');
      //alert('Calling logged in');
      navigator.serviceWorker.controller.postMessage('loggedIn');
    });
  }, [authenticate, loggedIn, qs.type]);

  useEffect(() => {
    if (loggedIn) {
      if (profile === null && !process.env?.REACT_APP_SESSION_ID) {
        //alert('profile none');
        navigator.serviceWorker.controller.postMessage('profile');
      } else {
        updateFormState((prev) => ({ ...prev, loading: false, formType: 'signedIn' }));
      }
    }
  }, [loggedIn, profile]);

  useEffect(() => {
    if (profile !== null) {
      if (Object.keys(profile).length > 0) {
        //alert(`Profile set`);
        console.log('profile set', profile);
        updateFormState((prev) => ({ ...prev, loading: false, formType: 'signedIn' }));
      }
    }
  }, [profile]);

  useEffect(() => {
    console.log('Logged in', loggedIn);
    console.log('Form State', formState);
  }, [formState]);

  const handleChange = (e) => {
    e.persist();
    updateFormState(() => ({
      ...formState,
      [e.target.name]: e.target.value
    }));
  };

  const ErrorTickIcon = ({ msg = '' }) => {
    return (
      <Tippy
        content={msg}
        hideOnClick={true}
        interactive={false}
        animation="scale-extreme"
        duration={[300, 250]}
        inertia={true}
        arrow={true}
        className="tooltip"
        zIndex={9999}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-6 w-6 text-abx-error`}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          />
        </svg>
      </Tippy>
    );
  };

  const SuccessTickIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-6 w-6 text-abx-success`}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    );
  };

  const TickIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-6 w-6 text-abx-light-grey`}
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
    );
  };

  const SpinnerIcon = () => {
    return (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          className="w-6 h-6 mr-2 block text-abx-dark-grey animate animate-spin"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#525252"
            strokeWidth="10"
            r="35"
            strokeDasharray="164.93361431346415 56.97787143782138"
            transform="matrix(1,0,0,1,0,0)"
          />
        </svg>
      </div>
    );
  };

  const apiTest = async () => {
    //console.log(Cookies.get());
    const generateRequest = async () => {
      //navigator.serviceWorker.controller.postMessage("profile");
      let header = {
        'Content-Type': 'application/json'
      };
      let options = {
        method: 'GET',
        headers: header
      };
      const out = await fetch(`${process.env.REACT_APP_VALIDATE_URL}/private`, options)
        .then((res) => {
          return res.json();
        })
        .catch((err) => console.log(err));
      return out;
    };
    const response = await generateRequest();
    setTest(response.message);
  };

  const ValidateRegistration = () => {
    return formState.email &&
      formState.password !== '' &&
      validator.isStrongPassword(formState.password) &&
      formState.password === formState.confirmPassword
      ? true
      : false;
  };

  const ValidateEmail = () => {
    if (!!formState.email) {
      if (validator.isEmail(formState.email)) {
        return <SuccessTickIcon className="flex-none" />;
      } else {
        return <ErrorTickIcon className="flex-none" msg="Invalid email address" />;
      }
    } else {
      return <TickIcon className="flex-none" />;
    }
  };

  const ValidateCode = () => {
    if (!!formState.authCode) {
      if (validator.isLength(formState.authCode, { min: 6 })) {
        return <SuccessTickIcon className="flex-none" />;
      } else {
        return <ErrorTickIcon className="flex-none" msg="Invalid code" />;
      }
    } else {
      return <TickIcon className="flex-none" />;
    }
  };

  const ValidatePassword = () => {
    if (formState.password !== '') {
      if (validator.isStrongPassword(formState.password)) {
        return <SuccessTickIcon className="flex-none" />;
      } else {
        return (
          <ErrorTickIcon
            className="flex-none"
            msg="Password must be at least 8 characters long and contain both upper and lower case letters and at least one special character and number"
          />
        );
      }
    } else {
      return <TickIcon className="flex-none" />;
    }
  };

  const CheckPasswordMatch = () => {
    if (formState.password !== '') {
      if (formState.password === formState.confirmPassword && formState.password !== '') {
        return <SuccessTickIcon className="flex-none" />;
      } else {
        return <ErrorTickIcon className="flex-none" msg="Passwords must match" />;
      }
    } else {
      return <TickIcon className="flex-none" />;
    }
  };

  return (
    <div>
      {formType === 'signUp' && (
        <div className="bg-abx-light-grey px-4 pb-4 pt-4 lg:px-12 lg:pb-12 lg:pt-4 h-screen">
          <div className="flex">
            <div className="flex-grow" />
            <div className="text-abx-dark-blue text-base mb-6 pr-4 flex-none">
              Already have an account?{' '}
              <span className="pink-link" onClick={authenticate.toggleSignIn}>
                Sign In
              </span>
            </div>
          </div>
          <div className="flex flex-row text-white justify-center align-middle items-center lg:h-container bg-abx-dark-blue">
            <div className="hidden bg-abx-dark-blue h-full w-1/2 p-12 md:block">
              <img
                className="relative sm:absolute sm:top-28 align-top h-auto w-16 mb-4"
                src={Logo}
              />
              <span className="text-5xl lg:text-6xl font-semibold leading-tight lg:leading-normal">
                Meet your CFO in the cloud
              </span>
              <img className="h-auto w-full mt-4 max-w-md" src={Clouds} />
            </div>
            <div className="bg-white w-full h-full md:w-1/2 flex-1">
              <div className="flex flex-col m-12">
                <img className="h-auto w-16 mb-4 md:hidden" src={BlueLogo} />
                <span className="text-abx-dark-blue font-regular text-xl">
                  Welcome to Alchemy Box
                </span>
                <span className="text-abx-dark-blue font-bold text-4xl mt-2 mb-12">Register</span>

                <div className="flex flex-col items-center justify-center">
                  <img
                    className="h-auto w-56 mb-4 object-center"
                    onClick={() => authenticate.federatedSignIn('Google')}
                    src={Google}
                  />
                  <img
                    className="h-auto w-56 mb-8 object-center"
                    onClick={() => authenticate.federatedSignIn('Facebook')}
                    src={FB}
                  />
                </div>
                <div className="flex flex-row items-center justify-center">
                  <input
                    placeholder="Email"
                    className="my-1 pt-4 pb-2 flex-grow outline-none focus:outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-dark-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
                    name="email"
                    autoComplete="email"
                    onChange={handleChange}
                    required
                  />
                  <ValidateEmail />
                </div>
                <div className="flex flex-row items-center justify-center">
                  <input
                    id="current-password"
                    className="my-1 pt-4 pb-2 flex-grow outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-dark-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
                    type="password"
                    name="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    required
                  />
                  <ValidatePassword />
                </div>
                <div className="flex flex-row items-center justify-center">
                  <input
                    id="confirm-password"
                    className="my-1 pt-4 pb-2 flex-grow outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-dark-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    required
                  />
                  <CheckPasswordMatch />
                </div>
                {formState.error !== '' ? (
                  <span className="text-abx-error">{formState.error}</span>
                ) : (
                  <span className="text-abx-error">&nbsp;</span>
                )}
                {ValidateRegistration() ? (
                  <button
                    id="signUp"
                    className={`${formState.loading ? 'btn-disabled' : 'btn-dark-blue'} mt-12 mb-4`}
                    onClick={authenticate.signUp}
                  >
                    <div className="flex flex-row items-center justify-center align-middle">
                      {formState.loading && <SpinnerIcon />}
                      <span>Register</span>
                    </div>
                  </button>
                ) : (
                  <button id="signUp" className="btn-disabled mt-12 mb-4">
                    <div className="flex flex-row items-center justify-center align-middle">
                      <span>Register</span>
                    </div>
                  </button>
                )}

                <div className="flex flex-row items-center justify-start pt-2">
                  <div className="text-abx-dark-blue" htmlFor="t-and-c">
                    By registering you agree to the{' '}
                    <a
                      href="https://alchemybox.io/terms"
                      target="_blank"
                      className="pink-link mr-2"
                    >
                      terms and conditions
                    </a>
                    {''}
                    and{' '}
                    <a
                      href="https://alchemybox.io/privacy"
                      target="_blank"
                      className="pink-link mr-2"
                    >
                      privacy policy
                    </a>
                  </div>
                  {/*
                  <input
                    className="checked:bg-abx-pink checked:border-transparent"
                    type="checkbox"
                    id="t-and-c"
                    name="t-and-c"
                    checked={tnc}
                    onChange={(e) => setTnc(e.target.checked)}
                    value="accept"
                  />
                  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {formType === 'signIn' && (
        <div className="bg-abx-light-grey px-4 pb-4 pt-4 lg:px-12 lg:pb-12 lg:pt-4 h-screen">
          <div className="flex">
            <div className="flex-grow" />
            <div className="text-abx-dark-blue text-base mb-6 pr-4 flex-none">
              Don't have an account?{' '}
              <span className="pink-link" onClick={authenticate.toggleSignIn}>
                Sign Up
              </span>
            </div>
          </div>
          <div className="flex flex-row text-white justify-center align-middle items-center lg:h-container bg-abx-dark-blue">
            <div className="hidden bg-abx-dark-blue h-full w-1/2 p-12 md:block">
              <img
                className="relative sm:absolute sm:top-28 align-top h-auto w-16 mb-4"
                src={Logo}
              />
              <span className="text-5xl lg:text-6xl font-semibold leading-tight lg:leading-normal">
                Meet your CFO in the cloud
              </span>
              <img className="object-scale-down h-auto w-full mt-4 max-w-md" src={Clouds} />
            </div>
            <div className="bg-white w-full h-full md:w-1/2 flex-1 pb-12">
              <div className="flex flex-col m-12">
                <img className="h-auto w-16 mb-4 md:hidden" src={BlueLogo} />
                <span className="text-abx-dark-blue font-regular text-xl">
                  Welcome to Alchemy Box
                </span>
                <span className="text-abx-dark-blue font-bold text-4xl mt-2 mb-12">
                  Welcome Back!
                </span>

                <div className="flex flex-col items-center justify-center">
                  <img
                    className="h-auto w-56 mb-4 object-center"
                    onClick={() => authenticate.federatedSignIn('Google')}
                    src={Google}
                  />
                  <img
                    className="h-auto w-56 mb-8 object-center"
                    onClick={() => authenticate.federatedSignIn('Facebook')}
                    src={FB}
                  />
                </div>
                <input
                  placeholder="Email"
                  className="my-1 pt-4 pb-2 outline-none focus:outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-dark-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
                  name="email"
                  autoComplete="email"
                  onChange={handleChange}
                  required
                />
                <input
                  id="current-password"
                  className="my-1 pt-4 pb-2 outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-dark-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  required
                />
                <div className="flex">
                  <div className="flex-grow" />
                  <span
                    className="flex-none pink-link text-sm"
                    onClick={authenticate.showForgottenPassword}
                  >
                    Forgotten Password
                  </span>
                </div>
                {formState.error !== '' ? (
                  <span className="text-abx-error">{formState.error}</span>
                ) : (
                  <span className="text-abx-error">&nbsp;</span>
                )}

                <button
                  id="signIn"
                  className={`${formState.loading ? 'btn-disabled' : 'btn-dark-blue'} mt-12 mb-4`}
                  onClick={authenticate.signIn}
                >
                  <div className="flex flex-row items-center justify-center align-middle">
                    {formState.loading && <SpinnerIcon />}
                    <span>Let's Go</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {formType === 'forgotPassword' && (
        <div className="bg-abx-light-grey px-4 pb-4 pt-4 lg:px-12 lg:pb-12 lg:pt-4 h-screen">
          <div className="flex">
            <div className="flex-grow" />
            <div className="text-abx-dark-blue text-base mb-6 pr-4 flex-none">
              Already have an account?{' '}
              <span className="pink-link" onClick={authenticate.toggleSignIn}>
                Sign In
              </span>
            </div>
          </div>
          <div className="flex flex-row text-white justify-center align-middle items-center md:h-full bg-abx-dark-blue">
            <div className="hidden bg-abx-dark-blue h-full w-1/2 p-12 pt-24 md:block">
              <img
                className="relative sm:absolute sm:top-28 align-top h-auto w-16 mb-4"
                src={Logo}
              />
              <span className="text-5xl md:text-6xl font-semibold leading-tight lg:leading-normal mt-16">
                Meet your CFO in the cloud
              </span>
              <img className="object-scale-down h-auto w-full mt-4 max-w-md" src={Clouds} />
            </div>
            <div className="bg-white w-full h-full min-h-full md:w-1/2 flex-1">
              <div className="flex flex-col m-12">
                <img className="h-auto w-16 mb-4 md:hidden" src={BlueLogo} />
                <span className="text-abx-dark-blue font-regular text-xl">
                  Welcome to Alchemy Box
                </span>
                <span className="text-abx-dark-blue font-bold text-4xl mt-2 mb-12">
                  Forgotten Password?
                </span>

                <span className="text-abx-dark-blue mb-12">
                  If you've forgotten your password, no need to worry, just enter your email address
                  below and we'll set you a reset code.
                </span>
                <div className="flex flex-row items-center justify-center">
                  <input
                    placeholder="Email"
                    className="pt- pb-2 flex-grow outline-none focus:outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-dark-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
                    name="email"
                    value={formState.email}
                    autoComplete="email"
                    onChange={handleChange}
                    required
                  />
                  <ValidateEmail />
                </div>

                {formState.error !== '' ? (
                  <span className="text-abx-error">{formState.error}</span>
                ) : (
                  <span className="text-abx-error">&nbsp;</span>
                )}
                {validator.isEmail(formState.email) && formState.email !== '' ? (
                  <button
                    id="signUp"
                    className="btn-dark-blue mt-12 mb-4"
                    onClick={authenticate.forgottenPassword}
                  >
                    Submit
                  </button>
                ) : (
                  <button id="signUp" className="btn-disabled mt-12 mb-4">
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {formType === 'confirmForgottenPassword' && (
        <div className="bg-abx-light-grey px-4 pb-4 pt-4 lg:px-12 lg:pb-12 lg:pt-4 h-screen">
          <div className="flex">
            <div className="flex-grow" />
            <div className="text-abx-dark-blue text-base mb-6 pr-4 flex-none">
              Already have an account?{' '}
              <span className="pink-link" onClick={authenticate.toggleSignIn}>
                Sign In
              </span>
            </div>
          </div>
          <div className="flex flex-row text-white justify-center align-middle items-center md:h-full bg-abx-dark-blue">
            <div className="hidden bg-abx-dark-blue h-full w-1/2 p-12 pt-24 md:block">
              <img
                className="relative sm:absolute sm:top-28 align-top h-auto w-16 mb-4"
                src={Logo}
              />
              <span className="text-5xl lg:text-6xl font-semibold leading-tight lg:leading-normal">
                Meet your CFO in the cloud
              </span>
              <img className="h-auto w-full mt-4 max-w-md" src={Clouds} />
            </div>
            <div className="bg-white w-full h-full min-h-full md:w-1/2 flex-1">
              <div className="flex flex-col m-12">
                <img className="h-auto w-16 mb-4 md:hidden" src={BlueLogo} />
                <span className="text-abx-dark-blue font-regular text-xl">
                  Welcome to Alchemy Box
                </span>
                <span className="text-abx-dark-blue font-bold text-4xl mt-2 mb-12">
                  Reset your password
                </span>

                <div className="flex flex-row items-center justify-center">
                  <input
                    placeholder="Email"
                    className="my-1 pt-4 pb-2 flex-grow outline-none focus:outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-dark-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
                    name="email"
                    autoComplete="email"
                    value={formState.email}
                    onChange={handleChange}
                    required
                  />
                  <ValidateEmail />
                </div>
                <div className="flex flex-row items-center justify-center">
                  <input
                    id="code"
                    className="my-1 pt-4 pb-2 flex-grow outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-dark-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
                    type="text"
                    name="authCode"
                    placeholder="Code"
                    autoComplete="one-time-code"
                    onChange={handleChange}
                    required
                  />
                  <ValidateCode />
                </div>
                <div className="flex flex-row items-center justify-center">
                  <input
                    id="new-password"
                    className="my-1 pt-4 pb-2 flex-grow outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-dark-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
                    type="password"
                    name="password"
                    placeholder="New Password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    required
                  />
                  <ValidatePassword />
                </div>

                {formState.error !== '' ? (
                  <span className="text-abx-error">{formState.error}</span>
                ) : (
                  <span className="text-abx-error">&nbsp;</span>
                )}
                {formState.password !== '' && formState.authCode !== '' ? (
                  <button
                    id="resetPasswordBtn"
                    className="btn-dark-blue mt-12 mb-4"
                    onClick={authenticate.confirmForgottenPassword}
                  >
                    Change Password
                  </button>
                ) : (
                  <button
                    id="changePwd"
                    className={`${formState.loading ? 'btn-disabled' : 'btn-dark-blue'} mt-12 mb-4`}
                    onClick={authenticate.confirmForgottenPassword}
                  >
                    <div className="flex flex-row items-center justify-center align-middle">
                      {formState.loading && <SpinnerIcon />}
                      <span>Change Password</span>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {formType === 'signedIn' && loggedIn && (
        <AuthContext.Provider value={{ ...profile, signOut: logout }}>
          {/*
          <div className="m-8 flex flex-row items-center align-middle">
            <div>
              <span className="text-abx-dark-blue font-semibold text-xl">Logged In</span>
              <br />
              <span className="pink-link" onClick={() => authenticate.signOut()}>
                Sign out
              </span>
              <div />
            </div>
            <div>
              <button className="btn-blue ml-12" onClick={apiTest}>
                Test API
              </button>
            </div>
            <span className="text-abx-dark-blue text-lg ml-8">{test}</span>
          </div>
          */}
          {children}
        </AuthContext.Provider>
      )}

      {formType === 'confirmSignUp' && (
        <div className="bg-abx-light-grey px-4 pb-4 pt-4 lg:px-12 lg:pb-12 lg:pt-4 h-screen">
          <div className="flex">
            <div className="flex-grow" />
            <div className="text-abx-dark-blue text-base mb-6 pr-4 flex-none">
              Don't have an account?{' '}
              <span className="pink-link" onClick={authenticate.toggleSignIn}>
                Sign Up
              </span>
            </div>
          </div>
          <div className="flex flex-row text-white justify-center align-middle items-center lg:h-container bg-abx-dark-blue">
            <div className="hidden bg-abx-dark-blue h-full w-1/2 p-12 md:block">
              <img
                className="relative sm:absolute sm:top-28 align-top h-auto w-16 mb-4"
                src={Logo}
              />
              <span className="text-5xl lg:text-6xl font-semibold leading-tight lg:leading-normal">
                Meet your CFO in the cloud
              </span>
              <img className="h-auto w-full mt-4 max-w-md" src={Clouds} />
            </div>
            <div className="bg-white w-full h-full md:w-1/2 flex-1">
              <div className="flex flex-col m-12">
                <img className="h-auto w-16 mb-4 md:hidden" src={BlueLogo} />
                <span className="text-abx-dark-blue font-regular text-xl">
                  Welcome to Alchemy Box
                </span>
                <span className="text-abx-dark-blue font-bold text-4xl mt-2 mb-12">
                  Welcome Back!
                </span>

                <div className="flex flex-col items-center justify-center">
                  <img className="h-auto w-56 mb-4 object-center" src={Google} />
                  <img className="h-auto w-56 mb-8 object-center" src={FB} />
                </div>
                <span className="text-abx-dark-blue">
                  Please enter the code you recieved in your email and your new password, if you
                  didn't recieve a code check that it is not in a spam folder.
                </span>
                <input
                  placeholder="Confirmation Code"
                  type="text"
                  className="my-1 pt-4 pb-2 outline-none focus:outline-none text-abx-dark-blue border-abx-light-blue placeholder-abx-dark-blue hover:border-abx-pink focus:border-abx-dark-blue border-b-2 focus-within:border-abx-dark-blue"
                  name="authCode"
                  inputMode="numeric"
                  autoComplete="one-time-code"
                  pattern="[0-9]*"
                  onChange={handleChange}
                  required
                />

                <div className="flex">
                  <div className="flex-grow" />
                  <span
                    className="flex-none pink-link text-sm"
                    onClick={authenticate.resendConfirmation}
                  >
                    Didn't get a code?
                  </span>
                </div>
                <button
                  id="confirmSignIn"
                  className={`${formState.loading ? 'btn-disabled' : 'btn-dark-blue'} mt-12 mb-4`}
                  onClick={authenticate.confirmSignUp}
                >
                  <div className="flex flex-row items-center justify-center align-middle">
                    {formState.loading && <SpinnerIcon />}
                    <span>Let's Go</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AuthProvider;
