import React from "react";
import { Skeleton } from "@material-ui/core";
import "@material-ui/lab";
import { Box, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => {});
const array = new Array(4);
export default function IntegrationSkeleton() {
  const classes = useStyles();
  return (
    <div>
      {[1, 2].map((item) => (
        <Box key={uuidv4()} my={3}>
          <Box mb={1}>
            <Skeleton variant="rect" width={200} />
          </Box>
          <Grid container spacing={8}>
            {array.fill(1).map((image, imageIndex) => (
              <Grid key={uuidv4()} item xs={6} sm={4} md={3} lg={3} xl={2}>
                <Paper
                  key={uuidv4()}
                  variant="outlined"
                  className={classes.paper}
                >
                  <Box key={uuidv4()}>
                    <Skeleton variant="rect" height="100px" width="100%" />
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </div>
  );
}
