import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Logo from '../Components/assets/ABx Logo.png';
import Background from '../Components/assets/ErrorPage.svg';
import { Link } from 'react-router-dom';
import { LongButton } from '../Components/LongButton';

const useStyles = makeStyles((theme) => ({
  root: {
    background: `${theme.palette.primary.light} url(${Background}) no-repeat fixed center`,
    backgroundSize: 'auto 90%',
    minHeight: '100vh'
  },
  overlay: {
    position: 'fixed', //Sit on the top of the page contect
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.65)',
    zIndex: 2
    // cursor: "pointer",
  },
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  errorText: {
    color: '#FFFFFF',
    fontSize: '2rem'
  },
  footerErrorText: {
    color: '#FFFFFF'
  },
  footer: {
    position: 'absolute',
    display: 'flex',
    bottom: theme.spacing(3)
  }
}));

interface ErrorProps {
  message?: string;
}
export default function Error({ message }: ErrorProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.overlay}>
        <div className={classes.container}>
          <div style={{ position: 'absolute', top: 38, left: 38 }}>
            <Link to="/">
              <img src={Logo} alt="Logo" width={39} height={45} />
            </Link>
          </div>
          <Typography
            variant="h1"
            color="textSecondary"
            align="center"
            className={classes.errorText}
          >
            {message === undefined ? 'Oh No! Something went wrong!' : message}
          </Typography>
          <LongButton
            variant="contained"
            color="primary"
            style={{ marginTop: 40 }}
            onClick={() => {
              window.history.back();
            }}
          >
            Go Back
          </LongButton>
          <div className={classes.footer}>
            <Typography color="textSecondary" className={classes.footerErrorText}>
              Keep getting an error? Contact us at
            </Typography>
            &nbsp;
            <a href="mailto:support@alchemybox.io" style={{ textDecoration: 'none' }}>
              <Typography color="secondary">support@alchemybox.io</Typography>
            </a>{' '}
          </div>
        </div>
      </div>
    </div>
  );
}
