import { createStyles, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import Autocomplete from '../../../../../Components/Autocomplete';
import { RepeatPopout } from '../../../../../Components/FormElements/RepeatPopout';
import TagField from '../../../../../Components/FormElements/TagInput';
import {
  TransactionInfoInput,
  useGetTransactionInfoQuery,
  useUpdateTransactionInfoMutation
} from '../../../../../generated/graphql-frontend';
import { CATEGORIES } from '../Consts';
import TransactionLoading from './TransactionLoading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      padding: theme.spacing(10)
    },

    transactionNumber: {
      borderRadius: 10,
      padding: '1px 12px 1px 12px',
      marginLeft: theme.spacing(2.5),
      backgroundColor: '#0D8ABC',
      color: '#FFF',
      fontSize: '1.2rem',
      fontWeight: 500
    }
  })
);

export default function TransactionTable(): ReactElement {
  const classes = useStyles();
  const { company_id } = useParams<CompanyIdProps>();
  const { data, loading } = useGetTransactionInfoQuery({
    variables: { company_id }
  });
  const [sortedRowData, setSortedRowData] = React.useState<TransactionInfo[]>();

  const rowData = data?.user?.company?.transaction_info;

  React.useEffect(() => {
    if (rowData && !sortedRowData) {
      const tempRowData = [...rowData].sort(sortByNew);
      setSortedRowData(tempRowData);
    }
  }, [rowData, sortedRowData]);

  console.log('row data ==>', rowData);
  if (loading) return <TransactionLoading />;
  if (!rowData) return <div>No Transaction infomation data</div>;

  return (
    <div className={classes.root}>
      <div className="bg-white rounded-xl mt-8 p-12">
        <div>
          <Typography variant="subtitle1" id="tableTitle">
            Transactions
            {loading && 'Updatting'}
            <span className={classes.transactionNumber}>
              <b>{rowData !== undefined ? rowData.length : 0}</b>
            </span>
          </Typography>
        </div>
        <hr className="my-8" />
        <table className="w-full grid">
          <thead>
            <tr className="flex flex-row items-center justify-center text-left">
              <th className="w-1/4 mr-4"></th>
              <th className="w-1/4 mr-2">Display Name</th>
              <th className="w-1/4 mr-2">Category</th>
              <th className="w-1/4 mr-2">Repeats</th>
            </tr>
          </thead>

          <tbody>
            {sortedRowData?.map((row, index) => (
              <Row
                key={`${row.transaction_name}-${company_id}`}
                company_id={company_id}
                row={row}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

interface RowProps {
  company_id: string;
  row: TransactionInfo;
}
const Row = React.memo(({ company_id, row }: RowProps) => {
  const [transactionInput, setTransactionInput] = React.useState<TransactionInfoInput>();

  const [updateTransactionInfo] = useUpdateTransactionInfoMutation();
  const tempTransactionInput = { company_id, transaction_name: row.transaction_name };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (transactionInput)
        updateTransactionInfo({
          variables: { input: transactionInput },
          update: (cache, updatedTransactionInfo) => {
            const updatedTransactionItem = updatedTransactionInfo.data?.updateTransactionInfo;
            if (updatedTransactionItem && updatedTransactionItem?.object) {
              const cacheId = cache.identify(updatedTransactionItem.object);
              cache.modify({
                fields: {
                  transactionInfo: (existingTransactionRefs = [], { toReference }) => {
                    if (cacheId) return [...existingTransactionRefs, toReference(cacheId)];
                  }
                }
              });
            }
          }
        });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [transactionInput, updateTransactionInfo]);

  const handleTagChange = (tags: string[]) => {
    setTransactionInput((prev) => ({
      ...(prev ? prev : tempTransactionInput),
      tags: tags
    }));
  };

  return (
    <React.Fragment key={`${row.transaction_name}-wrapper`}>
      <tr className={` p-4 flex flex-row items-center justify-center `} key={row.transaction_name}>
        <td className="w-1/4 flex flex-row items-center mr-4">
          {row.new && <div className="w-2 h-2 mr-4 bg-abx-orange" />}
          <span className="text-abx-dark-blue font-semibold">{row.transaction_name}</span>
        </td>
        <td className="w-1/4 mr-2 text-left pr-4">
          <b>
            <TextField
              fullWidth
              id={String(row.transaction_name)}
              variant="standard"
              value={transactionInput?.display_name ?? row.display_name}
              onChange={(e) =>
                setTransactionInput((prev) => ({
                  ...(prev ? prev : tempTransactionInput),
                  display_name: e.target.value
                }))
              }
            />
          </b>
        </td>
        <td className="w-1/4 mr-2 text-left">
          <Autocomplete
            id={String(row.transaction_name)}
            options={CATEGORIES}
            variant="outlined"
            defaultValue={row.category}
            value={transactionInput?.category}
            minWidth={100}
            onChange={(event, value) =>
              value &&
              setTransactionInput((prev) => ({
                ...(prev ? prev : tempTransactionInput),
                category: value as string
              }))
            }
          />
        </td>
        <td className="w-1/4 mr-2 text-left justify-items-center align-middle">
          <RepeatPopout
            button_text="Repeats"
            default_value_period={row.recurring ?? ''}
            default_value_endDate={row.recurring_end_date ?? ''}
            colour="white"
            endDate={transactionInput?.recurring_end_date ?? row.recurring_end_date ?? ''}
            period={transactionInput?.recurring ?? row.recurring ?? ''}
            onEndDateChange={(date) =>
              setTransactionInput((prev) => ({
                ...(prev ? prev : tempTransactionInput),
                recurring_end_date: date?.toDateString()
              }))
            }
            onPeriodChange={(event, value) =>
              setTransactionInput((prev) => {
                if (value) {
                  return {
                    ...(prev ? prev : tempTransactionInput),
                    recurring: value as string
                  };
                } else {
                  console.log('no selected value');
                }
              })
            }
          />
        </td>
      </tr>
      <tr>
        <td className={`pt-2 pb-4 px-4 `}>
          <TagField
            key={`${transactionInput?.transaction_name}-tag`}
            initialValues={row.tags}
            suggestedValues={row.tags}
            value={transactionInput?.tags}
            onChange={handleTagChange}
          />
        </td>
      </tr>
    </React.Fragment>
  );
});

const sortByNew = (a: TransactionInfo, b: TransactionInfo) => {
  if (a.new === true && b.new === false) {
    return -1;
  } else if (a.new === false && b.new === true) {
    return 1;
  }
  return 0;
};
