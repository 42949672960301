import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { ReactElement } from "react";
import DropBox from "./DropBox";
import Google from "./Google";
import OneDrive from "./OneDrive";

interface Props {
  providerName: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // flexGrow: 1,
      flexBasis: "100%",
    },
  })
);
export default function ImportFile({ providerName }: Props): ReactElement {
  const getProvider = (providerName: string) => {
    switch (providerName) {
      case "google":
        return <Google />;
      case "dropbox":
        return <DropBox />;
      case "microsoft":
        return <OneDrive />;
      default:
        break;
    }
  };
  const classes = useStyles();
  return <div className={classes.root}>{providerName && getProvider(providerName)}</div>;
}
