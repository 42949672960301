import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useCompanyStore } from '../../../Context/GlobalStore';
import { AuthContext } from '../../../Context/Login';
import { useGetProfileQuery } from '../../../generated/graphql-frontend';
import Loading from '../../../Loading';
import MoreIconMenu from './MoreIconMenu';

var randomColor = require('randomcolor');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    boxShadow: 'none'
  },
  table: {
    minWidth: 700
  },
  tableRow: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'transparent',
      '& > .MuiTableCell-root': {
        color: theme.palette.primary.main
      }
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  container: {
    '&:hover': {
      backgroundColor: '#f7f7f8'
    },
    cursor: 'pointer'
  }
}));

interface CompanyListProps {
  companies: any[];
  loading: boolean;
}
export default function CompanyList({ companies, loading }: CompanyListProps) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const user = useContext(AuthContext);
  const profileColour = randomColor({
    luminosity: 'light',
    hue: 'random'
  });

  const { data: profile } = useGetProfileQuery();

  const { setCurrentCompanyId, setCompanyName, setProfile } = useCompanyStore();

  useEffect(() => {
    setCurrentCompanyId(null);
    setCompanyName('');
    setProfile({
      user_id: '',
      first_name: '',
      last_name: '',
      email: '',
      timezone: '',
      currency: '',
      profile_colour: ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompanyClick = (id: string, name: string, profile: any) => {
    setCurrentCompanyId(id);
    setCompanyName(name);
    setProfile({
      user_id: profile?.user.id,
      first_name: profile?.user.first_name,
      last_name: profile?.user.last_name,
      email: profile?.user.email,
      timezone: profile?.user.time_zone,
      currency: profile?.user.currency,
      profile_colour: profileColour
    });
    history.push(`/data/${id}`);
  };

  if (loading) return <Loading />;
  return (
    <div className={classes.root}>
      {companies?.map((detail, index) => {
        return (
          <Box
            key={uuidv4()}
            bgcolor={theme.palette.background.default}
            m={2}
            p={5}
            borderRadius={2}
            display="flex"
            alignItems="center"
            onClick={() => handleCompanyClick(detail.id, detail.name, profile)}
            className={classes.container}
          >
            <Box key={uuidv4()}>
              <Box fontWeight="fontWeightBold" m={1}>
                <Typography color="primary.dark" variant="subtitle1">
                  {detail.name}
                </Typography>
                <Typography color="primary.dark" variant="body1">
                  {detail.description}
                </Typography>
                <Box mt={4}>
                  <Typography color="primary.dark" variant="labelText">
                    last accessed: {detail.last_accessed}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box marginRight={0} marginLeft="auto" key={uuidv4()}>
              <MoreIconMenu
                key={uuidv4()}
                id={detail.id}
                role={
                  detail.company_users &&
                  detail.company_users.find((item: any) => item.request_email === user?.email)?.role
                }
                detail={detail}
                email={user?.email}
              />
            </Box>
          </Box>
        );
      })}
    </div>
  );
}
