export const COUNTRIES = [
  {
    country: "Australia",
    iso_country: "AUS",
    currency_name: "Australian Dollar",
    currency_code: "AUD",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "Australian Dollar ($)",
  },
  {
    country: "Bulgaria",
    iso_country: "BGR",
    currency_name: "Bulgarian Lev",
    currency_code: "BGN",
    currency_symbol: "Лв",
    currency_suffix: true,
    currency_display_name: "Bulgarian Lev (Лв)",
  },
  {
    country: "Brazil",
    iso_country: "BRA",
    currency_name: "Brazilian Real",
    currency_code: "BRL",
    currency_symbol: "R$",
    currency_suffix: true,
    currency_display_name: "Brazilian Real (R$)",
  },
  {
    country: "Canada",
    iso_country: "CAN",
    currency_name: "Canadian Dollars",
    currency_code: "CAD",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "Canadian Dollars ($)",
  },
  {
    country: "Switzerland",
    iso_country: "CHE",
    currency_name: "Swiss Franc",
    currency_code: "CHF",
    currency_symbol: "CHF",
    currency_suffix: false,
    currency_display_name: "Swiss Franc (CHF)",
  },
  {
    country: "China",
    iso_country: "CHN",
    currency_name: "Chinese Yuan",
    currency_code: "CNY",
    currency_symbol: "¥",
    currency_suffix: true,
    currency_display_name: "Chinese Yuan (¥)",
  },
  {
    country: "Czechia",
    iso_country: "CZE",
    currency_name: "Czech Koruna",
    currency_code: "CZK",
    currency_symbol: "Kč",
    currency_suffix: false,
    currency_display_name: "Czech Koruna (Kč)",
  },
  {
    country: "Denmark",
    iso_country: "DNK",
    currency_name: "Danish Krone",
    currency_code: "DKK",
    currency_symbol: "Kr",
    currency_suffix: true,
    currency_display_name: "Danish Krone (Kr)",
  },
  {
    country: "United Kingdom",
    iso_country: "GBR",
    currency_name: "Pound Sterling",
    currency_code: "GBP",
    currency_symbol: "£",
    currency_suffix: true,
    currency_display_name: "Pound Sterling (£)",
  },
  {
    country: "Hong Kong",
    iso_country: "HKG",
    currency_name: "Hong Kong Dollar",
    currency_code: "HKD",
    currency_symbol: "HK$",
    currency_suffix: true,
    currency_display_name: "Hong Kong Dollar (HK$)",
  },
  {
    country: "Croatia",
    iso_country: "HRV",
    currency_name: "Croatian Kuna",
    currency_code: "HRK",
    currency_symbol: "kn",
    currency_suffix: false,
    currency_display_name: "Croatian Kuna (kn)",
  },
  {
    country: "Hungary",
    iso_country: "HUN",
    currency_name: "Hungarian Forint",
    currency_code: "HUF",
    currency_symbol: "Ft",
    currency_suffix: false,
    currency_display_name: "Hungarian Forint (Ft)",
  },
  {
    country: "Indonesia",
    iso_country: "IDN",
    currency_name: "Indonesian Rupiah",
    currency_code: "IDR",
    currency_symbol: "Rp",
    currency_suffix: true,
    currency_display_name: "Indonesian Rupiah (Rp)",
  },
  {
    country: "Israel",
    iso_country: "ISR",
    currency_name: "Israeli New Shekel",
    currency_code: "ILS",
    currency_symbol: "₪",
    currency_suffix: true,
    currency_display_name: "Israeli New Shekel (₪)",
  },
  {
    country: "India",
    iso_country: "IND",
    currency_name: "Indian rupee",
    currency_code: "INR",
    currency_symbol: "₹",
    currency_suffix: true,
    currency_display_name: "Indian rupee (₹)",
  },
  {
    country: "Iceland",
    iso_country: "ISL",
    currency_name: "Icelandic Króna",
    currency_code: "ISK",
    currency_symbol: "krónur",
    currency_suffix: false,
    currency_display_name: "Icelandic Króna (krónur)",
  },
  {
    country: "Japan",
    iso_country: "JPN",
    currency_name: "Japanese Yen",
    currency_code: "JPY",
    currency_symbol: "JP¥",
    currency_suffix: true,
    currency_display_name: "Japanese Yen (JP¥)",
  },
  {
    country: "South Korea",
    iso_country: "KOR",
    currency_name: "South Korean won",
    currency_code: "KRW",
    currency_symbol: "₩",
    currency_suffix: true,
    currency_display_name: "South Korean won (₩)",
  },
  {
    country: "Mexico",
    iso_country: "MEX",
    currency_name: "Mexican Peso",
    currency_code: "MXN",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "Mexican Peso ($)",
  },
  {
    country: "Malaysia",
    iso_country: "MYS",
    currency_name: "Malaysian Ringgit",
    currency_code: "MYR",
    currency_symbol: "RM",
    currency_suffix: true,
    currency_display_name: "Malaysian Ringgit (RM)",
  },
  {
    country: "Norway",
    iso_country: "NOR",
    currency_name: "Norwegian Krone",
    currency_code: "NOK",
    currency_symbol: "kr",
    currency_suffix: false,
    currency_display_name: "Norwegian Krone (kr)",
  },
  {
    country: "New Zealand",
    iso_country: "NZL",
    currency_name: "New Zealand Dollar",
    currency_code: "NZD",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "New Zealand Dollar ($)",
  },
  {
    country: "Philippines",
    iso_country: "PHL",
    currency_name: "Philippine peso",
    currency_code: "PHP",
    currency_symbol: "₱",
    currency_suffix: true,
    currency_display_name: "Philippine peso (₱)",
  },
  {
    country: "Poland",
    iso_country: "POL",
    currency_name: "Polish złoty",
    currency_code: "PLN",
    currency_symbol: "zł",
    currency_suffix: false,
    currency_display_name: "Polish złoty (zł)",
  },
  {
    country: "Romania",
    iso_country: "ROU",
    currency_name: "Romanian leu",
    currency_code: "RON",
    currency_symbol: "lei",
    currency_suffix: false,
    currency_display_name: "Romanian leu (lei)",
  },
  {
    country: "Russia",
    iso_country: "RUS",
    currency_name: "Russian Rouble",
    currency_code: "RUB",
    currency_symbol: "₽",
    currency_suffix: true,
    currency_display_name: "Russian Rouble (₽)",
  },
  {
    country: "Sweden",
    iso_country: "SWE",
    currency_name: "Swedish krona",
    currency_code: "SEK",
    currency_symbol: "kr",
    currency_suffix: false,
    currency_display_name: "Swedish krona (kr)",
  },
  {
    country: "Singapore",
    iso_country: "SGP",
    currency_name: "Singapore dollar",
    currency_code: "SGD",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "Singapore dollar ($)",
  },
  {
    country: "Thailand",
    iso_country: "THA",
    currency_name: "Thai baht",
    currency_code: "THB",
    currency_symbol: "฿",
    currency_suffix: true,
    currency_display_name: "Thai baht (฿)",
  },
  {
    country: "Turkey",
    iso_country: "TUR",
    currency_name: "Turkish lira",
    currency_code: "TRY",
    currency_symbol: "₺",
    currency_suffix: true,
    currency_display_name: "Turkish lira (₺)",
  },
  {
    country: "United States",
    iso_country: "USA",
    currency_name: "United States Dollar",
    currency_code: "USD",
    currency_symbol: "$",
    currency_suffix: true,
    currency_display_name: "United States Dollar ($)",
  },
  {
    country: "South Africa",
    iso_country: "ZAF",
    currency_name: "South African rand",
    currency_code: "ZAR",
    currency_symbol: "R",
    currency_suffix: true,
    currency_display_name: "South African rand (R)",
  },
  {
    country: "Austria",
    iso_country: "AUT",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Belgium",
    iso_country: "BEL",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Cyprus",
    iso_country: "CYP",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Estonia",
    iso_country: "EST",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Finland",
    iso_country: "FIN",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "France",
    iso_country: "FRA",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Germany",
    iso_country: "DEU",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Greece",
    iso_country: "GRC",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Ireland",
    iso_country: "IRL",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Italy",
    iso_country: "ITA",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Latvia",
    iso_country: "LVA",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Lithuania",
    iso_country: "LTU",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Luxembourg",
    iso_country: "LUX",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Malta",
    iso_country: "MLT",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Netherlands",
    iso_country: "NLD",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Portugal",
    iso_country: "PRT",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Slovakia",
    iso_country: "SVK",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Slovenia",
    iso_country: "SVN",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Spain",
    iso_country: "ESP",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "French Guiana",
    iso_country: "GUF",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Guadeloupe",
    iso_country: "GLP",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Martinique",
    iso_country: "MTQ",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Andorra",
    iso_country: "AND",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Monaco",
    iso_country: "MCO",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "San Marino",
    iso_country: "SMR",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Vatican City",
    iso_country: "VAT",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    currency_suffix: true,
    currency_display_name: "Euro (€)",
  },
  {
    country: "Jersey",
    iso_country: "JEY",
    currency_name: "Jersey Pound",
    currency_code: "GBP",
    currency_symbol: "£",
    currency_suffix: true,
    currency_display_name: "Jersey Pound (£)",
  },
  {
    country: "Guernsey",
    iso_country: "GGY",
    currency_name: "Guernsey Pound",
    currency_code: "GBP",
    currency_symbol: "£",
    currency_suffix: true,
    currency_display_name: "Guernsey Pound (£)",
  },
];

export const EMPLOY_NO = ["Just me", "2-5", "5-10", "10-25"];

export const BUSINESS_TYPE = [
  {
    category: "Age restricted services",
    items: "Pharmacies or pharmaceuticals",
  },
  { category: "Age restricted services", items: "Tobacco or cigars" },
  {
    category: "Age restricted services",
    items: "Adult content or services",
  },
  {
    category: "Age restricted services",
    items: "Vapes, e-cigarettes, e-juice or related products",
  },
  {
    category: "Age restricted services",
    items: "Weapons or munitions",
  },
  {
    category: "Age restricted services",
    items: "Supplements or nutraceuticals",
  },
  {
    category: "Age restricted services",
    items: "Marijuana dispensaries",
  },
  {
    category: "Age restricted services",
    items: "Marijuana related products",
  },
  { category: "Age restricted services", items: "Alcohol" },
  { category: "Financial Services", items: "Insurance" },
  {
    category: "Financial Services",
    items: "Security brokers or dealers",
  },
  { category: "Financial Services", items: "Money orders" },
  { category: "Financial Services", items: "Currency exchanges" },
  { category: "Financial Services", items: "Wire transfers" },
  { category: "Financial Services", items: "Cheque cashing" },
  { category: "Financial Services", items: "Loans or lending" },
  { category: "Financial Services", items: "Collections agencies" },
  {
    category: "Financial Services",
    items: "Money services or transmission",
  },
  { category: "Financial Services", items: "Investment services" },
  { category: "Financial Services", items: "Virtual currencies" },
  { category: "Financial Services", items: "Digital Wallets" },
  { category: "Financial Services", items: "Cryptocurrencies" },
  {
    category: "Financial Services",
    items: "Financial information and research",
  },
  {
    category: "Financial Services",
    items: "Other financial institutions",
  },
  { category: "Building Services", items: "General contractors" },
  { category: "Building Services", items: "Electrical contractors" },
  { category: "Building Services", items: "Carpentry contractors" },
  { category: "Building Services", items: "Special trade contractors" },
  { category: "Building Services", items: "Telecom services" },
  { category: "Building Services", items: "Telecom equipment" },
  {
    category: "Building Services",
    items: "Heating, Ventilation and A/C contractors",
  },
  { category: "Building Services", items: "Other building services" },
  {
    category: "Entertainment and Recreation",
    items: "Event ticketing",
  },
  {
    category: "Entertainment and Recreation",
    items: "Tourist attractions",
  },
  {
    category: "Entertainment and Recreation",
    items: "Recreational camps",
  },
  {
    category: "Entertainment and Recreation",
    items: "Musicians, bands or orchestras",
  },
  {
    category: "Entertainment and Recreation",
    items: "Amusement parks, carnivals or circuses",
  },
  {
    category: "Entertainment and Recreation",
    items: "Fortune tellers",
  },
  { category: "Entertainment and Recreation", items: "Cinemas" },
  {
    category: "Entertainment and Recreation",
    items: "Betting or fantasy sports",
  },
  { category: "Entertainment and Recreation", items: "Lotteries" },
  {
    category: "Entertainment and Recreation",
    items: "Sports forecasting",
  },
  {
    category: "Entertainment and Recreation",
    items: "Prediction services",
  },
  {
    category: "Entertainment and Recreation",
    items: "Online gambling",
  },
  {
    category: "Entertainment and Recreation",
    items: "Other entertainment and recreation",
  },
  { category: "Education", items: "Child care services" },
  { category: "Education", items: "Colleges and services" },
  {
    category: "Education",
    items: "Vocational schools or trade schools",
  },
  { category: "Education", items: "Elementary or secondary schools" },
  { category: "Education", items: "Other education services" },
  { category: "Medical Services", items: "Medical devices" },
  { category: "Medical Services", items: "Doctors and physicians" },
  { category: "Medical Services", items: "Opticians and eyeglasses" },
  { category: "Medical Services", items: "Dentists and orthodontists" },
  { category: "Medical Services", items: "Chiropractors" },
  { category: "Medical Services", items: "Nursing or personal care" },
  { category: "Medical Services", items: "Hospitals" },
  {
    category: "Medical Services",
    items: "Personal fundraising or crowdfunding",
  },
  { category: "Medical Services", items: "Mental Health Services" },
  { category: "Medical Services", items: "Assisted living" },
  { category: "Medical Services", items: "Veterinary services" },
  { category: "Medical Services", items: "Medical organisations" },
  {
    category: "Medical Services",
    items: "Telemedicine and telehealth",
  },
  { category: "Medical Services", items: "Other medical services" },
  { category: "Travel and Tourism", items: "Property rental" },
  { category: "Travel and Tourism", items: "Hotels, inns or motels" },
  { category: "Travel and Tourism", items: "Timeshares" },
  { category: "Travel and Tourism", items: "Other travel and tourism" },
  { category: "Transportation", items: "Ridesharing" },
  { category: "Transportation", items: "Taxis and limos" },
  { category: "Transportation", items: "Courier services" },
  { category: "Transportation", items: "Parking" },
  { category: "Transportation", items: "Travel agencies" },
  { category: "Transportation", items: "Freight forwarders" },
  { category: "Transportation", items: "Shipping services" },
  { category: "Transportation", items: "Commuter transport" },
  { category: "Transportation", items: "Cruise lines" },
  { category: "Transportation", items: "Airlines and air carriers" },
  { category: "Transportation", items: "Other transport services" },
  { category: "Personal Services", items: "Photography studios" },
  { category: "Personal Services", items: "Health and beauty spas" },
  { category: "Personal Services", items: "Salons or barbers" },
  { category: "Personal Services", items: "Landscaping services" },
  { category: "Personal Services", items: "Massage services" },
  { category: "Personal Services", items: "Counselling services" },
  {
    category: "Personal Services",
    items: "Health and wellness coaching",
  },
  {
    category: "Personal Services",
    items: "Laundry or cleaning services",
  },
  { category: "Personal Services", items: "Dating services" },
  { category: "Personal Services", items: "Other personal services" },
  {
    category: "Membership Organisations",
    items: "Social associations",
  },
  {
    category: "Membership Organisations",
    items: "Charities or services organisations",
  },
  {
    category: "Membership Organisations",
    items: "Religious Organisations",
  },
  { category: "Membership Organisations", items: "Country clubs" },
  {
    category: "Membership Organisations",
    items: "Political Organisations",
  },
  {
    category: "Membership Organisations",
    items: "Other membership organisations",
  },
  { category: "Professional Services", items: "Consulting" },
  {
    category: "Professional Services",
    items: "Printing and Publishing",
  },
  { category: "Professional Services", items: "Attorneys and Lawyers" },
  { category: "Professional Services", items: "Bankruptcy Services" },
  { category: "Professional Services", items: "Bail Bonds" },
  {
    category: "Professional Services",
    items: "Accounting, auditing or tax prep",
  },
  { category: "Professional Services", items: "Computer repair" },
  { category: "Professional Services", items: "Testing Laboratories" },
  { category: "Professional Services", items: "Auto services" },
  { category: "Professional Services", items: "car rentals" },
  { category: "Professional Services", items: "Car sales" },
  { category: "Professional Services", items: "Lead generation" },
  { category: "Professional Services", items: "Direct marketing" },
  { category: "Professional Services", items: "Utilities" },
  { category: "Professional Services", items: "Government Services" },
  { category: "Professional Services", items: "Telemarketing" },
  {
    category: "Professional Services",
    items: "Credit counselling or credit repair",
  },
  {
    category: "Professional Services",
    items: "Mortgage consulting services",
  },
  {
    category: "Professional Services",
    items: "Debt reduction services",
  },
  { category: "Professional Services", items: "Warranty Services" },
  {
    category: "Professional Services",
    items: "Other Marketing Services",
  },
  {
    category: "Professional Services",
    items: "Other Business services",
  },
  { category: "Food and Drink", items: "Restaurants and Nightlife" },
  { category: "Food and Drink", items: "Grocery Stores" },
  { category: "Food and Drink", items: "Caterers" },
  { category: "Food and Drink", items: "Other food and dining" },
  { category: "Digital Products", items: "SAAS" },
  { category: "Digital Products", items: "Apps" },
  { category: "Digital Products", items: "Books" },
  { category: "Digital Products", items: "Music or other media" },
  { category: "Digital Products", items: "Games" },
  { category: "Digital Products", items: "Blogs" },
  { category: "Digital Products", items: "Influencer" },
  { category: "Digital Products", items: "Other" },
  { category: "Retail", items: "Software" },
  { category: "Retail", items: "Clothing and accessories" },
  { category: "Retail", items: "Convenience Stores" },
  { category: "Retail", items: "Beauty products" },
  { category: "Retail", items: "Home goods and furniture" },
  { category: "Retail", items: "Home electronics" },
  { category: "Retail", items: "Auto parts and accessories" },
  { category: "Retail", items: "Jewellery and accessories" },
  { category: "Retail", items: "Other merchandise" },
];
