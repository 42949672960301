import { makeStyles, Skeleton, Theme } from "@material-ui/core";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  skeleton: {
    marginTop: 5,
  },
}));
export default function TransactionLoading(): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {Array(5)
        .fill(1)
        .map((item, index) => (
          <Skeleton
            key={index}
            className={classes.skeleton}
            variant="rectangular"
            height="10vh"
          />
        ))}
    </div>
  );
}
