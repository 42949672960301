import { Box, Dialog, IconButton } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  useCompanyUsageQuery,
  useIsFirstUserQuery,
  useLastAccessedCompanyMutation
} from '../../generated/graphql-frontend';
import { isEmpty } from '../../utils/isEmpty';
import Welcome from './Welcome';

interface WelcomeDialogProps {
  open?: boolean;
  company_id: string;
  toggleOpen?: () => void;
}
export default function WelcomeDialog({ company_id }: WelcomeDialogProps): ReactElement {
  const [openCarousel, setOpenCarousel] = useState(false);

  const { data, loading, error, refetch } = useIsFirstUserQuery({
    variables: { company_id: company_id }
  });

  const [updateLastAccess] = useLastAccessedCompanyMutation({
    variables: { companyId: company_id }
  });
  const {
    data: companyUsageData,
    loading: companyUsageLoading,
    error: companyUsageError
  } = useCompanyUsageQuery({
    variables: { company_id: company_id }
  });

  const firstUser = data?.user?.companies && data.user.companies[0]?.first_time_user;
  const role = companyUsageData?.user?.companies?.find((item) => !isEmpty(item))?.role;

  // Open carousel for the
  useEffect(() => {
    setOpenCarousel(firstUser ? firstUser : false);
  }, [firstUser]);

  if (loading || companyUsageLoading || !role) return <div></div>;
  if (error || companyUsageError) return <div>Error!</div>;

  const handleClose = async () => {
    try {
      await updateLastAccess().then((res) => {
        if (res.data?.lastAccessedCompany?.status) {
          refetch();
          setOpenCarousel(false);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={openCarousel}>
      <Box position="relative" mx={10} my={6}>
        <Box position="absolute" top={-20} right={-20}>
          <IconButton onClick={() => handleClose()}>
            <Cancel />
          </IconButton>
        </Box>
        <Welcome role={role} />
      </Box>
    </Dialog>
  );
}
