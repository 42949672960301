import { Box } from "@material-ui/core";
import React from "react";
import BasicContainer from "../../Containers/BasicContainer";
import Header from "../../Containers/Header";
import CashInBankChart from "./CashInBankChart";
import ScenarioLists from "./ScenarioLists";

export default function Scenarios() {
  return (
    <Box display="flex" flexDirection="column">
      <Header title="Scenarios" description="See the impact of your business decisions before you make them" />
      <BasicContainer>
        <CashInBankChart />
        <Box marginTop={10}>
          <ScenarioLists />
        </Box>
      </BasicContainer>
    </Box>
  );
}
