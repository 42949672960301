import { useQuery } from '@apollo/client';
import { Box, Button } from '@material-ui/core';
import { lighten, makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import ABTypography from '../../../Components/ABTypography';
import { useCompanyStore } from '../../../Context/GlobalStore';
import { GetAccountDocument, useDeleteAccountMutation } from '../../../generated/graphql-frontend';
import BankDeleteConfirm from '../Components/BankDeleteConfirm';
import BankMoreMenu from '../Components/BankMoreMenu';
import BankTableSkeleton from './BankTableSkeleton';
import { ABxNotificationOpen, ClearCallback, ABxNotificationClose } from '../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Sent from '../../../Components/assets/message_sent.svg';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', string: true, disablePadding: false, label: 'Name' },
  { id: 'inst', string: true, disablePadding: false, label: 'Institution' },
  { id: 'type', string: true, disablePadding: false, label: 'Type' },
  {
    id: 'carbs',
    string: true,
    disablePadding: false,
    label: 'Current Balance'
  },
  {
    id: 'last_updated',
    string: true,
    disablePadding: false,
    label: 'Last Updated'
  }
];

const TableCell = withStyles({
  root: {
    borderColor: '#F9F9F9'
  }
})(MuiTableCell);

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.string ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  companyNum: {
    borderRadius: 10,
    padding: '1px 12px 1px 12px',
    marginLeft: theme.spacing(2.5),
    backgroundColor: '#0D8ABC',
    color: '#FFF',
    fontSize: '1.2rem',
    fontWeight: 500
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    display: 'flex',
    justifyItems: 'space-between'
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numAccounts = 0, setOpenModal } = props;

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.titleContainer}>
        <ABTypography variant="subtitle1" id="tableTitle">
          Accounts{' '}
          <span className={classes.companyNum}>
            <b>{numAccounts}</b>
          </span>
        </ABTypography>
        <br />
      </div>
      <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
        Add bank Account
      </Button>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numAccounts: PropTypes.number.isRequired,
  setOpenModal: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

export default function BankTable({ setOpenModal }) {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedAccountId, setSelectedAccountId] = React.useState(null);
  const [selectedAccountName, setSelectedAccountName] = React.useState('');

  const [deleteBankAccount, { loading: deleteLoading, error: deletingError }] =
    useDeleteAccountMutation();

  const classes = useStyles();
  const theme = useTheme();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const { currentCompanyId, profile } = useCompanyStore();

  const { data, loading, error } = useQuery(GetAccountDocument, {
    variables: { company_id: currentCompanyId }
  });

  const dispatch = useDispatch();
  const reject_callback = useSelector((state) => {
    if (state.callback.caller === 'yes') {
      dispatch(ClearCallback());
      dispatch(ABxNotificationClose());
      handleDeleteAccount();
      //handleReject()
    } else if (state.callback.caller === 'no') {
      dispatch(ClearCallback());
      dispatch(ABxNotificationClose());
    } else {
    }
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const accounts = data?.user.company.accounts;

  if (loading || !accounts) return <BankTableSkeleton />;
  if (error) return <div>Error ...</div>;
  if (deletingError) return <div>Error ...</div>;
  const totalBalance = _.chain(accounts)
    .sumBy('balance')
    .value()
    ?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const handleDeleteAccount = () => {
    deleteBankAccount({
      variables: { company_id: currentCompanyId, account_id: selectedAccountId },
      update(cache, { data }) {
        if (data.deleteAccount.status === true) {
          const existingAccounts = cache.readQuery({
            query: GetAccountDocument,
            variables: { company_id: currentCompanyId }
          });
          //setConfirmOpen(false);

          const deletedAccountId = data?.deleteAccount?.object?.account_id;
          cache.writeQuery({
            query: GetAccountDocument,
            variables: { company_id: currentCompanyId },
            data: {
              accounts: existingAccounts?.accounts.filter((account) => {
                return account.account_id !== deletedAccountId;
              })
            }
          });
        }
      }
    });
  };
  const handleClickMenu = (accountId, accountName) => {
    //setConfirmOpen(true);
    setSelectedAccountId(accountId);
    setSelectedAccountName(accountName);
    dispatch(
      ABxNotificationOpen({
        title: 'Are you sure?',
        message: `Are you sure you want to delete the account ${selectedAccountName}?`,
        image: Sent,
        show_close: false,
        timeout: 600000,
        first_button: {
          text: 'Yes',
          style: 'main',
          link: '__callback__',
          id: 'yes'
        },
        second_button: {
          text: 'No',
          style: 'outline',
          link: '__callback__',
          id: 'no'
        }
      })
    );
  };
  return (
    <div className={classes.root}>
      <Box my={8}>
        <div className="text-abx-dark-blue text-lg">
          Cash in the bank : &nbsp;
          <span className="text-xl font-semibold">
            {`${totalBalance?.toLocaleString(undefined, {
              minimumFractionDigits: 2
            })} ${profile.currency}`}
          </span>
        </div>
      </Box>

      <Box
        bgcolor={theme.palette.background.paper}
        borderRadius={theme.spacing(4)}
        px={10}
        py={9}
        width="100%"
      >
        <EnhancedTableToolbar
          numAccounts={accounts.length}
          totalBalance={totalBalance}
          setOpenModal={setOpenModal}
        />

        <TableContainer
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <Table className={classes.table}>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={accounts.length}
            />
            <TableBody>
              {stableSort(accounts, getComparator(order, orderBy)).map((account, index) => {
                console.log(account);
                return (
                  <TableRow
                    key={`row${account.account_id}`}
                    className={`mt-8 p-4 flex flex-row items-center justify-center ${
                      index % 2 == 1 ? 'bg-white' : 'bg-gray-50'
                    }`}
                  >
                    <TableCell scope="row">
                      <Typography>{account.account_name}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{account?.institution}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{account.account_type}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {account.balance?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}{' '}
                        {account.currency}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {moment(Date.parse(account.last_updated)).format('D MMM YYYY')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <BankMoreMenu
                        onDelete={() => handleClickMenu(account.account_id, account.account_name)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
