import { Container } from '@material-ui/core';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NoMatch from '../NoMatch';
import BankAccounts from './BankConnect/BankAccounts';
import PlaidConnect from './BankConnect/PlaidConnect';
import { IntegrateProvider } from './hooks/IntegrateStore';
import Integrate from './Integrate/Integrate';
import Introduce from './Introduce';
import StatementUpload from './StatementUpload';
import TransactionInfo from './TransactionInfo/TransactionInfo';
import CheckData from './UploadStatement/CheckData';

export default function Index() {
  let { path } = useRouteMatch();

  return (
    <IntegrateProvider>
      <Container>
        <Switch>
          <Route exact path={path} component={Introduce} />
          <Route path={`${path}/bank`} component={BankAccounts} />
          <Route path={`${path}/bank/connect`} component={PlaidConnect} />
          <Route path={`${path}/upload`} component={StatementUpload} />
          <Route path={`${path}/integrate`} component={Integrate} />
          <Route path={`${path}/check`} component={CheckData} />
          <Route path={`${path}/transactions`} component={TransactionInfo} />
          <Route path="*" component={NoMatch} />
        </Switch>
      </Container>
    </IntegrateProvider>
  );
}
