import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  LabelProps,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import { KpiBreakdownData } from '../../../generated/graphql-frontend';
import { CashCardColor } from './CashCard';

interface Props {
  data?: KpiBreakdownData[];
  color?: CashCardColor;
}
const barColor = ['#9B51E0', '#F2994A', '#219653', '#2F80ED', '#FF008E'];
export default function HorizontalChart({ data, color }: Props): ReactElement {
  console.log('horizontal data=>', data);
  return (
    <Box height="100%" minHeight={200} minWidth={'100%'} >
      <ResponsiveContainer height="100%">
        <BarChart data={data} layout="vertical">
          <XAxis type="number" dataKey="amount" hide />
          <YAxis
            type="category"
            dataKey="amount"
            orientation="right"
            tickFormatter={(value) => Number(value).toFixed(2)}
            tickLine={false}
            axisLine={false}
            stroke={color === 'blue' ? '#FFF' : color === 'white' ? '#0E5A7B' : 'inherent'}
          />
          <YAxis
            type="category"
            dataKey="item"
            tickLine={false}
            axisLine={false}
            yAxisId="item"
            dy={-20}
            dx={30}
            hide
          />
          <Bar background dataKey="amount" barSize={16}>
            {data?.map((entry, index) => (
              <Cell cursor="pointer" fill={barColor[index % 5]} key={`cell-${index}`} />
            ))}
            <LabelList
              dataKey="item"
              content={(contentProps) => renderItem({ labelProps: contentProps, itemColor: color })}
              position="center"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

interface RenderItemInterface {
  labelProps: LabelProps;
  itemColor?: CashCardColor;
}

const renderItem = ({
  labelProps: { x = 0, y = 0, value },
  itemColor = 'blue'
}: RenderItemInterface) => {
  console.log('color of bar lable ==>');
  return (
    <g>
      <text
        x={x}
        y={y - 10}
        fill={itemColor === 'blue' ? '#FFF' : itemColor === 'white' ? '#0E5A7B' : 'inherent'}
        textAnchor="start"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  );
};
