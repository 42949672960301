import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useParams } from 'react-router';
import Header from '../../Containers/Header';
import { useGetTransactionsQuery } from '../../generated/graphql-frontend';
import Loading from '../../Loading';
import { isEmpty } from '../../utils/isEmpty';
import NoTransaction from '../DataManage/TransactionInfo/Components/NoTransactions';
import Error from '../Error';
import CashChart from './Components/CashChart';
import Expense from './Components/TransactionPanel/Expense/Expenses';
import { TransactionContainer } from './Components/TransactionPanel/TransactionContainer/TransactionContainer';
import AnalysisProvider from './Hooks/AnalysisContext';

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    top: 0,
    zIndex: 100,
    backgroundColor: theme.palette.background.default,
    transition: 'height 2s'
  }
}));

export default function Analyse() {
  const classes = useStyles();
  const { company_id } = useParams<CompanyIdProps>();
  const { data, error, loading } = useGetTransactionsQuery({
    variables: { company_id, limit: 50 }
  });

  const transactions_data = data?.user?.company?.transactions?.find(
    (transaction) => !isEmpty(transaction)
  );

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (
    transactions_data === undefined ||
    (transactions_data.total_count !== undefined && transactions_data?.total_count <= 0)
  )
    return <NoTransaction company_id={company_id} />;

  return (
    <AnalysisProvider>
      <Container>
        <Box display="flex" flexDirection="column" height="100vh">
          <Header
            title="Transaction History"
            description="Analyse your Company's performance find out what makes your business tick"
          />
          <Grid container spacing={10} className={classes.panelContainer}>
            <Grid item xs={12} md={7}>
              <TransactionContainer />
            </Grid>
            <Grid item xs={12} md={5}>
              <Box width="100%" height="100%">
                <Expense />
                <Box marginTop={3}>
                  <CashChart />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </AnalysisProvider>
  );
}
