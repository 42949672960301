import { makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import ABTypography from '../../../Components/ABTypography';
import SummaryCard from '../Components/SummaryCard';

const useStyle = makeStyles((theme) => ({
  root: {
    overflow: 'auto'
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gridGap: '0.75rem',
    gap: '0.75rem'
    // padding: "0.75rem",
  }
}));

export default function Summary(): ReactElement {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <ABTypography mt={5} variant="h3">
        New Product
      </ABTypography>
      <ABTypography mb={8} variant="body1">
        I want to see what happens if I add a new product
      </ABTypography>

      <div className={classes.cardContainer}>
        <SummaryCard
          headerString="How likey I am to run out of money?"
          price={67}
          percentage={13.7}
          firstDescription="The is a 67% chance of running out of money in the next 12 months in this scenario if no action is taken, this is"
          secondDescription="14% better than not changing anything"
        />

        <SummaryCard
          headerString="How profitable is this scenario?"
          price={3782}
          percentage={-27.4}
          firstDescription="Chance of running out of money in the next 12 months if no action is taken, this is "
          secondDescription="5% worse than not changing anything"
        />

        <SummaryCard
          headerString="Will I grow my income?"
          price={153}
          percentage={3.5}
          firstDescription="Yes, It is estimated that your income will grow 153% in the next year, this is"
          secondDescription="3% better than not changing anything"
        />
      </div>
    </div>
  );
}
