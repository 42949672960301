import { Box, Paper, Theme, Typography } from '@material-ui/core';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { SxProps } from '@material-ui/system';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { ReactComponent as DropZoneBefore } from '../../../../Components/assets/drop_zone_before.svg';
import { ReactComponent as DropZoneSuccess } from '../../../../Components/assets/drop_zone_success.svg';
import { LongButton } from '../../../../Components/LongButton';
import { UploadContext } from '../../UploadContext';
import { UploadStep } from './Const';
import DataFileLists from './DataFileLists';

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius,
  borderStyle: 'dashed',
  borderWidth: 2,
  borderColor: '#C0C2CA',
  transition: theme.transitions.create('padding'),
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer'
  },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

export interface CustomFile extends File {
  path?: string;
  preview?: string;
}

interface UploadSingleFileProps extends DropzoneOptions {
  error?: boolean;
  file: CustomFile | string | null;
  resetFile: () => void;
  sx?: SxProps<Theme>;
}

export default function TransactionDropzone({
  error = false,
  file,
  resetFile,
  sx,
  ...other
}: UploadSingleFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other
  });

  const { state, dispatch } = React.useContext(UploadContext);
  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size }: CustomFile = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {size}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        <input {...getInputProps()} />

        {file ? (
          <Box sx={{ p: 3, ml: { md: 2 }, width: 450 }}>
            <DropZoneSuccess />
          </Box>
        ) : (
          <div className="p-3 md:ml-2 min-w-min w-1/3 ">
            <DropZoneBefore />
            <h5 className=" text-center">DRAG & DROP</h5>

            <p className=" text-center ">
              your data file, or &nbsp;
              <a className=" no-underline  text-abx-pink ">browse</a>
            </p>
          </div>
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}
      {file ? (
        <div className="bg-white p-8 mt-4">
          <div className="text-abx-dark-blue text-lg font-semibold mb-4">
            {typeof file === 'object' ? file?.path : file.toString()}
          </div>

          <div className="flex">
            <LongButton
              variant="outlined"
              color="primary"
              style={{ marginLeft: 'auto', marginRight: 10 }}
            >
              Cancel
            </LongButton>
            <LongButton
              variant="contained"
              color="primary"
              onClick={() => {
                resetFile();
                switch (state.currentStep) {
                  case UploadStep.LOAD:
                    dispatch({ type: 'setCurrentStep', payload: UploadStep.MAPPING });
                    break;
                  default:
                    throw new Error();
                }
              }}
            >
              Next
            </LongButton>
          </div>
        </div>
      ) : (
        <div>
          <p className=" text-abx-form mt-11 mb-16 ">
            Please ensure date format is yyyy-mm-dd. For more help on formatting &nbsp;
            <a
              className="pink-underline-link"
              href="https://cdn.alchemybox.io/assets/ABx_transaction_template.xlsx"
            >
              Download a template file
            </a>
          </p>
          <DataFileLists />
        </div>
      )}
    </Box>
  );
}
