import { Box, Collapse, Grid, Typography } from "@material-ui/core";
import React, { ReactElement, useState } from "react";
import { ReactComponent as PropertIcon } from "../../../assets/property.svg";
import { ReactComponent as UtiltyIcon } from "../../../assets/utility.svg";
import { BoxContainer } from "../../../Components/BoxContainer";
import { ButtonContainer } from "../../../Components/ButtonContainer";
import TabTable from "../../../Components/TabTable";
import PropertyForm from "./PropertyForm";
import UtilityForm from "./UtilityForm";

export default function Property(): ReactElement {
  const [selected, setSelected] = useState("property");

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item sm={12} md={7}>
          <BoxContainer>
            <Typography variant="subtitle2" color="textSecondary">
              Add property & utilities
            </Typography>

            <Box display="flex" justifyContent="center">
              <ButtonContainer onClick={() => setSelected("property")}>
                <PropertIcon />
                <Typography color="white">Property</Typography>
              </ButtonContainer>

              <ButtonContainer onClick={() => setSelected("utility")}>
                <UtiltyIcon />
                <Typography>Utilities</Typography>
              </ButtonContainer>
            </Box>

            <Collapse in={selected === "property"}>
              <PropertyForm />
            </Collapse>
            <Collapse in={selected === "utility"}>
              <UtilityForm />
            </Collapse>
          </BoxContainer>
        </Grid>

        <Grid item sm={12} md={5}>
          <BoxContainer>
            <TabTable title="Property & Utilities cashflows" />
          </BoxContainer>
        </Grid>
      </Grid>
    </div>
  );
}
