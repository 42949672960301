import { LinearProgress } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { v4 as uuidv4 } from 'uuid';
import { Transaction } from '../../../../../generated/graphql-frontend';
import DayTransaction from './DayTransaction';

interface TransactionListProps {
  data: Transaction[];
  loading?: boolean;
  remainNum?: number;
  fetchMoreData: VoidFunction;
}

const useGetData = (transactions?: Transaction[]) => {
  const [transactionGroups, setTransactionGroups] = React.useState<TransactionGroup[]>([]);

  React.useEffect(() => {
    const moreTransactionGroup = _.chain(transactions?.slice(-50))
      .groupBy((item) => moment(item.date).format('DD MMMM YYYY'))
      .map((transactions, date) => ({
        date: date,
        groupId: uuidv4(),
        transactions: transactions
      }))
      .value();
    setTransactionGroups((prev) => {
      if (prev?.length === 0 || prev === undefined) return moreTransactionGroup;
      return prev.concat(moreTransactionGroup);
    });
  }, [transactions]);

  return { transactionGroups, setTransactionGroups };
};

const TransactionList = React.memo(
  ({ data, fetchMoreData, remainNum = 0 }: TransactionListProps) => {
    const { transactionGroups } = useGetData(data);
    if (!transactionGroups) return <div>Nodata</div>;

    return (
      <div
        id="scrollableDiv"
        style={{
          overflow: 'auto',
          height: 'calc(100vh - 300px)'
        }}
      >
        <InfiniteScroll
          dataLength={transactionGroups.length - 5}
          hasMore={remainNum > 0}
          next={fetchMoreData}
          loader={<LinearProgress />}
          scrollableTarget="scrollableDiv"
        >
          {transactionGroups.length === 0 ? (
            <LinearProgress />
          ) : (
            transactionGroups.map((transactions: TransactionGroup, index: number) => (
              <DayTransaction
                key={`day-${transactions.date}-${index}`}
                date={transactions.date}
                transactions={transactions.transactions}
              />
            ))
          )}
        </InfiniteScroll>
      </div>
    );
  }
);

export default TransactionList;
