import { Popover } from '@headlessui/react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { enGB } from 'date-fns/locale';
import { Multiselect } from 'multiselect-react-dropdown';
import Pickadate from 'pickadate/builds/react-dom';
import React, { useState } from 'react';
import { DateRangePicker, END_DATE, START_DATE } from 'react-nice-dates';
import { usePopper } from 'react-popper';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import Sent from '../Components/assets/message_sent.svg';
import { DropdownButton, DropdownButtonItem } from '../Components/FormElements/DropdownButton';
import { MultiSelect } from '../Components/FormElements/MultiSelect';
import { RepeatPopout } from '../Components/FormElements/RepeatPopout';
import TagField from '../Components/FormElements/TagInput';
import BotNotification from '../Components/Notification/BotNotification';
import { ABxNotificationOpen } from '../redux/actions';
import { InlineDatePicker } from './Analysis/Components/TransactionPanel/Filter/components';
import DateRange from './Analysis/Components/TransactionPanel/Filter/FilterComponents/DateRange';
import './index.css';

const roleOptions = [
  {
    value: 'admin',
    label: 'Admin',
    description: 'Can take any action'
  },
  {
    value: 'editor',
    label: 'Editor',
    description: 'Cannot manage companies, users, bank accounts, app integrations or billing'
  },
  {
    value: 'reviewer',
    label: 'Reviewer',
    description: 'Can only view scenarios, KPIs and analyse data, reviewers cannot download data'
  }
];

const ddStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#DAEAF6' : isSelected ? '#DAEAF6' : ''
    };
  }
  /*control: (_, { selectProps: { width }}) => ({
    // none of react-select's styles are passed to <Control />
    width:"50%",
    backgroundColor: "#fff",
    text:"#0E5A7B",
    underline: {
     borderBottom: "1px solid #0E5A7B",
    },
  }),*/
};

const Option = (props) => {
  const Selected =
    'bg-abx-light-blue p-0 m-0 text-abx-dark-blue w-full h-full flex flex-col border-none';
  const Focused = 'bg-abx-light-blue text-abx-dark-blue w-full rounded-lg flex flex-col';
  const Normal = 'bg-none text-abx-dark-blue w-full rounded-lg flex flex-col';

  return (
    <components.Option {...props}>
      <div className={props.isSelected ? Selected : props.isFocused ? Focused : Normal}>
        <div className="flex flex-col bg-none w-full h-full px-4   py-4 mb-1">
          <b className="title">{props.data.label}</b>
          <span>{props.data.description}</span>
        </div>
      </div>
    </components.Option>
  );
};

export default function TestNotification() {
  const dispatch = useDispatch();

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }]
  });

  const [btn1, setBtn1] = useState(false);
  const [btn2, setBtn2] = useState(false);
  const [btn3, setBtn3] = useState(false);
  const [tags, setTags] = useState([]);
  const [role, setRole] = useState('');
  const [role2, setRole2] = useState('');
  const [value, setValue] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const selectOptions = [
    { key: 'Option 1', cat: 'Group 1' },
    { key: 'Option 2', cat: 'Group 1' },
    { key: 'Option 3', cat: 'Group 1' },
    { key: 'Option 4', cat: 'Group 2' },
    { key: 'Option 5', cat: 'Group 2' },
    { key: 'Option 6', cat: 'Group 2' },
    { key: 'Option 7', cat: 'Group 2' }
  ];

  const selectStyle = {
    chips: {
      background: '#FF008E'
    },
    searchBox: {
      border: 'none',
      background: 'transparent',
      'border-bottom': 'none',
      'border-radius': '0px'
    },
    multiselectContainer: {
      color: '#FF008E',
      'border-radius': '0px'
    }
  };

  const SearchIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    );
  };

  const DownIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    );
  };

  const CalendarIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
    );
  };

  const CashIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    );
  };

  return (
    <div>
      <div className="p-4">
        <div className="m-5">
          <button className="btn-dark-blue" onClick={() => setBtn1(!btn1)}>
            Notification
          </button>
          <button className="btn-dark-blue" onClick={() => setBtn2(!btn2)}>
            Message
          </button>
          <button className="btn-dark-blue" onClick={() => setBtn3(!btn3)}>
            Wizard
          </button>
          <button
            className="btn-dark-blue"
            onClick={() =>
              dispatch(
                ABxNotificationOpen({
                  title: 'Redux Test',
                  message:
                    'This is a Redux test, I am called differently to other messages. I will be used everywhere!',
                  image: Sent,
                  second_button: { text: '< Back', style: 'outline', link: 'back' },
                  first_button: { text: 'Next >', style: 'main', link: 'integrate' }
                })
              )
            }
          >
            Redux
          </button>
          <>
            <button className="btn-light-blue" ref={setReferenceElement}>
              Open Me
            </button>
            <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
              Popper element
              <div ref={setArrowElement} style={styles.arrow} />
            </div>
          </>
          <Popover>
            {({ open }) => (
              <>
                <Popover.Button className="btn-light-blue flex flex-row align-middle">
                  My button
                  <div className="transform -translate-y-1 ml-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </Popover.Button>

                {open && (
                  <div className="bg-none mt-4 w-96 rounded-lg flex flex-col">
                    <div className="flex flex-col bg-white hover:bg-abx-light-blue w-full px-4 py-4 mb-1">
                      <b>Admin</b>
                      <span>Can take any action</span>
                    </div>
                    <div className="flex flex-col bg-white hover:bg-abx-light-blue w-full px-4 py-4 mb-1">
                      <b>Editor</b>
                      <span>
                        Cannot manage companies, users, bank accounts, app integrations or billing
                      </span>
                    </div>
                    <div className="flex flex-col bg-white hover:bg-abx-light-blue w-full px-4 py-4 mb-1">
                      <b>Reviewer</b>
                      <span>
                        Can only view scenarios, KPIs and analyse data, reviewers cannot download
                        data
                      </span>
                    </div>
                    {/*
                      Using `static`, `Popover.Panel` is always rendered and
                      ignores the `open` state.
                    */}
                    <Popover.Panel static>{/* ... */}</Popover.Panel>
                  </div>
                )}
              </>
            )}
          </Popover>
          <DropdownButton
            button_text="Access Type"
            default_value="admin"
            colour="white"
            callback={(x) => setRole(x)}
          >
            <DropdownButtonItem title="Admin">Can take any action</DropdownButtonItem>
            <DropdownButtonItem title="Editor">
              Cannot manage companies, users, bank accounts, app integrations or billing
            </DropdownButtonItem>
            <DropdownButtonItem title="Reviewer">
              Can only view scenarios, KPIs and analyse data, reviewers cannot download data
            </DropdownButtonItem>
          </DropdownButton>
          <Select
            label="Single select"
            className="form-select outline-none border-abx-dark-blue block w-full mt-1"
            options={roleOptions}
            styles={ddStyles}
            placeholder="Access Type"
            onChange={(e) => setRole2(e.value)}
            components={{
              Option
            }}
          />
          <br />
          <br />
          <TagField
            initialValues={['foo', 'brazil']}
            suggestedValues={['hello', 'quick', 'cat']}
            onChange={(x) => setTags(x)}
          />
          This is the role: {role}
          This is the role2: {role2}
          Tags:
          {tags.map((tag, idx) => (
            <div key={idx}>{tag}</div>
          ))}
          <RepeatPopout
            button_text="Repeats"
            default_value="Once"
            colour="white"
            callback={(x) => console.log(x)}
          />
          {btn1 ? (
            <BotNotification
              title="Invite sent!"
              message="Your invitation is on the way!"
              image={Sent}
              timeout={8000}
            />
          ) : (
            <div />
          )}
          {btn2 ? (
            <BotNotification
              title="The second msg"
              message="This is the second message, I am a bit longer than the others and I disappear quickly."
              timeout={5000}
              image={Sent}
            />
          ) : (
            <div />
          )}
          {btn3 ? (
            <BotNotification
              title="Wizard"
              message="I am a message with buttons"
              image={Sent}
              show_close={false}
              timeout={300000}
              second_button={{ text: '< Back', style: 'outline', link: 'back' }}
              first_button={{ text: 'Next >', style: 'main', link: 'integrate' }}
            />
          ) : (
            <div />
          )}
          <br />
          <div className="flex flex-row bg-abx-dark-blue h-16">
            <input
              type="text"
              placeholder="Transaction Name"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="w-full p-4 h-full text-white focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue"
            />
            <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
              <SearchIcon />
            </div>
          </div>
          <button className="btn-blue" onClick={(e) => setValue('')}>
            Clear
          </button>
          <br />
          <div className="flex flex-row bg-abx-dark-blue h-16">
            <input
              type="text"
              placeholder="Transaction Start Date"
              className="w-full p-4 h-full text-white focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue"
            />
            <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
              <CalendarIcon />
            </div>
          </div>
          <br />
          Yooo
          <div className="flex flex-row w-64 bg-abx-blue h-auto items-center">
            <MultiSelect
              placeholder="It's a me"
              options={[
                'hello',
                'quick',
                'cat',
                'ya',
                'nah',
                'lekker',
                'fish',
                'house',
                'food',
                'brown',
                'dog',
                'letter',
                'pin',
                'mario',
                'luigi',
                'famished'
              ]}
              onChange={(x) => console.log(x)}
            />
            <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
              <DownIcon />
            </div>
          </div>
          <br />
          <InlineDatePicker />
          <br />
          <div className="flex flex-row bg-abx-dark-blue h-16">
            <KeyboardDatePicker
              //autoOk
              //cancelLabel={true}
              variant="inline"
              size="small"
              className="border-none outline-none"
              //fullWidth
              disableFuture={true}
              disablePast={false}
              disableToolbar={false}
              //label="Start Date"
              //open={openDate}
              //onAccept={() => setOpenDate(!openDate)}
              format="MM/dd/yyyy"
              inputVariant="standard"
              InputProps={{
                disableUnderline: true
              }}
              //ToolbarComponent={CustomToolbar}
              //TextFieldComponent={() => <input type="date" />}
              //TextFieldComponent={renderInput}
              InputAdornmentProps={{ position: 'end' }}
              onChange={(e) => {
                //setOpenDate(!openDate);
                console.log(e);
              }}
            />

            <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
              <CalendarIcon />
            </div>
          </div>
          <br />
          <input type="date" id="birthday" name="birthday"></input>
          <br />
          <Pickadate>
            <Pickadate.Input placeholder="Select a date" />
            <Pickadate.DatePicker />
          </Pickadate>
          <br />
          {/* ######### DATE ########## */}
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            maximumDate={new Date()}
            minimumLength={0}
            format="dd/MMM/yyyy"
            locale={enGB}
          >
            {({ startDateInputProps, endDateInputProps, focus }) => (
              <div className="flex flex-row space-x-4">
                <div className="flex flex-row h-16 w-full bg-abx-orange">
                  <div className="date-range w-full">
                    <input
                      className={`w-full p-4 h-full text-white focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue ${
                        'input' + (focus === START_DATE ? ' -focused' : '')
                      }`}
                      {...startDateInputProps}
                      placeholder="Start date"
                    />
                  </div>
                  <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
                    <CalendarIcon />
                  </div>
                </div>
                <span className="flex items-center justify-center">to</span>
                <div className="flex flex-row h-16 w-full bg-abx-pink">
                  <div className="date-range w-full">
                    <input
                      className={`w-full p-4 h-full text-white focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue ${
                        'input' + (focus === END_DATE ? ' -focused' : '')
                      }`}
                      {...endDateInputProps}
                      placeholder="End date"
                    />
                  </div>
                  <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
                    <CalendarIcon />
                  </div>
                </div>
              </div>
            )}
          </DateRangePicker>
          <br />
          <div className="flex flex-row space-x-4">
            <div className="flex flex-row h-16 w-full bg-abx-orange">
              <div className="date-range w-full">
                <input
                  type="number"
                  step="10"
                  className="w-full p-4 h-full text-white focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue"
                  placeholder="Lower Amount"
                />
              </div>
              <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
                <CashIcon />
              </div>
            </div>
            <span className="flex items-center justify-center">to</span>
            <div className="flex flex-row h-16 w-full bg-abx-pink">
              <div className="date-range w-full">
                <input
                  type="number"
                  step="10"
                  className="w-full p-4 h-full text-white focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue"
                  placeholder="Upper Amount"
                />
              </div>
              <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
                <CashIcon />
              </div>
            </div>
          </div>
          <br />
          Hellloo
          <DateRange />
          <br />
          <div className="p-4 flex flex-row bg-abx-blue h-16 items-center">
            <Multiselect
              options={selectOptions}
              displayValue="key"
              placeholder="Category"
              className="placeholder-white"
              showCheckbox={true}
              onSelect={(e) => console.log(e)}
              onRemove={(e) => console.log(e)}
              style={selectStyle}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
