import { gql } from '@apollo/client';

//Query
export const COMPANY = gql`
  query {
    users {
      id
      first_name
      email
      companies {
        id
        name
        description
        business_type
        country
        city
        date_founded
        no_employees
        last_accessed
        position
        company_users {
          request_name
          request_email
          role
          last_updated
          status
          invitee {
            id
            display_name
          }
        }
      }
    }
  }
`;
export const GET_ONE_COMPANY = gql`
  query ($id: ID!) {
    users {
      id
      first_name
      companies(id: $id) {
        id
        name
        description
        business_type
        country
        city
        date_founded
        no_employees
        position
        last_accessed
        company_users {
          request_name
          request_email
          role
          last_updated
          status
          invitee {
            id
            display_name
          }
        }
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query {
    companies {
      id
      name
      description
      business_type
      country
      date_founded
      no_employees
      last_accessed
      company_users {
        request_name
        request_email
        role
        last_updated
        status
        invitee {
          id
          display_name
        }
      }
    }
  }
`;

export const GET_INTEGRATE_INFO = gql`
  query ($id: ID!) {
    users {
      companies(id: $id) {
        connected_integrations {
          integration_type
          name
          display_name
          what_can_do
          what_need
          image_url
          connected
          connected_files
        }
      }
    }
  }
`;

//Mutation
export const CREATE_USER = gql`
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      status
      error {
        code
        message
      }
      response
      object {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany($input: CompanyInput!) {
    createCompany(input: $input) {
      status
      error {
        message
        code
      }
      response
      object {
        id
        name
        description
        business_type
        no_employees
        company_users {
          request_name
          request_email
          role
          last_updated
          status
          invitee {
            id
            display_name
          }
        }
        connected_integrations {
          name
          integration_type
          what_need
          what_can_do
          connected
        }
      }
    }
  }
`;
export const DELETE_COMPANY = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      status
      error {
        code
        message
      }
      response
      object {
        id
        name
        description
      }
      id
    }
  }
`;
export const UPDATE_COMPANY = gql`
  mutation updateCompany($input: CompanyUpdateInput!) {
    updateCompany(input: $input) {
      status
      response
      object {
        id
        name
        description
        business_type
        country
        no_employees
        company_users {
          request_name
          request_email
          role
          last_updated
          status
          invitee {
            id
            display_name
          }
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export const RESOLVE_COMPANY_SHARE = gql`
  mutation resolveCompanyShare($input: ResolveShareCompanyInput!) {
    resolveCompanyShare(input: $input) {
      response
      status
      error {
        message
        code
      }
      object {
        id
        name
        description
      }
    }
  }
`;

export const SHARE_COMPANY = gql`
  mutation shareCompany($input: ShareCompanyInput!) {
    shareCompany(input: $input) {
      response
      status
      error {
        message
        code
      }
      object {
        company_id
        request_email
        request_name
        invitee {
          id
          display_name
          first_name
          last_name
          email
        }
      }
    }
  }
`;
export const DELETE_ROLE = gql`
  mutation deleteRole($user_id: ID!, $company_id: ID!) {
    deleteRole(user_id: $user_id, company_id: $company_id) {
      response
      status
      error {
        message
        code
      }
    }
  }
`;
