import React, { createContext, useContext, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useUrlQuery } from "./useUrlQuery";

type IntegrateContextStatus = {
  currentFileName: string;
  currentFileId: string;
  currentProviderName: string | null;
  currentColumn: string[];
  openDrawer: boolean;

  setCurrentFileName: (name: string) => void;
  setCurrentFileId: (fileId: string) => void;
  setCurrentProvider: (providerName: string) => void;
  setOpenDrawer: (open: boolean) => void;
  setCurrentColumn: (column: string[]) => void;
};

const initialStatus: IntegrateContextStatus = {
  currentFileName: "",
  currentFileId: "",
  openDrawer: false,
  currentColumn: [],
  currentProviderName: null,

  setCurrentFileName: () => {},
  setCurrentFileId: () => {},
  setCurrentProvider: () => {},
  setOpenDrawer: () => {},
  setCurrentColumn: () => {},
};

const IntegrateContext = createContext(initialStatus);

type ACTIONTYPE =
  | { type: "setCurrentFileName"; name: string }
  | { type: "setCurrentFileId"; fileId: string }
  | { type: "setCurrentProvider"; providerName: string | null }
  | { type: "setDrawerOpen"; open: boolean }
  | { type: "setCurrentColumn"; column: string[] };

function IntegrateReducer(state: typeof initialStatus, action: ACTIONTYPE) {
  switch (action.type) {
    case "setCurrentFileName":
      return { ...state, currentFileName: action.name };
    case "setCurrentFileId":
      return { ...state, currentFileId: action.fileId };
    case "setCurrentProvider":
      return { ...state, currentProviderName: action.providerName };
    case "setDrawerOpen":
      return { ...state, openDrawer: action.open };
    case "setCurrentColumn":
      return { ...state, currentColumn: action.column };
    default:
      throw new Error();
  }
}

export const IntegrateProvider: React.FC = ({ children }) => {
  const [integrateState, dispatch] = useReducer(IntegrateReducer, initialStatus);
  const urlQuery = useUrlQuery();
  const history = useHistory();
  const { pathname } = useLocation();

  const setFileName = (name: string) => {
    dispatch({ type: "setCurrentFileName", name: name });
  };
  const setFileId = (fileId: string) => {
    dispatch({ type: "setCurrentFileId", fileId: fileId });
  };
  const setProvider = React.useCallback((providerName: string | null) => {
    dispatch({ type: "setCurrentProvider", providerName: providerName });
  }, []);
  const setOpenDrawer = (open: boolean) => {
    dispatch({ type: "setDrawerOpen", open: open });
    urlQuery.set("success", open.toString());
    history.push({ pathname: pathname, search: urlQuery.toString() });
  };
  const setCurrentColumn = (column: string[]) => {
    dispatch({ type: "setCurrentColumn", column: column });
  };
  React.useEffect(() => {
    if (integrateState.currentFileName !== "") {
    }
    return () => {};
  }, [integrateState.currentFileName]);

  return (
    <IntegrateContext.Provider
      value={{
        currentFileName: integrateState.currentFileName,
        currentFileId: integrateState.currentFileId,
        currentProviderName: integrateState.currentProviderName,
        openDrawer: integrateState.openDrawer,
        currentColumn: integrateState.currentColumn,
        setCurrentFileName: setFileName,
        setCurrentFileId: setFileId,
        setCurrentProvider: setProvider,
        setOpenDrawer: setOpenDrawer,
        setCurrentColumn: setCurrentColumn,
      }}
    >
      {children}
    </IntegrateContext.Provider>
  );
};

export const useIntegrateStore = () => useContext(IntegrateContext);
