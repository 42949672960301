import { useTheme } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material';
import React, { ReactElement } from 'react';
import { Kpi } from '../../../../generated/graphql-frontend';
import palette from '../../../../Theme/palette';
import typography from '../../../../Theme/typograpy';
import BarCashCard from './BarCashCard';

export type KPIVariation = 'bar' | 'headline' | 'horizontal';

export type CashCardColor = 'white' | 'blue' | 'disabled';
interface CashCardProps {
  toggleDialog: (kpi?: Kpi) => void;
  kpi?: Kpi;
  isVisible?: boolean;
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    whiteOutline: true;
  }
}
export default function CashCard({
  kpi,
  toggleDialog,
  isVisible = true
}: CashCardProps): ReactElement | null {
  const kpiVariation = kpi?.variant;
  const variant = kpiVariation?.split('_')[1] as KPIVariation;
  const color = kpiVariation?.split('_')[0] as CashCardColor;
  const percentageSign = kpi?.positive_change;

  const theme = useTheme();
  if (!isVisible) return null;
  const cardTheme = createTheme({
    palette: {
      ...palette,
      background: {
        paper: color === 'white' ? 'white' : theme.palette.primary.dark
      },
      error: {
        main: color === 'white' ? theme.palette.error.main : theme.palette.error.dark
      },
      success: {
        main: color === 'white' ? theme.palette.success.main : '#04E3AC'
      }
    },
    typography: {
      ...typography,
      h1: {
        ...typography.h1,
        color: color === 'white' ? theme.palette.primary.dark : 'white'
      },
      body1: {
        ...typography.body1,
        color: color === 'white' ? theme.palette.primary.dark : 'white'
      },
      body2: {
        ...typography.body2,
        color: percentageSign === true ? theme.palette.success.main : theme.palette.secondary.main
      }
    },

    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'whiteOutline' },
            style: {
              color: color === 'white' ? theme.palette.primary.main : 'white',
              border:
                color === 'white' ? `1px solid ${theme.palette.primary.main}` : '1px solid white',
              textTransform: 'capitalize',
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto'
            }
          }
        ]
      }
    }
  });

  const disabledCardTheme = createTheme({
    palette: {
      ...palette,
      background: {
        paper: 'rgba(233, 233, 236, 0.95)'
      }
    },
    typography: {
      ...typography,
      h1: {
        ...typography.h1,
        color: color === 'white' ? theme.palette.primary.dark : 'white'
      },
      body1: {
        ...typography.body1,
        color: 'rgba(134, 131, 131, 0.95)'
      }
    },

    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'whiteOutline' },
            style: {
              color: color === 'white' ? theme.palette.primary.main : 'white',
              border: '1px solid white',
              textTransform: 'capitalize',
              width: 'fit-content',
              marginLeft: 'auto',
              marginRight: 'auto'
            }
          }
        ]
      }
    }
  });

  return (
    <ThemeProvider theme={color === 'disabled' ? disabledCardTheme : cardTheme}>
      <BarCashCard variant={variant} kpi={kpi} color={color} toggleDialog={toggleDialog} />
    </ThemeProvider>
  );
}
