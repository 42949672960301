import React, { ReactElement } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import MainSideBar from '../Components/MainSideBar/MainSideBar';

interface Props extends RouteProps {}

export default function PrivateRoute({ path, component, ...rest }: Props): ReactElement {
  return (
    <div className="flex flex-row space-x-4">
      <div className="w-4">
        <MainSideBar />
      </div>
      <div className="w-full px-2 sm:px-8">
        <Route {...rest} path={path} component={component} />
      </div>
    </div>
  );
}
