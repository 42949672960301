import { Box } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@mui/material';
import React from 'react';

interface BarProps {
  category?: string;
  percent?: number;
  amount?: number;
  currency?: string;
  color: string;
  barHeight?: number;
}

//  TODO Have to check the horizonbal on the expense chart
export default function HorizontalBar({
  category,
  percent,
  amount,
  currency,
  color,
  barHeight
}: BarProps) {
  const theme = useTheme();
  if (!percent) return <div>There is no percent data</div>;
  const barPercent = percent * 100;
  return (
    <Box height={barHeight}>
      <Typography variant="body1" textAlign="left">{`${category} (${barPercent.toFixed(
        2
      )}%)`}</Typography>
      <Box display="flex" mb={4}>
        <Box flex={1} bgcolor={theme.palette.background.default}>
          <Box
            display="block"
            height="100%"
            position="relative"
            style={{ width: `${barPercent}%`, backgroundColor: color }}
          ></Box>
        </Box>

        <Typography variant="body1" textAlign="right" noWrap>
          {Number(amount).toLocaleString()} {currency}
        </Typography>
      </Box>
    </Box>
  );
}
