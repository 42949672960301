import { useMutation } from '@apollo/client';
import { Autocomplete, Box, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { parse } from 'date-fns';
import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import ErrorImg from '../../../../../Components/assets/Error.png';
import SuccessImg from '../../../../../Components/assets/Success.png';
import ABLoading from '../../../../../Components/Loading/ABLoading';
import { LongButton } from '../../../../../Components/LongButton';
import Upgrade from '../../../../../Components/Upgrade';
import { COUNTRY_CODE as COUNTRY_AND_CODE } from '../../../../../Consts/CountryCode';
import { BUSINESS_TYPE } from '../../../../../Consts/FormData';
import { position_in_company } from '../../../../../Consts/Position';
import RegionData from '../../../../../Consts/RegionData.json';
import { useCompanyStore } from '../../../../../Context/GlobalStore';
import { AuthContext } from '../../../../../Context/Login';
import { CREATE_COMPANY } from '../../../../../Gqls/gql';
import { ABxNotificationOpen } from '../../../../../redux/actions';
import { getCountryCode } from '../../../../../utils/getCountryCode';
import { removeDup } from '../../../../../utils/removeDup';

const validationSchema = yup.object({
  companyName: yup.string().required('Company name is required'),
  description: yup.string().required('Description is required'),
  businessType: yup.object().nullable().required('Business type is required'),
  country: yup.string().nullable().required('Country is required'),
  city: yup.string().nullable().required('City is required'),
  date: yup.date().required('Date is required'),
  numEmploy: yup.string().required('Number of Employ is required'),
  posInCompany: yup.string().required('Position in your company required')
});

export default function CompanyDetail() {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const [upgradeOpen, setUpgradeOpen] = useState(false);
  const [createCompany, { loading }] = useMutation(CREATE_COMPANY);
  const theme = useTheme();
  const { setJustCreated } = useCompanyStore();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const toggleUpgrade = () => {
    setUpgradeOpen((prev) => !prev);
  };

  const formik = useFormik({
    initialValues: {
      companyName: '',
      description: '',
      businessType: null,
      country: user?.country,
      city: user?.city,
      date: new Date(),
      numEmploy: '',
      posInCompany: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const companyInfo = {
        name: values.companyName,
        description: values.description,
        business_type: values.businessType.items,
        country: values.country,
        city: values.city,
        date_founded: values.date,
        no_employees: values.numEmploy,
        position: values.posInCompany
      };

      createCompany({
        variables: { input: { ...companyInfo } },
        update(cache, { data: { createCompany } }) {
          if (createCompany.error === null) {
            const cacheId = cache.identify(createCompany.object);
            cache.modify({
              fields: {
                users: (existingFieldData = [], { toReference }) => {
                  return [...existingFieldData, toReference(cacheId)];
                }
              }
            });
          }
        }
      })
        .then((res) => {
          const error = res.data.createCompany?.error;

          if (error === null) {
            setJustCreated(res.data.createCompany.object.id, true);
            dispatch(
              ABxNotificationOpen({
                title: 'Company added!',
                message: `I have created the company ${res.data.createCompany.object.name} .`,
                image: SuccessImg
              })
            );
            history.push('/companies');
          } else if (error.code === 'QUOTA_EXCEEDED') {
            setUpgradeOpen(true);
          } else {
            dispatch(
              ABxNotificationOpen({
                title: 'Error!',
                message: res.data.shareCompany.error.message,
                image: ErrorImg
              })
            );
          }
        })
        .catch((error) => {
          dispatch(ABxNotificationOpen({ title: 'Error!', message: error, image: ErrorImg }));
        });
    }
  });

  const history = useHistory();

  console.log('data ==>', formik.values.date);

  return (
    <Box px={20} display="flex" flexDirection="column">
      <Box marginTop={8} marginBottom={5}>
        <Typography variant="h1" mt={6} mb={6}>
          Company Details
        </Typography>
        <Typography variant="body1">
          Let’s build your company a profile so your CFO in the cloud can provide you with the best
          insights
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box width={matches ? '60%' : '100%'}>
          <TextField
            fullWidth
            variant="standard"
            id="companyName"
            name="companyName"
            label="Company Name"
            margin="normal"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
            helperText={formik.touched.companyName && formik.errors.companyName}
          />
          <TextField
            fullWidth
            variant="standard"
            id="description"
            name="description"
            label="Description"
            margin="normal"
            value={formik.values.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
          <Autocomplete
            id="businessType"
            name="businessType"
            color="primary"
            forcePopupIcon={true}
            options={BUSINESS_TYPE}
            groupBy={(option) => option.category}
            getOptionLabel={(option) => option.items}
            value={formik.values.businessType}
            onChange={(event, value) => {
              formik.setFieldValue('businessType', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Business Type"
                margin="normal"
                variant="standard"
                error={formik.touched.businessType && Boolean(formik.errors.businessType)}
                helperText={formik.touched.businessType && formik.errors.businessType}
              />
            )}
          />
          <Autocomplete
            id="country"
            name="country"
            color="primary"
            forcePopupIcon={true}
            options={COUNTRY_AND_CODE.map((item) => item.name)}
            value={formik.values.country}
            onChange={(event, value) => {
              if (value) {
                formik.setFieldValue('country', value);
                formik.setFieldValue('city', null);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country of Registration"
                margin="normal"
                variant="standard"
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
            )}
          />
          {formik.values.country && (
            <Autocomplete
              id="city"
              name="city"
              color="primary"
              forcePopupIcon={true}
              options={removeDup(
                RegionData[getCountryCode(formik.values.country)].map((option) => option.region)
              )}
              value={formik.values.city}
              onChange={(event, value) => {
                formik.setFieldValue('city', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City of Registration"
                  margin="normal"
                  variant="standard"
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              )}
            />
          )}

          <KeyboardDatePicker
            fullWidth
            autoOk
            variant="inline"
            inputVariant="standard"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Date Founded"
            InputAdornmentProps={{ position: 'end' }}
            value={formik.values.date}
            onChange={(event, value) => {
              console.log('date value ==>', value);
              formik.setFieldValue('date', parse(value, 'dd/MM/yyyy', new Date()));
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />

          <Autocomplete
            id="numEmploy"
            name="numEmploy"
            color="primary"
            forcePopupIcon={true}
            options={['Just Me', '2-5', '5-10', '10-25', '25+']}
            value={formik.values.numEmploy}
            onChange={(event, value) => {
              formik.setFieldValue('numEmploy', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Number of Employees"
                margin="normal"
                variant="standard"
                error={formik.touched.numEmploy && Boolean(formik.errors.numEmploy)}
                helperText={formik.touched.numEmploy && formik.errors.numEmploy}
              />
            )}
          />
          <Autocomplete
            id="posInCompany"
            name="posInCompany"
            color="primary"
            forcePopupIcon={true}
            options={position_in_company.map((item, index) => item)}
            value={formik.values.posInCompany}
            onChange={(event, value) => {
              formik.setFieldValue('posInCompany', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Position in company"
                margin="normal"
                variant="standard"
                error={formik.touched.posInCompany && Boolean(formik.errors.posInCompany)}
                helperText={formik.touched.posInCompany && formik.errors.posInCompany}
              />
            )}
          />
        </Box>
        <Box display="flex" my={10}>
          <LongButton color="primary" variant="outlined" onClick={() => history.goBack()}>
            &lt; &nbsp; Back
          </LongButton>
          <LongButton
            color="primary"
            variant="contained"
            disabled={loading}
            type="submit"
            style={{ marginLeft: 'auto' }}
          >
            {loading ? <ABLoading /> : <div>Next &nbsp; &gt;</div>}
          </LongButton>
        </Box>
      </form>
      <Upgrade open={upgradeOpen} toggle={toggleUpgrade} />
    </Box>
  );
}
