import { Skeleton } from '@material-ui/core';
import React, { ReactElement } from 'react';

export default function ExpenseSkeleton(): ReactElement {
  return (
    <div>
      {Array(3)
        .fill(0)
        .map(() => (
          <Skeleton
            variant="rectangular"
            height={40}
            width="100%"
            sx={{ backgroundColor: 'lightblue', marginBottom: 4 }}
          />
        ))}
    </div>
  );
}
