import { Dialog } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9FAFB'
    }
  }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700
  },
  cancel: {
    position: 'absolute',
    right: 0
  },
  dialog: {
    position: 'relative'
  }
}));

export default function PreviewDialog({ open, setOpen, data }) {
  const getKeys = () => {
    return Object.keys(data[0]);
  };
  const getHeader = () => {
    var keys = getKeys();
    return keys.map((key, index) => {
      return (
        <td className="text-abx-dark-blue text-left font-semibold p-4" key={key}>
          {key.toUpperCase()}
        </td>
      );
    });
  };
  const RenderRow = (props) => {
    return props.keys.map((key, index) => {
      return (
        <td className="text-abx-dark-blue text-left font-normal p-4" key={props.data[key]}>
          {console.log('ovje=>', props.data[key]?.toString())}
          {typeof props.data[key] === 'object'
            ? moment(props.data[key]).format('dd/MM/yyyy')
            : props.data[key]}
        </td>
      );
    });
  };
  const getRowsData = () => {
    var items = data;
    var keys = getKeys();

    return (
      rowsPerPage > 0 ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : items
    ).map((row, index) => {
      return (
        <StyledTableRow key={index}>
          <RenderRow key={index} data={row} keys={keys} />
        </StyledTableRow>
      );
    });
  };

  const classes = useStyles();

  const rows = data;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log('rows per page', parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} maxWidth="lg" onClose={() => setOpen(false)} className="relative">
        <div className="p-8">
          <div className="mb-8">
            <div className="absolute right-4">
              <IconButton onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </div>
            <div className="text-xl font-semibold">Preview</div>
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>{getHeader()}</TableRow>
              </TableHead>
              <TableBody>{getRowsData()}</TableBody>
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </Table>
          </TableContainer>
          <Box display="flex" justifyItems="center" justifyContent="center">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              labelDisplayedRows={(dispRowsInfo) => {
                return dispRowsInfo.page + 1;
              }}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true
              }}
              // onChangePage={handleChangePage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
        </div>
      </Dialog>
    </div>
  );
}

//Footer action
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};
