import { TabsProps } from "@material-ui/core";
import { experimentalStyled as styled } from "@material-ui/core/styles";
import TabList from "@material-ui/lab/TabList/TabList";

export const ABTabList = styled((props: TabsProps) => <TabList {...props} />)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    height: 4,
  },
  "& .MuiTabs-flexContainer": {
    borderBottom: `4px solid ${theme.palette.primary.light}`,
  },
}));
