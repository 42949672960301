import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function DownloadIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 52 52" >
      <path id="Path_491" data-name="Path 491" d="M0,0H60V60H0Z" fill="none" />
      <path
        id="Path_366"
        data-name="Path 366"
        d="M37.478,22.394V37.478H7.31V22.394H3V37.478a4.322,4.322,0,0,0,4.31,4.31H37.478a4.322,4.322,0,0,0,4.31-4.31V22.394ZM24.549,23.838l5.581-5.56,3.038,3.038L22.394,32.091,11.62,21.317l3.038-3.038,5.581,5.56V3h4.31Z"
        transform="translate(3.465 3.465)"
      />
      <path
        id="Path_367"
        data-name="Path 367"
        d="M0,0H51.718V51.718H0Z"
        fill="none"
      />
    </SvgIcon>
  );
}
