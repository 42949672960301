import React, { useState } from "react";
import ErrorPic from "../assets/Error.svg";
import { Grid, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ProgressBar from "@material-ui/core/LinearProgress";
import { useSnackbar, SnackbarContent } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    transitionTimingFunction: "ease-in",
  },
  container: {
    height: "140px",
    width: "350px",
  },
  textWrapper: {
    backgroundColor: "#EE5359",
    color: "#FFF",
    height: "100%",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  picBackground: {
    backgroundColor: "#DAEAF6",
    height: "100%",
    padding: theme.spacing(2, 1, 2, 1),
  },
  textContainer: {
    position: "relative",
    top: "50%",
    transform: "translateY(-50%)",
  },
  picProps: {
    height: "100%",
    //   marginLeft:"10%",
  },
  close: {
    position: "absolute",
    padding: theme.spacing(1),
    top: 0,
    right: 0,
  },
  progressBar: {
    backgroundClip: "#F00",
  },
  progressColorPrimary: {
    backgroundColor: "#0D8ABC",
  },
  progressBarPrimary: {
    backgroundColor: "#DAEAF6",
  },
}));
const useTimeCounter = () => {
  const [progress, setProgress] = useState(100);

  React.useEffect(() => {
    let internalCount = 100;
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress - 1);
      internalCount = internalCount - 1;
      if (internalCount < 1) clearInterval(timer);
    }, 40);
  }, []);

  return [progress];
};

interface ErrorContentProps {
  message: string;
  id: string;
}
export default function ErrorContent({ message, id }: ErrorContentProps) {
  const classes = useStyles();
  const [progress] = useTimeCounter();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = React.useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent>
      <div className={classes.root}>
        <Grid container direction="row" className={classes.container}>
          <Grid item xs={6} className={classes.textWrapper}>
            <div className={classes.textContainer}>
              <Typography variant="h4">Error</Typography>
              <br />
              <Typography>{message}</Typography>
            </div>
          </Grid>
          <Grid item xs={6} className={classes.picBackground}>
            <img src={ErrorPic} alt="Error" className={classes.picProps} />
          </Grid>
        </Grid>
        <Box className={classes.close} style={{ height: 45, width: 45, border: "1px #f00" }}>
          <IconButton onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        </Box>
        <ProgressBar
          variant="determinate"
          className={classes.progressBar}
          value={progress}
          classes={{
            colorPrimary: classes.progressColorPrimary,
            barColorPrimary: classes.progressBarPrimary,
          }}
        />
      </div>
    </SnackbarContent>
  );
}
