import { useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ABTypography from '../../../Components/ABTypography';
import ErrorPic from '../../../Components/assets/Error.svg';
import Sent from '../../../Components/assets/message_sent.svg';
import {
  DropdownButton,
  DropdownButtonItem
} from '../../../Components/FormElements/DropdownButton';
import ButtonLoading from '../../../Components/Loading/ABLoading';
import { SHARE_COMPANY } from '../../../Gqls/gql';
import { ABxNotificationOpen } from '../../../redux/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(4),
    minHeight: 250
  },
  actionContainer: {
    margin: theme.spacing(3, 0)
  },
  cancel: {
    marginLeft: 0,
    marginRight: 'auto'
  },
  select: {
    minWidth: 120
  },
  selector: {
    marginTop: theme.spacing(1)
  }
}));

export default function InviteDialog({ open, setOpen, companyID, email }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [shareCompany, { loading: shareLoading }] = useMutation(SHARE_COMPANY);
  const { enqueueSnackbar } = useSnackbar();

  const [role, setRole] = useState(null);

  useEffect(() => {
    setInviteUser({ ...inviteUser, role: role });
  }, [role]);

  const [inviteUser, setInviteUser] = useState({
    id: '',
    name: '',
    email: '',
    role: ''
  });
  const [inviteUserError, setInviteUserError] = useState({
    nameError: false,
    emailError: false,
    roleError: false
  });

  const successContent = (name) => {
    dispatch(
      ABxNotificationOpen({
        title: 'Invite sent!',
        message: `I have sent your invitation to ${name}.`,
        image: Sent
      })
    );
  };

  const errorContent = () => {
    dispatch(
      ABxNotificationOpen({
        title: 'Error!',
        message:
          'Your message could not be sent, please try again later. I have notified the development team of this error.',
        image: ErrorPic
      })
    );
  };

  const handleInviteBtn = (event) => {
    event.stopPropagation();
    if (email === inviteUser.email)
      return enqueueSnackbar('You cannot send invitation to your email', {
        variant: 'error'
      });
    if (inviteUser.name === '' || inviteUser.email === '' || inviteUser.role === '') {
      setInviteUserError({
        ...inviteUserError,
        nameError: inviteUser.name === '' ? true : false,
        emailError: inviteUser.email === '' ? true : false,
        roleError: inviteUser.role === '' ? true : false
      });
    } else {
      setInviteUser({ name: '', email: '', role: '' });
      setInviteUserError({
        nameError: false,
        emailError: false,
        roleError: false
      });

      //share company
      let inviteDetail = { ...inviteUser, id: companyID };

      shareCompany({
        variables: { input: { ...inviteDetail } }
      })
        .then((res) => {
          if (res.data.shareCompany.status === true) {
            setOpen(false);
            successContent(inviteUser.name);
          } else {
            errorContent();
          }
        })
        .catch((res) => {
          console.log('error', res.message);
        });
    }
  };
  const handleChange = (event) => {
    setInviteUser({ ...inviteUser, role: event.target.value });
    if (event.target.value.length > 0) setInviteUserError({ ...inviteUserError, roleError: false });
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setInviteUserError({
      nameError: false,
      emailError: false,
      roleError: false
    });
  };

  return (
    <Dialog
      open={open}
      onClick={(e) => handleClose(e)}
      aria-labelledby="confirm-dialog"
      maxWidth="sm"
      classes={{ paper: classes.container }}
    >
      <Box my={10} mx={10} minHeight={250}>
        <ABTypography variant="h2" align="center">
          Invite to Company
        </ABTypography>
        <TextField
          variant="standard"
          color="primary"
          margin="dense"
          id="first"
          label="Full Name of Invitee"
          fullWidth
          value={inviteUser.name}
          error={inviteUserError.nameError}
          helperText={inviteUserError.nameError ? '*This field is required' : ''}
          onChange={(e) => {
            e.stopPropagation();
            if (e.target.value.length > 0)
              setInviteUserError({
                ...inviteUserError,
                nameError: false
              });
            setInviteUser({ ...inviteUser, name: e.target.value });
          }}
        />
        <TextField
          variant="standard"
          color="primary"
          margin="dense"
          id="second"
          label="Email Address"
          fullWidth
          value={inviteUser.email}
          helperText={inviteUserError.emailError ? '*This field is required' : ''}
          error={inviteUserError.emailError}
          onChange={(e) => {
            e.stopPropagation();
            if (e.target.value.length > 0)
              setInviteUserError({
                ...inviteUserError,
                emailError: false
              });
            setInviteUser({ ...inviteUser, email: e.target.value });
          }}
        />

        <div className="my-4">
          <DropdownButton
            button_text="Access Type"
            error={inviteUserError.roleError}
            callback={(x) => {
              x == null ? setRole('') : setRole(x);
            }}
          >
            <DropdownButtonItem title="Admin">Can take any action</DropdownButtonItem>
            <DropdownButtonItem title="Editor">
              Cannot manage companies, users, bank accounts, app integrations or billing
            </DropdownButtonItem>
            <DropdownButtonItem title="Reviewer">
              Can only view scenarios, KPIs and analyse data, reviewers cannot download data
            </DropdownButtonItem>
          </DropdownButton>
        </div>

        <Box display="flex" paddingTop="20px">
          <Button
            onClick={() => {
              setOpen(false);
              // setInviteUser({ name: "", email: "", role: "" });
              setInviteUserError({
                nameError: false,
                emailError: false,
                roleError: false
              });
            }}
            color="primary"
            variant="outlined"
            className={classes.cancel}
          >
            Cancel
          </Button>
          <Button
            disabled={shareLoading}
            onClick={handleInviteBtn}
            color="primary"
            variant="contained"
            style={{ marginLeft: 'auto' }}
          >
            {shareLoading && <ButtonLoading />}
            Send Invite
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
