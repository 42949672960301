import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { useGetFileDataQuery } from '../../../../../generated/graphql-frontend';
import { useIntegrateStore } from '../../../hooks/IntegrateStore';

interface Props {
  title?: string;
  file_id: string;
  provider: string;
  company_id: string;
  toggleLoading: (loading: boolean) => void;
}

const FileTitle = ({ company_id, provider, title, file_id, toggleLoading }: Props) => {
  const { data, loading } = useGetFileDataQuery({
    variables: {
      company_id,
      file_id,
      provider
    }
  });
  const { setCurrentColumn } = useIntegrateStore();

  React.useEffect(() => {
    toggleLoading(true);
    if (data?.file_integration) {
      const column = data?.file_integration![0]?.columns![0]!.columns as string[];
      try {
        setCurrentColumn(column);
        toggleLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
  }, [loading]);
  if (loading) return <LinearProgress />;
  return (
    <div>
      <div className="text-abx-dark-blue text-lg mt-4">{`${title}`}</div>
    </div>
  );
};

export default React.memo(FileTitle);
