export const google = {
  CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  SCOPE: 'https://www.googleapis.com/auth/drive.file',
  MIME_TYPES: [
    'application/vnd.google-apps.folder',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv'
  ]
};

export const dropbox = {
  APP_KEY: process.env.REACT_APP_DROPBOX_APP_ID
};
