import { Box, Collapse, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { ReactElement } from "react";
import { default as ABSwitch, default as ABSWitch } from "../../../../../Components/ABSwitch";
import { LongButton } from "../../../../../Components/LongButton";
import { BoxContainer } from "../../../Components/BoxContainer";
import TabTable from "../../../Components/TabTable";

export default function Custom(): ReactElement {
  const [change, setChange] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setChange(checked);
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={7}>
        <BoxContainer>
          <Typography variant="subtitle2" mb={4}>
            Add other cashflows
          </Typography>

          <ABSwitch value={change} onChange={handleChange} />
          {/* Name */}
          <Typography variant="formText" color="primary" mt={4}>
            What do you want to name this?
          </Typography>
          <TextField id="custom_name" name="custom_name" variant="standard" color="primary" placeholder="Name" />

          {/* Expense */}
          <Typography variant="formText" color="primary" mt={4}>
            Is this an income or an expense?
          </Typography>
          <ABSWitch />

          <Collapse in={change}>
            <Box display="flex" flexDirection="column">
              {/* Irregular payment */}
              <Typography variant="formText" color="primary" mt={4}>
                Is this a one-off or irregular payment? i.e. no regular period
              </Typography>
              <ABSWitch />

              {/* Start date of Cashflow */}
              <Typography variant="formText" color="primary" mt={4}>
                What is the start date of this this cashflow?
              </Typography>
              <TextField id="custom_name" name="custom_name" variant="standard" placeholder="Name" />

              {/* Cashflow occur */}
              <Typography variant="formText" color="primary" mt={4}>
                How often will this cashflow occur?
              </Typography>
              <TextField id="custom_name" name="custom_name" variant="standard" placeholder="Name" />

              {/* Each period */}
              <Typography variant="formText" color="primary" mt={4}>
                Will you pay/recieve the same amount each period?
              </Typography>
              <ABSWitch />

              {/* Each period */}
              <Typography variant="formText" color="primary" mt={4}>
                What is the upper and lower amount you will pay/recieve?
              </Typography>
              <Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
                <TextField id="custom_name" name="custom_name" variant="standard" placeholder="Lower Amount" />
                <Typography variant="formText" color="primary" mt={4} mx={4}>
                  and
                </Typography>
                <TextField id="custom_name" name="custom_name" variant="standard" placeholder="Upper Amount" />
              </Box>
            </Box>
          </Collapse>
          <Collapse in={!change}>
            <Box mt={4} display="flex" flexDirection="row">
              <TextField variant="standard" value="20 March 2020" label="Date of cashflow" />
              <Typography marginX={4}></Typography>
              <TextField variant="standard" value="" label="Cashflow amount" placeholder="Amount" />
              <IconButton>
                <AddCircleIcon color="secondary" />
              </IconButton>
            </Box>
          </Collapse>
          {/* payment you will make/receive */}

          <Box mt={4}>
            <LongButton variant="contained">Save</LongButton>
          </Box>
        </BoxContainer>
      </Grid>
      <Grid item xs={12} md={5}>
        <BoxContainer>
          <TabTable title="Other cashflows" />
        </BoxContainer>
      </Grid>
    </Grid>
  );
}
