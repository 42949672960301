import { Box, makeStyles, Theme } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import BasicContainer from '../../../../Containers/BasicContainer';
import BackButton from '../../Components/BackButton';
import { UploadContext } from '../../UploadContext';
import { UploadStep } from './Const';
import DropZone from './DropZone';
import DropZoneHeader from './DropZoneHeader';
import MapBody from './Mapping/MapBody';
import Review from './Review';

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));
export default function UploadBody(): ReactElement {
  const classes = useStyles();
  const [file, setFile] = useState<File | null>(null);
  const { state } = React.useContext(UploadContext);
  const [jsonData, setJsonData] = useState<Record<string, string>[]>([]);
  const setTransData = (dataArray: Record<string, string>[]) => {
    setJsonData(dataArray);
  };

  const getContent = (step: number) => {
    switch (step) {
      case UploadStep.LOAD:
        return <DropZone file={file} setDataFile={setFile} setTransactionData={setTransData} />;

      case UploadStep.MAPPING:
        return (
          <>
            <MapBody data={jsonData} />
          </>
        );

      case UploadStep.REVIEW:
        return <Review rows={jsonData} />;
      default:
        throw new Error();
    }
  };
  return (
    <BasicContainer>
      <BackButton />
      <div className={classes.dropzone}>
        <Box width="70%" my={5}>
          <DropZoneHeader />
        </Box>
        {getContent(state.currentStep)}
      </div>
    </BasicContainer>
  );
}
