import { Theme, withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';

export const TextField = withStyles((theme: Theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.primary.dark
    },
    '& .MuiInputBase-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.dark
      }
    }
  }
}))(MuiTextField);
