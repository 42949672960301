import { useQuery } from '@apollo/client';
import { Hidden, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CompanyBG } from '../../Components/assets/CompanyBG.svg';
import Sidebar from '../../Components/Sidebar';
import Upgrade from '../../Components/Upgrade';
import { COMPANY } from '../../Gqls/gql';
import Loading from '../../Loading';
import { NoPreference } from '../../NoPreference';
import Error from '../Error';
import NoMatch from '../NoMatch';
import CompanyList from './Component/CompanyList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFF'
  },
  sideBarContainer: {
    backgroundColor: theme.palette.primary.light,
    minWidth: 150,
    maxWidth: 400
  },
  coverImg: {
    margin: theme.spacing(7, 0),
    width: '80%'
  },
  paper: {
    margin: theme.spacing(8, 16),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4, 5)
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    minHeight: '100vh'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  logoContainer: {
    textAlign: 'left'
  },
  logoImg: {
    height: '45px',
    width: '39px'
  },
  titleContainer: {
    textAlign: 'left',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    color: '#FFF'
  },
  headerContainer: {
    backgroundColor: '#0d8abc',
    paddingLeft: '23px',
    paddingTop: '17px'
  },

  formTitle: {
    textAlign: 'left',
    margin: theme.spacing(6, 0, 3, 0)
  },
  dividerMargin: {
    marginTop: '10px',
    marginBottom: '15px'
  },
  imageBG: {
    textAlign: 'center'
  },
  outButContainer: {
    position: 'absolute',
    bottom: theme.spacing(2),
    top: 'auto',
    marginLeft: theme.spacing(4)
  },
  btnContainer: {
    padding: theme.spacing(7, 2),
    marginTop: 'auto'
  },
  companyNum: {
    borderRadius: 10,
    padding: '1px 12px 1px 12px',
    marginLeft: theme.spacing(2.5),
    backgroundColor: '#0D8ABC',
    color: '#FFF',
    fontSize: '1.5rem',
    fontWeight: 500
  }
}));

export default function Index() {
  const classes = useStyles();
  const history = useHistory();
  const { data, loading: companyLoading, error } = useQuery(COMPANY);
  const [openUpgrade, setOpenUpgrade] = React.useState(false);
  const handleToggle = () => {
    setOpenUpgrade((prev) => !prev);
  };

  if (companyLoading) return <Loading />;
  if (error) return <NoMatch notFound={false} />;
  if (data.users.length === 0) history.push('/onboarding/preferences');

  const companies = data.users[0]?.companies;
  const countCompany = companies?.length;

  const handleAddCompany = () => {
    if (countCompany < 10) {
      history.push('/onboarding/company');
    } else {
      setOpenUpgrade(true);
    }
  };

  return (
    <Grid container component="main" className={classes.root} direction="row">
      <Grid item xs={false} sm={5} md={4} lg={3} className={classes.sideBarContainer}>
        <Hidden smDown>
          <Sidebar
            logoTitle="Companies"
            logoSubtitle="Select a company"
            image={<CompanyBG width="100%" />}
          />
        </Hidden>
      </Grid>

      <Grid item xs={12} sm={7} md={8} lg={9} xl={8}>
        <div className={classes.paper}>
          <Grid container justifyContent="space-between" className={classes.formTitle}>
            <Grid item>
              <Typography variant="h1">
                Companies
                <span className={classes.companyNum}>{companies?.length}</span>
              </Typography>
            </Grid>
            <Grid item>
              <Button color="primary" variant="outlined" onClick={() => handleAddCompany()}>
                Add Company
              </Button>
            </Grid>
          </Grid>

          {/* <Companies/> */}
          <CompanyList companies={companies} loading={companyLoading} />
        </div>
      </Grid>
      <Upgrade name="" open={openUpgrade} toggle={handleToggle} />
    </Grid>
  );
}
