import { Container, makeStyles, Theme, Typography } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import BasicContainer from '../../Containers/BasicContainer';
import Header from '../../Containers/Header';
import { Kpi } from '../../generated/graphql-frontend';
import useLocalStorage from '../../hooks/useLocalStorage';
import CashCard from './components/CashCard';
import KPIDialog from './components/KPIDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  wrapper: {
    marginTop: '10vh'
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
    gridGap: '3rem',
    gap: '0.75rem'
  }
}));

interface KPIPageProps {
  kpis: Kpi[];
}
export default function KPI({ kpis }: KPIPageProps): ReactElement {
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedKPI, setSelectedKPI] = useState<Kpi>();
  const [kpiToggleLists, setKpiToggleLists] = useLocalStorage<Record<string, boolean>>(
    'kpiToggleLists',
    {}
  );

  const burnRateFlag = useFlag('burnRate');
  const totalIncomeFlg = useFlag('totalIncome');
  const totalExpensesFlag = useFlag('totalExpenses');
  const salesRevenueFlag = useFlag('salesRevenue');
  const revenueGrowthFlag = useFlag('revenueGrowth');
  const numSalesFlag = useFlag('numSales');
  const netIncomeFlag = useFlag('netIncome');
  const emergencyFundFlag = useFlag('emergencyFund');
  const cashRunwayFlag = useFlag('cashRunway');
  const cashInBankFlag = useFlag('cashInBank');
  const totalCustomersFlag = useFlag('totalCustomers');

  useEffect(() => {
    const newKpiFlagLists: Record<string, boolean> = {
      'Cash in the Bank': cashInBankFlag,
      'Burn Rate': burnRateFlag,
      'Cash Runway': cashRunwayFlag,
      'Emergency Fund': emergencyFundFlag,
      'Net Income': netIncomeFlag,
      'Total Expenses': totalExpensesFlag,
      'Total Income': totalIncomeFlg,
      'Revenue Growth': revenueGrowthFlag,
      'Total Number of Customers': totalCustomersFlag,
      'Number of Sales': numSalesFlag,
      'Sales Revenue': salesRevenueFlag
    };
    Object.keys(kpiToggleLists).forEach((kpiKey) => {
      if (kpiToggleLists[kpiKey] !== newKpiFlagLists[kpiKey]) {
        setKpiToggleLists({ ...kpiToggleLists, [kpiKey]: newKpiFlagLists[kpiKey] });
        if (newKpiFlagLists[kpiKey]) console.log(`${kpiKey}feature is enabled`);
      }
    });
  }, [
    cashInBankFlag,
    burnRateFlag,
    cashRunwayFlag,
    emergencyFundFlag,
    netIncomeFlag,
    totalExpensesFlag,
    totalIncomeFlg,
    revenueGrowthFlag,
    totalCustomersFlag,
    numSalesFlag,
    salesRevenueFlag,
    kpiToggleLists,
    setKpiToggleLists
  ]);

  const toggleOpenDetail = (kpi?: Kpi) => {
    setOpenDetail((prev) => !prev);
    if (kpi) setSelectedKPI(kpi);
  };
  const classes = useStyles();

  const kpiGroupByCategory = _.chain(kpis)
    .sortBy((item) => item.order)
    .groupBy((item) => item.category)
    .value();
  console.log('grouped kpi==>', kpiGroupByCategory);

  console.log('kpis by category==>', kpiGroupByCategory);
  const categories = Object.keys(kpiGroupByCategory);
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Header
          title="Key performance Indicators"
          description="Key performance indicators (KPIs) are the measures that show you the health of your business "
        />
        <BasicContainer>
          <div className={classes.wrapper}>
            {categories.map((category) => (
              <div key={category} style={{ marginTop: 40 }}>
                <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
                  {category}
                </Typography>
                <div className={classes.cardContainer}>
                  {kpiGroupByCategory[category].map((kpi) => (
                    <CashCard
                      key={`${category} - ${kpi?.name}`}
                      toggleDialog={toggleOpenDetail}
                      kpi={kpi}
                      isVisible={kpiToggleLists[kpi.name ?? '']}
                    />
                  ))}
                </div>
              </div>
            ))}

            {selectedKPI && (
              <KPIDialog
                open={openDetail}
                selectedKpi={selectedKPI}
                toggleDialog={toggleOpenDetail}
              />
            )}
          </div>
        </BasicContainer>
      </Container>
    </div>
  );
}
