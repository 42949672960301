import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import { LongButton } from "../../../../Components/LongButton";
import { BoxContainer } from "../../Components/BoxContainer";
import TabTable from "../../Components/TabTable";


export default function Tax(): ReactElement {

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={7}>
        <BoxContainer>
          <Typography variant = "formText" marginBottom={4}>Add Tax</Typography>

          {/* Name */}
          <Typography  variant = "formText" color="primary" marginTop={4}>
            What do you want to name this?
          </Typography>
          <TextField id="tax_name" name="tax_name" placeholder="Name" variant="standard" />

          {/* Date */}
          <Typography variant = "formText"color="primary" marginTop={4}>
            When does your current tax year end?
          </Typography>
          <TextField id="tax_name" name="tax_name" placeholder="Name" variant="standard" />

          {/* Rate */}
          <Typography color="primary" marginTop={4}>
            What is your tax rate?
          </Typography>
          <TextField id="tax_name" name="tax_name" placeholder="Tax Rate" variant="standard" />

          <Box mt={4}>
            <LongButton variant="contained" color="primary">
              Save
            </LongButton>
          </Box>
        </BoxContainer>
      </Grid>
      <Grid item xs={12} md={5}>
        <BoxContainer>
          <TabTable title="Tax cashflows" />
        </BoxContainer>
      </Grid>
    </Grid>
  );
}
