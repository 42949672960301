import { Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useCompanyStore } from '../../../../../Context/GlobalStore';
import ProductIcon from '../../../Components/Icons/ProductIcon';
import { AnalysisContext } from '../../../Hooks/AnalysisContext';

export default function TransactionModal() {
  const {
    transOpen = false,
    toggleTransOpen,
    currentTransaction
  } = React.useContext(AnalysisContext);
  const { profile } = useCompanyStore();

  interface detailType {
    category?: string | undefined;
    amount?: number | undefined;
    name?: string | undefined;
    display_name?: string | undefined;
    tags?: string[] | undefined;
    repeat?: string | undefined;
    avg_transactions?: number | undefined;
    number_transactions?: number | undefined;
    total_transactions?: number | undefined;
  }
  const detail: detailType | undefined = currentTransaction?.details;

  const handleClose = () => {
    toggleTransOpen();
  };

  const formatCurrency = (amount: number | undefined) => {
    return amount === undefined
      ? ''
      : Number(amount) > 0
      ? `+${Number(amount).toFixed(2)} ${profile?.currency}`
      : `${Number(amount).toFixed(2)} ${profile?.currency}`;
  };
  return (
    <Dialog open={transOpen} maxWidth="md" onClose={handleClose}>
      <div className="pt-8 pb-12 px-12 flex flex-col">
        <div className="flex flex-row items-start">
          <div className="flex-none">
            <div className="flex flex-row mt-4 mb-8 items-center">
              <ProductIcon category={detail?.category} />
              <span className="ml-4 text-gray-400">{detail?.category}</span>
            </div>
          </div>
          <div className="flex-grow"></div>
          <div className="flex-none">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="flex flex-row mt-2 items-center">
          <span className="flex-none font-semibold text-lg text-abx-dark-blue">
            {detail?.display_name}
          </span>
          <span className="flex-grow"></span>
          <span className="flex-none font-normal text-abx-dark-blue pl-8 text-base">
            {formatCurrency(detail?.amount)}
          </span>
        </div>
        {detail?.tags !== undefined && (
          <div className="flex flex-row space-x-2 mt-4">
            {detail?.tags?.map((tag) => (
              <div className="bg-abx-pink text-white py-1 px-3 rounded-lg">{tag}</div>
            ))}
          </div>
        )}
        <hr className="my-8 opacity-50" />
        <div className="flex row">
          <span className="text-md font-semibold">Original Name:</span>
          <span className="text-md font-normal ml-2">{detail?.name}</span>
        </div>
        <div className="flex row mt-8">
          <span className="text-md font-semibold">Repeats:</span>
          <span className="text-md font-normal ml-2">{detail?.repeat}</span>
        </div>
        <div className="flex row mt-8">
          <span className="text-md font-semibold">Number of transactions:</span>
          <span className="text-md font-normal ml-2">{detail?.number_transactions}</span>
        </div>
        <div className="flex row mt-8">
          <span className="text-md font-semibold">Total transaction amount:</span>
          <span className="text-md font-normal ml-2">
            {formatCurrency(detail?.total_transactions)}
          </span>
        </div>
        <div className="flex row mt-8">
          <span className="text-md font-semibold">Average transaction amount:</span>
          <span className="text-md font-normal ml-2">
            {formatCurrency(detail?.avg_transactions)}
          </span>
        </div>
      </div>
    </Dialog>
  );
}
