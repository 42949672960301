import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { SnackbarProvider } from 'notistack';
import './App.css';
import BotNotification from './Components/Notification/BotNotificationRedux';
import { useUIStore } from './Context/GlobalStore';
import CurrentSection from './Routes/index';
import NoMatch from './Routes/NoMatch';
import { validateResourceName } from './utils/validateResourceName';

function App() {
  const { theme } = useUIStore();

  const urlParams = new URLSearchParams(window.location.search);
  if (window.location.pathname === '/share_company') {
    const shareToken = urlParams.get('tkn');

    if (validateResourceName(shareToken)) {
      if (shareToken !== null) {
        localStorage.setItem('share_company', shareToken);
      }
    } else {
      const oldToken = localStorage.getItem('share_company');
      if (!validateResourceName(oldToken)) localStorage.removeItem('share_company');
      return <NoMatch notFound={false} />;
    }
  }

  //if (!isAuthenticated) loginWithRedirect();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <CurrentSection />
      </SnackbarProvider>
      <BotNotification />
    </ThemeProvider>
  );
}
export default withWidth()(App);
