import { Grid, TextField } from "@material-ui/core";
import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import ABBox from "../../../../Components/ABBox";
import ABStepper from "../../../../Components/ABStepper";
import ABTypography from "../../../../Components/ABTypography";
import { ReactComponent as AddScenarioBackground } from "../../../../Components/assets/add_scenario.svg";
import BasicContainer from "../../../../Containers/BasicContainer";
import Header from "../../../../Containers/Header";
import { useScenarioStore } from "../../hooks/ScenarioContext";

export default function AddScenario(): ReactElement {
  const { activeStep, increaseStep, decreaseStep } = useScenarioStore();

  const history = useHistory();

  const handleIncrease = () => {
    increaseStep();
    history.replace("./cashflows");
  };

  return (
    <div>
      <Header title="Scenarios" description="See the impact of your business decisions before you make them" />
      <BasicContainer>
        <ABBox px={8} py={10}>
          <Grid container spacing={10} justifyContent="space-between">
            <Grid item xs={6} direction="row">
              <ABTypography variant="subtitle2">Add a scenario</ABTypography>
              <ABTypography>This process will only take a few minutes</ABTypography>
              <TextField
                id="scenario_name"
                name="scenario_name"
                variant="standard"
                label="Scenario Name"
                fullWidth
                margin="normal"
              />
              <TextField
                id="description"
                name="description"
                variant="standard"
                label="Description"
                fullWidth
                margin="normal"
              />
              <TextField
                id="optimise"
                name="optimise"
                variant="standard"
                label="I want to optimise for..."
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={5}>
              <AddScenarioBackground width="100%" />
            </Grid>
          </Grid>
        </ABBox>
      </BasicContainer>
      <ABStepper activeValue={activeStep} increaseStep={() => handleIncrease()} decreasestep={decreaseStep} />
    </div>
  );
}
