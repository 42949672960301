import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { ReactElement } from 'react';
import ABSelect from '../../../../Components/ABSelect';
import ABSWitch from '../../../../Components/ABSwitch';
import { BoxContainer } from '../../Components/BoxContainer';
import TabTable from '../../Components/TabTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginBottom: 80
    }
  })
);

export default function Employees(): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={7}>
          <BoxContainer>
            <Typography variant="subtitle2">Add Employee</Typography>

            <Typography variant="formText" color="primary" mt={4}>
              What do you want to name this?
            </Typography>
            <TextField name="name" id="name" value="" label="Name" variant="standard" />
            <Typography variant="formText" color="primary" mt={4}>
              What date you plan to hire?
            </Typography>

            <KeyboardDatePicker
              fullWidth
              autoOk
              variant="inline"
              inputVariant="standard"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date Founded"
              InputAdornmentProps={{ position: 'end' }}
              value={new Date().toLocaleDateString()}
              onChange={() => console.log('Changed')}
            />

            <Typography variant="formText" color="primary" mt={4}>
              If they are temporary or contract workers, when will the stop working?
            </Typography>
            <TextField
              name="date"
              id="date"
              value={new Date().toISOString().slice(0, 10)}
              variant="standard"
            />

            <Typography variant="formText" color="primary" mt={4}>
              How often will you pay them?
            </Typography>
            <ABSelect name="Monthly" />

            <Typography variant="formText" color="primary" mt={4}>
              Will you pay the same amount each period?
            </Typography>
            <ABSWitch />

            <Typography variant="formText" color="primary" mt={4}>
              What is the upper and lower amount you will pay?
            </Typography>
            <Box display="flex" alignItems="flex-end">
              <TextField name="name" label="Lower amount" variant="standard" />
              &nbsp;
              <Box ml={2} mr={2}>
                <Typography variant="formText" color="primary">
                  and
                </Typography>
              </Box>
              &nbsp;
              <TextField name="name" label="cower amount" variant="standard" />
            </Box>
            <Box mt={6}>
              <Button color="primary" variant="contained">
                Save
              </Button>
            </Box>
          </BoxContainer>
        </Grid>
        <Grid item xs={12} md={5}>
          <BoxContainer>
            <TabTable title="Employee & Staff cashflows" />
          </BoxContainer>
        </Grid>
      </Grid>
    </div>
  );
}
