import { Box, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useFlag } from '@unleash/proxy-client-react';
import React from 'react';
import ABTypography from '../../../../Components/ABTypography';
import DownloadIcon from './DownloadIcon';

export default function Download() {
  const theme = useTheme();
  const downloadFlag = useFlag('txn-download-data');
  return (
    <div>
      {downloadFlag && (
        <Box display="flex" alignItems="center" color={theme.palette.secondary.main}>
          <IconButton color="secondary">
            <DownloadIcon />
          </IconButton>
          <ABTypography color="secondary">Download Data</ABTypography>
        </Box>
      )}
    </div>
  );
}
