import { Box, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useFlag } from '@unleash/proxy-client-react';
import React from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as ConnectBG } from '../../Components/assets/connect_data.svg';
import WelcomeDialog from '../../Components/Carousel/WelcomeDialog';
import ConnectionCard from '../../Components/ConnectionCard/ConnectionCard';
import BasicContainer from '../../Containers/BasicContainer';
import Header from '../../Containers/Header';
import { DataStatus, Maybe, useGetAccountCardQuery, useCompanyUsageQuery,useIntegrationStatusQuery } from '../../generated/graphql-frontend';
import { isEmpty } from '../../utils/isEmpty';

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '3rem',
    gap: '0.75rem',
    marginTop: 40
  }
}));

interface ParamTypes {
  company_id: string;
  provider:string;
}
export default function Introduce() {
  const history = useHistory();
  const classes = useStyles();
  const { pathname } = useLocation();
  const bankAccountToggle = useFlag('bank-account');

  console.log('togggle bank account ==>', bankAccountToggle);

  const { company_id, provider } = useParams<ParamTypes>();
  const { data, loading } = useGetAccountCardQuery({
    variables: { company_id }
  });

  //-----------------------------
  const { data:cardData, loading: cardLoadng, error } = useIntegrationStatusQuery({
      variables: {
         company_id, // value for 'company_id'
         provider, // value for 'provider'
       },
       pollInterval: 500,
     });

console.log("cardData",cardData);

  //---------------------

  const cardArray = data?.companies?.find((item) => !isEmpty(item))?.data_status;

  console.log(
    'card array ==>',
    cardArray?.map((card) => card.account_type)
  );

  const cardToggleLists: Record<string, boolean> = {
    'Business Bank Accounts': bankAccountToggle,
    'Add Integrations': true,
    'Transaction Information': true
  };

  if (loading) return <LinearProgress />;
  const linkArray = {
    bank: 'Business Bank Accounts',
    integrate: 'Add Integrations',
    transactions: 'Transaction Information'
  };

  const handleClickCard = (cardType: Maybe<string> | undefined) => {
    switch (cardType) {
      case linkArray.bank:
        history.push(`${pathname}/bank`);
        break;

      case linkArray.integrate:
        history.push(`${pathname}/integrate`);
        break;

      case linkArray.transactions:
        history.push(`${pathname}/transactions`);
        break;
      default:
    }
  };
  return (
    <div>
      <Header />
      <BasicContainer>
        <Grid container justifyContent="space-between">
          <Grid item sm={12} md={6}>
            <Box mt={1}>
              <div className="text-black text-2xl font-semibold">Connect Data</div>
              <br />
              <div className="text-abx-dark-grey text-md my-4">
                Connect your bank account, merchant providers & other data connections & let your
                CFO do the rest.
              </div>
              <br />
            </Box>
          </Grid>
          <Grid item sm={12} md={4}>
            <Box textAlign="center">
              <ConnectBG width="80%" />
            </Box>
          </Grid>
        </Grid>

        <div className={classes.cardContainer}>
          {cardArray?.map((card: DataStatus, index: number) => {
            if (!cardToggleLists[card.account_type ?? '']) return null;
            return (
              <ConnectionCard
                key={`integration-${index}`}
                type={card?.account_type}
                connectStatus={card?.connection_status}
                accountNum={card?.no_items}
                cardColor={card?.colour}
                mainText={card.main_text}
                subText={card.sub_text}
                buttonText={card.button_text}
                inactive={card.button_inactive}
                handleClick={() => handleClickCard(card?.account_type)}
              />
            );
          })}
        </div>
      </BasicContainer>
      <WelcomeDialog company_id={company_id} />
    </div>
  );
}
