import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SendIcon from '@material-ui/icons/Send';
import { TabContext, TabPanel } from '@material-ui/lab';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ABTab } from '../../../Components/ABTab';
import { ABTabList } from '../../../Components/ABTabList';
import {
  DropdownButton,
  DropdownButtonItem
} from '../../../Components/FormElements/DropdownButton';
import MoreMenu from './MoreMenu';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'display_name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    hide_sort: false
  },
  {
    id: 'last_accessed',
    numeric: false,
    disablePadding: false,
    label: 'Last Accessed',
    hide_sort: false
  },
  {
    id: 'accessType',
    numeric: false,
    disablePadding: false,
    label: 'Access Type',
    hide_sort: false
  }
];

const headInvitedCells = [
  {
    id: 'display_name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    hide_sort: true
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    hide_sort: true
  },
  {
    id: 'accessType',
    numeric: false,
    disablePadding: false,
    label: 'Access Type',
    hide_sort: true
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    hide_sort: true
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function InvitedEnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headInvitedCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.hide_sort ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  },
  select: {
    minWidth: 120
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

export default function UserTable({ companyID, rows }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('request_name');
  let idx = -1;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CurrentUserTable = () => {
    return (
      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
              if (row.status !== 'pending') {
                idx = idx + 1;
                return (
                  <TableRow
                    hover
                    key={`userlist${index}`}
                    className={`min-h-32 mt-8 p-4 flex flex-row items-center justify-center ${
                      idx % 2 === 1 ? 'bg-white' : 'bg-gray-50'
                    }`}
                  >
                    <TableCell component="th" scope="row">
                      <Typography>
                        {row.role === 'owner' ? <span>Me</span> : <span>{row.request_name}</span>}
                      </Typography>
                    </TableCell>
                    {row.status === 'pending' ? (
                      <TableCell>
                        <Typography>
                          Pending &nbsp;
                          {/* <Link  color = "primary" >Resend Invite</Link> */}
                          <Tooltip title="Resend invite">
                            <IconButton>
                              <SendIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Typography>
                          {moment(Date.parse(row.last_updated)).format('dd/MM/yyyy')}
                        </Typography>
                      </TableCell>
                    )}

                    <TableCell>
                      {row.role !== 'owner' ? (
                        <FormControl className={classes.formControl}>
                          <div className="my-4">
                            <DropdownButton
                              button_text="Access Type"
                              default_value={row.role}
                              hide_clear={true}
                              error={false}
                              callback={(x) => {
                                x == null ? console.log('') : console.log(x);
                              }}
                            >
                              <DropdownButtonItem title="Admin">
                                Can take any action
                              </DropdownButtonItem>
                              <DropdownButtonItem title="Editor">
                                Cannot manage companies, users, bank accounts, app integrations or
                                billing
                              </DropdownButtonItem>
                              <DropdownButtonItem title="Reviewer">
                                Can only view scenarios, KPIs and analyse data, reviewers cannot
                                download data
                              </DropdownButtonItem>
                            </DropdownButton>
                          </div>
                        </FormControl>
                      ) : (
                        <Typography>{row.role}</Typography>
                      )}
                    </TableCell>

                    <TableCell>
                      <Typography>
                        {row.role !== 'owner' && row.status !== 'pending' && (
                          <MoreMenu
                            id={companyID}
                            userID={row.invitee && row.invitee.id}
                            userName={row.display_name}
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const InvitedUserTable = () => {
    return (
      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle" aria-label="enhanced table">
          <InvitedEnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
              if (row.status === 'pending') {
                return (
                  <TableRow
                    hover
                    key={`userlist${index}`}
                    className={`min-h-32 mt-8 p-4 flex flex-row items-center justify-center ${
                      index % 2 === 1 ? 'bg-white' : 'bg-gray-50'
                    }`}
                  >
                    <TableCell component="th" scope="row">
                      <Typography>
                        {row.role === 'owner' ? <span>Me</span> : <span>{row.request_name}</span>}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.request_email}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography>{row.role}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography>
                        {(moment(new Date()).diff(moment(Date.parse(row.last_updated)), 'days') >
                          2) &
                        (row.status === 'pending') ? (
                          <Typography>
                            Expired &nbsp;
                            {/* <Link  color = "primary" >Resend Invite</Link> */}
                            <Tooltip title="Resend invite">
                              <IconButton>
                                <SendIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        ) : (
                          <Typography>{row.status}</Typography>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <Box px={10} className="pb-8">
          <ABTabList onChange={handleChange} aria-label="kpi-tabs" variant="fullWidth">
            <ABTab label="Current Users" value="1" classes={{}} />
            <ABTab label="Invited Users" value="2" />
          </ABTabList>
        </Box>

        <TabPanel value="1">
          <CurrentUserTable />
        </TabPanel>
        <TabPanel value="2">
          <InvitedUserTable />
        </TabPanel>
      </TabContext>
      {/*current ? <CurrentUserTable /> : <InvitedUserTable />*/}
    </div>
  );
}
