import { Skeleton } from '@material-ui/core';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router';
import NumberBadge from '../../../../Components/NumberBadge';
import { useGetDataFilesQuery } from '../../../../generated/graphql-frontend';
import DataFileMoreButton from './DataFileMoreButton';

export default function DataFileLists(): ReactElement {
  const { company_id } = useParams<CompanyIdProps>();
  const { data, loading } = useGetDataFilesQuery({ variables: { company_id } });

  const fileLists = data?.data_files;
  if (loading) return <Skeleton />;
  if (!fileLists) return <div>You don't have any data file yet</div>;
  return (
    <div className=" bg-white rounded-2xl p-11">
      <h5 className=" leading-snug ">
        Data Files <NumberBadge>{fileLists.length}</NumberBadge>{' '}
      </h5>
      <hr className=" my-10" />

      <table className=" w-full">
        <thead className=" text-abx-th">
          <tr>
            <td>Name</td>
            <td>Description</td>
            <td>Upload Date</td>
          </tr>
        </thead>
        <tbody className=" text-abx-body">
          {fileLists?.map((file) => (
            <tr key={file.file_id}>
              <td>{file.name}</td>
              <td>{file?.description}</td>
              <td className=" text-abx-dark-grey">
                {console.log(file.upload_date)}
                {
                moment(file.upload_date).format('YYYY-MM-DD hh:mm')
                }
              </td>
              <td>
                {file.file_id && (
                  <DataFileMoreButton
                    key={file.file_id}
                    company_id={company_id}
                    fileid={file.file_id}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
