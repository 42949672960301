import React, { useCallback, useContext } from 'react';
import * as XLSX from 'xlsx';
import { UploadContext } from '../../UploadContext';
import TransactionDropzone from './TransactionDropzone';

interface DropzoneProps {
  setTransactionData: (dataArray: Record<string, string>[]) => void;
  setDataFile: (file: File | null) => void;
  file: File | null;
}
export default function DropZone({ file, setDataFile, setTransactionData }: DropzoneProps) {
  const { state, dispatch } = useContext(UploadContext);
  const handleResetFile = () => {
    setDataFile(null);
  };
  const handleFile = (file: File) => {
    if (file) {
      setDataFile(file);
      var allowedExtensions = /(\.csv|\.xls|\.xlsx)$/i;
      if (!allowedExtensions.exec(file.name)) {
        return false;
      } else {
        dispatch({ type: 'setFileName', payload: file.name });
        const reader = new FileReader();
        reader.onload = (evt) => {
          const bstr = evt.target?.result;
          const wb = XLSX.read(bstr, {
            type: 'binary'
          });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws);
          console.log('parsed data ==>', data);
          setTransactionData(data as Record<string, string>[]);
        };
        reader.readAsBinaryString(file);
      }
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        handleFile(file);
        setDataFile(file);
      }
    },
    [setDataFile]
  );

  return (
    <TransactionDropzone
      file={file}
      resetFile={handleResetFile}
      onDrop={handleDrop}
      accept=".csv,.xlsx"
    />
  );
}
