import { Box, Dialog, Link, LinkProps, Typography, withStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import ABTypography from '../../../Components/ABTypography';
import { ReactComponent as ConnectBG } from '../../../Components/assets/connect_bank.svg';
import { LongButton } from '../../../Components/LongButton';
import PlaidConnect from './PlaidConnect';

const ABLink = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer'
  }
}))((props: LinkProps) => <Link {...props} />);

interface ConnectBankDialogProps {
  open: boolean;
  linkToken: string;
  toggleOpen: () => void;
}
export default function ConnectBankDialog({
  open,
  linkToken,
  toggleOpen
}: ConnectBankDialogProps): ReactElement {
  const history = useHistory();
  return (
    <Dialog open={open}>
      <Box my={10} mx={15}>
        <ABTypography variant="h3" align="center">
          Connect your business bank accounts
        </ABTypography>
        <Box my={5} display="flex" justifyContent="center">
          <ConnectBG style={{ width: '80%' }} />
        </Box>
        <Typography>
          Securely connect your business bank accounts so your CFO can monitor your business while
          you sleep, no need to check your phone in the middle of the night!
        </Typography>
        <Box display="flex" mt={4} mb={4}>
          <LongButton variant="outlined" color="primary" onClick={toggleOpen}>
            Back
          </LongButton>
          <Box mr={0} ml="auto">
            <PlaidConnect linkToken={linkToken} />
          </Box>
        </Box>
        <ABTypography align="center">
          Can't connect an account, upload a{' '}
          <ABLink onClick={() => history.replace('upload')}>data file</ABLink>
        </ABTypography>
      </Box>
    </Dialog>
  );
}
