import { Box } from "@material-ui/core";
import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  dot: {
    width: 30,
    height: 30,
    margin: 20,
    borderRadius: 15,
    backgroundColor: theme.palette.action.disabled,
  },
  activedot: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface StepperProps {
  steps: Number;
  activeStep: Number;
}

export default function Stepper({
  steps,
  activeStep,
}: StepperProps): ReactElement {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center">
      {new Array(steps).fill(0).map((_, index) => (
        <div
          key={index}
          className={clsx(classes.dot, {
            [classes.activedot]: index <= activeStep,
          })}
        >
        </div>
      ))}
    </Box>
  );
}
