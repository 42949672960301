import { Chip } from '@material-ui/core';
import React, { ReactElement, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TransactionFilter } from '../../../../../generated/graphql-frontend';
import { AnalysisContext } from '../../../Hooks/AnalysisContext';

type ChipType = {
  key: string;
  property: string;
  label: string;
};

export default function FilterChipList(): ReactElement {
  const { filter = {}, updateFilter } = useContext(AnalysisContext);
  const [chipList, setChipList] = React.useState<ChipType[]>();

  const handleDeleteFilter = (selectedChip: ChipType) => {
    setChipList((prev) => prev?.filter((chip) => chip.key !== selectedChip.key));
    const value = filter[selectedChip.property as keyof TransactionFilter];
    if (Array.isArray(value)) {
      const deletedValue = value.filter((item) => item !== selectedChip.label);
      updateFilter({ ...filter, [selectedChip.property]: deletedValue });
    } else {
      updateFilter({ ...filter, [selectedChip.property]: undefined });
    }
  };

  // React.useEffect(() => {
  //   let tempList: ChipType[] = [];
  //   for (const property in filter) {
  //     const value = filter[property as keyof TransactionFilter];

  //     if (Array.isArray(value)) {
  //       value.forEach((item) => {
  //         tempList = tempList.concat([{ key: uuidv4(), property, label: item }]);
  //       });
  //     } else {
  //       tempList = tempList.concat([{ key: uuidv4(), property, label: value as string }]);
  //     }
  //   }
  //   setChipList(tempList);
  // }, [filter]);

  return (
    <div>
      {chipList?.map((chip) => {
        if (!chip?.label) return null;
        return (
          <Chip
            key={chip.key}
            label={chip?.label ?? ''}
            onDelete={() => handleDeleteFilter(chip)}
            sx={{ marginRight: 3, marginBottom: 2 }}
          />
        );
      })}
    </div>
  );
}
