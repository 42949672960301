import { experimentalStyled as styled } from "@material-ui/core/styles";
import Tab, { TabProps } from "@material-ui/core/Tab";

export const ABTab = styled((props: TabProps) => <Tab {...props} />)(({ theme }) => ({

  color: theme.palette.primary.main,
  textTransform: "none",
  opacity: 1,
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  "&:hover":{
    color: theme.palette.secondary.main,
  }
}));
