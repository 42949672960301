import { Typography } from "@material-ui/core";
import React, { ReactElement } from "react";

export default function Cashflow(): ReactElement {
  return (
    <div>
      <Typography color="text.secondary">
        Cash Runway <Typography component="span" color="success.main"></Typography>
      </Typography>
    </div>
  );
}
