import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { COMPANY } from '../../Gqls/gql';
import Loading from '../../Loading';
import NoMatch from '../NoMatch';

export default function Home(): ReactElement {
  const { data, loading, error, networkStatus } = useQuery(COMPANY);
  const history = useHistory();

  React.useEffect(() => {
    if (data?.users.length === 0) {
      history.push('/detail/user');
    } else {
      history.push('/companies/');
    }
  }, [data]);
  if (loading) return <Loading />;
  if (error) return <NoMatch notFound={false} />;
  if (networkStatus === 8) return <NoMatch notFound={false} />;
  if (data === undefined) return <NoMatch notFound={false} />;

  return <div>Home</div>;
}
