import { ButtonProps, experimentalStyled as styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  minWidth: 130
}));

export const LongButton = styled(Button)<ButtonProps>(({ theme }) => ({
  minWidth: 130
}));
