import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";

interface SearchProps {
  searchTerm: string;
  setSearchTerm: (searchWord: string) => void;
}
export default function SearchBox({ searchTerm, setSearchTerm }: SearchProps) {
  return (
    <FormControl>
      <InputLabel htmlFor="standard-adornment-password">Search for an app</InputLabel>
      <Input
        id="standard-adornment-password"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="search">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
}
