import Tippy from '@tippy.js/react';
import React from 'react';
import 'tippy.js/animations/scale-extreme.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import '../../../../tooltip.css';
import { categoryColor } from '../category';
import AccountingIcon from './assets/accounting-icon.svg';
import BankIcon from './assets/bank-fees-icon.svg';
import EducationIcon from './assets/education-icon.svg';
import EmployeeBenefitsIcon from './assets/employee-benefits-icon.svg';
import EmployeeIcon from './assets/employee-icon.svg';
import FoodDrinkIcon from './assets/food-drink-icon.svg';
import IncomingIcon from './assets/incoming-icon.svg';
import InsuranceIcon from './assets/insurance-icon.svg';
import InvestmentIcon from './assets/investment-icon.svg';
import LegalIcon from './assets/legal-icon.svg';
import LoanIcon from './assets/loan-icon.svg';
import MarketingIcon from './assets/marketing-icon.svg';
import SuppliesIcon from './assets/office-supplies-icon.svg';
import OtherIcon from './assets/other-icon.svg';
import OutgoingIcon from './assets/outgoing-icon.svg';
import ProductExpenseIcon from './assets/product-icon.svg';
import ProfessionalIcon from './assets/professional-services-icon.svg';
import PropertyIcon from './assets/property-icon.svg';
import ResearchIcon from './assets/research-icon.svg';
import SaleIcon from './assets/sale-icon.svg';
import TaxIcon from './assets/tax-icon.svg';
import TransportIcon from './assets/transport-icon.svg';
import TravelIcon from './assets/travel-icon.svg';
import UtilitiesIcon from './assets/utilities-icon.svg';

export default function ProductIcon({ category }) {
  const mapping = {
    Sales: { icon: <img src={SaleIcon} alt=" sale icon " />, colour: '#ECAD2A' },
    'Investment Income': {
      icon: <img src={InvestmentIcon} alt="investment icon" />,
      colour: '#3557CF'
    },
    'Loan Income': { icon: <img src={LoanIcon} alt="loan income" />, colour: '#5E1F4B' },
    'Other Income': { icon: <img src={OtherIcon} alt="other income" />, colour: '#4C97A0' },
    'Marketing/Advertising': {
      icon: <img src={MarketingIcon} alt="marketing icon" />,
      colour: '#5326C1'
    },
    Transportation: {
      icon: <img src={TransportIcon} alt="transportation icon" />,
      colour: '#EA8EDF'
    },
    'Payroll/Salaries': { icon: <img src={EmployeeIcon} alt="payroll icon" />, colour: '#0CB86E' },
    'Employee Benefits': {
      icon: <img src={EmployeeBenefitsIcon} alt="employee icon" />,
      colour: '#9922AE'
    },
    'Food, Drink and Entertainment': {
      icon: <img src={FoodDrinkIcon} alt="food icon" />,
      colour: '#8264c9'
    },
    'Rent/Property': { icon: <img src={PropertyIcon} alt="rend icon" />, colour: '#72E8FA' },
    Utilities: { icon: <img src={UtilitiesIcon} alt="utilities icon" />, colour: '#18C4BE' },
    'Office Expenses/Supplies': {
      icon: <img src={SuppliesIcon} alt="office icon" />,
      colour: '#5E61B6'
    },
    'Legal Services': { icon: <img src={LegalIcon} alt="legal icon" />, colour: '#E57359' },
    'Accounting Services': {
      icon: <img src={AccountingIcon} alt="account icon" />,
      colour: '#B73409'
    },
    'Professional Services': {
      icon: <img src={ProfessionalIcon} alt="professional icon" />,
      colour: '#6B8645'
    },
    Travel: { icon: <img src={TravelIcon} alt="travel icon" />, colour: '#F1BB91' },
    'Research and Development': {
      icon: <img src={ResearchIcon} alt="research icon" />,
      colour: '#27652B'
    },
    Tax: { icon: <img src={TaxIcon} alt="tax icon" />, colour: '#8E255F' },
    Insurance: { icon: <img src={InsuranceIcon} alt="insurance icon" />, colour: '#F06B0C' },
    'Banking Fees': { icon: <img src={BankIcon} alt="bank icon" />, colour: '#465994' },
    'Training/Education': {
      icon: <img src={EducationIcon} alt="training icon" />,
      colour: '#0F5D7B'
    },
    'Product Expenses': {
      icon: <img src={ProductExpenseIcon} alt="product icon" />,
      colour: '#F2994A'
    },
    'Money Transfer - Outgoing': {
      icon: <img src={OutgoingIcon} alt="money transfer icon" />,
      colour: '#C70039'
    },
    'Money Transfer - Incoming': {
      icon: <img src={IncomingIcon} alt="money income icon" />,
      colour: '#00AD82'
    },
    'Tax Rebate': { icon: <img src={TaxIcon} alt="tax icon" />, colour: '#291A3F' },
    'Other Expenses': { icon: <img src={OtherIcon} alt="other expense icon" />, colour: '#2D9CDB' }
  };
  /*
  "transaction_categories": [
                                "Sales",
                                "Investment Income",
                                "Loan Income",
                                "Other Income",
                                "Marketing/Advertising",
                                "Transportation",
                                "Payroll/Salaries",
                                "Employee Benefits",
                                "Food, Drink and Entertainment",
                                "Rent",
                                "Utilities",
                                "Office Expenses/Supplies",
                                "Legal Services",
                                "Accounting Services",
                                "Professional Services",
                                "Travel",
                                "Research and Development",
                                "Tax",
                                "Insurance",
                                "Other Expenses"
                            ]
                            */

  return (
    /*<SvgIcon {...props} viewBox="0 0 14.86 14.86" style={{ border: "solid 1px", backgroundColor:"#F00"}}>
      <path
        id="Path_2305"
        data-name="Path 2305"
        d="M5.953,15.209A1.651,1.651,0,1,0,7.6,16.86,1.649,1.649,0,0,0,5.953,15.209ZM1,2V3.651H2.651L5.623,9.917,4.509,11.94a1.6,1.6,0,0,0-.206.793,1.656,1.656,0,0,0,1.651,1.651H15.86V12.732H6.3a.2.2,0,0,1-.206-.206l.025-.1.743-1.346h6.15a1.643,1.643,0,0,0,1.445-.85l2.956-5.358a.807.807,0,0,0,.1-.4.828.828,0,0,0-.826-.826H4.476L3.7,2H1ZM14.209,15.209A1.651,1.651,0,1,0,15.86,16.86,1.649,1.649,0,0,0,14.209,15.209Z"
        transform="translate(-1 -2)"
        fill="#fff"
      />
    </SvgIcon>*/
    <>
      <Tippy
        content={category}
        hideOnClick={true}
        interactive={false}
        animation="scale-extreme"
        duration={[300, 250]}
        inertia={true}
        arrow={false}
        className="tooltip"
        zIndex={9999}
      >
        <div
          className="w-9 h-9 p-2 rounded-md"
          style={{ backgroundColor: categoryColor[category]?.colour }}
        >
          {mapping[category]?.icon}
        </div>
      </Tippy>
    </>
  );
}
