import { Box } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Transaction } from '../../../../../generated/graphql-frontend';
import TransactionItem from './TransactionItem';

interface DayTransactionProps {
  date: string;
  transactions: Transaction[];
}
const DayTransaction = React.memo(({ date, transactions }: DayTransactionProps) => {
  return (
    <React.Fragment key={uuidv4()}>
      <Box display="flex" my={1}>
        <div className="ml-4 flex-grow">
          <span className="text-abx-dark-grey text-base">{date}</span>
        </div>
        <div className="mr-8">
          <span
            className={`text-base ${
              Number(_.chain(transactions).sumBy('amount').value()) < 0
                ? 'text-abx-dark-blue'
                : 'text-abx-dark-grey'
            }`}
          >
            {Number(_.chain(transactions).sumBy('amount').value()).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
            &nbsp;
            {transactions[0].currency}
          </span>
        </div>
      </Box>
      <div className="py-1 mb-12 mt-4 bg-white rounded-xl">
        {transactions.map((transaction) => (
          <TransactionItem key={transaction.transaction_id} transaction={transaction} />
        ))}
      </div>
    </React.Fragment>
  );
});
export default React.memo(DayTransaction);
