/* eslint-disable no-use-before-define */
import React from 'react';
import { MultiSelect } from '../../../../../../Components/FormElements/MultiSelect';
import { AnalysisContext } from '../../../../Hooks/AnalysisContext';

interface CategoriesProps {
  loading?: boolean;
  categories: string[];
  type: string;
  placeholder: string;
  reset?: number;
}
export default function Category({ categories, type, placeholder, reset = 0 }: CategoriesProps) {
  const { filter, updateFilter } = React.useContext(AnalysisContext);

  const DownIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    );
  };

  return (
    <React.Fragment>
      <div className="flex flex-row w-full bg-abx-blue h-auto items-center">
        <MultiSelect
          options={categories}
          placeholder={placeholder}
          onChange={(type) => updateFilter({ ...filter, transaction_type: type })}
          reset={reset}
        />
        <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
          <DownIcon />
        </div>
      </div>
    </React.Fragment>
  );
}
