import { Box, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFlag } from '@unleash/proxy-client-react';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SwipeableViews from 'react-swipeable-views';
import { useCompanyStore } from '../../Context/GlobalStore';
import PlaidConnect from '../../Routes/DataManage/BankConnect/PlaidConnect';
import ABTypography from '../ABTypography';
import { LongButton } from '../LongButton';
import Step1 from './assets/step1.png';
import Step2 from './assets/step2.png';
import Step3 from './assets/step3.png';
import Step4 from './assets/step4.png';
import Step5 from './assets/step5.png';
import Stepper from './Stepper';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    height: 250
  },
  dot: {
    width: 30,
    height: 30,
    margin: 15
  },
  dotActive: {
    backgroundColor: theme.palette.error.main
  },
  setpperRoot: {
    backgroundColor: '#FFF'
  },
  progressRoot: {
    backgroundColor: '#FFF'
  }
}));

const useLinkToken = (token: string | null, companyId: string | null) => {
  const [linkToken, setLinkToken] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getLinkToken() {
      if (token !== null && companyId !== null) {
        const url = `${process.env.REACT_APP_BASE_URL}/external/plaid/link/${companyId}`;
        const header = new Headers();
        header.append('authorization', `Bearer ${token}`);
        const request = new Request(url, {
          method: 'GET',
          headers: header
        });
        const response = await fetch(request);
        const data = await response.text();
        setLinkToken(data);
        setLoading(false);
      }
    }
    getLinkToken()
      .then((res) => {})
      .catch((err) => {
        console.log('fetch Error:', err);
      });
  }, [companyId, token]);

  return { linkToken: linkToken, plaidLoading: loading };
};

interface WelcomeProps {
  role: Maybe<string> | undefined;
}

function Welcome({ role }: WelcomeProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const { currentCompanyId, bearerToken } = useCompanyStore();
  const { linkToken, plaidLoading } = useLinkToken(bearerToken, currentCompanyId);
  const [nextButton, setNextButton] = useState('Next');

  const analysisPageToggle = useFlag('analysis-page');
  const scenariosPageToggle = useFlag('Scenarios-page');
  const kpiPageToggle = useFlag('kpi-page');

  const tutorialSteps: {
    label: string;
    imgSrc: string;
    describe: string;
    enabled: boolean;
  }[] = [
    {
      label: 'Welcome to Alchemy Box',
      imgSrc: Step1,
      describe:
        'Welcome to Alchemy Box your CFO in the cloud, let’s get you started. The whole process will only take a couple of minutes.',
      enabled: true
    },
    {
      label: 'View your Key Performance Indicators',
      imgSrc: Step2,
      describe: 'Always know the health of your company with your Key Performance Indicators',
      enabled: kpiPageToggle
    },
    {
      label: 'Manage your cash flow',
      imgSrc: Step3,
      describe:
        'Always know where your money is going with a breakdown of all your company spending and income in one place',
      enabled: analysisPageToggle
    },
    {
      label: 'Predict the future ',
      imgSrc: Step4,
      describe:
        'Want to know if you can afford to hire that employee or make that big purchase. Know the impact of your decisions before you make them with scenarios',
      enabled: scenariosPageToggle
    },
    {
      label: 'Connect your business bank accounts',
      imgSrc: Step5,
      describe:
        'Securely connect your business bank accounts so your CFO can monitor your business while you sleep, no need to check your phone in the middle of the night!',
      enabled: false
    }
  ];

  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (activeStep === maxSteps - 2) {
      if (role?.toLocaleLowerCase() === 'editor' || role?.toLocaleLowerCase() === 'reviewer') {
        setNextButton('Get Started');
      }
    } else {
      setNextButton('Next');
    }
  }, [role, activeStep, maxSteps]);

  return (
    <div className={classes.root}>
      <Box mb={6}>
        <Typography variant="h3" align="center">
          {tutorialSteps[activeStep].label}
        </Typography>
      </Box>
      {!tutorialSteps[activeStep].enabled && <Typography color="secondary">Coming Soon</Typography>}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {tutorialSteps.map((step, index) => (
          <div key={step.label} style={{ display: 'flex', justifyContent: 'center' }}>
            {Math.abs(activeStep - index) <= 2 ? (
              <LazyLoadImage
                className={classes.img}
                effect="blur"
                src={step.imgSrc}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <Box height={70} mt={4}>
        <ABTypography>{tutorialSteps[activeStep].describe}</ABTypography>
      </Box>
      <Stepper steps={5} activeStep={activeStep} />
      <Box display="flex" mx={5}>
        <Box flexGrow={1}>
          <LongButton
            variant="outlined"
            color="primary"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            Back
          </LongButton>
        </Box>

        {activeStep === maxSteps - 1 ? (
          <PlaidConnect linkToken={linkToken} loading={plaidLoading} onboarding={true} />
        ) : (
          <LongButton
            variant="contained"
            color="primary"
            onClick={handleNext}
            style={{ marginLeft: 'auto' }}
          >
            {nextButton}
          </LongButton>
        )}
      </Box>
    </div>
  );
}

export default Welcome;
