import React from 'react';

export function createCtx<StateType, ActionType>(
  reducer: React.Reducer<StateType, ActionType>,
  initialState: StateType
) {
  const defaultDispatch: React.Dispatch<ActionType> = () => initialState; // we never actually use this
  const ctx = React.createContext({
    state: initialState,
    dispatch: defaultDispatch // just to mock out the dispatch type and make it not optioanl
  });

  function Provider(props: React.PropsWithChildren<{}>) {
    const [state, dispatch] = React.useReducer<React.Reducer<StateType, ActionType>>(
      reducer,
      initialState
    );
    const contextValue = React.useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return <ctx.Provider value={contextValue} {...props} />;
  }

  return [ctx, Provider] as const;
}
