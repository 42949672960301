import { useMutation } from '@apollo/client';
import {
  Box,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MoreIcon from '@material-ui/icons/MoreVertRounded';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ABLoading from '../../../Components/Loading/ABLoading';
import { COMPANY, DELETE_COMPANY } from '../../../Gqls/gql';
import Invite from '../../UserList/Component/InviteConfirm';
import Confirm from './CompanyDelete';
import EditCompanyModal from './EditCompanyModal';

export default function MoreIconMenu({ id, role, detail, email }) {
  const [open, setOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [editOpen, setEditOpen] = useState(false);
  const history = useHistory();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [deleteCompany, { loading: deleteLoading }] = useMutation(DELETE_COMPANY);

  const handleToggle = (event) => {
    event.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleDelete = (event) => {
    deleteCompany({
      variables: { id: id },
      update: (
        cache,
        {
          data: {
            deleteCompany: { id }
          }
        }
      ) => {
        const data = cache.readQuery({ query: COMPANY });
        let newData = data;
        newData = newData.users[0].companies.filter((item) => item.id !== id);
        cache.writeQuery({
          query: COMPANY,
          data: { users: [{ companies: newData }] }
        });
      }
    })
      .then((data) => {
        console.log(data);
      })
      .catch((res) => {
        console.log(res.message);
      });

    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const isDisabled = role?.toLowerCase() !== ('owner' || 'admin');
  return (
    <div style={{ display: isDisabled ? 'none' : 'block' }}>
      <span>
        {deleteLoading ? (
          <ABLoading />
        ) : (
          <Box py={5} pl={5} onClick={(event) => handleToggle(event)}>
            <IconButton ref={anchorRef} disabled={isDisabled}>
              <MoreIcon />
            </IconButton>
          </Box>
        )}
      </span>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      setEditOpen(true);
                      handleClose(event);
                    }}
                  >
                    <Typography mx={2}>Edit</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      setInviteOpen(true);
                    }}
                  >
                    <Typography mx={2}>Share</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      history.push(`/companies/${id}`);
                    }}
                  >
                    <Typography mx={2}>Users</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      setConfirmOpen(true);
                      handleClose(event);
                    }}
                    disabled={role !== 'owner'}
                  >
                    <Typography mx={2} color="error">
                      Delete
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Confirm
        title="Are you sure?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
        deleteName={detail.name}
      />
      {editOpen && (
        <EditCompanyModal id={id} open={editOpen} setOpen={setEditOpen} companyDetail={detail} />
      )}
      {inviteOpen && (
        <Invite companyID={id} setOpen={setInviteOpen} open={inviteOpen} email={email} />
      )}
    </div>
  );
}
