import { useTheme } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { HistoricalBreakdown } from '../../../../../generated/graphql-frontend';

interface Props {
  detailData: HistoricalBreakdown[];
}

export default function DetailChart({ detailData }: Props): ReactElement {
  const theme = useTheme();
  return (
    <div style={{ height: 150, color: 'white' }}>
      <ResponsiveContainer>
        <BarChart width={500} data={detailData} maxBarSize={60}>
          <XAxis
            dataKey="date"
            stroke="white"
            //tickFormatter={(value: string) => value.split(' ')[0]}
            tickLine={false}
            axisLine={false}
          />
          <YAxis hide />
          <Tooltip cursor={{ fill: 'transparent' }} />
          <Bar dataKey="amount" fill={theme.palette.secondary.main} background={{ fill: '#eee' }} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
