import {
  Box,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import _ from 'lodash';
import React, { ReactElement, useState } from 'react';
import ABTypography from '../../../Components/ABTypography';
import { useCompanyStore } from '../../../Context/GlobalStore';

interface Data {
  date: string;
  cashInBank: number;
  profit: number;
  income: number;
  expenses: number;
}

function createData(
  date: string,
  cashInBank: number,
  profit: number,
  income: number,
  expenses: number
): Data {
  return { date, cashInBank, profit, income, expenses };
}

const rows = [
  createData('January', 230423, -282.21, 2304.23, 2521.39),
  createData('February', 1987.23, 30.66, 1950.83, 1932.02),
  createData('March', 3213.98, 177.83, 3092.12, 1203.21),
  createData('January', 230423, -282.21, 2304.23, 2521.39),
  createData('February', 1987.23, 30.66, 1950.83, 1932.02),
  createData('March', 3213.98, 177.83, 3092.12, 1203.21)
];

const CustomHeadCell = withStyles((theme) =>
  createStyles({
    icon: {
      opacity: 1
    }
  })
)(TableSortLabel);

interface HeadCell {
  disabledPadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'date', numeric: false, disabledPadding: true, label: 'Date' },
  { id: 'cashInBank', numeric: true, disabledPadding: false, label: 'Cash in Bank' },
  { id: 'profit', numeric: true, disabledPadding: false, label: 'Profit' },
  { id: 'income', numeric: true, disabledPadding: false, label: 'Income' },
  { id: 'expenses', numeric: true, disabledPadding: false, label: 'Expenses' }
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order?: Order;
  orderBy?: string;
}

function EnhancedTableHead({
  onRequestSort,
  order = 'desc',
  orderBy = 'date'
}: EnhancedTableProps): ReactElement {
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell align={headCell.numeric ? 'center' : 'left'}>
            <CustomHeadCell
              IconComponent={ArrowDropDownIcon}
              onClick={createSortHandler(headCell.id)}
              direction={orderBy === headCell.id ? order : 'asc'}
            >
              {headCell.label}
            </CustomHeadCell>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

type Order = 'asc' | 'desc';
const dataSort = (array: any[], key: string, order: Order): any[] => {
  if (order === 'desc') return _.chain(array).sortBy(key).reverse().value();
  else return _.chain(array).sortBy(key).value();
};

export default function DataTable(): ReactElement {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('date');
  const { profile } = useCompanyStore();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  return (
    <div>
      <ABTypography mt={5} variant="h3">
        Data Summary
      </ABTypography>
      <ABTypography mb={8} variant="body1">
        See period-by-period values for your scenario, all values are in{' '}
        <strong>{profile?.currency} </strong>{' '}
      </ABTypography>
      <Box maxHeight="40vh">
        <TableContainer>
          <Table>
            <EnhancedTableHead onRequestSort={handleRequestSort} orderBy={orderBy} order={order} />
            <TableBody>
              {dataSort(rows, orderBy, order).map((row, index) => (
                <TableRow key={`data-row-${index}`}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell align="center">
                    <ABTypography color={row.cashInBank < 0 ? 'secondary' : undefined}>
                      {row.cashInBank}
                    </ABTypography>
                  </TableCell>
                  <TableCell align="center">
                    <ABTypography color={row.profit < 0 ? 'secondary' : undefined}>
                      {row.profit}
                    </ABTypography>
                  </TableCell>
                  <TableCell align="center">
                    <ABTypography color={row.income < 0 ? 'secondary' : undefined}>
                      {row.income}
                    </ABTypography>
                  </TableCell>
                  <TableCell align="center">
                    <ABTypography color={row.expenses < 0 ? 'secondary' : undefined}>
                      {row.expenses}
                    </ABTypography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
