import { ButtonBase, InputLabel, ListItem, Typography } from '@material-ui/core';
import MuiAutocomplete, {
  AutocompleteChangeDetails,
  AutocompleteCloseReason
} from '@material-ui/core/Autocomplete';
import InputBase from '@material-ui/core/InputBase';
import Popper from '@material-ui/core/Popper';
import { alpha, makeStyles, styled, Theme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import React from 'react';
interface ClassNameProps {
  minWidth?: number;
}
const useStyles = makeStyles<Theme, ClassNameProps>((theme) => ({
  outlineButton: {
    color: 'inherit',
    borderRadius: 0,
    backgroundColor: 'transparent',
    borderBottom: '1px solid'
  },
  popper: {
    borderRadius: 0,
    zIndex: theme.zIndex.modal,
    backgroundColor: '#FFF'
  },
  inputBase: {
    padding: 10,
    borderBottom: '1px solid rgba(0,0,0,0.03)',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      outline: 'none',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent'
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    }
  },
  popperDisablePortal: {
    position: 'relative'
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  },
  upIcon: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  downIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  noSelect: {
    color: 'transparent',
    outline: 'none'
  }
}));
type Variant = 'filled' | 'outlined';

const Button = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  paddingBottom: '8px !important',
  borderBottom: '1px solid #DAEAF6 !important',
  '& svg': {
    width: 16,
    height: 16
  }
}));

interface AutProps {
  id?: string;
  options: string[];
  label?: string;
  value?: string | string[];
  defaultValue?: string | string[];
  name?: string;
  loading?: boolean;
  multiple?: boolean;
  variant?: Variant;
  minWidth?: number;
  required?: boolean;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | string[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => void;
}

const Autocomplete = React.memo(
  ({
    id,
    options,
    label,
    defaultValue,
    multiple,
    onChange,
    value,
    name,
    loading,
    minWidth,
    required,
    variant = 'filled'
  }: AutProps) => {
    const classes = useStyles({ minWidth: 300 });
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
      if (reason === 'toggleInput') {
        return;
      }
      if (multiple === true && reason === 'select-option') return;
      if (anchorEl) {
        anchorEl.focus();
      }
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const buttonValue = value ?? defaultValue ?? 'Select';
    // const buttonValue = value;

    return (
      <div>
        <InputLabel htmlFor="component-outlined" className={clsx({ [classes.noSelect]: !value })}>
          {name}
        </InputLabel>
        <Button disableRipple onClick={handleClick} style={{ minWidth: minWidth }}>
          <Typography noWrap textOverflow="ellipsis" width="100%">
            {buttonValue}
          </Typography>
          <KeyboardArrowDownIcon
            className={clsx(classes.upIcon, { [classes.downIcon]: open })}
            fontSize="large"
          />
        </Button>
        <Popper open={open} anchorEl={anchorEl} placement="bottom-start" className={classes.popper}>
          <MuiAutocomplete
            id={id}
            open={true}
            defaultValue={defaultValue}
            multiple={multiple}
            onClose={handleClose}
            noOptionsText="No labels"
            options={options}
            disablePortal
            onChange={onChange}
            value={multiple === false ? value : (value as string[])}
            loading={loading}
            renderOption={(props, option, { selected }) => (
              <React.Fragment key={`${option}`}>
                <ListItem {...props} selected={selected}>
                  {option}
                </ListItem>
              </React.Fragment>
            )}
            classes={{ popper: classes.popper, paper: classes.paper }}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                placeholder="Search ..."
                className={classes.inputBase}
                required={required}
              />
            )}
          />
        </Popper>
      </div>
    );
  }
);
export default Autocomplete;
