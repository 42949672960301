import { Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

type Button = {
  text: string;
  style: string;
  link: string;
};

interface NotificationContentProps {
  title?: string;
  message: string;
  image?: null | string; //React.ReactNode;
  show_close: boolean;
  timeout: number;
  first_button?: null | Button;
  second_button?: null | Button;
}

// interface MessageProps {
//   msg?: string;
//   reply?: boolean;
// }

export default function BotNotification({
  title = 'Success!',
  message = 'Your invitation has been sent',
  image = null,
  show_close = true,
  timeout = 5000,
  first_button = null,
  second_button = null
}: NotificationContentProps) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setOpen(true);
  }, []);

  // const MsgBubble = ({ msg = '', reply = false }: MessageProps) => {
  //   if (!reply) {
  //     return (
  //       <div className="">
  //         <div className="bg-abx-orange w-4 h-4 mb-2 animate-shake" />
  //         <div className="bg-abx-dark-blue rounded-r-lg rounded-bl-lg text-white p-4 font-light animate-menuOpen">
  //           {msg}
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return <div>{msg}</div>;
  //   }
  // };

  const mainStyle =
    'shadow-none bottom-0 right-0 mr-10 bg-abx-light-blue p-8 rounded-xl min-w-xs max-w-sm opacity-95 max-h-md';
  const mainTransition = 'fixed shadow-none bottom-0 right-0 mr-10 z-50';
  const get_button_style = (btn: null | Button) => {
    if (btn != null) {
      if (btn.style === 'outline') {
        return 'btn-outline-blue';
      } else if (btn.style === 'main') {
        return 'btn-dark-blue';
      } else if (btn.style === 'light') {
        return 'btn-light-blue';
      } else if (btn.style === 'orange') {
        return 'btn-orange';
      }
    }
  };

  const handle_click = (link: string) => {
    if (link === 'back') {
      history.goBack();
    } else {
      history.push(link);
    }
  };

  setTimeout(() => {
    setOpen(false);
  }, timeout);

  return (
    <Transition
      show={open}
      className={`${mainTransition} transform`}
      enter="transition duration-1000 transform"
      enterFrom="translate-y-full opacity-0"
      enterTo="-translate-y-0"
      leave="transition duration-1000 transform"
      leaveFrom="-translate-y-0"
      leaveTo="translate-y-full opacity-0"
    >
      {/*<div className={ open ? `${mainStyle} animate-menuOpen` : `${mainStyle} animate-menuClose`}>*/}
      <div className={`${mainStyle} transform -translate-y-10`}>
        <div className="m-0">
          <div className={`float-right ${show_close ? '' : 'hidden'}`}>
            <div className="cursor-pointer" onClick={() => setOpen(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-abx-blue hover:text-abx-pink"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <div className="font-semibold py-5 text-lg">{title}</div>
        </div>
        <div>
          <div className="">
            <div className="bg-abx-orange w-4 h-4 mb-2 animate-shake delay-1000" />
            <div className="bg-abx-dark-blue rounded-r-lg rounded-bl-lg text-white p-4 font-light delay-1000 animate-pop">
              {message}
            </div>
          </div>
        </div>
        <div className="py-4 grid justify-center">
          {image ? <img src={image} className="" alt="logo" /> : <div />}
        </div>
        <div className="pt-8 grid grid-flow-col grid-cols-2 grid-rows-1 gap-4 justify-center">
          <div>
            {second_button != null ? (
              <button
                className={`w-full ${get_button_style(second_button)}`}
                onClick={() => handle_click(second_button.link)}
              >
                {second_button.text}
              </button>
            ) : (
              <div />
            )}
          </div>
          <div>
            {first_button != null ? (
              <button
                className={`w-full ${get_button_style(first_button)}`}
                onClick={() => handle_click(first_button.link)}
              >
                {first_button.text}
              </button>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    </Transition>
  );
}
