import { TextField, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import ABTypography from "../../../../../Components/ABTypography";

export default function InfestForm(): ReactElement {
  return (
    <div>
      {/* name */}
      <ABTypography color="primary" mt={4}>
        What do you want to name this?
      </ABTypography>
      <TextField name="display_name" id="display_name" label="Display Name" variant="standard" />

      {/* limit */}
      <ABTypography color="primary" mt={4}>
        How much are you recieving?
      </ABTypography>
      <TextField name="limit" id="limit" label="Limit" variant="standard" />

      {/* Owe */}
      <ABTypography color="primary" mt={4}>
        When are you recieving it?
      </ABTypography>
      <Typography color = "" >this os </Typography>
      <TextField name="outstand_balance" id="outstand_balance" label="Outstanding Balance" variant="standard" />
    </div>
  );
}
