import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Download from './Download';
import Filter from './Filter';
import FilterChipList from './Filter/FilterChipList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3)
  },
  chipContainer: {
    display: 'flex',
    flexGrow: 1
  }
}));

export default function TransactionHeader() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Typography variant="h3">Transactions</Typography>
        <Box pl={3}>
          <Filter />
        </Box>
        <Box marginLeft="auto">
          <Download />
        </Box>
      </div>
      <div className={classes.chipContainer}>
        <FilterChipList />
      </div>
    </div>
  );
}
