import { Box, Grid } from '@material-ui/core';
import React from 'react';
import ABTypography from '../../../../../Components/ABTypography';
import GoogleDrive from '../../../../../Components/assets/google-drive.png';
import { ConnectedIntegration } from '../../../../../generated/graphql-frontend';

interface ConfirmModalProps {
  tool: ConnectedIntegration;
}

export default function Header({ tool }: ConfirmModalProps) {
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={7}>
        <Box my={2}>
          <ABTypography color="primary">Connect an Integration</ABTypography>
        </Box>
        <ABTypography variant="h1" color="primary">
          {tool.display_name}
        </ABTypography>
      </Grid>
      <Grid item xs={5}>
        <img src={tool.image_url} alt="google" width="100%" height={150} />
      </Grid>
    </Grid>
  );
}
