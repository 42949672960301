import React from 'react';
import Autocomplete from '../../../../../Components/Autocomplete';
import { useIntegrateStore } from '../../../hooks/IntegrateStore';

const fields = [
  {
    fieldName: 'name',
    label: 'Transaction Name',
    required: true
  },
  {
    fieldName: 'date',
    label: 'Transaction Date',
    required: true
  },
  {
    fieldName: 'amount',
    label: 'Amount',
    required: true
  },
  {
    fieldName: 'cus_id',
    label: 'Customer ID',
    required: false
  }
];

interface Props {
  setColumnLoading: (loading: boolean) => void;
  mappedValue: {
    [name: string]: string;
  };
  setMappedValue: React.Dispatch<
    React.SetStateAction<{
      [name: string]: string;
    }>
  >;
}

const Map = ({ setColumnLoading, setMappedValue, mappedValue }: Props) => {
  const { currentColumn } = useIntegrateStore();

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | string[] | null
  ) => {
    const domId = event.currentTarget.id;
    const fieldName = domId.split('-')[0];
    setMappedValue((prev) => ({ ...prev, [fieldName]: value as string }));
  };

  React.useEffect(() => {
    const mappedString = Object.keys(mappedValue).toString();
    const requiredFields = fields.filter((item) => item.required === true);
    if (
      requiredFields.filter((item) => mappedString.includes(item.fieldName)).length ===
      requiredFields.length
    ) {
      setColumnLoading(false);
    }
  }, [mappedValue]);

  return (
    <div>
      {fields.map((field, index) => (
        <div key={`field-${index}`} className="mt-2 bg-abx-blue text-white p-2">
          <Autocomplete
            key={field.fieldName}
            multiple={false}
            name=""
            id={field.fieldName}
            options={currentColumn}
            label={field.required ? `Column ${field.label}` : `Column ${field.label} *`}
            onChange={handleChange}
            value={mappedValue[field.fieldName] || ''}
            loading={true}
            minWidth={300}
          />
        </div>
      ))}
      <div className="mt-4">
        <span className="text-abx-dark-blue mt-4">* Optional</span>
      </div>
    </div>
  );
};

export default React.memo(Map);
