import { withStyles } from '@material-ui/core/styles';
import Tab, { TabProps } from '@material-ui/core/Tab';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';
import React from 'react';
import { UploadContext } from '../../UploadContext';
import { UploadStep } from './Const';

const DropZoneTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 3,
    '& > span': {
      maxWidth: 150,
      width: '100%',
      height: '4px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 1.5
    }
  },
  scroller: {
    overflow: 'visible'
  }
}))((props: TabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const DropZoneTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    '&:focus': {
      opacity: 1
    },
    borderBottom: `solid 2px ${theme.palette.primary.light}`
  }
}))((props: TabProps) => <Tab disableRipple {...props} />);

export default function Header() {
  const { state, dispatch } = React.useContext(UploadContext);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch({ type: 'setCurrentStep', payload: newValue });
  };

  return (
    <DropZoneTabs
      variant="fullWidth"
      value={state.currentStep}
      indicatorColor="primary"
      textColor="primary"
      aria-label="disabled tabs example"
      onChange={handleChange}
    >
      <DropZoneTab label="Load File" value={UploadStep.LOAD} disableRipple />
      <DropZoneTab
        label="Map Data"
        value={UploadStep.MAPPING}
        disableRipple
        disabled={state.currentStep === UploadStep.LOAD}
      />
      <DropZoneTab
        label="Review Data"
        value={UploadStep.REVIEW}
        disableRipple
        disabled={state.currentStep !== UploadStep.REVIEW}
      />
    </DropZoneTabs>
  );
}
