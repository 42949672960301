import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ABTypography from '../../../../../Components/ABTypography';
import Autocomplete from '../../../../../Components/Autocomplete';
import ABLoading from '../../../../../Components/Loading/ABLoading';
import { useCompanyStore } from '../../../../../Context/GlobalStore';
import {
  useGetTransactionNameQuery,
  useUpdateIntegrationMutation
} from '../../../../../generated/graphql-frontend';
import { useIntegrateStore } from '../../../hooks/IntegrateStore';
import { useUrlQuery } from '../../../hooks/useUrlQuery';
import { STEP_FOUR, STEP_ONE, STEP_THREE, STEP_TWO } from '../../Const';
import FileTitle from './FileTitle';
import ImportFile from './ImportFile';
import Map from './Map';

const drawMinWidth = 350;

const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: drawMinWidth,
    display: 'flex',
    zIndex: '1000 !important' as any
  },
  headContainer: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    height: '20vh'
  },
  close: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  btnContainer: {
    position: 'absolute',
    padding: theme.spacing(3),
    bottom: 0
  }
}));

const Drawer = styled(MuiDrawer)`
  & .MuiDrawer-paperAnchorRight {
    background-color: #daeaf6;
  }
`;

const drawTitle = [
  {
    title: 'Select a file to monitor'
  },
  {
    title: 'Map your Data to Alchemy Box'
  },
  {
    title: 'Do these replace any bank transactions?'
  },
  {
    title: 'Added file'
  }
];

const cloudServiceNames: { [name: string]: string } = {
  google: 'Google Drive',
  microsoft: 'OneDrive',
  dropbox: 'DropBox'
};

interface ParamsProps {
  company_id: string;
}
export default function SideDrawer() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(STEP_ONE);
  const { currentProviderName, currentFileName, currentFileId, openDrawer, setOpenDrawer } =
    useIntegrateStore();
  const { currentCompanyId } = useCompanyStore();
  const { company_id } = useParams<ParamsProps>();
  const [mappedValue, setMappedValue] = React.useState<{ [name: string]: string }>({});
  const [ignoreTransaction, setIgnoreTransaction] = useState<string | string[]>([]);
  const [loadingColumn, setLoadingColumn] = useState<boolean>(true);
  console.log('loading column:', loadingColumn);
  const urlQuery = useUrlQuery();
  const provider = urlQuery.get('provider');
  const [updateIntegration, { loading }] = useUpdateIntegrationMutation({
    variables: {
      company_id: company_id,
      mapping: JSON.stringify(mappedValue),
      item_id: currentFileId,
      provider: provider!,
      replace_transactions: ignoreTransaction
    }
  });
  const nextRef = React.useRef<HTMLButtonElement>(null);

  const handleConfirmClose = () => {
    setOpenDrawer(false);
    setActiveStep(STEP_ONE);
  };
  const toggleLoadingColumn = (loading: boolean) => {
    setLoadingColumn(loading);
  };
  const handleFinish = async () => {
    try {
      await updateIntegration();
      setOpenDrawer(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleNextButton = () => {
    setActiveStep((prev) => prev + 1);
    if (activeStep === STEP_ONE) setLoadingColumn(true);
    nextRef.current?.click();
  };

  const getContent = (step: number, provider: string) => {
    switch (step) {
      case STEP_ONE:
        return <ImportFile providerName={provider} />;
      case STEP_TWO:
        return (
          <Map
            setColumnLoading={setLoadingColumn}
            mappedValue={mappedValue}
            setMappedValue={setMappedValue}
          />
        );
      case STEP_THREE:
        return (
          <TransactionToIgnore
            ignoreTransfer={ignoreTransaction}
            setIgnoreTransaction={setIgnoreTransaction}
          />
        );
      case STEP_FOUR:
        return <FileLists />;
      default:
        break;
    }
  };

  return (
    <Drawer
      open={openDrawer}
      anchor="right"
      className={classes.drawer}
      classes={{ paper: classes.drawer }}
    >
      <Box display="flex" flexDirection="column" className={classes.close}>
        <IconButton onClick={() => handleConfirmClose()} size="small">
          <CloseIcon style={{ color: 'white' }} fontSize="large" />
        </IconButton>

        <Tooltip title="Help">
          <IconButton size="small">
            <HelpIcon style={{ color: 'white' }} fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>
      <div className={classes.headContainer}>
        <Box mx={3} my={5}>
          <ABTypography abColor="white">{drawTitle[activeStep].title}</ABTypography>
          <ABTypography abColor="white" variant="h3">
            {cloudServiceNames[currentProviderName ?? 'google']}
          </ABTypography>
        </Box>
      </div>
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="wrap"
        height="500px"
        justifyContent="center"
        padding={5}
      >
        <Box display="flex" alignContent="space-around">
          {currentProviderName && getContent(activeStep, currentProviderName)}
        </Box>
        {currentProviderName && currentCompanyId && currentFileId && activeStep === STEP_ONE && (
          <FileTitle
            title={currentFileName}
            file_id={currentFileId}
            provider={currentProviderName}
            company_id={currentCompanyId}
            toggleLoading={toggleLoadingColumn}
          />
        )}
      </Box>
      <Grid container className={classes.btnContainer}>
        {activeStep > STEP_ONE && activeStep < STEP_FOUR && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setActiveStep((prev) => prev - 1)}
          >
            &lt; Back
          </Button>
        )}
        {activeStep < STEP_FOUR && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextButton}
            style={{ marginRight: 0, marginLeft: 'auto' }}
            disabled={loadingColumn}
          >
            Next &gt;
          </Button>
        )}
        {activeStep === STEP_FOUR && (
          <Button
            variant="contained"
            style={{ marginRight: 0, marginLeft: 'auto' }}
            onClick={handleFinish}
            disabled={loading}
          >
            {loading && <ABLoading />}
            Finish
          </Button>
        )}
      </Grid>
      {/*<CloseConfirm
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirmClose}
        title="Are you sure?"
        children="Closing the sidebar will disconnect this service."
      />*/}
    </Drawer>
  );
}

interface IgnoreProps {
  ignoreTransfer: string | string[];
  setIgnoreTransaction: React.Dispatch<React.SetStateAction<string | string[]>>;
}
const TransactionToIgnore = ({ ignoreTransfer, setIgnoreTransaction }: IgnoreProps) => {
  const { data, loading } = useGetTransactionNameQuery();
  const handleClickIgnore = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | string[] | null
  ) => {
    if (value) {
      setIgnoreTransaction(value);
    }
  };
  if (!data?.transaction_info) return <div>Loading sidebar...</div>;
  return (
    <div className="flex flex-col">
      <div className="p-2 bg-abx-blue text-white">
        <Autocomplete
          label="Transactions to ignore*"
          multiple={true}
          options={data!.transaction_info!.map((item) => item!.transaction_name)}
          onChange={handleClickIgnore}
          value={ignoreTransfer}
          name=""
          loading={loading}
          minWidth={300}
        />
      </div>
      <div className="mt-4">
        <span className="text-abx-dark-blue mt-4">* Optional</span>
      </div>
    </div>
  );
};

const useStyles1 = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    minWidth: 300
  },
  list: {
    margin: theme.spacing(3)
  }
}));
const FileLists = () => {
  const classes = useStyles1();
  const { currentFileName } = useIntegrateStore();

  return (
    <List className={classes.list}>
      <Box p={2} className={classes.listItem}>
        <Typography>{currentFileName}</Typography>
      </Box>
    </List>
  );
};
