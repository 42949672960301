import { Button, ButtonProps, experimentalStyled } from "@material-ui/core";
import React, { ReactElement } from "react";

export const IconWrapperButton = experimentalStyled((props: ButtonProps) => <Button {...props} />)(({ theme }) => ({
  borderRadius: 10,
  padding: 15,
  margin: 20,
  minWidth: 110,

  "& .MuiButton-root": {
    padding: theme.spacing(3),
    margin: theme.spacing(4),
    minWidth: 110,
  },
  "& .MuiButton-label": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

interface Props extends ButtonProps {
  children?: React.ReactNode;
  selected?: boolean;
}

export function ButtonContainer({ children, selected, ...props }: Props): ReactElement {
  return (
    <IconWrapperButton variant="contained" color="primary" {...props}>
      {children}
    </IconWrapperButton>
  );
}
