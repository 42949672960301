import { Box, Button, Dialog, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { ReactElement } from "react";
import { ReactComponent as UpgradeBG } from "../Components/assets/upgrade.svg";
import ABTypography from "./ABTypography";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: 20,
    top: 20,
  },
}));

interface Props {
  open: boolean;
  toggle: () => void;
  name: string;
}

export default function Upgrade({ name, open, toggle }: Props): ReactElement {
  const classes = useStyles();
  const handleClose = () => {
    toggle();
  };
  return (
    <Dialog open={open} maxWidth="sm">
      <Box className={classes.closeButton}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>

      <Box my={10} mx={8}>
        <ABTypography variant="h2" align="center">
          Upgrade your plan
        </ABTypography>
        <Typography component="p" my={2}>
          You have added the maximum number of companies allowed in the Alchemy Box insider program.
        </Typography>
        <Typography component="p" my={2}>
          As part of the program you can only add one company
        </Typography>
        <Typography component="p">Adding more companies will be available soon.</Typography>
        <Box my={7} textAlign="center">
          <UpgradeBG />
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" disabled>
            Upgrade Now
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
