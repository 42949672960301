import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { useCompanyStore } from '../../../Context/GlobalStore';
import { useGetKpiDrilldownQuery } from '../../../generated/graphql-frontend';
import { formatCash } from '../../../utils/formatCash';

function CashChartWIP() {
  const { company_id } = useParams();
  const [drillDown, setDrillDown] = React.useState('monthly');
  const cib = 'cash_in_bank';
  const { data } = useGetKpiDrilldownQuery({
    variables: { company_id: company_id, kpi: cib }
  });
  const { profile } = useCompanyStore();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white rounded-lg opacity-95">
          <p className="label">{`${label} : ${payload[0].value.toLocaleString(undefined, {
            minimumFractionDigits: 2
          })} ${profile.currency}`}</p>
          {drillDown === 'monthly' && <p className="desc">Click to view more</p>}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="p-2">
      <div className="flex flex-row w-full mt-8">
        <span className="text-abx-dark-blue text-lg flex-none font-semibold">Cash in the Bank</span>
        <div className="flex-grow"></div>
        <span className="font-normal flex-none">
          {`${data?.user?.company.kpi_drilldown['monthly'][0]['amount'].toLocaleString(undefined, {
            minimumFractionDigits: 2
          })} ${profile.currency}`}
        </span>
      </div>
      <div className="flex-col pb-4 pt-2">
        <p className="text-abx-dark-blue text-md">
          {drillDown !== 'monthly' ? (
            <div>
              Cash in the bank
              <span className="font-semibold">
                {data?.user?.company.kpi_drilldown[drillDown][0]['date']}
              </span>
              to
              <span className="font-semibold">
                {
                  data?.user?.company.kpi_drilldown[drillDown][
                    data?.user?.company.kpi_drilldown[drillDown].length - 1
                  ]['date']
                }
              </span>
            </div>
          ) : (
            'Monthly cash in the bank'
          )}
        </p>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          width={450}
          height={300}
          data={
            drillDown === 'monthly'
              ? data?.user?.company.kpi_drilldown[drillDown].slice(0, 12).reverse()
              : data?.user?.company.kpi_drilldown[drillDown]
          }
          margin={{
            top: 5,
            right: 0,
            left: -10,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <XAxis dataKey="date" interval="preserveStartEnd" />
          <YAxis
            padding={{ bottom: 20, top: 20 }}
            tickFormatter={(value) => formatCash(value)}
            tickLine={false}
            axisLine={false}
          />
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke="#000" alwaysShow={true} />
          <Bar dataKey="amount" fill="#FF008E" />
        </BarChart>
      </ResponsiveContainer>
      {drillDown !== 'monthly' && (
        <button className="btn-dark-blue" onClick={() => setDrillDown('monthly')}>
          Month View
        </button>
      )}
    </div>
  );
}

export default React.memo(CashChartWIP);
