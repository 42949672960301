/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { AnalysisContext } from '../../../../Hooks/AnalysisContext';

interface CategoriesProps {
  loading?: boolean;
  type: string;
  placeholder: string;
  reset?: number;
  defaultValue?: string;
}
export default function Category({ type, placeholder, reset = 0 }: CategoriesProps) {
  const { filter = {}, updateFilter } = React.useContext(AnalysisContext);
  const [value, setValue] = useState<string>(filter.search ?? '');

  React.useEffect(() => {
    if (filter?.search) setValue(filter.search);
  }, [filter?.search]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value && value !== filter.search) updateFilter({ ...filter, search: value });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [value, updateFilter, filter]);

  const SearchIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    );
  };

  return (
    <React.Fragment>
      <div className="flex flex-row bg-abx-dark-blue h-16">
        <input
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
          className="border border-abx-blue w-full p-4 h-full text-white focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue"
        />
        <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
          <SearchIcon />
        </div>
      </div>
    </React.Fragment>
  );
}
