import { Box, Typography } from '@material-ui/core';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiIconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useState } from 'react';
import styled from 'styled-components';
import ABTypography from '../../../../../Components/ABTypography';
import FilterBody from './FilterBody';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },

  drawer: {
    overflowY: 'scroll',
    minWidth: 460,
    width: 480
  },
  headContainer: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    height: '20vh'
  },
  close: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  btnContainer: {
    position: 'absolute',
    padding: theme.spacing(3),
    bottom: 0
  }
}));

const Drawer = styled(MuiDrawer)`
  & .MuiDrawer-paperAnchorRight {
    background-color: #daeaf6;
  }
`;

const IconButton = withStyles({
  root: {
    padding: 5
  }
})(MuiIconButton);

export default function Filter() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const handleFilterButton = () => {
    setOpenDrawer(true);
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <IconButton onClick={handleFilterButton} color="inherit">
        <FilterListIcon style={{ color: theme.palette.primary.dark }} />
      </IconButton>
      <ABTypography variant="body1">Filter</ABTypography>
      <Drawer
        open={openDrawer}
        anchor="right"
        classes={{ paper: classes.drawer }}
        onClose={handleClose}
      >
        <Box display="flex" flexDirection="column" className={classes.close}>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: 'white' }} fontSize="large" />
          </IconButton>
        </Box>
        <div className={classes.headContainer}>
          <Box mx={3} my={5} color="white">
            <Typography variant="body1">Filter</Typography>
            <Typography variant="h3">Filter your transactions</Typography>
          </Box>
        </div>
        <FilterBody />
      </Drawer>
    </div>
  );
}
