import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { ReactElement } from "react";

function createData(name: string, amount: number, period: number) {
  return { name, amount, period };
}

const rows = [createData("Cookies", 159, 6.0), createData("Amount", 237, 9.0), createData("Period", 262, 16.0)];

interface Props {
  title?: string;
}

export default function TabTable({ title }: Props): ReactElement {
  const theme = useTheme();
  return (
    <TableContainer>
      <Typography variant="subtitle2" color={theme.palette.primary.dark}>
        {title}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel direction="asc" active IconComponent={ArrowDropUpIcon}>
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">
              <TableSortLabel direction="asc" IconComponent={ArrowDropUpIcon}>
                Amount
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">
              <TableSortLabel direction="asc" IconComponent={ArrowDropUpIcon}>
                Period
              </TableSortLabel>
            </TableCell>
            <TableCell padding="checkbox"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={row.name}>
              <TableCell>{row.name}</TableCell>
              <TableCell align="center">{row.amount}</TableCell>
              <TableCell align="center">{row.period}</TableCell>
              <TableCell padding="checkbox">
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
