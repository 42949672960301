import { Box, Button, Container, LinearProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as NoTransactionsBG } from '../../../../Components/assets/NoTransactions.svg';
import BasicContainer from '../../../../Containers/BasicContainer';
import Header from '../../../../Containers/Header';
import { useGetUserInCompanyQuery } from '../../../../generated/graphql-frontend';
import { isEmpty } from '../../../../utils/isEmpty';

interface NoTransProps {
  company_id: string;
}

export default function NoTransaction({ company_id }: NoTransProps) {
  const { data, loading, error } = useGetUserInCompanyQuery({
    variables: { company_id: company_id }
  });
  const history = useHistory();
  const role = data?.user?.companies?.find((company) => !isEmpty(company))?.role;
  const handleAddData = () => {
    history.push(`/data/${company_id}`);
  };

  if (loading) return <LinearProgress />;

  return (
    <Container>
      <Header />
      <BasicContainer>
        <Grid container justifyContent="space-between">
          <Grid item sm={12} md={6}>
            <Box mt={5}>
              <Typography variant="h2">No transactions</Typography>
              <br />
              <Typography>
                You have connected an account but there are no transactions yet, as soon as we get
                some transactions we will display information here.
              </Typography>
              <br />
              {role === 'owner' ? (
                <Typography>
                  You can connect multiple bank accounts and apps, so why not connect another data
                  source to get started.
                </Typography>
              ) : (
                <Typography>
                  You can connect multiple bank accounts and apps, so why not ask the account owner
                  to connect another account.
                </Typography>
              )}
            </Box>
            <Box mt={10}>
              {role === 'owner' || role === 'admin' ? (
                <Button variant="contained" onClick={handleAddData}>
                  Add Data
                </Button>
              ) : (
                <Button>Notify account owner</Button>
              )}
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <NoTransactionsBG width="100%" />
          </Grid>
        </Grid>
      </BasicContainer>
    </Container>
  );
}
