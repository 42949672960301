import { useMutation } from '@apollo/client';
import { Autocomplete, Box, Dialog, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { parse } from 'date-fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ABLoading from '../../../Components/Loading/ABLoading';
import { LongButton } from '../../../Components/LongButton';
import { COUNTRY_CODE } from '../../../Consts/CountryCode';
import { BUSINESS_TYPE } from '../../../Consts/FormData';
import { position_in_company } from '../../../Consts/Position';
import RegionData from '../../../Consts/RegionData.json';
import { UPDATE_COMPANY } from '../../../Gqls/gql';
import { getCountryCode } from '../../../utils/getCountryCode';
import { removeDup } from '../../../utils/removeDup';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));
const validationSchema = yup.object({
  companyName: yup.string().required('Company name is required'),
  description: yup.string().required('Description is required'),
  businessType: yup.object().nullable().required('Business type is required'),
  country: yup.string().nullable().required('Country is required'),
  city: yup.string().nullable().required('City is required'),
  date: yup.string().required('Date is required'),
  numEmploy: yup.string().required('Number of Employ is required'),
  posInCompany: yup.string().required('Position in your company required')
});

export default function EditCompanyModal({ id, open, setOpen, companyDetail }) {
  const [updateCompany, { loading }] = useMutation(UPDATE_COMPANY);

  const formik = useFormik({
    initialValues: {
      companyName: companyDetail.name,
      description: companyDetail.description,
      businessType: {
        category: '',
        items: companyDetail.business_type
      },
      country: companyDetail.country,

      city: companyDetail.city,
      date: companyDetail.date_founded,
      numEmploy: companyDetail.no_employees,
      posInCompany: companyDetail.position
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const companyInfo = {
        id: id,
        name: values.companyName,
        description: values.description,
        business_type: values.businessType.items,
        country: values.country,
        city: values.city,
        date_founded: values.date,
        no_employees: values.numEmploy,
        position: values.posInCompany
      };
      updateCompany({
        variables: { input: { ...companyInfo } },
        update: (cache, { data: { updateCompany } }) => {
          if (updateCompany.error === null) {
            const cacheId = cache.identify(updateCompany.object);
            cache.modify({
              fields: {
                users: (existingFieldData = [], { toReference }) => {
                  return [...existingFieldData, toReference(cacheId)];
                }
              }
            });
          }
        }
      });
    }
  });

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      aria-labelledby="company-dialog-title"
      open={open}
      fullWidth
      maxWidth={'sm'}
      onClose={handleClose}
      onClick={(event) => {
        event.stopPropagation();
      }}
      className={classes.dialog}
    >
      <Box mx={10} display="flex" flexDirection="column">
        <Box marginTop={8}>
          <Typography variant="h2" align="center">
            Edit Company
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <TextField
              fullWidth
              id="companyName"
              name="companyName"
              variant="standard"
              label="Company Name"
              margin="normal"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={formik.touched.companyName && Boolean(formik.errors.companyName)}
              helperText={formik.touched.companyName && formik.errors.companyName}
            />
            <TextField
              fullWidth
              id="description"
              name="description"
              variant="standard"
              label="Description"
              margin="normal"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
            <Autocomplete
              id="businessType"
              name="businessType"
              color="primary"
              forcePopupIcon={true}
              options={BUSINESS_TYPE}
              groupBy={(option) => option.category}
              getOptionLabel={(option) => option.items}
              getOptionSelected={(option) => option.category}
              value={formik.values.businessType}
              onChange={(event, value) => {
                formik.setFieldValue('businessType', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Business Type"
                  margin="normal"
                  variant="standard"
                  error={formik.touched.businessType && Boolean(formik.errors.businessType)}
                  helperText={formik.touched.businessType && formik.errors.businessType}
                />
              )}
            />
            <Autocomplete
              id="country"
              name="country"
              color="primary"
              forcePopupIcon={true}
              options={removeDup(COUNTRY_CODE.map((item) => item.name))}
              value={formik.values.country}
              onChange={(event, value) => {
                if (value) {
                  formik.setFieldValue('country', value);
                  formik.setFieldValue('city', null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country of Registration"
                  margin="normal"
                  variant="standard"
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                />
              )}
            />
            {formik.values.country && (
              <Autocomplete
                id="city"
                name="city"
                color="primary"
                forcePopupIcon={true}
                options={removeDup(
                  RegionData[getCountryCode(companyDetail.country)].map((option) => option.region)
                )}
                value={formik.values.city}
                onChange={(event, value) => {
                  formik.setFieldValue('city', value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="City of Registration"
                    margin="normal"
                    variant="standard"
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                )}
              />
            )}

            <KeyboardDatePicker
              disableToolbar
              fullWidth
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date Founded"
              InputAdornmentProps={{ position: 'end' }}
              value={formik.values.date}
              inputVariant="standard"
              onChange={(event, value) => {
                formik.setFieldValue('date', parse(value, 'dd/MM/yyyy', new Date()));
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />

            <Autocomplete
              id="numEmploy"
              name="numEmploy"
              color="primary"
              forcePopupIcon={true}
              options={['Just Me', '2-5', '5-10', '10-25', '25+']}
              value={formik.values.numEmploy}
              onChange={(event, value) => {
                formik.setFieldValue('numEmploy', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Number of Employees"
                  margin="normal"
                  variant="standard"
                  error={formik.touched.numEmploy && Boolean(formik.errors.numEmploy)}
                  helperText={formik.touched.numEmploy && formik.errors.numEmploy}
                />
              )}
            />

            <Autocomplete
              id="posInCompany"
              name="posInCompany"
              color="primary"
              forcePopupIcon={true}
              options={position_in_company.map((item, index) => item)}
              value={formik.values.posInCompany}
              onChange={(event, value) => {
                formik.setFieldValue('posInCompany', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Position in company"
                  margin="normal"
                  variant="standard"
                  error={formik.touched.posInCompany && Boolean(formik.errors.posInCompany)}
                  helperText={formik.touched.posInCompany && formik.errors.posInCompany}
                />
              )}
            />
          </Box>
          <Box display="flex" mt={5} mb={3}>
            <LongButton color="primary" variant="outlined" onClick={handleClose}>
              Cancel
            </LongButton>
            <LongButton
              color="primary"
              variant="contained"
              disabled={loading}
              type="submit"
              style={{ marginLeft: 'auto' }}
            >
              {loading ? <ABLoading /> : <div>Save</div>}
            </LongButton>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
}
