import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import DropboxChooser from 'react-dropbox-chooser';
import { ReactComponent as DropboxIcon } from '../../../../../Components/assets/dropbox.svg';
import { dropbox } from '../../../../../Consts/Config';
import { useIntegrateStore } from '../../../hooks/IntegrateStore';

const APP_KEY = dropbox.APP_KEY;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '&:hover': {
      opacity: 0.5,
      cursor: 'pointer'
    },
    border: '1px dashed ',
    padding: '20px 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));
const DropBox = React.forwardRef((props, ref) => {
  const [url, setUrl] = useState('');
  const { setCurrentFileId, setCurrentFileName } = useIntegrateStore();
  const classes = useStyles();
  function handleSuccess(files) {
    setUrl(files[0].thumbnailLink);
    const fileId = files[0].id;
    const fileName = files[0].name;
    setCurrentFileName(fileName);
    setCurrentFileId(fileId);
  }
  return (
    <DropboxChooser
      appKey={APP_KEY}
      success={handleSuccess}
      cancel={() => console.log('closed')}
      multiselect={true}
    >
      <div className={classes.wrapper}>
        <DropboxIcon width={150} />
        <Button ref={ref} variant="contained" style={{ marginTop: '20px' }}>
          Pick File
        </Button>
      </div>
    </DropboxChooser>
  );
});
export default DropBox;
