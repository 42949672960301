import { Box, Stack, Typography } from '@material-ui/core';
import { useFlag } from '@unleash/proxy-client-react';
import _ from 'lodash';
import { default as React, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  ConnectedIntegration,
  useGetIntegrationInfoQuery
} from '../../../../../generated/graphql-frontend';
import { isEmpty } from '../../../../../utils/isEmpty';
import NoMatch from '../../../../NoMatch';
import { useIntegrateStore } from '../../../hooks/IntegrateStore';
import Confirm from '../Confirm/Confirm';
import IntegraionSkeleton from '../IntegrationSkeleton/IntegrationSkeleton';
import ToolCard from './ToolCard';

interface ToolListsProps {
  searchTerm?: string;
}

export default function ToolArray({ searchTerm }: ToolListsProps) {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [currentTool, setCurrentTool] = useState<ConnectedIntegration>();
  const { company_id } = useParams<CompanyIdProps>();
  const { setCurrentProvider } = useIntegrateStore();
  const { data, loading, error } = useGetIntegrationInfoQuery({ variables: { company_id } });

  const googleToogle = useFlag('integration-google');
  const oneDriveFlag = useFlag('integration-onedrive');
  const dropboxFlag = useFlag('integration-dropbox');
  const paypalFlag = useFlag('integration-paypal');
  const shopifyFlag = useFlag('integration-shopify');
  const stripeFlag = useFlag('integration-stripe');

  const integrationFlagLists: Record<string, boolean> = {
    google: googleToogle,
    microsoft: oneDriveFlag,
    dropbox: dropboxFlag,
    shopify: shopifyFlag,
    paypal: paypalFlag,
    stripe: stripeFlag
  };
  const integrationTool = data?.user?.companies?.find(
    (company) => !isEmpty(company)
  )?.connected_integrations;

  const searchedTool = _.chain(integrationTool)
    .filter((v) => _.includes(v?.display_name?.toLowerCase(), searchTerm?.toLowerCase()))
    .value();
  const toolGroups = _.chain(searchedTool)
    .groupBy('integration_type')
    .map((value, key) => ({ type: key, images: value }))
    .value();

  if (loading) return <IntegraionSkeleton />;
  if (error) return <NoMatch notFound={false} />;
  if (!integrationTool || integrationTool.length === 0)
    return <Typography>There is no integration data</Typography>;

  const handleClick = (tool?: ConnectedIntegration) => {
    tool?.name && setCurrentProvider(tool.name);
    setCurrentTool(tool);
    setConfirmOpen(true);
  };

  return (
    <div>
      {currentTool && <Confirm open={confirmOpen} setOpen={setConfirmOpen} tool={currentTool} />}
      {toolGroups?.map((toolGroup, index) => (
        <React.Fragment key={`tool-group-${index}`}>
          <Typography color="primary" variant="subtitle1">
            <strong>{toolGroup.type}</strong>
          </Typography>
          {/* TODO: Mobile responsive */}
          <Box key={uuidv4()} my={3}>
            <Stack direction="row" spacing={10}>
              {toolGroup?.images?.map((tool) => (
                <React.Fragment key={uuidv4()}>
                  {integrationFlagLists[tool.name ?? ''] && (
                    <ToolCard
                      displayName={tool?.display_name}
                      connected={true}
                      imgURL={tool?.image_url}
                      name={tool?.name}
                      toggleToolDialog={() => handleClick(tool)}
                    />
                  )}
                </React.Fragment>
              ))}
            </Stack>
          </Box>
        </React.Fragment>
      ))}
    </div>
  );
}
