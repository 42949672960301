import { Box, TextField, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import { LongButton } from "../../../../../Components/LongButton";

export default function PropertyForm(): ReactElement {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="formText" color="textSecondary">
        What do you want to name this?
      </Typography>
      <TextField label="Name" variant="standard" placeholder="Name" />

      <Typography variant="formText" color="textSecondary">
        If you have to pay a depository
      </Typography>
      <TextField label="Deposit and costs" variant="standard" />

      <Typography variant="formText" color="textSecondary">
        What date do you start paying for this property
      </Typography>
      <TextField variant="standard" />

      <Typography variant="formText" color="textSecondary">
        How much do you pay per month
      </Typography>
      <TextField label="Amount" variant="standard" />

      <Box mt={4}>
        <LongButton type="submit" variant="contained" color="primary">
          Save
        </LongButton>
      </Box>
    </Box>
  );
}
