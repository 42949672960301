import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import notification, { callback } from './reducers';

// Use ES6 object literal shorthand syntax to define the object shape
const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    notification,
    callback
  });

export default rootReducer;
