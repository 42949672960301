export const categoryColor: CategoryColorType = {
  Sales: { colour: '#C70039' },
  'Investment Income': { colour: '#C70039' },
  'Loan Income': { colour: '#C70039' },
  'Other Income': { colour: '#C70039' },
  'Marketing/Advertising': { colour: '#C70039' },
  Transportation: { colour: '#C70039' },
  'Payroll/Salaries': { colour: '#C70039' },
  'Employee Benefits': { colour: '#C70039' },
  'Food, Drink and Entertainment': { colour: '#8264c9' },
  'Rent/Property': { colour: '#C70039' },
  Utilities: { colour: '#C70039' },
  'Office Expenses/Supplies': { colour: '#C70039' },
  'Legal Services': { colour: '#C70039' },
  'Accounting Services': { colour: '#C70039' },
  'Professional Services': { colour: '#C70039' },
  Travel: { colour: '#C70039' },
  'Research and Development': { colour: '#C70039' },
  Tax: { colour: '#C70039' },
  Insurance: { colour: '#C70039' },
  'Banking Fees': { colour: '#C70039' },
  'Training/Education': { colour: '#C70039' },
  'Product Expenses': { colour: '#F2994A' },
  'Money Transfer - Outgoing': { colour: '#C70039' },
  'Money Transfer - Incoming': { colour: '#00AD82' },
  'Tax Rebate': { colour: '#C70039' },
  'Other Expenses': { colour: '#2D9CDB' }
};
