import React, { useState } from 'react';
import { AnalysisContext } from '../../../../Hooks/AnalysisContext';

interface CategoriesProps {
  loading?: boolean;
  reset?: number;
}
export default function NumberRange({ reset = 0 }: CategoriesProps) {
  const { filter, updateFilter } = React.useContext(AnalysisContext);
  const [upperAmount, setUpperAmount] = useState<number | string>('');
  const [lowerAmount, setLowerAmount] = useState<number | string>('');

  React.useEffect(() => {
    if (reset) {
      setUpperAmount('');
      setLowerAmount('');
      let newFilter = filter;
      delete newFilter?.upper_amount;
      delete newFilter?.lower_amount;
    }
  }, [reset]);

  const handleSelect = (select_type: string, amount: number | string) => {
    if (select_type === 'upper') {
      setUpperAmount(Number(amount));
      updateFilter({ ...filter, upper_amount: Number(amount) });
    } else if (select_type === 'lower') {
      setLowerAmount(Number(amount));
      updateFilter({ ...filter, lower_amount: Number(amount) });
    }
  };
  const CashIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 text-white"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    );
  };

  return (
    <React.Fragment>
      <div className="flex flex-row space-x-4">
        <div className="flex flex-row h-16 w-full">
          <div className="date-range w-full">
            {lowerAmount === undefined ? (
              <input
                type="number"
                step="10"
                onChange={(e) => {
                  handleSelect('lower', Number(e.target.value));
                }}
                className="w-full p-4 h-full text-white border border-abx-blue focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue"
                placeholder="Lower Amount"
              />
            ) : (
              <input
                type="number"
                step="10"
                value={lowerAmount}
                onChange={(e) => {
                  handleSelect('lower', Number(e.target.value));
                }}
                className="w-full p-4 h-full text-white border border-abx-blue focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue"
                placeholder="Lower Amount"
              />
            )}
          </div>
          <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
            <CashIcon />
          </div>
        </div>
        <span className="flex items-center justify-center">to</span>
        <div className="flex flex-row h-16 w-full bg-abx-pink">
          <div className="date-range w-full">
            {upperAmount === undefined ? (
              <input
                type="number"
                //step="10"
                onChange={(e) => {
                  handleSelect('upper', Number(e.target.value));
                }}
                className="w-full p-4 h-full text-white border border-abx-blue focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue"
                placeholder="Upper Amount"
              />
            ) : (
              <input
                type="number"
                step="10"
                value={upperAmount}
                onChange={(e) => {
                  handleSelect('upper', Number(e.target.value));
                }}
                className="w-full p-4 h-full text-white border border-abx-blue focus:text-abx-dark-blue outline-none bg-abx-blue focus:bg-abx-light-blue placeholder-white focus:outline-none focus:border-2 focus:border-abx-blue focus:placeholder-abx-blue"
                placeholder="Upper Amount"
              />
            )}
          </div>
          <div className="bg-abx-blue w-12 h-full p-2 flex items-center justify-center">
            <CashIcon />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
