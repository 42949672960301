import { Box, Divider, MenuItem, TextField, Typography } from '@material-ui/core';
import { Select, SelectChangeEvent } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LongButton } from '../../../../Components/LongButton';
import { useCompanyStore } from '../../../../Context/GlobalStore';
import {
  AlertInput,
  Kpi,
  useCreateKpiAlertMutation,
  useGetKpiAlertsQuery
} from '../../../../generated/graphql-frontend';
import DescriptionTable from './DescriptionTable';

export default function Alerts({ kpi }: { kpi: Kpi }): ReactElement {
  const { company_id } = useParams<CompanyIdProps>();
  const { profile } = useCompanyStore();

  const kpi_name = kpi.name ?? '';
  const [alertInput, setAlertInput] = useState<AlertInput>({
    condition: 'less than',
    amount: 200,
    company_id,
    kpi_name
  });

  const { data, loading, error } = useGetKpiAlertsQuery({ variables: { company_id, kpi_name } });
  const [createKPIAlert, { loading: createLoading }] = useCreateKpiAlertMutation();
  if (loading) return <div>Loading ...</div>;
  if (error) return <div>Error..</div>;

  const alerts = data?.alerts;

  const handleConditionChange = (event: SelectChangeEvent) => {
    setAlertInput((prev) => ({ ...prev, condition: event.target.value as string }));
  };

  const handleCreateAlert = () => {
    if (alertInput)
      createKPIAlert({
        variables: { alertInput },
        update: (cache, createdAlert) => {
          const newAlert = createdAlert.data?.createAlert?.object;
          if (newAlert) {
            const cacheId = cache.identify(newAlert);
            cache.modify({
              fields: {
                alerts: (existingAlerts, { readField, toReference }) => {
                  if (cacheId) {
                    return existingAlerts.some(
                      (existAlert: any) => readField('kpi_name', existAlert) === newAlert.kpi_name
                    )
                      ? [...existingAlerts, toReference(cacheId)]
                      : [...existingAlerts];
                  }
                }
              }
            });
          }
        }
      });
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = event.target.value;
    setAlertInput((prev) => ({ ...prev, amount: parseFloat(amount) }));
  };

  return (
    <div>
      <Typography variant="subtitle2" color="text.secondary">
        Add Alerts
      </Typography>
      <Box display="flex" alignContent="center" alignItems="center">
        <Typography variant="formText" noWrap color="primary">
          Alert when {kpi.name} is &nbsp;{' '}
        </Typography>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={alertInput?.condition}
          defaultValue="Less than"
          onChange={handleConditionChange}
          variant="standard"
        >
          <MenuItem value="exactly">exactly</MenuItem>
          <MenuItem value="greater than">greater than</MenuItem>
          <MenuItem value="less than">less than</MenuItem>
        </Select>
        &nbsp;
        <TextField
          id="amount"
          name="amount"
          value={alertInput.amount}
          helperText=""
          margin="normal"
          style={{ marginBottom: 15, width: 50 }}
          variant="standard"
          onChange={handleAmountChange}
        />
        <Typography variant="formText" color="primary">
          {profile?.currency}
        </Typography>
        <LongButton
          color="primary"
          variant="contained"
          style={{ marginLeft: 30 }}
          onClick={handleCreateAlert}
          disabled={createLoading}
        >
          Add Alert
        </LongButton>
      </Box>
      <Divider />
      <Box mt={4}>
        <Typography variant="subtitle1" mb={8}>
          Alerts
          <span style={{ backgroundColor: '#0d8abc', padding: 5, color: 'white', borderRadius: 5 }}>
            {alerts?.length}
          </span>
        </Typography>
      </Box>
      {alerts ? <DescriptionTable alertList={alerts} /> : <div>There is no alert list</div>}
    </div>
  );
}
