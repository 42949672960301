export const CATEGORIES = [
  "Sales",
  "Investment Income",
  "Other Income",
  "Marketing/Advertising",
  "Transportation",
  "Payroll/Salaries",
  "Employee Benefits",
  "Food, Drink and Entertainment",
  "Rent/Utilities",
  "Office Expenses/Supplies",
  "Legal Services",
  "Accounting Services",
  "Professional Services",
  "Travel",
  "Research and Development",
  "Tax",
  "Insurance",
  "Other Expenses",
];

export const recurring = [
  "none",
  "daily",
  "weekly",
  "monthly",
  "quarterly",
  "annually",
  "one-Off",
];
