import { Box, Skeleton } from "@material-ui/core";
import React from "react";

export default function BankTableSkeleton() {
  return (
    <div>
      <Box my={2}></Box>
      <Skeleton variant="rectangular" height={30} width={300} />
      <Box my={2}></Box>
      <Skeleton variant="rectangular" height={300} />
    </div>
  );
}
