export const position_in_company = [
  "Founder",
  "Director",
  "IT Professional",
  "Marketing / Sales",
  "Developer / Engineer",
  "HR Professional",
  "Finance / Accounting",
  "Legal Professional",
  "Customer Service",
  "Administrative Assistant",
  "Other",
];
