import { Box } from '@material-ui/core';
import React, { ReactElement } from 'react';
import ABTypography from '../../../Components/ABTypography';
import ArrowIcon from '../../../Components/ArrorIcon';
import { useCompanyStore } from '../../../Context/GlobalStore';

interface Props {
  headerString?: string;
  price?: number;
  currency?: string;
  percentage?: number;
  firstDescription?: string;
  secondDescription?: string;
}

export default function SummaryCard({
  headerString,
  price,
  percentage,
  firstDescription,
  secondDescription
}: Props): ReactElement {
  const { profile } = useCompanyStore();
  return (
    <Box bgcolor="#F6F6F9" padding={4} borderRadius={4}>
      <ABTypography>{headerString}</ABTypography>
      <Box display="flex" alignItems="center" my={5}>
        <ABTypography variant="subtitle1">
          {price}
          {profile?.currency}
        </ABTypography>
        <ABTypography variant="subtitle2" abColor="success" mr={2} ml={2}>
          {percentage}%
        </ABTypography>
        <ArrowIcon width="100%" height="100%" fontSize="small" />
      </Box>

      <Box>
        <ABTypography>
          {firstDescription} <strong>{secondDescription}</strong>{' '}
        </ABTypography>
      </Box>
    </Box>
  );
}
